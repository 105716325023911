import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, ListGroup } from "react-bootstrap";
import { ModalBasic } from "../../../../Common/ModalBasic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { PlanGratuitoFrom } from "../../PlanGratuito";
import { useCatalagos } from "../../../../../hooks";
import { Image } from "react-bootstrap";
import { initAOS } from '../../../../Common/AOS';

import transferencia_adopcion from "../../../../../images/transferencia_adopcion.svg"
import emprender_acelerar from "../../../../../images/emprender_acelerar.svg"
import medio_ambiente from "../../../../../images/medio_ambiente.svg"
import bubble_chart from "../../../../../images/bubble_chart.svg";
import crisis_alert from "../../../../../images/crisis_alert.svg";
import add_business from "../../../../../images/add_business.svg";
import psychology from "../../../../../images/psychology.svg";
import model_training from "../../../../../images/model_training.svg";
import batch_prediction from "../../../../../images/batch_prediction.svg";
import local_shipping from "../../../../../images/local_shipping.svg";
import diversity_3 from "../../../../../images/diversity_3.svg";
import flip_camera_android from "../../../../../images/flip_camera_android.svg";

import "./Oferta.scss";

export function Oferta() {
  const [showModal, setshowModal] = useState(false);
  const [iconModal, setIconModal] = useState(null);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("sm");
  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);
  const tipoCliente = ["Gratuito", "Basico", "Premium"];

  const { getSectores, sectores, getPaises, paises } = useCatalagos();

  useEffect(() => {
    getSectores();
    getPaises();
    initAOS();
  }, [refetch]);

  const addClienteFree = () => {
    setTitleModal(" Alta Plan Gratis");
    setIconModal(faUserPlus);
    setsizeModal("xl");
    setContentModal(
      <PlanGratuitoFrom
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        paises={paises}
        tipoCliente={tipoCliente[0]}
        plan={1}
      />
    );
    openCloseModal();
  };

  const addClienteBasico = () => {
    setTitleModal(" Alta Plan Basico");
    setIconModal(faUserPlus);
    setsizeModal("xl");
    setContentModal(
      <PlanGratuitoFrom
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        paises={paises}
        tipoCliente={tipoCliente[1]}
        plan={2}
      />
    );
    openCloseModal();
  };

  const addClientePremium = () => {
    setTitleModal(" Alta Plan Premium");
    setIconModal(faUserPlus);
    setsizeModal("xl");
    setContentModal(
      <PlanGratuitoFrom
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        paises={paises}
        tipoCliente={tipoCliente[2]}
        plan={3}
      />
    );
    openCloseModal();
  };

  return (
    <div className="oferta my-4">
      <Row className="justify-content-center mx-5">

        <Col className="card-planes animacionSomn_arriba">
          <Card className="text-center h-100 card-planes">
            <Card.Body className="d-flex flex-column">
              <Card.Title className="oferta_titleA">Gratuito</Card.Title>
              <Card.Subtitle className="mb-2 oferta_sbtitleA">
                Cuenta Gratuíta
              </Card.Subtitle>
              <ul>
                <li>Acceso al menú recursos </li>
                <li>Boletín pdf mensual por correo electrónico</li>
              </ul>
              <Button
                onClick={addClienteFree}
                className="mt-auto oferta_botonA"
              >
                Seleccionar plan
              </Button>
            </Card.Body>
            <Card.Footer className="card-footer">Pago Gratuito</Card.Footer>
          </Card>
        </Col>

        <Col className="card-planes animacionSomn_arriba">
          <Card className="text-center h-100 card-planes">
            <Card.Body className="d-flex flex-column">
              <Card.Title className="oferta_titleB">Básico</Card.Title>
              <Card.Subtitle className="mb-2 oferta_sbtitleB">
                $500 / Anual / MXN
              </Card.Subtitle>
              <ul>
                <li>Membresia gratuíta</li>
                <li>
                  Catálogos de estudios de inteligencia proporcionados por OTS
                </li>
                <li>
                  Acceso a proveedores de bienes y servicios para consumo o
                  venta
                </li>
                <li>
                  Bases de datos / Minería de datos{" "}
                  <span className="text-success">(1 cada semestre)</span>
                </li>
                <li>
                  Vigilancia tecnológica especializada{" "}
                  <span className="text-success">(15% de descuento)</span>
                </li>
                <li>
                  Mapa tecnológico{" "}
                  <span className="text-success">(1 vez por semestre)</span>
                </li>
                <li>
                  Estudios de mercado{" "}
                  <span className="text-success">(15% de descuento)</span>
                </li>
                <li>
                  Inteligencia competitiva{" "}
                  <span className="text-success">
                    (15% de descuento trámite)
                  </span>
                </li>
                <li>
                  Gestión de propiedad intelectual{" "}
                  <span className="text-success">(1 al año)</span>
                </li>
                <li>
                  Trámites de registro de propiedad intelectual{" "}
                  <span className="text-success">
                    (15% de descuento trámite)
                  </span>
                </li>
                <li>
                  Estado del arte{" "}
                  <span className="text-success">
                    (15% de descuento trámite)
                  </span>
                </li>
              </ul>
              <Button
                onClick={addClienteBasico}
                className="mt-auto oferta_botonB"
              >
                Seleccionar plan
              </Button>
            </Card.Body>
            <Card.Footer className="card-footer">Pago anual</Card.Footer>
          </Card>
        </Col>

        <Col className="card-planes animacionSomn_arriba">
          <Card className="text-center h-100 card-planes">
            <Card.Body className="d-flex flex-column ">
              <Card.Title className="oferta_titleC">Premium</Card.Title>
              <Card.Subtitle className="mb-2 oferta_sbtitleC">
                $1,000 / Anual / MXN
              </Card.Subtitle>
              <ul>
                <li>Membresia básica</li>
                <li>Perfilamiento y colocación de talento especializado</li>
                <li>Webinars sobre tendencias e inteligencia de negocio</li>
                <li>
                  Bases de datos / Minería de datos{" "}
                  <span className="text-success">(2 cada semestre)</span>
                </li>
                <li>
                  Vigilancia tecnológica especializada{" "}
                  <span className="text-success">(30% de descuento)</span>
                </li>
                <li>
                  Mapa tecnológico{" "}
                  <span className="text-success">(2 veces por semestre)</span>
                </li>
                <li>
                  Estudios de mercado{" "}
                  <span className="text-success">(30% de descuento)</span>
                </li>
                <li>
                  Inteligencia competitiva{" "}
                  <span className="text-success">
                    (30% de descuento tramite)
                  </span>
                </li>
                <li>
                  Gestión de propiedad intelectual{" "}
                  <span className="text-success">(2 al año)</span>
                </li>
                <li>
                  Trámites de registro de propiedad intelectual{" "}
                  <span className="text-success">
                    (30% de descuento trámite)
                  </span>
                </li>
                <li>
                  Estado del arte{" "}
                  <span className="text-success">
                    (30% de descuento trámite)
                  </span>
                </li>
              </ul>
              <Button
                onClick={addClientePremium}
                className="mt-auto oferta_botonC"
              >
                Seleccionar plan
              </Button>
            </Card.Body>
            <Card.Footer className="card-footer">Pago anual</Card.Footer>
          </Card>
        </Col>

      </Row>
    
      <Row className="my-4 mx-5">
        <Col data-aos="zoom-in" data-aos-delay="200" >
          <Card className="h-100">
            <Card.Body className="container_oferta_titleD">
              <Card.Title className="text-center oferta_titleD">
                Servicios Tecnológicos
              </Card.Title>

              <Card.Text className="oferta_titleE">
                Consiste en la combinación de tecnología, herramientas y
                procesos que permiten transformar los datos almacenados en
                información, esta información en conocimiento y este
                conocimiento dirigido a un plan o una estrategia comercial.
              </Card.Text>

              <Card.Text className="oferta_titleE">
                La inteligencia de negocios debe ser parte de la estrategia
                empresarial, esta le permite optimizar la utilización de
                recursos, monitorear el cumplimiento de los objetivos de la
                empresa y la capacidad de tomar buenas decisiones para así
                obtener mejores resultados
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    
      <Row xs={1} md={2} className="g-4 mx-5">
        
        <Col className="animacionSomn_derecha" data-aos="zoom-in-right" >
          <Card className="text container-cards h-100 ">
            <Card.Body >
              <Image className="imagenes" src={bubble_chart} />
              <Card.Title className="oferta_titleG">
                Minería de datos
              </Card.Title>

              <Card.Text>
                Proceso técnico, automático o semiautomático, que analiza
                grandes cantidades de información dispersa para darle sentido y
                convertirla en conocimiento, combina estadística. Inteligencia
                Artificial y aprendizaje automático, las empresas pueden crear
                modelos para descubrir conexiones entre millones de registros.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>

              <Card.Text>
                Marketing, comercio minorista, banca, medicina, televisión o
                radio.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col className="animacionSomn_izquierda" data-aos="zoom-in-left">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={flip_camera_android} />
              <Card.Title className="oferta_titleG">
                Vigilancia tecnológica
              </Card.Title>
              <Card.Text>
                Identificación, evaluación y uso de señales débiles para
                reconocer y advertir en una fase temprana, tecnologías
                emergentes, discontinuidades tecnológicas (innovaciones
                disruptivas o rupturistas), oportunidades y amenazas.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Rastreo de innovaciones, oportunidades tecnológicas, rastreo de
                patentes, tendencias.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col className="animacionSomn_izquierda" data-aos="zoom-in-right">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={crisis_alert} />
              <Card.Title className="oferta_titleG">
                Mapa tecnológico
              </Card.Title>
              <Card.Text>
                Es una herramienta que sintetiza de forma gráfica o en
                secuencias gráficas, aquellas tecnologías o sub-tecnologías
                sobre las que se está investigando más. Mide el esfuerzo
                investigador de unas tecnologías frente a otras y su evolución
                en un determinado periodo de tiempo.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Mide la evolución tecnológica de algún sector, provee datos
                concretos sobre patentes y ámbitos de protección.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="animacionSomn_derecha" data-aos="zoom-in-left">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={medio_ambiente} />
              <Card.Title className="oferta_titleG">
                Medio Ambiente
              </Card.Title>
              <Card.Text>
                Servicio integral en medio ambiente abarcamos la gestión de residuos, 
                conservación de recursos, evaluación de impacto, educación ambiental 
                y desarrollo sostenible para promover sostenibilidad y protección 
                ambiental.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Accede a los bonos verdes y azules, nosotros te ayudamos
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="animacionSomn_izquierda" data-aos="zoom-in-left">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={emprender_acelerar} />
              <Card.Title className="oferta_titleG">
                Emprender y Acelerar.
              </Card.Title>
              <Card.Text>
                Servicios especializados para ayudar a startups 
                y empresas a crecer y alcanzar su máximo potencial 
                en el mercado actual.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Tu idea al mercado
                Tu problema nuestro reto
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="animacionSomn_derecha" data-aos="zoom-in-left">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={transferencia_adopcion} />
              <Card.Title className="oferta_titleG">
                Transferencia y Adopción del conocimiento.
              </Card.Title>
              <Card.Text>
                Ofrecemos asesoramiento y apoyo en la transferencia 
                y adopción de tecnología para mejorar la eficiencia 
                y competitividad de su empresa.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Tu conocimiento tiene mercado y tu problema tiene solución.
                Valuación y comercialización del conocimiento.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="animacionSomn_izquierda" data-aos="zoom-in-left">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={add_business} />
              <Card.Title className="oferta_titleG">
                Estudios de mercado
              </Card.Title>
              <Card.Text>
                Este tipo de estudio es especialmente útil para analizar
                aspectos como hábitos de compra, región, de operación,
                requerimientos de productos o análisis de las competencias para
                asegurar el buen desempeño del negocio.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Cuando desea implementar una nueva línea de negocio, un nuevo
                servicio o producto.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col className="animacionSomn_derecha" data-aos="zoom-in-right">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={psychology} />
              <Card.Title className="oferta_titleG">
                Inteligencia competitiva
              </Card.Title>
              <Card.Text>
                Investigación en la que se identifican hechos y evidencias
                valiosas para la competitividad de la organización, y se
                determinan acciones a seguir, a partir de la detección de los
                movimientos estratégicos, presentes o futuros del entorno.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Cualquier empresa que requiera analizar a sus competidores.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col className="animacionSomn_izquierda" data-aos="zoom-in-left">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={model_training} />
              <Card.Title className="oferta_titleG">
                Gestión de la propiedad intelectual
              </Card.Title>
              <Card.Text>
                Consiste en la búsqueda de patentes con alcance nacional
                internacional.
              </Card.Text>
              <Card.Text>
                Tramites con respecto a patentes, invenciones o secretos
                industriales
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Persona física moral que quiera concluir su prototipo o acceder
                a recursos para patentarlo.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col className="animacionSomn_derecha" data-aos="zoom-in-right">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={batch_prediction} />
              <Card.Title className="oferta_titleG">
                Estados del arte
              </Card.Title>

              <Card.Text>
                El estado del arte proviene originalmente del campo de la
                investigación técnica, científica e industrial y significa, en
                pocas palabras, la situación de una determinada tecnología. Lo
                más innovador o reciente con respeto a un arte especifico.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Empresas o proyectos de deseen fundamentar de manera técnica y
                documental alguna tecnología
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col className="animacionSomn_izquierda" data-aos="zoom-in-left">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={local_shipping} />
              <Card.Title className="oferta_titleG"> Proveedores </Card.Title>
              <Card.Text>
                Calificamos y perfilamos proveedores a nivel nacional para
                sugerirlos en acciones o líneas de negocio determinas.
              </Card.Text>
              <Card.Text>
                Una vez filtrados proporcionamos esta información para que pueda
                gestionar y mejorar su cadena de valor
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Persona física o moral que requiera la evaluación de un
                proveedor.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col className="animacionSomn_derecha" data-aos="zoom-in-right">
          <Card className=" container-cards text h-100 ">
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Image className="imagenes" src={diversity_3} />
              <Card.Title className="oferta_titleG">
                Recurso humano especializado
              </Card.Title>
              <Card.Text>
                Evaluamos diversos perfiles de alta especialidad a través de sus
                competencias y habilidades, para proveer a las pymes Capital
                Humano de alto valor.
              </Card.Text>

              <Card.Title className="oferta_titleF">
                ¿Dónde la puede usar?
              </Card.Title>
              <Card.Text>
                Rastreo de innovaciones, oportunidades tecnológicas, rastreo de
                patentes, tendencias.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </div>
  );
}
