import { ExtraLayout } from "../layouts";
import { updatePass } from "../pages/Extras";

const routesExtras = [
  {
    path: "/recovery/:id/:otp",
    layout: ExtraLayout,
    component: updatePass,
  },
];

export default routesExtras;
