import { AdminLayout } from "../layouts";
import {
  AlianzasAdmin,
  AyudaAdmin,
  AyudaCambio,
  AyudaNavegacion,
  AyudaPagos,
  AyudaServicio,
  Boletin,
  Carousel,
  CatalogosB2B,
  ChatIA,
  Colaboradores,
  ContactanosAdmin,
  EquipoOTSAdmin,
  EstudiosInteligenciaCliente,
  EstudiosInteligenciaOperativo,
  Eventos,
  ExperienciaClienteAdmin,
  HomeAdmin,
  Indicadores,
  MapCluster,
  Noticias,
  PageB2b,
  ProductMenu,
  ProveedoresBienesServiciosCliente,
  ProveedoresBienesServiciosOperativo,
  QuestionsAdmin,
  RecursoDetalle,
  RecursosAdmin,
  RecursosClient,
  RequestClient_Admin,
  RequestClient_Client,
  RequestClient_Colaborador,
  RequestClient_Operativo,
  SectoresNoticias,
  ServiciosLicenciamientoCliente,
  ServiciosLicenciamientoOperativo,
  TalentoEspecializadoCliente,
  TalentoEspecializadoOperativo,
  UsersAdmin,
  WebinarsCliente,
  WebinarsOperativo
} from "../pages/Admin";

import { SoporteAdmin, SoporteCRMAdmin, SoporteS } from "../pages/Admin/Soporte";

const routesAdmin = [
  {
    path: "/admin",
    layout: AdminLayout,
    component: HomeAdmin,
  },
  {
    path: "/admin/users",
    layout: AdminLayout,
    component: UsersAdmin,
  },
  {
    path: "/admin/noticias",
    layout: AdminLayout,
    component: Noticias,
  },
  {
    path: "/admin/colaboradores",
    layout: AdminLayout,
    component: Colaboradores,
  },
  {
    path: "/admin/indicadores",
    layout: AdminLayout,
    component: Indicadores,
  },
  {
    path: "/admin/editorCarousel",
    layout: AdminLayout,
    component: Carousel,
  },
  {
    path: "/admin/eventos",
    layout: AdminLayout,
    component: Eventos,
  },
  {
    path: "/admin/recursos-admin",
    layout: AdminLayout,
    component: RecursosAdmin,
  },
  {
    path: "/admin/recursos-client",
    layout: AdminLayout,
    component: RecursosClient,
  },
  {
    path: "/admin/recursos-client/detalle-recurso/:id",
    layout: AdminLayout,
    component: RecursoDetalle,
  },
  {
    path: "/admin/solicitudCliente",
    layout: AdminLayout,
    component: RequestClient_Client,
  },
  {
    path: "/admin/solicitudCliente-admin",
    layout: AdminLayout,
    component: RequestClient_Admin,
  },
  {
    path: "/admin/asignacionesInvestigacion",
    layout: AdminLayout,
    component: RequestClient_Colaborador,
  },
  {
    path: "/admin/solicitudCliente-operativo",
    layout: AdminLayout,
    component: RequestClient_Operativo,
  },
  {
    path: "/admin/boletin",
    layout: AdminLayout,
    component: Boletin,
  },
  {
    path: "/admin/equipo-ots",
    layout: AdminLayout,
    component: EquipoOTSAdmin,
  },
  {
    path: "/admin/alianzas",
    layout: AdminLayout,
    component: AlianzasAdmin,
  },
  {
    path: "/admin/questions",
    layout: AdminLayout,
    component: QuestionsAdmin,
  },
  {
    path: "/admin/ayuda",
    layout: AdminLayout,
    component: AyudaAdmin,
  },
  {
    path: "/admin/ayuda/servicio",
    layout: AdminLayout,
    component: AyudaServicio,
  },
  {
    path: "/admin/ayuda/navegacion",
    layout: AdminLayout,
    component: AyudaNavegacion,
  },
  {
    path: "/admin/ayuda/pagos",
    layout: AdminLayout,
    component: AyudaPagos,
  },
  {
    path: "/admin/ayuda/cambioplan",
    layout: AdminLayout,
    component: AyudaCambio,
  },

  {
    path: "/admin/contactanos",
    layout: AdminLayout,
    component: ContactanosAdmin,
  },
  {
    path: "/admin/soporteNavegacaion",
    layout: AdminLayout,
    component: SoporteAdmin,
  },
  {
    path: "/admin/soporte/crm",
    layout: AdminLayout,
    component: SoporteCRMAdmin,
  },
  {
    path: "/admin/soporte/soporte",
    layout: AdminLayout,
    component: SoporteS,
  },
  {
    path: "/admin/serviciosLicenciamiento",
    layout: AdminLayout,
    component: ServiciosLicenciamientoCliente,
  },
  {
    path: "/admin/serviciosLicenciamiento/EstudiosInteligencia",
    layout: AdminLayout,
    component: EstudiosInteligenciaCliente,
  },
  {
    path: "/admin/serviciosLicenciamiento/ProveedoresBienesServicios",
    layout: AdminLayout,
    component: ProveedoresBienesServiciosCliente,
  },
  {
    path: "/admin/serviciosLicenciamiento/TalentoEspecializado",
    layout: AdminLayout,
    component: TalentoEspecializadoCliente,
  },
  {
    path: "/admin/serviciosLicenciamiento/Webinars",
    layout: AdminLayout,
    component: WebinarsCliente,
  },
  {
    path: "/admin/serviciosLicenciamiento/EstudiosInteligenciaOperativo",
    layout: AdminLayout,
    component: EstudiosInteligenciaOperativo,
  },
  {
    path: "/admin/serviciosLicenciamiento/ProveedoresBienesServiciosOperativo",
    layout: AdminLayout,
    component: ProveedoresBienesServiciosOperativo,
  },
  {
    path: "/admin/serviciosLicenciamientoOperativo",
    layout: AdminLayout,
    component: ServiciosLicenciamientoOperativo,
  },
  {
    path: "/admin/serviciosLicenciamiento/TalentoEspecializadoOperativo",
    layout: AdminLayout,
    component: TalentoEspecializadoOperativo,
  },
  {
    path: "/admin/serviciosLicenciamiento/WebinarsOperativo",
    layout: AdminLayout,
    component: WebinarsOperativo,
  },
  {
    path: "/admin/mapCluster",
    layout: AdminLayout,
    component: MapCluster,
  },
  {
    path: "/admin/experienciaCliente",
    layout: AdminLayout,
    component: ExperienciaClienteAdmin,
  },
  {
    path: "/admin/b2b",
    layout: AdminLayout,
    component: PageB2b,
  },
  {
    path: "/admin/catalogosb2b",
    layout: AdminLayout,
    component: CatalogosB2B,
  },

  {
    path: "/admin/productMenu",
    layout: AdminLayout,
    component: ProductMenu,
  },
  {
    path: "/admin/sectoresNoticias",
    layout: AdminLayout,
    component: SectoresNoticias,
  },
  {
    path: "/admin/chatIA",
    layout: AdminLayout,
    component: ChatIA,
  },
];

export default routesAdmin;
