import { BASE_API } from "../utils/constants";

export async function getRecursosApi(token){
    try{
        const url = `${BASE_API}/api/recursos/formatoRecurso-recurso/`;
        const params = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch(error){
        throw error;
    }
}

export async function getRecursosInvestigacionApi(idUser, token){
    try{
        console.log(idUser);
        const url = `${BASE_API}/api/recursos/formatoRecurso-investigaciones/${idUser}/`;
        const params = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch(error){
        throw error;
    }
}

export async function getRecursosIdApi(id){
    try{
        const url = `${BASE_API}/api/recursos/formatoRecursoID/${id}/`;
        const params = {
            method: "GET",
            headers: {
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch(error){
        throw error;
    }
}

export async function addRecursoApi(data, tipoDocumento, token){
    try{
        const formData = new FormData();
        formData.append('titulo', data.titulo);
        formData.append('catalogo', data.catalogo);
        formData.append('descripcionTotal', data.descripcionTotal);
        formData.append('tipoDeinvestigacion', tipoDocumento);
        formData.append('tipoRecurso', data.tipoRecurso);
        if(data.link) formData.append('link', data.link);
        if(data.imagen) formData.append('imagen', data.imagen);
        if(data.documento) formData.append('documento', data.documento);
        const url = `${BASE_API}/api/recursos/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };

        const response = await fetch(url, params);
        const result = await response.json();
        return result;

    }catch(error){
        throw error;
    }
}

export async function updateRecursosApi(id, data, tipoDocumento, token){
    try {
        const formData = new FormData();
        formData.append('titulo', data.titulo);
        formData.append('catalogo', data.catalogo['id'] ? data.catalogo['id'] : data.catalogo);
        formData.append('descripcionTotal', data.descripcionTotal);
        formData.append('tipoRecurso', data.tipoRecurso);
        formData.append('tipoDeinvestigacion', tipoDocumento)
        if(data.link) formData.append('link', data.link);
        if(data.imagen) formData.append('imagen', data.imagen);
        if(data.documento) formData.append('documento', data.documento);
        const url = `${BASE_API}/api/recursos/${id}/`;
        const params= {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function deleteRecursosApi(id, token){
    try {
        const url = `${BASE_API}/api/recursos/${id}/`;
        const params= {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },

        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

