import React from "react";
import Button from "react-bootstrap/Button";
import { useNoticia } from "../../../../hooks";
import "./DeleteNewsForm.scss";

export function DeleteNewsForm(props) {
  const { onClose, onRefetch, noticia } = props;
  const { deleteNew } = useNoticia();

  async function delNew() {
    try {
      await deleteNew(noticia.id);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h3 className="title-spacing">¿Está seguro de eliminar la noticia?</h3>
      <div className="delete-z-form">
        <Button className="btnaling" onClick={delNew}>
          Eliminar
        </Button>
        <Button className="btnaling" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}
