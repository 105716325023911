import {
  faCircleCheck,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { DividerIcon, ModalBasic, ModalError } from "../../../../components/Common";

import { ConteinerCards } from '../../../../components/b2b';
import { TablaUbicacionesMesas } from "../../../../components/b2b/admin";
import { useB2B } from "../../../../hooks";

import "./AgendasB2B.scss";

export function AgendasB2B() {
  const {
    loading, getUsersWebSite,
    userData, postGenerarAgenda,
    getAgendaAdmin, agenda
  } = useB2B();
  const [contentModal, setContentModal] = useState(undefined);
  const [showModal, setshowModal] = useState(false);
  const [icon, setIcon] = useState(undefined);
  const [title, setTitle] = useState(undefined);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    const getData = async () => {
      await getUsersWebSite(1);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    const getData = async () => {
      if (userData)
        await getAgendaAdmin(userData[0].id_micrositio_cliente.id);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const generarAgenda = async () => {
    const data = userData.find(usuario => (usuario.mesa_asignada === null || usuario.mesa_asignada === 0))
    if (data) {
      setIcon(faExclamationTriangle)
      setTitle("Error en la generación de la agenda")
      setContentModal(
        <ModalError
          tipoError="Faltan mesas por asignar"
          onClose={openCloseModal}
          icon={faExclamationTriangle}
          color="#F0DE36"
        />
      )
    } else {
      await postGenerarAgenda().then(() => {
        setIcon(faCircleCheck)
        setTitle("Agenda Generada con éxito")
        setContentModal(
          <ModalError
            tipoError="Mesas asignadas con éxito"
            onClose={openCloseModal}
            icon={faCircleCheck}
            color="#C5FF95"
          />
        )
        onRefetch()
      })
    }
    openCloseModal()
  }


  return (
    <div className="conteinerPrincipal">
      <DividerIcon titulo="Administrar ubicaciones de mesas" />
      {!loading ? (
        <TablaUbicacionesMesas loading={loading} usuarios={userData} onRefetch={onRefetch} />
      ) : (
        <div>algo</div>
      )}
      {agenda && agenda?.length !== 0
        ?
        <div>
          <DividerIcon titulo="Gente registrada en la agenda" />
          <ConteinerCards data={agenda} />
        </div>
        :
        <center>
          <Button style={{ width: 250 }} onClick={() => generarAgenda()}>
            Generar agenda
          </Button>
        </center>}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={icon}
        title={title}
        children={contentModal}
        size="md"
      />
    </div>
  );
}
