import React, { useEffect } from 'react'
import * as Yup from "yup";
import { map } from "lodash";
import { useFormik } from "formik";
import { Form, Button, Row, Badge } from 'react-bootstrap';

import { formatDateMonthName } from '../../../../../metodosGlobales';
import { useRequestClient, useColaborador, useSolicitudServicios } from '../../../../../hooks';
import { IngresaDatosForm } from '../../../../Generales';
import "./EditRequestClient.scss"

export function FormRequestClientAdmin(props) {

  const {
    onClose, onRefetch, service,
    alcanceGeografico, status, operativos } = props;
  const {
    serviciosPorMembresia, getServiciosPorMembresia,
    editReporteAdmin,
  } = useRequestClient()
  const { editServicioGeneralAdmin } = useSolicitudServicios();
  const { colaboradores, getColaboradores } = useColaborador();

  useEffect(() => {
    const fetchData = async () => {
      await getServiciosPorMembresia();
      await getColaboradores();
    };
    fetchData()
  }, []);

  const formik = useFormik({
    initialValues: initialValues(service),
    //validationSchema: newSchema(),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      await editReporteAdmin(formvalue, service.id)
      await editServicioGeneralAdmin(formvalue, service.idSolicitudServicio.id)
      onRefetch();
      onClose();
    }
  });

  return (
    <>
      <Form className='formRequestService' onSubmit={formik.handleSubmit}>
        <IngresaDatosForm
          tituloLabel="Datos generales del cliente"
          formatoLabel="tituloCentrado"
        />
        <br />
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-12 col-sm-8 col-lg-6">
            <IngresaDatosForm
              tituloLabel="Nombre del cliente"
              formatoLabel="tituloCentrado"
            />
            <Form.Control
              placeholder={`${service.idSolicitudServicio.idCliente.user['nombre']} ${service.idSolicitudServicio.idCliente.user['apellido_paterno']} ${service.idSolicitudServicio.idCliente.user['apellido_materno']} `}
              disabled
            />
          </Form.Group>
          <Form.Group widths="equal" className="col-6 col-sm-4 col-lg-3">
            <IngresaDatosForm
              tituloLabel="Sector de interés"
              formatoLabel="tituloCentrado"
            />
            <Form.Control placeholder={service.idSolicitudServicio.sectores['descripcion']} disabled />

          </Form.Group>
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-3">
            <IngresaDatosForm
              tituloLabel="Fecha de alta del reporte"
              formatoLabel="tituloCentrado"
            />
            <Form.Control
              placeholder={formatDateMonthName(service.idSolicitudServicio.fechaAlta)}
              disabled
            />
          </Form.Group>
        </Row>
        <br />
        <IngresaDatosForm
          tituloLabel="Información inicial del reporte"
          formatoLabel="tituloCentrado"
        />
        <br />

        <Row className="mb-6">
          <Form.Group widths="equal" className="col-12 col-sm-12 col-lg-6">
            <IngresaDatosForm
              tituloLabel="Objetivo del reporte"
              formatoLabel="tituloCentrado"
            />
            <Form.Control
              placeholder={service.idSolicitudServicio.objetivoServicio}
              disabled
            />
          </Form.Group>
          <Form.Group widths="equal" className="col-12 col-sm-12 col-lg-6">
            <IngresaDatosForm
              tituloLabel="Indicadores"
              formatoLabel="tituloCentrado"
            />
            {map(service.idSolicitudServicio.indicadores, (indicador, index) => (
              <span className="etiqueta" key={index}>
                <Badge bg="success" key={index}>{indicador}</Badge>
              </span>
            ))}
          </Form.Group>
        </Row>

        <br />

        <IngresaDatosForm
          tituloLabel="Datos adicionales del reporte"
          formatoLabel="tituloCentrado"
        />
        <br />

        <Row>
          <Form.Group widths="equal" className="col-4 col-sm-4 col-lg-3">
            <IngresaDatosForm
              tituloLabel="Tipo de servicio"
              formatoLabel="tituloCentrado"
            />
            <Form.Select
              name="tipoServicio"
              defaultValue={formik.values.tipoServicio}
              onChange={formik.handleChange}
            >
              {map(serviciosPorMembresia, (servicio, index) => (
                <option key={index} value={servicio['id']}>{servicio['nombre']}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group widths="equal" className="col-4 col-sm-4 col-lg-3">
            <IngresaDatosForm
              tituloLabel="Horas estimadas"
              formatoLabel="tituloCentrado"
            />
            <Form.Select
              name="horasEstimadas"
              defaultValue={formik.values.horasEstimadas}
              onChange={formik.handleChange}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
            </Form.Select>
          </Form.Group>
          <Form.Group widths="equal" className="col-4 col-sm-4 col-lg-3">
            <IngresaDatosForm
              tituloLabel="Estatus Reporte"
              formatoLabel="tituloCentrado"
            />
            <Form.Select
              name="estatus"
              defaultValue={formik.values.estatus}
              onChange={formik.handleChange}
            >
              {map(status, (estatuss, index) => (
                <option key={index} value={estatuss['id']}>{estatuss['nombre']}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-3">
            <IngresaDatosForm
              tituloLabel="Alcance Geográfico"
              formatoLabel="tituloCentrado"
            />
            <Form.Select
              name="alcanceGeografico"
              defaultValue={formik.values.alcanceGeografico}
              onChange={formik.handleChange}
            >
              {map(alcanceGeografico, (alcance, index) => (
                <option key={index} value={alcance['id']}>{alcance['nombre']}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-3">
            <IngresaDatosForm
              tituloLabel="Tipo de reporte"
              formatoLabel="tituloCentrado"
              nameControl="tipoReporte"
              type="text"
              placeHolderControl="Qué tipo de reporte es"
              valueControl={formik.values.tipoReporte}
              onChangeControl={formik.handleChange}
            />
          </Form.Group>
        </Row>
        <br />
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-4 col-sm-3 col-lg-4">
            <Form.Label className='titulo2'>Titulo del reporte:</Form.Label>
          </Form.Group>
          <Form.Group widths="equal" className="col-8 col-sm-9 col-lg-8">
            <Form.Control
              name="nombreReporte"
              placeholder="Ingresa el título del reporte"
              value={formik.values.nombreReporte}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Row>
        <br />
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-4 col-sm-3 col-lg-4">
            <Form.Label className='titulo2'>Requerimientos importantes:</Form.Label>
          </Form.Group>
          <Form.Group widths="equal" className="col-8 col-sm-9 col-lg-8">
            <Form.Control
              name="requerimientos"
              placeholder="Ingresa los requermientos más importantes a cumplir para la investigación"
              value={formik.values.requerimientos}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Row>
        <br />
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-4 col-sm-3 col-lg-4">
            <Form.Label className='titulo2'>Temas a desarrollar.</Form.Label>
          </Form.Group>
          <Form.Group widths="equal" className="col-8 col-sm-9 col-lg-8">
            <Form.Control
              name="temasDesarrollar"
              placeholder="Ingresa los temas más importantes a desarrollar para la investigación"
              value={formik.values.temasDesarrollar}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Row>
        <br />
        <IngresaDatosForm
          tituloLabel="Asignar colaborador y operativo responsable del reporte:"
          formatoLabel="tituloCentrado"
        />
        <br />
        <Row>
          <Form.Group widths="equal" className="col-6 col-sm-6">
            <IngresaDatosForm
              tituloLabel="Asigna al colaborador"
              formatoLabel="tituloCentrado"
            />
            <Form.Select
              name="idColaborador"
              defaultValue={formik.values.idColaborador}
              onChange={formik.handleChange}
            >
              <option>Selecciona un colaborador</option>
              {map(colaboradores, (colaborador, index) => (
                <option key={index} value={colaborador.id_colaborador.user['id']}>{
                  `${colaborador.id_colaborador.user['nombre']} ${colaborador.id_colaborador.user['apellido_paterno']} ${colaborador.id_colaborador.user['apellido_materno']} `
                }</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group widths="equal" className="col-6 col-sm-6">
            <IngresaDatosForm
              tituloLabel="Asigna al Operativo"
              formatoLabel="tituloCentrado"
            />
            <Form.Select
              name="idOperativo"
              defaultValue={formik.values.idOperativo}
              onChange={formik.handleChange}
            >
              <option>Selecciona un operativo</option>
              {map(operativos, (operativo, index) => (
                <option key={index} value={operativo['id']}>{
                  `${operativo['nombre']} ${operativo['apellido_paterno']} ${operativo['apellido_materno']}`
                }</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <br />
        <Button variant="primary" type="submit">
          Enviar
        </Button>
      </Form>
    </>
  )
}
function initialValues(data) {
  return {

    tipoServicio: data?.tipoServicio || 1,
    horasEstimadas: data?.horasEstimadas || 1,
    estatus: data?.estatus || 1,
    alcanceGeografico: data?.alcanceGeografico || 1,
    tipoReporte: data?.tipoReporte || "",
    nombreReporte: data?.nombreReporte || "",
    requerimientos: data?.requerimientos || "",
    temasDesarrollar: data?.temasDesarrollar || "",
    idColaborador: data?.idColaborador || "",
    idOperativo: data?.idOperativo || "",
  };
}

function newSchema() {
  return {
    requerimientos: Yup.string("Debe especificar un requerimiento")
      .required("Debe especificar un requerimiento"),
    temasDesarrollar: Yup.string("Asigna los temas a desarrollar")
      .required("Este campo es requerido"),
  };
}