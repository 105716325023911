import { useFormik } from "formik";
import React from "react";
import { Button, Form, FormGroup, Row } from "react-bootstrap";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { useUser } from "../../../../hooks";
import { IngresaDatosForm } from "../../../Generales";
import "./AddEditUserForm.scss";


export function AddEditUserForm(props) {
  const { onClose, onRefetch, user } = props;
  const { addUser, updateUser, sendMailForNewUser } = useUser();
  const formik = useFormik({
    initialValues: initialValues(user),
    validationSchema: Yup.object(user ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      try {

        if (user)
          try {
            await updateUser(user.id, formvalue);
            toast.success("Los datos fueron actualizados correctamente");
          } catch (error) {
            toast.error(
              "Hubo problemas para actualizar datos por favor vuelva a intentarlo."
            );
            console.error(error);
          }
        else
          try {
            await addUser(
              formvalue,
              formvalue.role,
              formvalue.estatus,
              formvalue.is_active,
              formvalue.is_staff
            );
            await sendMailForNewUser(formvalue.email)
            toast.success("Has creado un nuevo usuario");
          } catch (error) {
            
            console.error(error);
          }
        onRefetch();
        onClose();
      } catch (error) {
        toast.error(
          "Hubo problemas para actualizar datos por favor vuelva a intentarlo."
        );
        console.log(error);
      }
    },
  });

  return (
    <Form className="add-edit-user-form col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" onSubmit={formik.handleSubmit}>
      <Row className="mb-6">
        <IngresaDatosForm
          col="col-12 col-sm-6 col-xl-4"
          tituloLabel="Nombre"
          formatoLabel="tituloIzquierda"
          nameControl="nombre"
          placeHolderControl="Ingresa el nombre del usuario"
          valueControl={formik.values.nombre}
          onChangeControl={formik.handleChange}
          error={formik.errors.nombre}
          touched={formik.touched.nombre}
        />
        <IngresaDatosForm
          col="col-12 col-sm-6 col-xl-4"
          tituloLabel="Apellido Paterno"
          formatoLabel="tituloIzquierda"
          nameControl="apellido_paterno"
          placeHolderControl="Ingresa el apellido paterno del usuario"
          valueControl={formik.values.apellido_paterno}
          onChangeControl={formik.handleChange}
          error={formik.errors.apellido_paterno}
          touched={formik.touched.apellido_paterno}
        />
        <IngresaDatosForm
          col="col-12 col-sm-6 col-xl-4"
          tituloLabel="Apellido Materno"
          formatoLabel="tituloIzquierda"
          nameControl="apellido_materno"
          placeHolderControl="Ingresa el apellido materno del usuario"
          valueControl={formik.values.apellido_materno}
          onChangeControl={formik.handleChange}
          error={formik.errors.apellido_materno}
          touched={formik.touched.apellido_materno}
        />
      </Row>
      <br />
      <Row className="mb-6">
        <IngresaDatosForm
          col="col-12 col-sm-12 col-xl-6"
          tituloLabel="Correo electrónico"
          formatoLabel="tituloIzquierda"
          nameControl="email"
          placeHolderControl="Ingresa el correo del usuario"
          valueControl={formik.values.email}
          onChangeControl={formik.handleChange}
          error={formik.errors.email}
          touched={formik.touched.email}
        />
      </Row>
      <br />
      <Row className="mb-6">
        <FormGroup className="col-6 col-sm-6 col-xl-2">
          <Form.Label className="titulo">Rol del usuario.</Form.Label>
          <Form.Select
            name="role"
            defaultValue={formik.values.role}
            onChange={formik.handleChange}
          >
            <option value={1}>administrador</option>
            <option value={2}>Operativo</option>
            <option value={3}>Colaborador</option>
            <option value={4}>Costumer Service</option>
            <option value={5}>Cliente</option>
            <option value={6}>Cliente Comprador de B2B</option>
            <option value={7}>Cliente vendedor de B2B</option>
          </Form.Select>
        </FormGroup>
        <FormGroup className="col-6 col-sm-6 col-xl-2">
          <Form.Label className="titulo">Estatus del usuario.</Form.Label>
          {user ? (
            <Form.Select
              name="estatus"
              defaultValue={formik.values.estatus}
              onChange={formik.handleChange}
            >
              <option value={1}>Nuevo</option>
              <option value={2}>Activo</option>
              <option value={3}>Baja</option>
            </Form.Select>
          ) : (
            <Form.Select
              name="estatus"
              defaultValue={1}
              onChange={formik.handleChange}
              disabled
            >
              <option value={1}>
                Nuevo
              </option>
              <option value={2}>Activo</option>
              <option value={3}>Baja</option>
            </Form.Select>
          )}
        </FormGroup>
        <Form.Group className="col col-sm-12">
          <Row className="mb-6">
            <Form.Group className="col col-sm-6 col-xl-2">
              <div className="add-edit-user-form__active">
                <Form.Check
                  type="checkbox"
                  label="Usuario activo"
                  defaultChecked={formik.values.is_active}
                  onChange={(data) =>
                    formik.setFieldValue("is_active", data.target.checked)
                  }
                />
              </div>
            </Form.Group>
            <Form.Group className="col col-sm-6 col-xl-2">
              <div className="add-edit-user-form__staff">
                <Form.Check
                  type="checkbox"
                  label="Usuario administrador"
                  defaultChecked={formik.values.is_staff}
                  onChange={(data) =>
                    formik.setFieldValue("is_staff", data.target.checked)
                  }
                />
              </div>
            </Form.Group>
          </Row>
        </Form.Group>
      </Row>
      <br />
      <Button variant="primary" type="submit">
        {user ? "Actualizar" : "Crear"}
      </Button>
    </Form>
  );
}

function initialValues(data) {
  return {
    email: data?.email || "",
    nombre: data?.nombre || "",
    apellido_paterno: data?.apellido_paterno || "",
    apellido_materno: data?.apellido_materno || "",
    password: "",
    passwordCon: "",
    is_active: data?.is_active ? true : false,
    is_staff: data?.is_staff ? true : false,
    role: data?.role || 1,
    estatus: data?.estatus || '1',
  };
}
function newSchema() {
  return {
    nombre: Yup.string("El nombre no puede ser vacio").required(
      "El usuario es requerido"
    ),
    email: Yup.string("El mail no puede ser vacio")
      .email("No es un formato de mail valido")
      .required("El mail es requerido"),
    apellido_paterno: Yup.string("Este campo no puede ser vacio"),
    apellido_materno: Yup.string("Este campo no puede ser vacio"),
    is_active: Yup.bool().required("Este campo es requerido"),
    is_staff: Yup.bool().required("Este campo es requerido"),
  };
}

function updateSchema() {
  return {
    nombre: Yup.string("El nombre no puede ser vacio").required(
      "El nombre es requerido"
    ),
    email: Yup.string("El mail no puede ser vacio")
      .email("No es un formato de mail valido")
      .required("El mail es requerido"),
    apellido_paterno: Yup.string("Este campo no puede ser vacio").required(
      "Este campo no puede ser vacio"
    ),
    apellido_materno: Yup.string("Este campo no puede ser vacio").required(
      "Este campo no puede ser vacio"
    ),
    is_active: Yup.bool().required("Este campo es requerido"),
    is_staff: Yup.bool().required("Este campo es requerido"),
  };
}
