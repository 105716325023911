import React from 'react'
import Button from "react-bootstrap/Button";
import { useSectoresN } from "../../../../hooks";
import "./DeleteSectorsForm.scss";

export function DeleteSectorsForm(props) {
    const { onClose, onRefetch, sector } = props;
    const {deleteSectorNoticia} = useSectoresN();

    async function delSec() {
        try {
          await deleteSectorNoticia(sector.id);
          onRefetch();
          onClose();
        } catch (error) {
          console.error(error);
        }
    }
    
    function closeModal() {
        onClose();
    }

    return (
        <div>
          <h3 className="title-spacing">¿Está seguro de eliminar el sector?</h3>
          <div className="delete-z-form">
            <Button className="btnaling" onClick={delSec}>
              Eliminar
            </Button>
            <Button className="btnaling" onClick={closeModal}>
              Cancelar
            </Button>
          </div>
        </div>
      );
}
