import React from "react";
import { useParams } from "react-router-dom";

import { IndicadorListaPage } from "../../components/Client";

export function IndicadorLista() {

  const { clave } = useParams();

  if (clave)
    return <IndicadorListaPage clave={clave}/>;
  else
    return <IndicadorListaPage />;
}
