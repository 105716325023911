import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import "./LeftMenu.scss";


export function BotonSidebar(props) {
    
    const { btnSidebar } = props;

  return (
    <>
     <div className="mobile-nav">
  <button
    className="mobile-nav-btn"
    onClick={btnSidebar}
  >
   {/*  <div className='barra' > </div>
    <div className='barra' > </div>
    <div className='barra' > </div> */}
    <FontAwesomeIcon icon={faBars} className="icon" />
  </button>
  </div>
    </>
  )
}
