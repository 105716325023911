import { useState } from "react";
import {
  addAyudaServicioApi,
  getAyudaServicioApi,
  updateAyudaServicioApi,
  deleteAyudaServicioApi,
  getServicioEstadoFiltroApi,
  addAyudaNavegacionApi,
  getAyudaNavegacionApi,
  updateAyudaNavegacionApi,
  deleteAyudaNavegacionApi,
  getNavegacionEstadoFiltroApi,
  addAyudaPagosApi,
  getAyudaPagosApi,
  updateAyudaPagosApi,
  deleteAyudaPagosApi,
  getPagosEstadoFiltroApi,
  getAyudaCambioApi,
  getAyudaCambioUserApi,
  addAyudaCambioApi,
  updateAyudaCambioApi,
  deleteAyudaCambioApi,
  getNavegacionClienteFiltroApi,
  getServicioClienteFiltroApi,
  getPagosClienteFiltroApi
} from "../api/ayuda";
import { useAuth } from "./";

export function useAyuda() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [servicioayuda, setServicioayuda] = useState(null);
  const [serviciouser, setServiciouser] = useState(null);

  const [navegacionayuda, setNavegacionayuda] = useState(null);
  const [navegacionuser, setNavegacionuser] = useState(null);

  const [pagosayuda, setPagosayuda] = useState(null);
  const [pagosuser, setPagosuser] = useState(null);

  const [cambios, setCambios] = useState(null);
  const [cambiosUser, setCambiosUser] = useState(null);

  const { auth } = useAuth();

  const getAyudaServicio = async () => {
    try {
      setLoading(true);
      const response = await getAyudaServicioApi();
      setLoading(false);
      setServicioayuda(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const getServicioClienteFiltro = async (cliente_id) => {
    try {
      setLoading(true);
      const response = await getServicioClienteFiltroApi(cliente_id);
      setLoading(false);
      setServiciouser(response)
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  const addAyudaServicio = async (data) => {
    try {
      setLoading(true);
      await addAyudaServicioApi(data, auth.token, auth.me.id);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updateAyudaServicio = async (id, data) => {
    try {
      setLoading(true);
      await updateAyudaServicioApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteAyudaServicio = async (id) => {
    try {
      setLoading(true);
      await deleteAyudaServicioApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getServicioEstadoFiltrado = async (estado) => {
    try {
      setLoading(true);
      const response = await getServicioEstadoFiltroApi(estado);
      setLoading(false);
      setServicioayuda(response)
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  const getAyudaNavegacion = async () => {
    try {
      setLoading(true);
      const response = await getAyudaNavegacionApi();
      console.log("hooks", response);
      setNavegacionayuda(response);
      setLoading(false);
      console.log("navegacion", navegacionayuda);
      return response;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const getNavegacionClienteFiltro = async (cliente_id) => {
    try {
      setLoading(true);
      const response = await getNavegacionClienteFiltroApi(cliente_id);
      setLoading(false);
      setNavegacionuser(response)
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  const addAyudaNavegacion = async (data) => {
    try {
      setLoading(true);
      await addAyudaNavegacionApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updateAyudaNavegacion = async (id, data) => {
    try {
      setLoading(true);
      await updateAyudaNavegacionApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteAyudaNavegacion = async (id) => {
    try {
      setLoading(true);
      await deleteAyudaNavegacionApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  const getNavegacionEstadoFiltro = async (estado) => {
    try {
      setLoading(true);
      const response = await getNavegacionEstadoFiltroApi(estado);
      setLoading(false);
      setNavegacionayuda(response)
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  const getAyudaPagos = async () => {
    try {
      setLoading(true);
      const response = await getAyudaPagosApi();
      setLoading(false);
      setPagosayuda(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };


  const getPagosClienteFiltro = async (cliente_id) => {
    try {
      setLoading(true);
      const response = await getPagosClienteFiltroApi(cliente_id);
      setLoading(false);
      setPagosuser(response)
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  const addAyudaPagos = async (data) => {
    try {
      setLoading(true);
      await addAyudaPagosApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updateAyudaPagos = async (id, data) => {
    try {
      setLoading(true);
      await updateAyudaPagosApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteAyudaPagos = async (id) => {
    try {
      setLoading(true);
      await deleteAyudaPagosApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getPagosEstadoFiltro = async (estado) => {
    try {
      setLoading(true);
      const response = await getPagosEstadoFiltroApi(estado);
      setLoading(false);
      setPagosayuda(response)
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  const getAyudaCambio = async () => {
    try {
      setLoading(true);
      const response = await getAyudaCambioApi();
      setLoading(false);
      setCambios(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const getAyudaCambioUser = async (userId) => {
    try {
      setLoading(true);
      const response = await getAyudaCambioUserApi(userId);
      setLoading(false);
      setCambiosUser(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const addAyudaCambio = async (userId, data) => {
    try {
      setLoading(true);
      await addAyudaCambioApi(userId, data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updateAyudaCambio = async (id, data) => {
    try {
      setLoading(true);
      await updateAyudaCambioApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteAyudaCambio = async (id) => {
    try {
      setLoading(true);
      await deleteAyudaCambioApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    servicioayuda,
    navegacionayuda,
    pagosayuda,
    addAyudaServicio,
    getAyudaServicio,
    updateAyudaServicio,
    deleteAyudaServicio,
    getServicioEstadoFiltrado,
    addAyudaNavegacion,
    getAyudaNavegacion,
    updateAyudaNavegacion,
    deleteAyudaNavegacion,
    getNavegacionEstadoFiltro,
    addAyudaPagos,
    getAyudaPagos,
    updateAyudaPagos,
    deleteAyudaPagos,
    getPagosEstadoFiltro,
    cambios,
    cambiosUser,
    getAyudaCambio,
    getAyudaCambioUser,
    addAyudaCambio,
    updateAyudaCambio,
    deleteAyudaCambio,
    getNavegacionClienteFiltro,
    navegacionuser,
    getServicioClienteFiltro,
    serviciouser,
    getPagosClienteFiltro,
    pagosuser
  };
}
