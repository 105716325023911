import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faPlus, faTimes, faXmark, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import './TableCatalogos.scss';
import { ModalBasic } from '../../../Common';
import { DeleteCatalogoForm } from '../DeleteCatalogoForm';
import { toast } from 'react-toastify';

export function TableCatalogos({ categories, onEdit, onDelete }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [subcategoria, setSubcategoria] = useState('');
  const [subcategoriasList, setSubcategoriasList] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [children, setchildren] = useState(null);
  const openCloseModal = () => setShowSuccessModal((prev) => !prev);
  const borrar = (index) => {
    setTitleModal("¡Eliminar categoría!");
    setchildren(<DeleteCatalogoForm
      onClose={openCloseModal}
      index={index}
      onDelete={onDelete}
    />); openCloseModal()
  }



  const handleEditClick = (index) => {
    setSelectedCategoryIndex(index);
    setIsPopoverOpen(true);
    setSubcategoriasList(categories[index].subcategorias || []);
  };

  const handleDeleteClick = (index) => {
    setShowSuccessModal(true);
    // onDelete(index);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
    setSelectedCategoryIndex(null);
    setSubcategoria('');
    setSubcategoriasList([]);
  };

  const handleSubcategoriaChange = (event) => {
    setSubcategoria(event.target.value);
  };

  const handleAgregarSubcategoria = () => {
    setSubcategoriasList((prevList) => [...prevList, subcategoria]);
    setSubcategoria('');
  };
  const handleBorrarSubcategoria = (index) => {
    setSubcategoriasList((prevList) => {
      const newList = [...prevList];
      newList.splice(index, 1);
      return newList;
    });
  };
  const handleGuardarClick = () => {
    toast.info(
      <div>
        <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{ marginRight: '8px' }} />
        Se ha guardado con éxito la nueva categoría
      </div>,
      { position: 'top-center' }
    );
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };



  return (
    <div>
      <h2 className="categorialist">Listado de Categorías</h2>
      <div className="categories-container">
        {categories.map((category, index) => (
          <div key={index} className="category-item">
            <div className="category-box">{category}</div>
            <div className="action-buttons">
              <button onClick={() => handleEditClick(index)}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
              <button className="action-buttons2" onClick={() => borrar(index)}>
                <FontAwesomeIcon icon={faTrash} />
              </button>

            </div>
          </div>
        ))}
      </div>
      {isPopoverOpen && (
        <div className="popover">
          <p><strong>Categoría:</strong>  {categories[selectedCategoryIndex]}
            <button className="btn-out" onClick={closePopover}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </p>
          <input
            className="subcategoryText"
            type="text"
            placeholder="Subcategoría"
            value={subcategoria}
            onChange={handleSubcategoriaChange}
          />

          <button className="add-subcategoria-button"
            onClick={handleAgregarSubcategoria}>
            <FontAwesomeIcon icon={faPlus} />
          </button>

          <div className="subcategorias-container">
            {subcategoriasList.map((subcategoria, index) => (
              <div key={index} className="subcategoria-item">
                <span>{subcategoria}</span>
                <button onClick={() => handleBorrarSubcategoria(index)}>Borrar</button>
              </div>
            ))}
          </div>
          <div className="popover-buttons">
            <button className="save-button" onClick={handleGuardarClick} >Guardar</button>
          </div>
        </div>
      )}
      <ModalBasic
        show={showSuccessModal}
        onClose={openCloseModal}
        title={titleModal}
        children={children}
      />
    </div>
  );

}
