import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faGears,
  faRoute,
  faMoneyCheckDollar,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";

import "./AyudaAdmin.scss";

export function AyudaAdmin() {
  return (
    <Row className="ayuda justify-content-center mt-5">
      <Col xs={10}>
        <Card>
          <Card.Body>
            <Row className="my-3">
              <Col className="ayuda-menu-izq p-0">
                <Link to="/admin/ayuda/servicio">
                  <Button className="ayuda-btn" variant="outline-primary">
                    <div className="ayuda-icon-btn">
                      <FontAwesomeIcon icon={faGears} className="icon" />
                    </div>
                    <div className="ayuda-text-btn">Servicio</div>
                  </Button>
                </Link>
              </Col>
              <Col className="ayuda-menu-der p-0">
                <Link to="/admin/ayuda/navegacion">
                  <Button className="ayuda-btn" variant="outline-secondary">
                    <div className="ayuda-icon-btn">
                      <FontAwesomeIcon icon={faRoute} className="icon" />
                    </div>
                    <div className="ayuda-text-btn">Navegación</div>
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row className="my-3">
              <Col className="ayuda-menu-izq p-0">
                <Link to="/admin/ayuda/pagos">
                  <Button className="ayuda-btn" variant="outline-success">
                    <div className="ayuda-icon-btn">
                      <FontAwesomeIcon
                        icon={faMoneyCheckDollar}
                        className="icon"
                      />
                    </div>
                    <div className="ayuda-text-btn">Pagos y facturación</div>
                  </Button>
                </Link>
              </Col>
              <Col className="ayuda-menu-der p-0">
                <Link to="/admin/ayuda/cambioplan">
                  <Button className="ayuda-btn" variant="outline-warning">
                    <div className="ayuda-icon-btn">
                      <FontAwesomeIcon
                        icon={faShareFromSquare}
                        className="icon"
                      />
                    </div>
                    <div className="ayuda-text-btn">Cambio de Plan</div>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
