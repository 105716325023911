import React from "react";
import "./ModalBasic.scss";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ModalBasic(props) {
  const { show, size, icon, title, children, onClose } = props;

  return (
    <Modal className="model-basic" show={show} onHide={onClose} size={size}>
      {title && (
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={icon} />
          {title}
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

ModalBasic.defaultProps = {
  size: "sm",
};
