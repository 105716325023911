import React, { useCallback, useEffect, useState } from "react";

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { map } from "lodash";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { InputTags } from "react-bootstrap-tagsinput";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";

import "react-bootstrap-tagsinput/dist/index.css";
import { useNoticia } from "../../../../hooks";
import { formatDate } from "../../../../metodosGlobales/FormatoFecha";
import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";
import "./AddEditNewsForm.scss";

export function AddEditNewsForm(props) {
  const { onClose, onRefetch, sectores, noticia } = props;
  const [state, setState] = useState(noticia?.keywords || []);
  const { addNoticia, updateNoticia } = useNoticia();
  const [previewImage, setPreviewImage] = useState(
    noticia?.imagen ? noticia.imagen : null
  );
  const [previewPDF, setPreviewPDF] = useState(
    noticia?.pdf ? noticia.pdf : null
  );
  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("imagen", file);
    setPreviewImage(URL.createObjectURL(file));
  }, []);

  const onPDF = useCallback(async (e) => {
    const file = e.target.value;
    await formik.setFieldValue("pdf", e.target.files[0]);
    setPreviewPDF(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  useEffect(() => {
    const fetchData = async () => {
      formik.setFieldValue("keywords", state);
    };
    fetchData();
  }, [state]);

  const formik = useFormik({
    initialValues: initialValues(noticia),
    validationSchema: Yup.object(noticia ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      if (noticia) await updateNoticia(noticia.id, formvalue);
      else await addNoticia(formvalue);
      onRefetch();
      onClose();
    },
  });

  return (
    <Form className="add-edit-new-form" onSubmit={formik.handleSubmit}>
      <Row className="mb-6">
        <IngresaDatosForm
          col="col-12 col-sm-6 col-xl-4"
          tituloLabel="Titular de la noticia."
          formatoLabel="tituloCentrado"
          nameControl="titular_noticia"
          type="text"
          placeHolderControl="Ingresa el título"
          valueControl={formik.values.titular_noticia}
          onChangeControl={formik.handleChange}
          error={formik.errors.titular_noticia}
          touched={formik.touched.titular_noticia}
        />
        <IngresaDatosForm
          col="col-12 col-sm-6 col-xl-4"
          tituloLabel="Autor-Intitución."
          formatoLabel="tituloCentrado"
          nameControl="autor_institucion"
          type="text"
          placeHolderControl="Creador de la noticia"
          valueControl={formik.values.autor_institucion}
          onChangeControl={formik.handleChange}
          error={formik.errors.autor_institucion}
          touched={formik.touched.autor_institucion}
        />
        <Form.Group className="col-12 col-sm-12 col-xl-4">
          <IngresaDatosForm
            tituloLabel="Fecha de subida:"
            formatoLabel="tituloCentrado"
          />
          <IngresaDatosForm
            tituloLabel={
              noticia
                ? formatDate(noticia.fecha_alta)
                : formatDate(new Date().toISOString())
            }
            formatoLabel="tituloCentrado"
          />
        </Form.Group>
      </Row>
      <Row className="mb-6 mt-3">
        <IngresaDatosForm
          col="col-12 col-sm-6 col-lg-4"
          tituloLabel="Link de referencia."
          formatoLabel="tituloCentrado"
          nameControl="link_referencia"
          type="text"
          placeHolderControl="Link"
          valueControl={formik.values.link_referencia}
          onChangeControl={formik.handleChange}
        />
        <IngresaDatosForm
          col="col-12 col-sm-6 col-lg-4"
          tituloLabel="Link del video."
          formatoLabel="tituloCentrado"
          nameControl="link_video"
          type="text"
          placeHolderControl="Link"
          valueControl={formik.values.link_video}
          onChangeControl={formik.handleChange}
        />
        <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-4">
          <IngresaDatosForm
            tituloLabel="Sectores:"
            formatoLabel="tituloCentrado"
          />
          <Form.Select
            name="sectores"
            defaultValue={formik.values.sectores}
            onChange={formik.handleChange}
          >
            <option>Selecciona un sector</option>
            {map(sectores, (sector, index) => (
              <option key={index} value={sector.id}>
                {sector.descripcion}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-6 mt-3">
        <Col md='9'>
          <Form.Group>
            <IngresaDatosForm
              tituloLabel="Palabras clave:"
              formatoLabel="tituloCentrado"
            />
            <div style={{ margin: 10 }}>
              <div className="input-group">
                <InputTags
                  values={state}
                  onTags={(value) => setState(value.values)}
                />
              </div>
            </div>
          </Form.Group>
        </Col>
        <Col md='3'>
          <IngresaDatosForm
            tituloLabel="Subir PDF"
            formatoLabel="tituloCentrado"
            nameControl="pdf"
            type="file"
            acceptControl="application/pdf"
            onChangeControl={(e) => onPDF(e)}
            rounded="10" />
          {
            previewPDF
              ? (
                <Row>
                  <Col md={6} className="text-center">
                    Ver archivo:
                  </Col>
                  <Col md={6}>
                    <a
                      target="_blank"
                      href={previewPDF}
                    >
                      <FontAwesomeIcon icon={faFilePdf} size='2xl' />
                    </a>
                  </Col>
                </Row>
              )
              : null
          }
        </Col>
      </Row>
      <IngresaDatosForm
        tituloLabel="Resumen."
        formatoLabel="tituloIzquierda"
        nameControl="resumen"
        type="text"
        rows={2}
        placeHolderControl="Resumen"
        valueControl={formik.values.resumen}
        onChangeControl={formik.handleChange}
        error={formik.errors.resumen}
        touched={formik.touched.resumen}
      />
      <IngresaDatosForm
        tituloLabel="Redacción completa."
        formatoLabel="tituloIzquierda"
        nameControl="redaccion_completa"
        type="text"
        rows={3}
        placeHolderControl="Redacción"
        valueControl={formik.values.redaccion_completa}
        onChangeControl={formik.handleChange}
        error={formik.errors.redaccion_completa}
        touched={formik.touched.redaccion_completa}
      />
      <Row className="mb-6 mt-3">
        <div className="contenedor">
          <Form.Group widths="equal">
            <Form.Label className="titulo">Imagen.</Form.Label>
            <Button type="button" {...getRootProps()}>
              {previewImage ? "Cambiar imagen" : "Subir imagen"}
            </Button>

            <input {...getInputProps()} />
            <Image src={previewImage} className="imagen" />
          </Form.Group>
        </div>
      </Row>

      <Button variant="primary" type="submit">
        {noticia ? "Actualizar" : "Crear noticia"}
      </Button>
    </Form>
  );
}

function initialValues(data) {
  return {
    titular_noticia: data?.titular_noticia || "",
    autor_institucion: data?.autor_institucion || "",
    link_referencia: data?.link || "",
    keywords: data?.keywords || "",
    resumen: data?.resumen || "",
    redaccion_completa: data?.redaccion_completa || "",
    link_video: data?.video || "",
    sectores: data?.sectores["id"] || 1,
    imagen: "",
    pdf: "",
  };
}
function newSchema() {
  return {
    titular_noticia: Yup.string("El titular no puede estar vacío").required(
      "El titular es requerido"
    ),
    autor_institucion: Yup.string(
      "Debe de haber un autor/institución de la noticia"
    ).required("El autor/institución es requerido"),
    resumen: Yup.string("El resumen no puede ir vacío").required(
      "El resumen es requerido"
    ),
    redaccion_completa: Yup.string(
      "Debe de tener una redacción la noticia"
    ).required("La redacción es requerida"),
  };
}

function updateSchema() {
  return {
    titular_noticia: Yup.string("El titular no puede estar vacío").required(
      "El titular es requerido"
    ),
    autor_institucion: Yup.string(
      "Debe de haber un autor/institución de la noticia"
    ).required("El autor/institución es requerido"),
    resumen: Yup.string("El resumen no puede ir vacío").required(
      "El resumen es requerido"
    ),
    redaccion_completa: Yup.string(
      "Debe de tener una redacción la noticia"
    ).required("La redacción es requerida"),
  };
}
