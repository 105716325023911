import { BASE_API } from "../utils/constants";

import { formatDateNow } from "../metodosGlobales/FormatoFecha";

export async function getAyudaServicioApi() {
  try {
    const url = `${BASE_API}/api/ayudaServicio/`;
    const response = await fetch(url);
    const result = await response.json();
    console.log('Resultado', result)
    return result;
  } catch (error) {
    throw error;
  }
}
export async function getServicioEstadoFiltroApi(estatus) {
  try {
    const url = `${BASE_API}/api/ayudaServicio/filtradoEstatus/${estatus}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getServicioClienteFiltroApi(clienteId) {
  try {
    const url = `${BASE_API}/api/ayudaServicio/filtradoUserServicio/${clienteId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addAyudaServicioApi(data, token) {
  try {
    const formData = new FormData();
    formData.append("user", data.user);
    formData.append("tipo_servicio", data.tipo_servicio);
    formData.append("fecha_solicitud", data.fecha_solicitud);
    formData.append("telefono", data.telefono);
    formData.append("email", data.email);
    formData.append("tiene_servicios", data.tiene_servicios);
    formData.append("tiempo_forma", data.tiempo_forma);
    formData.append("cumplio", data.cumplio);
    formData.append("accede", data.accede);
    formData.append("descripcion", data.descripcion);
    formData.append("estatus", "1");
    formData.append("fecha_atencion", "");
    formData.append("solucion", "");

    const url = `${BASE_API}/api/ayudaServicio/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function updateAyudaServicioApi(id, data, token) {
  try {
    const formData = new FormData();
    formData.append("tipo_servicio", data.tipo_servicio);
    formData.append("fecha_solicitud", data.fecha_solicitud);
    formData.append("telefono", data.telefono);
    formData.append("email", data.email);
    formData.append("tiene_servicios", data.tiene_servicios);
    formData.append("tiempo_forma", data.tiempo_forma);
    formData.append("cumplio", data.cumplio);
    formData.append("accede", data.accede);
    formData.append("descripcion", data.descripcion);
    formData.append("estatus", data.estatus);
    formData.append("fecha_atencion", formatDateNow());
    formData.append("solucion", data.solucion);

    const url = `${BASE_API}/api/ayudaServicio/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteAyudaServicioApi(id, token) {
  try {
    const url = `${BASE_API}/api/ayudaServicio/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getAyudaNavegacionApi() {
  try {
    const url = `${BASE_API}/api/ayudaNavegacion/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function getNavegacionEstadoFiltroApi(estatus) {
  try {
    const url = `${BASE_API}/api/ayudaNavegacion/filtradoEstatus/${estatus}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getNavegacionClienteFiltroApi(clienteId) {
  try {
    const url = `${BASE_API}/api/ayudaNavegacion/filtradoUserNavegacion/${clienteId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addAyudaNavegacionApi(data, token) {
  try {
    const formData = new FormData();
    formData.append("user", data.user);
    formData.append("fecha_solicitud", data.fecha_solicitud);
    formData.append("telefono", data.telefono);
    formData.append("email", data.email);
    formData.append("problema_ots", data.problema_ots);
    formData.append("asesorias", data.asesorias);
    formData.append("descripcion", data.descripcion);
    formData.append("estatus", "1");
    formData.append("fecha_atencion", "");
    formData.append("solucion", "");
    console.log("data", formData);

    const url = `${BASE_API}/api/ayudaNavegacion/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function updateAyudaNavegacionApi(id, data, token) {
  try {
    const formData = new FormData();
    formData.append("fecha_solicitud", data.fecha_solicitud);
    formData.append("telefono", data.telefono);
    formData.append("email", data.email);
    formData.append("problema_ots", data.problema_ots);
    formData.append("asesorias", data.asesorias);
    formData.append("descripcion", data.descripcion);
    formData.append("estatus", data.estatus);
    formData.append("fecha_atencion", formatDateNow());
    formData.append("solucion", data.solucion);

    const url = `${BASE_API}/api/ayudaNavegacion/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteAyudaNavegacionApi(id, token) {
  try {
    const url = `${BASE_API}/api/ayudaNavegacion/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getAyudaPagosApi() {
  try {
    const url = `${BASE_API}/api/ayudaPagos/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getPagosEstadoFiltroApi(estatus) {
  try {
    const url = `${BASE_API}/api/ayudaPagos/filtradoEstatus/${estatus}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getPagosClienteFiltroApi(clienteId) {
  try {
    const url = `${BASE_API}/api/ayudaPagos/filtradoUserPagos/${clienteId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addAyudaPagosApi(data, token) {
  try {
    const formData = new FormData();
    formData.append("user", data.user);
    formData.append("tipo_servicio", data.tipo_servicio);
    formData.append("fecha_solicitud", data.fecha_solicitud);
    formData.append("telefono", data.telefono);
    formData.append("email", data.email);
    formData.append("factura_actual", data.factura_actual);
    formData.append("solicitar_factura", data.solicitar_factura);
    formData.append("problema_factura", data.problema_factura);
    formData.append("descripcion", data.descripcion);
    formData.append("estatus", "1");
    formData.append("fecha_atencion", "");
    formData.append("solucion", "");
    console.log("data", formData);

    const url = `${BASE_API}/api/ayudaPagos/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function updateAyudaPagosApi(id, data, token) {
  try {
    const formData = new FormData();
    formData.append("fecha_solicitud", data.fecha_solicitud);
    formData.append("telefono", data.telefono);
    formData.append("email", data.email);
    formData.append("factura_actual", data.factura_actual);
    formData.append("solicitar_factura", data.solicitar_factura);
    formData.append("problema_factura", data.problema_factura);
    formData.append("descripcion", data.descripcion);
    formData.append("estatus", data.estatus);
    formData.append("fecha_atencion", formatDateNow());
    formData.append("solucion", data.solucion);

    const url = `${BASE_API}/api/ayudaPagos/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteAyudaPagosApi(id, token) {
  try {
    const url = `${BASE_API}/api/ayudaPagos/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getAyudaCambioApi() {
  try {
    const url = `${BASE_API}/api/ayudaCambio/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getAyudaCambioUserApi(userId) {
  try {
    const url = `${BASE_API}/api/ayudaCambio/usuario/${userId}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addAyudaCambioApi(userId, data, token) {
  try {
    const formData = new FormData();
    formData.append("fecha_solicitud", data.fecha_solicitud);
    formData.append("telefono", data.telefono);
    formData.append("email", data.email);
    formData.append("membresia_actual", data.membresia_actual);
    formData.append("membresia_solicitada", data.membresia_solicitada);
    formData.append("fecha_atencion", "");
    formData.append("comentario", data.comentario);
    formData.append("solucion", "");
    formData.append("estatus", "1");
    formData.append("user", userId);

    const url = `${BASE_API}/api/ayudaCambio/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateAyudaCambioApi(id, data, token) {
  try {
    const formData = new FormData();
    formData.append("fecha_solicitud", data.fecha_solicitud);
    formData.append("telefono", data.telefono);
    formData.append("email", data.email);
    formData.append("membresia_actual", data.membresia_actual);
    formData.append("membresia_solicitada", data.membresia_solicitada);
    formData.append("fecha_atencion", data.fecha_atencion);
    formData.append("comentario", data.comentario);
    formData.append("solucion", data.solucion);
    formData.append("estatus", data.estatus);

    const url = `${BASE_API}/api/ayudaCambio/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteAyudaCambioApi(id, token) {
  try {
    const url = `${BASE_API}/api/ayudaCambio/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
