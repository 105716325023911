import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { formatDateMonthName } from "../../../../metodosGlobales";
import { useRecurso } from "../../../../hooks";
import "./DetalleRecurso.scss";

export function DetalleRecursoInformacion(props) {
  const { idRecurso } = props;
  const { getRecursoID } = useRecurso();
  const [recurso, setRecurso] = useState(null);

  useEffect(() => {
    getRecursoID(idRecurso).then((recu) => {
      setRecurso(recu);
    });
  }, []);

  return (
    <div className="informacion">
      <Row className="recurso-detalle justify-content-center">
        <Row className="recurso-detalle-titulo mb-3">
          <h2 className="text-center">{recurso?.titulo}</h2>
        </Row>
        <Row className="justify-content-center recurso-detalle-titulo mb-3">
          <Col xl={3}>
            <p className="text-center">
              {`Fecha de subida:   ${formatDateMonthName(recurso?.fecha_alta)}`}
            </p>
          </Col>
          <Col xl={3}>
            <p className="text-center">
              {`Sector: ${recurso?.catalogo["descripcion"]}`}
            </p>
          </Col>
          <Col xl={3}>
            <p className="text-center">
              {`Tipo de investigación: ${recurso?.tipoDeinvestigacion["nombre"]}`}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col className="col-12 col-sm-11 col-md-11 col-lg-10 col-xl-8 col-xxl-6">
            {recurso?.imagen ? (
              <Image src={`${recurso.imagen}`} />
            ) : (
              <Image src={null} />
            )}
          </Col>
        </Row>
        <Row className="justify-content-center mb-3" />
        <Row className="justify-content-center mb-3" />
        <Row className="recurso-detalle-content justify-content-center mb-5">
          <Col xs={12}>
            <div className="format">{recurso?.descripcionTotal}</div>
          </Col>
        </Row>
        <Row className="col-12 col-sm-11 col-md-11 col-lg-10 col-xl-8 col-xxl-6">
          {recurso?.link ? (
            <>
              <Row className="recurso-detalle-boton mb-3">
                <h2 className="text-center">Video relacionado:</h2>
              </Row>
              <div className="player-wrapper">
                <h2 className="text-center">Video</h2>
                <ReactPlayer
                  className="react-player"
                  url={recurso?.link}
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            </>
          ) : null}
        </Row>
        <Row className="justify-content-center mb-3" />
        <Row className="justify-content-center mb-3" />
        <Row className="botondeRecursos col-xl-6">
          {recurso?.documento ? (
            <Row className="justify-content-center recurso-detalle-boton mb-3">
              <a
                href={`${recurso.documento}`}
                className="btn btn-secondary btn-sm"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                Ver documento complementario
              </a>
            </Row>
          ) : null}
        </Row>

        <Row className="justify-content-center mb-3" />
        <Row className="justify-content-center mb-3" />
        <Row className="justify-content-center mb-3" />
        <Row className="justify-content-center mb-3" />
      </Row>
    </div>
  );
}
