import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import {
  HeaderPage,
  TableEquipoOTS,
  AddEditEquipoOTSForm,
  DeleteEquipoOTS,
} from "../../components/Admin";
import { useEquipoOTS } from "../../hooks";
import { ModalBasic } from "../../components/Common";
import {
  faPeopleGroup,
  faTrash,
  faFilePen,
} from "@fortawesome/free-solid-svg-icons";

export function EquipoOTSAdmin() {
  const { loading, equipoOTS, getEquipoOTS } = useEquipoOTS();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [sizeModal, setsizeModal] = useState("g");
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    getEquipoOTS();
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const addEquipoOTS = () => {
    setTitleModal("Nuevo Integrante");
    setIconModal(faPeopleGroup);
    setsizeModal("l");
    setContentModal(
      <AddEditEquipoOTSForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };
  const updateEquipoOTS = (data) => {
    setTitleModal("Actualizar Integrante");
    setIconModal(faFilePen);
    setsizeModal("l");
    setContentModal(
      <AddEditEquipoOTSForm
        equipo={data}
        onClose={openCloseModal}
        onRefetch={onRefetch}
      />
    );
    openCloseModal();
  };

  const deleteEquipoOTS = async (data) => {
    setTitleModal("Borrar Integrante");
    setIconModal(faTrash);
    setsizeModal("s");
    setContentModal(
      <DeleteEquipoOTS
        onClose={openCloseModal}
        onRefetch={onRefetch}
        equipo={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <HeaderPage
        title="Equipo OTS"
        btnTitle="Nuevo Integrante"
        btnClick={addEquipoOTS}
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <TableEquipoOTS
          equipoOTS={equipoOTS}
          updateEquipoOTS={updateEquipoOTS}
          deleteEquipoOTS={deleteEquipoOTS}
        />
      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
