import React, { useState } from "react";
import {
  Row,
  Tabs,
  Tab,
  Container,
  Col,
  Form,
  Button,
  Table,
} from "react-bootstrap";

export function NewList() {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  const handleAddItem = () => {
    if (newItem.trim() !== "") {
      setItems([...items, { name: newItem, description: "" }]);
      setNewItem("");
    }
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleDescriptionChange = (index, event) => {
    const updatedItems = [...items];
    updatedItems[index].description = event.target.value;
    setItems(updatedItems);
  };
  return (
    <>
      <h2 className="mt-4 mb-4">Productos o servicios ofertados</h2>
      <Row className="mb-3">
        <Col>
          <Form.Control
            type="text"
            placeholder="Nuevo producto o servicio"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
          />
        </Col>
        <Col col="col-4">
          <Button onClick={handleAddItem}>Agregar</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>Producto o Servicio</th>
                <th>Descripción</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Form.Check type="checkbox" />
                  </td>
                  <td>{item.name}</td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Descripción"
                      value={item.description}
                      onChange={(e) => handleDescriptionChange(index, e)}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteItem(index)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
