
import React from 'react'
import { Accordion } from 'react-bootstrap'
import { FormCluster } from '../formCluster/FormCluster';
import './AccordionCluster.scss'
export function AccordionCluster() {

    return (
        <div className='espacio'>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Formulario de búsqueda</Accordion.Header>
                    <Accordion.Body>
                        <FormCluster />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}
