import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AddRequerimientosVendedor, TablaRegistroExterno } from "../../../components/Client/MenuRequerimientosVendedor";
import { useB2B } from "../../../hooks";

export function VendedorB2BHome() {
  const [refetch, setRefetch] = useState(false);
  const { loading, getSitioWeb, agenda } = useB2B();

  useEffect(() => {
    getSitioWeb();
  }, [refetch]);

  console.log(agenda);

  return (
    <>
      <Row className="justify-content-center">

        <article>{loading ? null : <TablaRegistroExterno data={agenda} AddRequerimientosVendedor={AddRequerimientosVendedor} />}</article>
      </Row>
    </>
  );
}

// Registro Vendedor
