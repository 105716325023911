import React, { useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { TituloPrincipal } from '../../../components/Common'
import { useEventos,  useIndicadores, useNoticia} from '../../../hooks'
import { TopBusqueda } from '../../../components/Client'
import "./ResultadoBuscador.scss"

export function ResultadoBuscador() {
  
  const { clave } = useParams();
  const { getEventosBuscadorTop, eventos } = useEventos();
  const { getIndicadoresBuscadorTop, indicadores } = useIndicadores();
  const { getNoticiasBuscadorTop, noticia } = useNoticia();

  useEffect(() => {
    const fetchData = async () => {
      await getEventosBuscadorTop(clave);
      await getIndicadoresBuscadorTop(clave);
      await getNoticiasBuscadorTop(clave);
    };
    fetchData();
  }, []);

  return (
    <div className='contenedorResultadoBuscador'>
      <TituloPrincipal
        titulo={`Búsqueda: ${clave}`}
      />
      <br />
      <TopBusqueda
        tituloPrincipal="Noticias"
        palabraClave={clave}
        data = {noticia}
      />
      <br />
      <TopBusqueda
        tituloPrincipal='Eventos'
        palabraClave={clave}
        data={eventos}
      />
      <br />
      <TopBusqueda
        tituloPrincipal='Indicadores'
        palabraClave={clave}
        data={indicadores}
      />
    </div>
  )
}
