import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint } from "@fortawesome/free-solid-svg-icons";

import { useUser } from "../../../hooks";
import { ButtonLoad } from "../../../components/Common";
import "./ResetPasswordForm.scss";

export function ResetPasswordForm() {
  const { id, otp } = useParams();
  const { resetPassword } = useUser();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      try {
        const reset = { otp: otp, password: formValue.password };
        setLoading(true);
        await resetPassword(id, reset);
        toast.success(
          "El password fue actualizado correctamente vuelve al inicio para ingresar tu acceso",
          {
            autoClose: 2000,
          }
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    },
  });

  return (
    <>
      <Form className="reset-password" onSubmit={formik.handleSubmit}>
        <span>
          <FontAwesomeIcon icon={faFingerprint} size="3x" color="#A9C4DD" />
        </span>
        <h5 className="reset-password_titulo">Cambio de Password</h5>
        <Form.Group className="mb-3" controlId="formNewPassword">
          <Form.Label>Ingresa tu nueva Contraseña</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.touched.password && formik.errors.password ? "error" : null
            }
          />
          <Form.Text className="text-danger">
            {formik.touched.password && formik.errors.password ? (
              <div className="text-danger">{formik.errors.password}</div>
            ) : null}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formConfirmPassword">
          <Form.Label>Confirma tú Contraseña</Form.Label>
          <Form.Control
            type="password"
            name="passwordCon"
            value={formik.values.passwordCon}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.touched.passwordCon && formik.errors.passwordCon
                ? "error"
                : null
            }
          />
          <Form.Text className="text-danger">
            {formik.touched.passwordCon && formik.errors.passwordCon ? (
              <div className="text-danger">{formik.errors.passwordCon}</div>
            ) : null}
          </Form.Text>
        </Form.Group>

        {loading ? (
          <ButtonLoad></ButtonLoad>
        ) : (
          <Button variant="primary" type="submit">
            Actualizar
          </Button>
        )}

        <Link className="reset-password_link" to="/admin" replace>
          Volver a iniciar sesion
        </Link>
      </Form>
    </>
  );
}

function initialValues() {
  return {
    password: "",
    passwordCon: "",
  };
}

function validationSchema() {
  return {
    password: Yup.string()
      .required("La contraseña es requerida")
      .min(8, "Debe contener un mínimo de 8 carácteres")
      .matches(/^(?=.*[a-z])/, " Debe tener como mínimo una letra en minúscula")
      .matches(/^(?=.*[A-Z])/, " Debe tener como mínimo una letra en mayúscula")
      .matches(/^(?=.*[0-9])/, " Debe tener como mínimo un número")
      .matches(/^(?=.*[!@#$%^&*()\-_=+{};:,<.>'])/, " Debe tener como mínimo un carácter"),
    passwordCon: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "La confirmacion de password no es igual al proporcionado"
    ),
  };
}