import { BASE_API } from "../utils/constants";

export async function getDisplayServicesDataApi(token) {
  try {
    const url = `${BASE_API}/api/solicitudReportes/vistaAdministrador/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  };
}

export async function getDisplayServicesDataIdClientApi(id, token) {
  try {
    const url = `${BASE_API}/api/solicitudReportes/filtro/${id}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  };
}

export async function getDisplayServicesDataIdServiceApi(id, token) {
  try {
    const url = `${BASE_API}/api/solicitudReportesDatosAdicionales/filtro/${id}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  };
}

export async function getDisplayServicesDataIdColaboratorApi(id, token) {
  try {
    const url = `${BASE_API}/api/vistaColaboradorSolicitudReporte/filtrado/${id}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  };
}

export async function getDisplayServicesDataIdOperativeApi(id, token) {
  try {
    const url = `${BASE_API}/api/vistaOperativoSolicitudReporte/filtrado/${id}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  };
}

export async function getServicesMembershipApi( token) {
  try {
    const url = `${BASE_API}/api/tipoServicio/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  };
}



export async function addServiceClientApi(data, idCliente, token) {
  try {
        const indicadores = JSON.stringify({ ...data.indicadores });
        const formData = new FormData();
        formData.append("idCliente", idCliente);
        formData.append("indicadores", indicadores);
        formData.append("sectores", data.sectores);
        formData.append("objetivoServicio", data.objetivoReporte);
        const url = `${BASE_API}/api/solicitudServicio/nuevaSolicitud/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;

  } catch (error) {
    throw error;
  };
}

export async function addServiciosDatosAdicionalesApi( idSolicitudServicio, token) {
  try {
    const formData = new FormData();
    formData.append("idSolicitudServicio", idSolicitudServicio);
    const url = `${BASE_API}/api/solicitudReportes/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;

  } catch (error) {
    throw error;
  };
}

export async function editServiceClient_Admin_Api(data, id, token) {
  try {
    const formData = new FormData();
    formData.append("estatus", data.estatus);
    formData.append("tipoServicio", data.tipoServicio);
    const url = `${BASE_API}/api/solicitudReportes/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  };
}

export async function editAditionalData_Admin_Api(data, id, token) {
  try {
    console.log("=============>");
    console.log(data);
    const formData = new FormData();
    formData.append("idColaborador", data.idColaborador);
    formData.append("idOperativo", data.idOperativo);
    formData.append("horasEstimadas", data.horasEstimadas);
    formData.append("tipoReporte", data.tipoReporte);
    formData.append("nombreReporte", data.nombreReporte);
    formData.append("requerimientos", data.requerimientos);
    formData.append("temasDesarrollar", data.temasDesarrollar);
    formData.append("alcanceGeografico", data.alcanceGeografico);
    formData.append("estatus", data.estatus);
    const url = `${BASE_API}/api/solicitudReportes/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  };
}

export async function editAditionalData_ColabOpe_Api(data, id, token) {
  try {
    const formData = new FormData();
    formData.append("archivoReporte", data.archivoReporte);
    const url = `${BASE_API}/api/solicitudReportes/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  };
}
