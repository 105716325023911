import React from 'react'
import { Image, Form, Col, Row } from 'react-bootstrap'

import './IconDetails.scss'

export function IconsDetails(props) {

    const { dato, onClose, setIcono } = props;

    const seleccionImagen = (img) => {
        setIcono(img);
        onClose();
    }

    return (
        <Form>
            <br />
            <Row>
                <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={2}>
                    <Image
                        className="iconos"
                        src={dato[0]}
                        onClick={() => seleccionImagen(dato[0])}
                    />
                </Col>
                {
                    dato[1]
                        ?
                        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={2}>
                            <Image
                                className="iconos"
                                src={dato[1]}
                                onClick={() => seleccionImagen(dato[1])}
                            />
                        </Col>
                        : null
                }
                {
                    dato[2]
                        ?
                        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={2}>
                            <Image
                                className="iconos"
                                src={dato[2]}
                                onClick={() => seleccionImagen(dato[2])}
                            />
                        </Col>
                        : null
                }
                {
                    dato[3]
                        ?
                        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={2}>
                            <Image
                                className="iconos"
                                src={dato[3]}
                                onClick={() => seleccionImagen(dato[3])}
                            />
                        </Col>
                        : null
                }
                {
                    dato[4]
                        ?
                        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={2}>
                            <Image
                                className="iconos"
                                src={dato[4]}
                                onClick={() => seleccionImagen(dato[4])}
                            />
                        </Col>
                        : null
                }
                {
                    dato[5]
                        ?
                        <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={2}>
                            <Image
                                className="iconos"
                                src={dato[5]}
                                onClick={() => seleccionImagen(dato[5])}
                            />
                        </Col>
                        : null
                }
            </Row>
        </Form>
    )
}
