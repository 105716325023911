import React, { useMemo, useState } from "react";
import { map } from "lodash";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { formatDate } from "../../../../metodosGlobales/FormatoFecha";
import "./TableNews.scss";
import { FilterInput } from "../../../Common";

export function TableNews(props) {
  const { noticias, updateNoticia, onDeleteNews } = props;

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true
    },
    {
      name: "Titular",
      selector: (row) => row.titular_noticia,
      sortable: true
    },
    {
      name: "Autor",
      selector: (row) => row.autor_institucion,
      sortable: true
    },
    {
      name: "Sector",
      selector: (row) => row.sectores.descripcion,
      sortable: true
    },
    {
      name: "Fecha de alta",
      selector: (row) => row.fecha_alta,
      sortable: true,
      cell: (row) => (<span>{formatDate(row.fecha_alta)}</span>)
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="success"
            className="table-webinar-admin__boton btn-boton"
            onClick={() => updateNoticia(row)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button
            size="sm"
            variant="danger"
            className="table-webinar-admin__boton btn-boton"
            onClick={() => onDeleteNews(row)}
          >
            <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
          </Button>
        </>
      )
    }
  ]

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.noticias.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterInput
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    // <div className="table-responsive-xl">
    //   <Table striped bordered hover className="table-users-new table-sm">
    //     <thead>
    //       <tr>
    //         <th>ID</th>
    //         <th>Titular</th>
    //         <th>Autor / Institución</th>
    //         <th>Sector</th>
    //         <th>Fecha de alta</th>
    //         <th>Acciones</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {map(noticias, (noticia, index) => (
    //         <tr key={index}>
    //           <td>{noticia.id}</td>
    //           <td>{noticia.titular_noticia}</td>
    //           <td>{noticia.autor_institucion}</td>
    //           <th>{noticia.sectores["descripcion"]}</th>
    //           <td>{formatDate(noticia.fecha_alta)}</td>
    //           <Actions
    //             noticia={noticia}
    //             updateNews={updateNoticia}
    //             onDeleteNews={onDeleteNews}
    //           />
    //         </tr>
    //       ))}
    //     </tbody>
    //   </Table>
    // </div>
    <DataTable
      columns={columns}
      data={filteredItems}
      defaultSortField="name"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
    />
  );
}

function Actions(props) {
  const { noticia, updateNews, onDeleteNews } = props;
  return (
    <td>
      <Button
        size="sm"
        variant="success"
        className="table-webinar-admin__boton btn-boton"
        onClick={() => updateNews(noticia)}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Button>
      <Button
        size="sm"
        variant="danger"
        className="table-webinar-admin__boton btn-boton"
        onClick={() => onDeleteNews(noticia)}
      >
        <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
      </Button>
    </td>
  );
}
