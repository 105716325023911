import React from "react";
import { Form, Row, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { map } from "lodash";
import { IngresaDatosForm } from "../../../../components/Generales";
import { usePagos } from "../../../../hooks";

import "./FormFacturacion.scss";
export function FormFacturacion(props) {
  const { onClose, clienteId, datosFactura, regimenFiscal } = props;
  const { addDatosFactura, updateDatosFactura } = usePagos();
  const formik = useFormik({
    initialValues: initialValues(datosFactura),
    validationSchema: Yup.object(newSchema()),
    onSubmit: async (formvalue) => {
      try {
        if (datosFactura)
          await updateDatosFactura(formvalue, datosFactura.id, clienteId);
        else await addDatosFactura(formvalue, clienteId);
        toast.info("Se han guardado correctamente los datos de facturacion", {
          position: "top-center",
        });
        onClose();
      } catch (error) {
        console.log(error);
        toast.error(
          "No fue posible guardar los datos de facturacion, por favor intente de nuevo"
        );
        console.log(error);
        onClose();
      }
    },
  });

  return (
    <Form className="facturacion" onSubmit={formik.handleSubmit}>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Razon Social"
          formatoLabel="tituloIzquierda"
          nameControl="razon"
          placeHolderControl="Ingresa la razon social"
          valueControl={formik.values.razon}
          onChangeControl={formik.handleChange}
          error={formik.errors.razon}
          touched={formik.touched.razon}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="RFC con homoclave"
          formatoLabel="tituloIzquierda"
          nameControl="rfc"
          placeHolderControl="Ingresa el RFC"
          valueControl={formik.values.rfc}
          onChangeControl={formik.handleChange}
          error={formik.errors.rfc}
          touched={formik.touched.rfc}
        />
        <Form.Group controlId="formRegimenFidcal" className="col col-sm-4">
          <Form.Label>Regimen Fiscal</Form.Label>
          <Form.Select
            defaultValue={formik.values.regimen}
            className="form-control"
            name="regimen"
            onChange={formik.handleChange}
          >
            {map(regimenFiscal, (regimen, index) => (
              <option key={index} value={regimen["id"]}>
                {regimen["nombre"]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Calle"
          formatoLabel="tituloIzquierda"
          nameControl="calle"
          placeHolderControl="Ingresa la calle"
          valueControl={formik.values.calle}
          onChangeControl={formik.handleChange}
          error={formik.errors.calle}
          touched={formik.touched.calle}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Numero"
          formatoLabel="tituloIzquierda"
          nameControl="numero"
          placeHolderControl="Ingresa el numero"
          valueControl={formik.values.numero}
          onChangeControl={formik.handleChange}
          error={formik.errors.numero}
          touched={formik.touched.numero}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Colonia"
          formatoLabel="tituloIzquierda"
          nameControl="colonia"
          placeHolderControl="Ingresa el colonia"
          valueControl={formik.values.colonia}
          onChangeControl={formik.handleChange}
          error={formik.errors.colonia}
          touched={formik.touched.colonia}
        />
      </Row>

      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Ciudad"
          formatoLabel="tituloIzquierda"
          nameControl="ciudad"
          type="mobile"
          placeHolderControl="Ingresa la ciudad"
          valueControl={formik.values.ciudad}
          onChangeControl={formik.handleChange}
          error={formik.errors.ciudad}
          touched={formik.touched.ciudad}
        />

        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Estado"
          formatoLabel="tituloIzquierda"
          nameControl="estado"
          type="mobile"
          placeHolderControl="Ingresa el estado"
          valueControl={formik.values.estado}
          onChangeControl={formik.handleChange}
          error={formik.errors.estado}
          touched={formik.touched.estado}
        />

        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Codigo Postal"
          formatoLabel="tituloIzquierda"
          nameControl="cp"
          placeHolderControl="Ingresa el codigo postal"
          valueControl={formik.values.cp}
          onChangeControl={formik.handleChange}
          error={formik.errors.cp}
          touched={formik.touched.cp}
        />
      </Row>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Uso factura"
          formatoLabel="tituloIzquierda"
          nameControl="uso"
          placeHolderControl="Ingresa el uso de la factura"
          valueControl={formik.values.uso}
          onChangeControl={formik.handleChange}
          error={formik.errors.uso}
          touched={formik.touched.uso}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Correo electrónico"
          formatoLabel="tituloIzquierda"
          nameControl="email"
          placeHolderControl="Ingresa el correo del usuario"
          valueControl={formik.values.email}
          onChangeControl={formik.handleChange}
          error={formik.errors.email}
          touched={formik.touched.email}
        />

        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Telefono"
          formatoLabel="tituloIzquierda"
          nameControl="telefono"
          placeHolderControl="telefono"
          valueControl={formik.values.telefono}
          onChangeControl={formik.handleChange}
          error={formik.errors.telefono}
          touched={formik.touched.telefono}
        />
      </Row>
      <Row className="mb-3">
        <Form.Group controlId="formCheckbox" className="col col-sm-6">
          <Button variant="primary" type="submit" className="me-4 btn-block mb-3">
            Aceptar
          </Button>
          <Button
            variant="secondary"
            className="me-4 btn-block"
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </Button>
        </Form.Group>
      </Row>
    </Form>
  );
}

function initialValues(data) {
  return {
    razon: data?.razon_social || "",
    rfc: data?.rfc || "",
    regimen: 1,
    calle: data?.calle || "",
    numero: data?.numero || "",
    colonia: data?.colonia || "",
    ciudad: data?.ciudad || "",
    estado: data?.estado || "",
    cp: data?.codigo_potal || "",
    uso: data?.uso_factura || "",
    email: data?.mail_factura || "",
    telefono: data?.telefono_factura || "",
  };
}
function newSchema() {
  return {
    razon: Yup.string("El nombre de razon socila no puede ser vacio").required(
      "El nombre de razon social es requerido"
    ),
    rfc: Yup.string("El rfc no puede ser vacio").required(
      "El rfc es requerido"
    ),
    regimen: Yup.string("El regimen fiscal no puede ser vacio").required(
      "El regimen fiscal es requerido"
    ),
    calle: Yup.string("El telefono particular no puede ser vacio").required(
      "El telefono particular es requerido"
    ),
    numero: Yup.string("El numero no puede ser vacio").required(
      "El numero es requerido"
    ),
    colonia: Yup.string("La colonia no puede ser vacio").required(
      "La colonia es requerida"
    ),
    ciudad: Yup.string("La ciudad no puede ser vacia").required(
      "La ciudad es requerida"
    ),
    email: Yup.string("El mail no puede ser vacio")
      .email("No es un formato de mail valido")
      .required("El mail es requerido"),
    estado: Yup.string("El estado no puede ser vacio").required(
      "El estado es requerido"
    ),
    cp: Yup.string("El codigo postal es requerido").required(
      "El codigo postal es requerido"
    ),
    uso: Yup.string("El uso de la factura no puede ser vacio").required(
      "El uso de factura es requerido"
    ),
    telefono: Yup.string().required("El telefono es requerido"),
  };
}
