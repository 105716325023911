import React, { useState } from "react";
import { ComposableMap, Geographies } from "react-simple-maps";
import { StateCluster } from "../stateCluster";
import { ChartCluster } from "../chartCluster";
import mapaNiv1 from "../../../../maps/gadm41_MEX_1.json";

import { Col, Row } from "react-bootstrap";
import "./GeographyCluster.scss";
export function GeographyCluster(props) {

  const { colors } = props;
  const [data, setData] = useState(null);
  const [dataC, setDataC] = useState(null);

  return (
    <div>
      <Row className="content">
        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
          {dataC && (
            <div className="rigth">
              <ChartCluster data={dataC} setDataC={setDataC} />
            </div>
          )}
        </Col>
        <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
          <div className="map">
            <ComposableMap
              projection="geoAzimuthalEqualArea"
              projectionConfig={{
                rotate: [80, 1, 0],
                center: [-20, 20],
                scale: 1200,
              }}
            >
              <Geographies geography={mapaNiv1}>
                {({ geographies }) => (
                  <>
                    {geographies.map((geo) => (
                      <StateCluster
                        geog={geo}
                        key={geo.rsmKey}
                        colors={colors}
                        setData={setData}
                        setDataC={setDataC}
                        dataC={dataC}
                      />
                    ))}
                  </>
                )}
              </Geographies>
            </ComposableMap>
          </div>
        </Col>

        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
          {data && (
            <div className="left">
              <ChartCluster data={data} />
            </div>

          )}
        </Col>
      </Row>
    </div>
  );
}
