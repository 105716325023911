import React, { useState, useEffect } from "react";
import { ModalBasic } from "../../components/Common";
import { Row, Spinner } from "react-bootstrap";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import {
  HeaderPage,
  TableRequestAdmin,
  FormRequestClientAdmin,
} from "../../components/Admin";
import { useRequestClient, useCatalagos, useUser } from "../../hooks";
export function RequestClient_Admin() {
  const { loading, getServiciosSolicitados, serviciosSolicitados } =
    useRequestClient();
  const { getStatus, status, getSectores, sectores } = useCatalagos();
  const { users, getUsersFiltrados } = useUser();

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      getServiciosSolicitados();
      getStatus();
      getSectores();
      getUsersFiltrados(2);
    };
    fetchData();
  }, [refetch]);
  
  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateService = (data, alcanceGeografico) => {
    setTitleModal("Actualizar trabajo solicitado");
    setIconModal(faEdit);
    setContentModal(
      <FormRequestClientAdmin
        onClose={openCloseModal}
        onRefetch={onRefetch}
        service={data}
        status={status}
        alcanceGeografico={alcanceGeografico}
        sectores={sectores}
        operativos={users}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <Row>
        <HeaderPage title="Gestión de servicios" />
      </Row>
      <Row>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">
                Obteniendo información .....
              </span>
            </Spinner>
          </div>
        ) : (
          <TableRequestAdmin
            servicios={serviciosSolicitados}
            updateService={updateService}
          />
        )}
      </Row>
      <Row></Row>
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={"xl"}
      />
    </>
  );
}
