import React, { useState, useEffect, useCallback } from "react";
import { Form, Row, Button, Image, Col } from "react-bootstrap";
import { InputTags } from "react-bootstrap-tagsinput";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { map } from "lodash";
import { useFormik } from "formik";
import { useHolderjs } from "use-holderjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import "./AddEditEventoForm.scss";
import { useEventos } from "../../../../hooks";
import "react-bootstrap-tagsinput/dist/index.css";
import { Calendario } from "../../../../components/Common";
import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";

export function AddEditEventoform(props) {
  useHolderjs();

  const { onClose, onRefetch, paises, evento } = props;
  const [state, setState] = useState(evento?.keywords || []);
  const { addEvento, updateEvento } = useEventos();
  const [previewImage, setPreviewImage] = useState(
    evento ? (evento.imagen ? evento.imagen : "holder.js/400x300") : undefined
  );

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("imagen", file);
    setPreviewImage(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  useEffect(() => {
    const fetchData = async () => {
      formik.setFieldValue("keywords", state);
    };
    fetchData();
  }, [state]);

  const formik = useFormik({
    initialValues: initialValues(evento),
    validationSchema: Yup.object(evento ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      formValue.pais = formValue.pais.id || formValue.pais;
      try {
        if (evento) {
          await updateEvento(evento.id, formValue);
          toast.info("Evento se actualizo correctamente");
        } else {
          await addEvento(formValue);
          toast.info("Evento dado de alta correctamente");
        }
        onRefetch();
        onClose();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  return (
    <>
      <Form className="edit-evento" onSubmit={formik.handleSubmit}>
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-12 col-sm-12 col-lg-4">
            <Form.Label>Evento.</Form.Label>
            <Form.Control
              className="col-6 col-sm-4 col-lg-4"
              name="nombre"
              placeholder="Ingresa el nombre del evento"
              value={formik.values.nombre}
              onChange={formik.handleChange}
            />
            <Form.Text className="text-danger">
              {formik.touched.nombre && formik.errors.nombre ? (
                <div className="text-danger">{formik.errors.nombre}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-4">
            <Form.Label>Fecha de Evento</Form.Label>

            <Calendario
              className="col col-sm-4"
              name="fecha_evento"
              fecha={formik.values.fecha_evento}
              fcformik={formik}
            />
            <Form.Text className="text-danger">
              {formik.touched.fecha_evento && formik.errors.fecha_evento ? (
                <div className="text-danger">{formik.errors.fecha_evento}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col col-sm-6 col-lg-4">
            <Form.Label>Hora del Evento.</Form.Label>
            <Form.Control
              className="col col-sm-4"
              name="hora_evento"
              placeholder="HH:MM"
              value={formik.values.hora_evento}
              onChange={formik.handleChange}
            />
            <Form.Text className="text-danger">
              {formik.touched.hora_evento && formik.errors.hora_evento ? (
                <div className="text-danger">{formik.errors.hora_evento}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
        </Row>
        <Row className="mb-6">
          <Form.Group widths="equal">
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="descripcion"
              placeholder="Describe el Evento"
              value={formik.values.descripcion}
              onChange={formik.handleChange}
            />
            <Form.Text className="text-danger">
              {formik.touched.descripcion && formik.errors.descripcion ? (
                <div className="text-danger">{formik.errors.descripcion}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
        </Row>
        <Row className="mb-6">
          <Form.Group widths="equal" className="col col-sm-8 col-lg-4">
            <Form.Label>Autor.</Form.Label>
            <Form.Control
              className="col col-sm-4"
              name="autor"
              placeholder="Autor"
              value={formik.values.autor}
              onChange={formik.handleChange}
            />
            <Form.Text className="text-danger">
              {formik.touched.autor && formik.errors.autor ? (
                <div className="text-danger">{formik.errors.autor}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col col-sm-8 col-lg-4">
            <IngresaDatosForm
              tituloLabel="Palabras clave:"
              formatoLabel="tituloCentrado"
            />
            <div style={{ margin: 10 }}>
              <div className="input-group">
                <InputTags
                  values={state}
                  onTags={(value) => setState(value.values)}
                />
              </div>
            </div>
            <Form.Text className="text-danger">
              {formik.touched.keywords && formik.errors.keywords ? (
                <div className="text-danger">{formik.errors.keywords}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col col-sm-4 col-lg-4">
            <Form.Label>Modalidad.</Form.Label>
            <Form.Select
              name="modalidad"
              defaultValue={formik.values.modalidad}
              onChange={formik.handleChange}
            >
              <option value={1}>En Linea</option>
              <option value={2}>Presencial</option>
            </Form.Select>
          </Form.Group>
        </Row>
        {parseInt(formik.values.modalidad) === 2 ? (
          <div>
            <Row>
              <Form.Group widths="equal" className="col-12 col-sm-12 col-lg-4">
                <Form.Label>Sede.</Form.Label>
                <Form.Control
                  className="col col-sm-4"
                  name="sede"
                  placeholder="Sede del evento"
                  value={formik.values.sede}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal" className="col-12 col-sm-8 col-lg-4">
                <Form.Label>Calle.</Form.Label>
                <Form.Control
                  className="col col-sm-4"
                  name="calle"
                  placeholder="Calle"
                  value={formik.values.calle}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal" className="col-12 col-sm-4 col-lg-4">
                <Form.Label>Numero.</Form.Label>
                <Form.Control
                  className="col col-sm-6"
                  name="numero"
                  placeholder="Numero"
                  value={formik.values.numero}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-6">
              <Form.Group widths="equal" className="col-12 col-sm-6 col-lg-4">
                <Form.Label>Colonia.</Form.Label>
                <br />
                <Form.Control
                  className="col col-sm-4"
                  name="colonia"
                  placeholder="Colonia"
                  value={formik.values.colonia}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal" className="col-12 col-sm-6 col-lg-4">
                <Form.Label>Ciudad.</Form.Label>
                <Form.Control
                  className="col col-sm-4"
                  name="ciudad"
                  placeholder="Ciudad"
                  value={formik.values.ciudad}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group className="col-6 col-sm-4 col-lg-4">
                <Form.Label>Pais.</Form.Label>
                <Form.Select
                  name="pais"
                  defaultValue={formik.values.pais["id"]}
                  onChange={formik.handleChange}
                >
                  {map(paises, (pais, index) => (
                    <option key={index} value={pais["id"]}>
                      {pais["nombre"]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-6">
              <Form.Group widths="equal" className="col-6 col-sm-4 col-lg-4">
                <Form.Label>Codigo Postal.</Form.Label>
                <Form.Control
                  className="col col-sm-4"
                  name="cp"
                  placeholder="Codigo Postal"
                  value={formik.values.cp}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Row>
          </div>
        ) : null}
        <Row className="mb-6">
          <Form.Group widths="equal" className="col col-sm-12">
            <Form.Label>URL</Form.Label>
            <Form.Control
              className="col col-sm-4"
              name="url"
              placeholder="Pagina del evento"
              value={formik.values.url}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-6">
          <div className="contenedor">
            <Form.Group widths="equal">
              <Form.Label className="titulo">Imagen.</Form.Label>
              <input {...getInputProps()} />
              <Image src={previewImage} thumbnail />
              <Row>
                <Button type="button" variant="secondary" {...getRootProps()}>
                  <FontAwesomeIcon icon={faUpload} className="me-2" />
                  {evento ? "Cambiar imagen" : "Subir imagen"}
                </Button>
              </Row>
            </Form.Group>
          </div>
        </Row>

        <Button
          variant="primary"
          type="submit"
          className="edit-evento-crear mt-3"
        >
          {evento ? "Actualizar" : "Crear"}
        </Button>
      </Form>
    </>
  );

  function initialValues(data) {
    return {
      nombre: data?.nombre || "",
      descripcion: data?.descripcion || "",
      fecha_evento: data?.fecha_evento || "",
      hora_evento: data?.hora_evento || "",
      autor: data?.autor || "",
      keywords: data?.keywords || "",
      modalidad: data?.modalidad || 1,
      sede: data?.sede || "",
      calle: data?.calle || "",
      numero: data?.numero || 0,
      colonia: data?.colonia || "",
      ciudad: data?.ciudad || "",
      pais: data?.pais || 1,
      cp: data?.cp || "",
      url: data?.url || "",
      imagen: "",
    };
  }
  function newSchema() {
    return {
      nombre: Yup.string("El nombre no puede estar vacío").required(
        "El nombre es requerido"
      ),
      descripcion: Yup.string("La descripcion no puede estar vacío").required(
        "El descripcion es requerido"
      ),
      fecha_evento: Yup.string("Debe de haber una fecha de evento").required(
        "La fecha de evento es requerida"
      ),
      hora_evento: Yup.string("La hora de evento no puede ser vacia").required(
        "La hora del evento es requerida"
      ),
      autor: Yup.string("El autor no puede ser vacio").required(
        "El autor es requerido"
      ),
      modalidad: Yup.string("La modalidad no puede ser vacia").required(
        "La modalidad es requerida"
      ),
    };
  }

  function updateSchema() {
    return {
      nombre: Yup.string("El nombre no puede estar vacío").required(
        "El nombre es requerido"
      ),
      descripcion: Yup.string("La descripcion no puede estar vacío").required(
        "El descripcion es requerido"
      ),
      fecha_evento: Yup.string("Debe de haber una fecha de evento").required(
        "La fecha de evento es requerida"
      ),
      hora_evento: Yup.string("La hora de evento no puede ser vacia").required(
        "La hora del evento es requerida"
      ),
      autor: Yup.string("El autor no puede ser vacio").required(
        "El autor es requerido"
      ),
      modalidad: Yup.string("La modalidad no puede ser vacia").required(
        "La modalidad es requerida"
      ),
    };
  }
}
