import React from 'react'
import { Table, Button, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faPencil } from '@fortawesome/free-solid-svg-icons'

import { map } from 'lodash'
import './TableAlianzas.scss'

export function TableAlianzas(props) {
    const { alianzas, updateAlianza, deleteAlianzas } = props;
    return (
        <div className="table-responsive-xl">
            <Table striped bordered hover className='table-alianzas table-sm'>
                <thead>
                    <tr>
                        <th>Logo</th>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Link</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {map(alianzas, (alianza, index) => (
                        <tr key={index}>
                            <td><Image src={alianza.logo} className='foto' /></td>
                            <td>{alianza.nombre}</td>
                            <td>{alianza.descripcion}</td>
                            <td>{alianza.link}</td>

                            <Actions alianza={alianza} updateAlianza={updateAlianza} deleteAlianzas={deleteAlianzas} />
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )

    function Actions(props) {
        const { alianza, updateAlianza, deleteAlianzas } = props;

        return (
            <td>
                <Button
                    size='m'
                    variant='success'
                    onClick={() => updateAlianza(alianza)}
                    className='action-button green btn-boton'
                >
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
                <Button
                    size='m'
                    variant='danger'
                    onClick={() => deleteAlianzas(alianza)}
                    className='action-button red btn-boton'
                >
                   <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />

                </Button>
            </td>
        )
    }
}