import React, { useEffect, useState } from "react";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { toast } from "react-toastify";
import home from "../../../../images/b2b-template-1.png";
import icontacto from "../../../../images/contacto_template.png";
import {
  default as information_page_1,
  default as information_page_2,
  default as information_page_3,
} from "../../../../images/info_template_1.png";
import comprador_registro from "../../../../images/registry-customer-tmpl.png";
import vendedor_registro from "../../../../images/registry-tmpl.png";
import { FormaCategorias } from "./FormaCategorias";
import { FormaDatos } from "./FormaDatos";
import { FormaEdit } from "./FormaEdit";
import { FormaNombre } from "./FormaNombre";
import { FormaSeleccion } from "./FormaSeleccion";
import { FormaTema } from "./FormaTema";

import { useB2B } from "../../../../hooks";

export function Wizardb2b() {
  const [micrositio, setMicrositio] = useState({
    nombre: "",
  });
  const [datos, setDatos] = useState({
    nombre: "",
    email: "",
    telefono: "",
    fecha_inicio: "",
    fecha_fin: "",
    hora_inicio: "",
    hora_fin: "",
    capacidad_personas: "",
  });
  const urlSitioWeb = "http://b2b-sites.s3-website-us-east-1.amazonaws.com/";
  const [tema, setTema] = useState(undefined);
  const [keywords, setKeywords] = useState([
    { text: "Inicio", image: home, check: true },
    { text: "Registro Comprador", image: comprador_registro, check: true },
    { text: "Registro Vendedor", image: vendedor_registro, check: true },
    { text: "Contacto", image: icontacto, check: true },
    { text: "Página Información 1", image: information_page_1, check: false },
    { text: "Página Información 2", image: information_page_2, check: false },
    { text: "Página Información 3", image: information_page_3, check: false },
  ]);
  const { addMicrositeB2B } = useB2B();
  const [dataPages, setDataPages] = useState(undefined);
  const [categorias, setCategorias] = useState([]);
  const [editingName, setEditingName] = useState({
    type: null,
    index: null,
    name: "",
  });
  const [pagina, setPagina] = useState(undefined);
  const [buttonNext, setButtonNext] = useState(true);

  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleComplete = async () => {
    console.log("Form completed!");
    setLoading(true);
    await addMicrositeB2B(dataPages);
    console.log("datitos");
    console.log(dataPages);
    setLoading(false);
    setCompleted(true);
  };

  const tabChanged = ({ prevIndex, nextIndex }) => {
    setPagina(prevIndex + 1);
  };

  const isObjectEmpty = (objectName) => {
    let claves = Object.keys(objectName);
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      if (!objectName[clave]) {
        return false;
      }
    }
    return true;
  };

  console.log("Buon", buttonNext);

  const actionButton = (handleNext) => {
    switch (pagina) {
      case 1:
        if (micrositio.nombre) {
          handleNext();
        } else {
        }
        break;
      case 2:
        if (isObjectEmpty(datos)) {
          handleNext();
        } else {
          toast.error("Necesita ingresar todos los datos");
        }
        break;
      case 3:
        if (tema) {
          handleNext();
        } else {
          toast.error("Necesitas escojer un tema");
        }
        break;
      case 4:
        handleNext();
        break;
      case 5:
        if (categorias.length > 0) {
          for (let i = 0; i < categorias.length; i++) {
            console.log(categorias[i].subcategorias);
            if (categorias[i].subcategorias.length <= 0) {
              toast.error(
                "Necesitas agregar almenos una Subcategoria en todas las categorias"
              );
              return;
            }
          }
          handleNext();
        } else {
          toast.error("Necesitas agregar almenos una categoria");
        }
        break;
      case 6:
        const final = {
          nombre: micrositio,
          datosOrga: datos,
          temaSitio: tema,
          paginas: keywords,
          categorias,
          inicio: {
            logotipo: logo,
            redes: social,
            bannerPrincipal: principal,
            informacionGeneral: infoGeneral,
            bannerInformativo: bannerInfo,
            galeriaInformativa: {
              tituloGaleria: tituloGale,
              columnas: colElements,
            },
          },
          paginasDeInformacion: paginas,
          paginaContacto: contacto,
        };
        console.log("datos  ", final);
        setDataPages(final);
        break;
      default:
        break;
    }
    handleNext();
  };

  //estados de la pantalla inicio
  const [logo, setLogo] = useState();
  const [social, setSocial] = useState({
    facebook: "",
    instagram: "",
    linkedin: "",
    twitter: "",
    youtube: "",
  });

  const [principal, setPrincipal] = useState({
    tituloEvento: "",
    anfrition: "",
    description: "",
    enlace: "",
    color: "",
    imagen: "",
    modalidad: "",
  });
  const [infoGeneral, setInfoGeneral] = useState({
    tituloSeccion: "",
    titulo1: "",
    texto1: "",
    icono1: "",
    titulo2: "",
    texto2: "",
    icono2: "",
    titulo3: "",
    texto3: "",
    icono3: "",
  });
  const [bannerInfo, setbannerInfo] = useState({
    colorBanner: "",
    imagen: "",
    titulo: "",
    texto: "",
    tituloContacto: "",
    subtituloConacto: "",
    asunto: false,
    nombre: true,
    telefono: false,
    correo: true,
  });
  const [tituloGale, setTituloGale] = useState({ titulo: "" });
  const [colElements, setColElements] = useState([{}]);

  const inicio = {
    logo,
    setLogo,
    social,
    setSocial,
    principal,
    setPrincipal,
    infoGeneral,
    setInfoGeneral,
    bannerInfo,
    setbannerInfo,
    tituloGale,
    setTituloGale,
    colElements,
    setColElements,
  };

  const [paginas, setPaginas] = useState([
    {
      titulo: {
        titulo: "",
        color: "",
        imagen: "",
      },
      informacionGen: {
        titulo: "",
        texto: "",
        imagen: "",
      },
      infoCom: {
        titulo1: "",
        texto1: "",
        titulo2: "",
        texto2: "",
        titulo3: "",
        texto3: "",
        titulo4: "",
        texto4: "",
        icono1: "",
        icono2: "",
        icono3: "",
        icono4: "",
      },
    },
    {
      titulo: {
        titulo: "",
        color: "",
        imagen: "",
      },
      informacionGen: {
        titulo: "",
        texto: "",
        imagen: "",
      },
      infoCom: {
        titulo1: "",
        texto1: "",
        titulo2: "",
        texto2: "",
        titulo3: "",
        texto3: "",
        titulo4: "",
        texto4: "",
        icono1: "",
        icono2: "",
        icono3: "",
        icono4: "",
      },
    },
    {
      titulo: {
        titulo: "",
        color: "",
        imagen: "",
      },
      informacionGen: {
        titulo: "",
        texto: "",
        imagen: "",
      },
      infoCom: {
        titulo1: "",
        texto1: "",
        titulo2: "",
        texto2: "",
        titulo3: "",
        texto3: "",
        titulo4: "",
        texto4: "",
        icono1: "",
        icono2: "",
        icono3: "",
        icono4: "",
      },
    },
  ]);

  const informacion = {
    paginas,
    setPaginas,
  };

  //contacto
  const [contacto, setContacto] = useState({
    direccion: "",
    email: "",
    telefono: "",
    api_google:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7525.370368095734!2d-99.19429027436217!3d19.426003638461772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d201b420c8c849%3A0x84231ae36f6ec524!2sMuseo%20Nacional%20de%20Antropolog%C3%ADa!5e0!3m2!1ses-419!2smx!4v1693595524865!5m2!1ses-419!2smx",
  });

  const informacionContacto = {
    contacto,
    setContacto,
  };

  useEffect(() => { }, [principal, buttonNext]);

  return (
    <>
      <FormWizard
        shape="circle"
        color="#00AEEF"
        stepSize="xs"
        onTabChange={tabChanged}
        nextButtonTemplate={(handleNext) =>
          buttonNext ? (
            <button
              className="base-button"
              onClick={() => actionButton(handleNext)}
            >
              Guardar y continuar
            </button>
          ) : (
            <button
              className="base-button"
              onClick={() =>
                toast.error(
                  "Por favor llena todos los datos ó\ningresa datos válidos"
                )
              }
            >
              Guardar y continuar
            </button>
          )
        }
        backButtonTemplate={(handlePrevious) => (
          <button className="base-button" onClick={handlePrevious}>
            back
          </button>
        )}
        finishButtonTemplate={() => (
          <button className="finish-button" onClick={handleComplete} disabled={loading}>
            {loading ? "Cargando..." : "Finish"}
          </button>
        )}
      >
        <FormWizard.TabContent title="Nombre" icon="fa-solid fa-globe">
          <FormaNombre
            datos={micrositio}
            setDatos={setMicrositio}
            setButtonNext={setButtonNext}
          />
        </FormWizard.TabContent>

        <FormWizard.TabContent title="Datos" icon="fa-solid fa-user">
          <FormaDatos
            datos={datos}
            setDatos={setDatos}
            setButtonNext={setButtonNext}
          />
        </FormWizard.TabContent>

        <FormWizard.TabContent title="Tema" icon="fa-solid fa-palette">
          <FormaTema
            tema={tema}
            setTema={setTema}
            setButtonNext={setButtonNext}
          />
        </FormWizard.TabContent>

        <FormWizard.TabContent
          title="Seleccion"
          icon="fa-solid fa-hand-pointer"
        >
          <FormaSeleccion
            keywords={keywords}
            setKeywords={setKeywords}
            setButtonNext={setButtonNext}
          />
        </FormWizard.TabContent>

        <FormWizard.TabContent
          title="Categorias"
          icon="fa-regular fa-object-ungroup"
        >
          <FormaCategorias
            categorias={categorias}
            setCategorias={setCategorias}
            editingName={editingName}
            setEditingName={setEditingName}
            setButtonNext={setButtonNext}
          />
        </FormWizard.TabContent>

        <FormWizard.TabContent title="Editar" icon="fa-solid fa-file-pen">
          <FormaEdit
            keywords={keywords}
            inicio={inicio}
            informacion={informacion}
            informacionContacto={informacionContacto}
            setButtonNext={setButtonNext}
            datos={datos}
          />
        </FormWizard.TabContent>
        <FormWizard.TabContent title="Publicar" icon="fa-solid fa-upload">
          <h1>Publicar</h1>
          <p>Some content for the last tab</p>
          {completed && (
            <a href={`${urlSitioWeb}${micrositio.nombre}`} target="_blank" rel="noopener noreferrer">
              Ver mi sitio web
            </a>
          )}
        </FormWizard.TabContent>
      </FormWizard>
      {/* add style */}
      <style>{`
       @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");
       .wizard-card-footer{
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
      .base-button {
        background-color: #00a3f2;
        border: none;
        color: white;
        padding: 15px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 20px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s ease;
        }
        
        .base-button:hover {
        background-color: navy;
        }
        
        .base-button:focus {
        outline: none;
        }
        
        .base-button:active {
        transform: translateY(2px);
        }

      .finish-button{
        background-color: green;
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 50px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s ease;
      }
      .finish-button:hover {
        background-color: darkgreen;
        }
      
      .finish-button:focus {
        outline: none;
       }
        
      .finish-button:active {
        transform: translateY(2px);
       }
      `}</style>
    </>
  );
}
