import React from 'react'
import { map } from 'lodash';
import { Card, ProgressBar, Row, Col } from 'react-bootstrap'

import "./DescriptionCluster.scss"

export function DescriptionCluster(props) {

    const { minValue, maxValue, medValue, colors } = props;
    console.log(colors);
    return (
        <div>
            <Card>
                <Card.Body>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <ProgressBar>
                                {map(colors, (color, index) => (
                                    <ProgressBar
                                        style={{ backgroundColor: `hsl(${color['hsl']['h']}, ${color['hsl']['s']}%, ${color['hsl']['l']}%)` }}
                                        now={10}
                                        key={index}
                                    />
                                ))}
                            </ProgressBar>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <h5 className='leftTitle'>
                                {minValue || 750}
                            </h5>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <h5 className='centerTitle'>
                                media = {medValue || 1000}
                            </h5>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <h5 className='rigthTitle'>
                                {maxValue || 1250}
                            </h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}

/*

xs 	
The number of columns to span on extra small devices (<576px)

sm 	
The number of columns to span on small devices (≥576px)

md 	
The number of columns to span on medium devices (≥768px)

lg 	
The number of columns to span on large devices (≥992px)

xl 		
The number of columns to span on extra large devices (≥1200px)

xxl
The number of columns to span on extra extra large devices (≥1400px)

*/
