import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  faNewspaper,
  faEdit,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";

import { useNoticia, useSectoresN } from "../../../hooks";
import {
  HeaderPage,
  AddEditNewsForm,
  DeleteNewsForm,
  TableNews,
} from "../../../components/Admin";
import { ModalBasic } from "../../../components/Common";

export function Noticias() {
  const { loading, noticia, getNoticias, getNoticiasFiltrdas } = useNoticia();
  const { getSectoresN, sectoresNoti } = useSectoresN();
  const [filtro, setFiltro] = useState("todos");

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("sm");

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    const fetchData = async () => {
      await getSectoresN();
      if (filtro == "todos") await getNoticias();
      else await getNoticiasFiltrdas(filtro);
    };
    fetchData();
  }, [refetch]);

  console.log("sECTORES noticias: ",sectoresNoti);
  console.log("noricias: ",noticia);

  const addNew = () => {
    setTitleModal("Crear Noticia");
    setIconModal(faNewspaper);
    setsizeModal("xl");
    setContentModal(
      <AddEditNewsForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectoresNoti}
      />
    );
    openCloseModal();
  };

  const updateNoticia = (data) => {
    setTitleModal("Actualizar Noticia");
    setIconModal(faEdit);
    setsizeModal("xl");
    setContentModal(
      <AddEditNewsForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        noticia={data}
        sectores={sectoresNoti}
      />
    );
    openCloseModal();
  };

  const onDeleteNews = async (data) => {
    setTitleModal("Borrar Noticia");
    setIconModal(faDeleteLeft);
    setsizeModal("sm");
    setContentModal(
      <DeleteNewsForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        noticia={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <HeaderPage title="Noticias" btnTitle="Nueva Noticia" btnClick={addNew} />


      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <TableNews
          noticias={noticia}
          updateNoticia={updateNoticia}
          onDeleteNews={onDeleteNews}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
