import React from "react";
import "./Descripcion.scss";

export function Descripcion() {
  return (
    <div className="descripcionRecursos">
      <div className="justify-content-center">
        <h1 className="tituloRecursos">La información que buscas en un clic</h1>
        <p className="infoRecursos">
          Encuentra la información que necesitas para completar la investigación,
          aquí encontraras artículos y videos especializados;
          realizados por los expertos del OTS.
        </p>
      </div>
    </div>
  );
}
