import { useState } from "react";
import {
    getServicioGeneralApi,
    setServicioGeneralApi,
    editServicioGeneralApi,
    deleteServicioGeneralApi,
    getSolicitudPeticionApi,
    getSolicitudPeticionOperativoApi,
    setSolicitudPeticionApi,
    deleteSolicitudPeticionApi,
    sendMailPeticionServicioApi,
    getWebinarApi,
    setWebinarApi,
    editWebinarApi,
    deleteWebinarApi,
    getEstudioInteligenciaApi,
    setEstudioInteligenciaApi,
    editEstudioInteligenciaApi,
    deleteEstudioInteligenciaApi,
    editServicioGeneralAdminApi
} from "../api/SolicitudServicio";
import { useAuth, usePlanPago } from "./";

export function useSolicitudServicios() {
    const [loading, setloading] = useState(true);
    const [error, setError] = useState(false);
    const [serviciosGenerales, setServiciosGenerales] = useState(null);
    const [solicitudPeticiones, setSolucitudPeticiones] = useState(null);
    const [webinars, setWebinars] = useState(null);
    const [estudiosInteligencia, setEstudiosInteligencia] = useState(null);
    const { auth } = useAuth();
    const { getClienteIdUser, clienteIdUser } = usePlanPago();

    const getServicioGeneral = async () => {
        try {
            setloading(true);
            const response = await getServicioGeneralApi(auth.token);
            setloading(false);
            setServiciosGenerales(response);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const addServicioGeneral = async (data) => {
        try {
            setloading(true);
            await getClienteIdUser(auth.me['id']);
            const response = await setServicioGeneralApi(data, clienteIdUser.id, auth.token);
            setloading(false);
            return response
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const editServicioGeneral = async (data, idServicio) => {
        try {
            setloading(true);
            await editServicioGeneralApi(data, idServicio, auth.token);
            setloading(false);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const editServicioGeneralAdmin = async (data, idServicio) => {
        try {
            setloading(true);
            await editServicioGeneralAdminApi(data, idServicio, auth.token);
            setloading(false);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const deleteServicioGeneral = async (id) => {
        try {
            setloading(true);
            await deleteServicioGeneralApi(id, auth.token);
            setloading(false);
        } catch (error) {
            setError(error);
            setloading(false);
        }
    };

    const getSolicitudPeticion = async (tipoPeticion, idCliente) => {
        try {
            setloading(true);
            const response = await getSolicitudPeticionApi(tipoPeticion, idCliente, auth.token);
            setloading(false);
            setSolucitudPeticiones(response);
        } catch (error) {
            setloading(false);
            setError(error);
            console.log(error);
        }
    };

    const getSolicitudPeticionOperativo = async (tipoPeticion) => {
        try {
            setloading(true);
            const response = await getSolicitudPeticionOperativoApi(tipoPeticion, auth.token);
            setloading(false);
            setSolucitudPeticiones(response);
        } catch (error) {
            setloading(false);
            setError(error);
            console.log(error);
        }
    };

    const addSolicitudPeticion = async (tipoPeticion, data) => {
        try {
            setloading(true);
            await setSolicitudPeticionApi(auth.me['id'], tipoPeticion, data, auth.token);
            setloading(false);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const deleteSolicitudPeticion = async (id) => {
        try {
            setloading(true);
            await deleteSolicitudPeticionApi(id, auth.token);
            setloading(false);
        } catch (error) {
            setError(error);
            setloading(false);
        }
    };

    const sendMailSolicitudPeticion = async (data) => {
        try {
            console.log(data)
            await sendMailPeticionServicioApi(data, auth.token);
        } catch (error) {
            setError(error);
        }
    };

    const getWebinar = async () => {
        try {
            setloading(true);
            const response = await getWebinarApi(auth.token);
            setloading(false);
            setWebinars(response);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const addWebinar = async (data) => {
        try {
            setloading(true);
            await setWebinarApi(data, auth.token);
            setloading(false);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const editWebinar = async (data, idWebinar) => {
        try {
            setloading(true);
            await editWebinarApi(data, idWebinar, auth.token);
            setloading(false);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const deleteWebinar = async (id) => {
        try {
            setloading(true);
            await deleteWebinarApi(id, auth.token);
            setloading(false);
        } catch (error) {
            setError(error);
            setloading(false);
        }
    };

    const getEstudioInteligencia = async () => {
        try {
            setloading(true);
            const response = await getEstudioInteligenciaApi(auth.token);
            setloading(false);
            setEstudiosInteligencia(response);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const addEstudioInteligencia = async (data) => {
        try {
            setloading(true);
            await setEstudioInteligenciaApi(data, auth.token);
            setloading(false);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const editEstudioInteligencia = async (data, idEstudio) => {
        try {
            setloading(true);
            await editEstudioInteligenciaApi(data, idEstudio, auth.token);
            setloading(false);
        } catch (error) {
            setloading(false);
            setError(error);
        }
    };

    const deleteEstudioInteligencia = async (id) => {
        try {
            setloading(true);
            await deleteEstudioInteligenciaApi(id, auth.token);
            setloading(false);
        } catch (error) {
            setError(error);
            setloading(false);
        }
    };

    return {
        loading,
        error,
        serviciosGenerales,
        solicitudPeticiones,
        webinars,
        estudiosInteligencia,
        getServicioGeneral,
        addServicioGeneral,
        editServicioGeneral,
        editServicioGeneralAdmin,
        deleteServicioGeneral,
        getSolicitudPeticion,
        getSolicitudPeticionOperativo,
        addSolicitudPeticion,
        deleteSolicitudPeticion,
        sendMailSolicitudPeticion,
        getWebinar,
        addWebinar,
        editWebinar,
        deleteWebinar,
        getEstudioInteligencia,
        addEstudioInteligencia,
        editEstudioInteligencia,
        deleteEstudioInteligencia,
        sendMailSolicitudPeticion
    };
}


