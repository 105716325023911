import React, { useState, useEffect } from 'react'
import {
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { Spinner } from 'react-bootstrap';

import { HeaderPage, MuestraCarousel, EditFormCarousel } from "../../components/Admin"
import { useCarousel } from '../../hooks';
import { ModalBasic } from "../../components/Common";

export function Carousel() {

  const { loading, carousel, getCarousel } = useCarousel();

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("sm");

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    getCarousel(carousel);
  }, [refetch]);

  console.log();

  const setCarousel = (data) => {
    setTitleModal("Nueva imagen carousel");
    setIconModal(faEdit);
    setContentModal(
      <EditFormCarousel
        onClose={openCloseModal}
        onRefetch={onRefetch}
        carousel={data}
      />
    );
    openCloseModal();
  };

  const updateCarousel = (data) => {
    setTitleModal("Actualizar carousel");
    setIconModal(faEdit);
    setContentModal(
      <EditFormCarousel
        onClose={openCloseModal}
        onRefetch={onRefetch}
        carousel={data}
      />
    );
    openCloseModal();
  };


  return (
    <>
      <HeaderPage
        title="Editor de imagenes del Carousel."
      />

      {
        loading
          ?
          <Spinner animation="border" role="status" variant='info'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <MuestraCarousel
            carousels={carousel}
            updateFormCarousel={updateCarousel}
            addCarousel={setCarousel}
          />
      }



      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        size={sizeModal}
        children={contentModal}
      />
    </>
  )
}


