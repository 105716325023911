import React from "react";
import { ResetPasswordForm } from "../../components/Generales";

export function updatePass() {
  return (
    <>
      <ResetPasswordForm />
    </>
  );
}
