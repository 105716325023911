import React from "react";
import { Tab, Tabs, Container, Row } from "react-bootstrap";
import { Wizardb2b } from "../../../components/Admin";
export function PageB2b() {
  return (
    // hola
    <Container className="m-4 p-0 border rounded w-90">
      <Tabs
        defaultActiveKey="crear"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="crear" title="Crear Micrositio">
          <Wizardb2b />
        </Tab>
        <Tab eventKey="interno" title="B2B Interno">
          B2B interno
        </Tab>
      </Tabs>
    </Container>
  );
}
