import React from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import "react-form-wizard-component/dist/style.css";
import "./FormaSeleccion.scss";



export function FormaSeleccion(props) {

  const {keywords,setKeywords, setButtonNext} = props;

  const change = (index) => {
    const data = [...keywords]
    data[index].check=!data[index].check
    setKeywords(data)
  }

  

  return (
    <Container className="w-750">
      <Form className="w-100">
        <Row>
          <Col md={12}>
            <label className="d-flex justify-content-start text-bold fs-5-5 text-resal mb-3">
              Elige las páginas que integrarán el micrositio
            </label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3 className="text-start fs-5-5 text-bold mb-2">
              Las páginas seleccionadas son obligatorias, puedes seleccionar hasta 3 páginas informativas.
            </h3>
          </Col>
        </Row>
        <div>
          <Row>
            {keywords.map((keyword, index) => (
              <Col key={index} xs={2} sm={6} md={6} lg={3} mt={2}>
                <div className="flex-column align-items-right">
                  <span
                    className="d-flex justify-content-start text-bold fs-4-5 text-resal"
                    style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                    <input
                      type="checkbox"
                      name={keyword.name}
                      checked={keyword.check}
                      onChange={()=>change(index)}
                      disabled={(index<4)}
                      className="me-1 mb-1 align-item-right check-box-style"
                      style={{ width: "15px", height: "15px" }}
                    />
                    {keyword.text || keyword}
                  </span>
                  {keyword.image && (
                    <div className="mt-6 md-2 sm-1 lg-1 xs-1 d-flex justify-content-center imagen-style">
                      <img src={keyword.image} alt={keyword.text || keyword} className="img-fluid" style={{width:"400px", height:"400px", objectFit:"cover"}} />
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Form>
    </Container>
  );
}
