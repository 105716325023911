import React from 'react'
import './ViewQuestionsServicios.scss'

export function ViewQuestionsServicios() {
    return (
        <div>
            <div className='container_Servicio'>
                <h3 className='questions_title_Servicio'>¿Cómo solicito un servicio?</h3>
                <p className='question_description'>
                    Hay servicios incluidos en tu membresía que no requieren ser solicitados, puedes acceder a ellos de manera permanente ingresando a tu sesión.
                </p>
                <p className='description_Servicio'>
                    Los servicios de carácter especializado para tu empresa, deberás dar clic en mostrar más para ingresar al detalle de lo que es el servicio, y posteriormente dar clic en solicitar,
                    llenar el formulario correspondiente para que el área de OTS se contacte contigo y atienda tus necesidades.
                </p>
            </div>
            <div className='container_Servicio'>
                <h3 className='questions_title_Servicio'>¿Cómo pago un servicio especializado?</h3>
                <p className='description_Servicio'>
                    Seleccionar el servicio, dar clic en solicitar, llenar el formulario y dar clic en enviar, en un lapso no mayor a 48 horas el equipo de
                    OTS se contactará contigo para saber tus requerimientos y te proporcionará la cuenta bancaria para realizar la transferencia del costo del servicio.
                </p>
            </div>
        </div>
    )
}
