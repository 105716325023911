import React, { useState, useEffect } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { Form, Row, Button, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilm } from "@fortawesome/free-solid-svg-icons";

import { useSolicitudServicios } from "../../../../../hooks"
import { IngresaDatosForm } from '../../../../Generales';
import { formatDateMonthName } from '../../../../../metodosGlobales';
import "./AddEditWebinars.scss"

export function AddEditWebinarsOperativo(props) {

  const { onRefetch, onClose, webinar } = props;
  const {
    addWebinar,
    editWebinar,
  } = useSolicitudServicios();
  const [loading, setloading] = useState(null);
  const [videoPreview, setVideoPreview] = useState(webinar?.video || null);

  async function handleUpload(event) {
    setVideoPreview(event.target.files[0]);
    const file = event.target.files[0]
    await formik.setFieldValue("video", file);
  }

  useEffect(() => {
  }, [loading])

  console.log(webinar);


  const formik = useFormik({
    initialValues: initialValues(webinar),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      setloading(true);
      if (webinar) await editWebinar(formvalue, webinar.id);
      else await addWebinar(formvalue);
      onRefetch();
      onClose();
    },
  });

  return (
    <div>
      <Form className="" onSubmit={formik.handleSubmit}>
        <IngresaDatosForm
          tituloLabel={
            webinar
              ? "Formulario de edición de Webinar."
              : "Agregar nuevo Webinar."
          }
          formatoLabel="tituloCentrado"
        />
        <br />
        <Row className="mb-6">
          <Form.Group className="col col-sm-6">
            <IngresaDatosForm
              tituloLabel="Título de webinar"
              formatoLabel="tituloCentrado"
              nameControl="tituloWebinar"
              type="text"
              placeHolderControl="Ingresa el titulo de visualización del Webinar"
              valueControl={formik.values.tituloWebinar}
              onChangeControl={formik.handleChange}
              error={formik.errors.tituloWebinar}
              touched={formik.touched.tituloWebinar}
            />
          </Form.Group>
          <Form.Group className="col col-sm-6">
            <IngresaDatosForm
              tituloLabel="Autor del Webinar"
              formatoLabel="tituloCentrado"
              nameControl="autorWebinar"
              type="text"
              placeHolderControl="Autor del webinar"
              valueControl={formik.values.autorWebinar}
              onChangeControl={formik.handleChange}
              error={formik.errors.autorWebinar}
              touched={formik.touched.autorWebinar}
            />
          </Form.Group>
        </Row>
        <br />
        <Row className="mb-6">
          <Form.Group className="col-6 col-sm-6 col-lg-4">
            <IngresaDatosForm
              tituloLabel="link del webinar"
              formatoLabel="tituloCentrado"
              nameControl="link"
              type="text"
              placeHolderControl="link de Webinar"
              valueControl={formik.values.link}
              onChangeControl={formik.handleChange}
              error={formik.errors.link}
              touched={formik.touched.link}
            />
          </Form.Group>
          <Form.Group className="col-6 col-sm-6 col-lg-3">
            <IngresaDatosForm
              tituloLabel="Fecha de alta"
              formatoLabel="tituloCentrado"
            />
            <Form.Control
              placeholder={`${formatDateMonthName(formik.values.fecha_alta)}`}
              disabled
            />
          </Form.Group>
          <Form.Group className='col-12 col-sm-12 col-lg-5'>
            <IngresaDatosForm
              tituloLabel={<FontAwesomeIcon className='icon_webinar' icon={faFilm} />}
              formatoLabel="tituloCentrado"
            />
            <input
              type="file"
              onChange={handleUpload}
              className="text"
              accept="video/*"
            />
            {videoPreview
              ?
              <div className='formatoTexto'>
                <IngresaDatosForm
                  tituloLabel={mostrarNombreVideo(videoPreview)}
                  formatoLabel="tituloRecortado"
                />
              </div>
              : null
            }

          </Form.Group>
        </Row>

        <br />
        <IngresaDatosForm
          tituloLabel="Descripción del Webinar"
          formatoLabel="tituloCentrado"
          nameControl="descripcionWebinar"
          rows={3}
          placeHolderControl="Descripción del webinar"
          valueControl={formik.values.descripcionWebinar}
          onChangeControl={formik.handleChange}
          error={formik.errors.descripcionWebinar}
          touched={formik.touched.descripcionWebinar}
        />
        <br />

        <Row className='mb-6'>
          <Form.Group className='col-12'>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading
                ? <>
                  <Spinner animation="grow" size="sm" />
                  {' '}
                  Subiendo Webinar...
                </>
                : "Subir Webinar"
              }
            </Button>
          </Form.Group>
        </Row>

      </Form>
    </div>
  )
}

function initialValues(data) {
  return {
    tituloWebinar: data?.tituloWebinar || "",
    autorWebinar: data?.autorWebinar || "",
    descripcionWebinar: data?.descripcionWebinar || "",
    link: data?.link || "",
    fecha_alta: data?.fecha_alta || new Date().toISOString(),
    video: ""
  };
}

function newSchema() {
  return {
    objetivoServicio: Yup.string("Debe de haber un objetivo")
      .required("Es necesario especificar el objetivo del reporte"),
    indicadores: Yup.string("Debe especificar un requerimiento")
      .required("Debe especificar un requerimiento"),
  };
}

function mostrarNombreVideo(video) {
  let array;
  if (video.lenght > 64) {
    const nombreRecortado = video.slice(64);
    array = nombreRecortado.split('?');
  } else
    return video['name']
  return array[0]
}