import React from "react";
import { useParams } from "react-router-dom";

import { EventList } from "../../components/Client";

export function EventosLista() {

  const {clave} = useParams();

  if (clave) return <EventList clave = {clave}/>;
  else return <EventList />;
}
