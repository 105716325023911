import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { DetalleSuccess } from "../../../components/Client";
import "./Pagos.scss";

export function PagoSuccess() {
  const { clave } = useParams();

  return <DetalleSuccess clave={clave}></DetalleSuccess>;
}
