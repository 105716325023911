import { Container, Form, Row } from "react-bootstrap";
import React, { useEffect, useState, Select } from "react";
import { IngresaDatosForm } from "../../../Generales";
import "./CompanyProducts.scss";

export function CompanyProducts() {

  return (
    <>
      <Container>
        <header>
          <center>
            <h1 className="bar-pro">Ofertas</h1>
            <h2 className="bar-pro2">
              Complete datos de tu Empresa para ofertar productos
            </h2>
          </center>
        </header>

        <Form>
          <Row>
            <IngresaDatosForm
              col="col-10 col-sm-6 col-xl-3"
              tituloLabel="Nombre."
              formatoLabel="tituloCentrado"
              nameControl="titular_noticia"
              type="text"
              placeHolderControl="Ingresa su nombre"
              // valueControl={formik.values.titular_noticia}
              // onChangeControl={formik.handleChange}
              // error={formik.errors.titular_noticia}
              // touched={formik.touched.titular_noticia}
            />
            <IngresaDatosForm
              col="col-10 col-sm-6 col-xl-3"
              tituloLabel="Apellido Paterno."
              formatoLabel="tituloCentrado"
              nameControl="titular_noticia"
              type="text"
              placeHolderControl="Ingresa apellido paterno"
              // valueControl={formik.values.titular_noticia}
              // onChangeControl={formik.handleChange}
              // error={formik.errors.titular_noticia}
              // touched={formik.touched.titular_noticia}
            />
            <IngresaDatosForm
              col="col-10 col-sm-6 col-xl-3"
              tituloLabel="Apellido Materno."
              formatoLabel="tituloCentrado"
              nameControl="titular_noticia"
              type="text"
              placeHolderControl="Ingresa su apellido materno"
              // valueControl={formik.values.titular_noticia}
              // onChangeControl={formik.handleChange}
              // error={formik.errors.titular_noticia}
              // touched={formik.touched.titular_noticia}
            />
            <IngresaDatosForm
              col="col-10 col-sm-6 col-xl-3"
              tituloLabel="Telefono."
              formatoLabel="tituloCentrado"
              nameControl="titular_noticia"
              type="text"
              placeHolderControl="Ingresa el Telefono"
              // valueControl={formik.values.titular_noticia}
              // onChangeControl={formik.handleChange}
              // error={formik.errors.titular_noticia}
              // touched={formik.touched.titular_noticia}
            />
            <IngresaDatosForm
              col="col-12 col-sm-6 col-xl-4"
              tituloLabel="Empresa."
              formatoLabel="tituloCentrado"
              nameControl="titular_noticia"
              type="text"
              placeHolderControl="Ingresa el nombre de la empresa"
              // valueControl={formik.values.titular_noticia}
              // onChangeControl={formik.handleChange}
              // error={formik.errors.titular_noticia}
              // touched={formik.touched.titular_noticia}
            />
            <IngresaDatosForm
              col="col-12 col-sm-6 col-xl-4"
              tituloLabel="Cargo."
              formatoLabel="tituloCentrado"
              nameControl="titular_noticia"
              type="text"
              placeHolderControl="Ingresa el cargo"
              // valueControl={formik.values.titular_noticia}
              // onChangeControl={formik.handleChange}
              // error={formik.errors.titular_noticia}
              // touched={formik.touched.titular_noticia}
            />
            <Form.Group controlId="sector" className="col-12 col-sm-6 col-xl-4">
              <Form.Label>Sector:</Form.Label>
              <Form.Control as="select">
                <option value="" disabled selected>
                  Selecciona un sector
                </option>
                <option value="xd">xd</option>
                <option value="dx">dx</option>
              </Form.Control>
            </Form.Group>
            <IngresaDatosForm
              col="col-12 col-sm-12 col-xl-8"
              tituloLabel="Descripcion."
              formatoLabel="tituloCentrado"
              nameControl="titular_noticia"
              type="text"
              style={{ height: "300px" }}
              placeHolderControl="Descripcion"
              // valueControl={formik.values.titular_noticia}
              // onChangeControl={formik.handleChange}
              // error={formik.errors.titular_noticia}
              // touched={formik.touched.titular_noticia}
            />
            <IngresaDatosForm
              col="col-12 col-sm-4 col-xl-4"
              tituloLabel="Subir Archivo:"
              formatoLabel="tituloCentrado"
              nameControl="archivo_noticia"
              type="file"
              // onChangeControl={formik.handleChange}
              // error={formik.errors.archivo_noticia}
              // touched={formik.touched.archivo_noticia}
            />
            <IngresaDatosForm
              col="col-12 col-sm-6 col-xl-6"
              tituloLabel="Sitio web de la empresa."
              formatoLabel="tituloCentrado"
              nameControl="sitio_web"
              pattern="https?://.*"
              type="text"
              placeHolderControl="Ingresa el link"
              // valueControl={formik.values.titular_noticia}
              // onChangeControl={formik.handleChange}
              // error={formik.errors.titular_noticia}
              // touched={formik.touched.titular_noticia}
            />
            <IngresaDatosForm
              col="col-12 col-sm-6 col-xl-6"
              tituloLabel="Correo electrónico:"
              formatoLabel="tituloCentrado"
              nameControl="correo_electronico"
              type="email"
              placeHolderControl="Ingresa tu correo electrónico"
              // valueControl={formik.values.correo_electronico}
              // onChangeControl={formik.handleChange}
              // error={formik.errors.correo_electronico}
              // touched={formik.touched.correo_electronico}
            />
          </Row>
        </Form>
      </Container>
    </>
  );
}
