import React, { useState, useEffect } from "react";
import { Row, Spinner } from "react-bootstrap";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import {
  HeaderPage,
  NewEditProveedoresServiciosCliente,
  DeleteProveedoresServiciosCliente,
  TableProveedoresServiciosCliente,
} from "../../../components/Admin";
import {
  useCatalagos,
  useSolicitudServicios,
  usePlanPago,
  useAuth,
} from "../../../hooks";
import { BackButton, ModalBasic } from "../../../components/Common";

export function ProveedoresBienesServiciosCliente() {
  const { getSolicitudPeticion, solicitudPeticiones, loading } =
    useSolicitudServicios();
  const { getSectores, sectores } = useCatalagos();
  const { returnClienteIdUser, clienteIdUser } = usePlanPago();
  const { auth } = useAuth();

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("sm");
  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    const fetchData = async () => {
      returnClienteIdUser(auth.me["id"]).then((idUser) => {
        getSolicitudPeticion("1", idUser.id);
      });
      getSectores();
    };
    fetchData();
  }, [refetch]);

  const addPeticionBienesServicios = () => {
    setTitleModal("Crear peticion de Bienes y servicios");
    setIconModal(faCirclePlus);
    setsizeModal("xl");
    setContentModal(
      <NewEditProveedoresServiciosCliente
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        cliente={clienteIdUser}
      />
    );
    openCloseModal();
  };

  const updatePeticionBienesServicios = (data) => {
    setTitleModal("Modificación peticion de Bienes y servicios");
    setIconModal(faCirclePlus);
    setsizeModal("xl");
    setContentModal(
      <NewEditProveedoresServiciosCliente
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        cliente={clienteIdUser}
        proveedoresServicios={data}
      />
    );
    openCloseModal();
  };

  const deletePeticionBienesServicios = (data) => {
    setTitleModal("Borrar peticion de Bienes y servicios");
    setIconModal(faCirclePlus);
    setsizeModal("xl");
    setContentModal(
      <DeleteProveedoresServiciosCliente
        onClose={openCloseModal}
        onRefetch={onRefetch}
        proveedoresServicios={data}
      />
    );
    openCloseModal();
  };

  return (
    <div>
      <br />
      <Row className="mb-6">
        <BackButton
          btnTitle="Regresar a los servicios de Membresía"
          link="/admin/serviciosLicenciamiento"
        />
      </Row>
      <br />
      <Row className="mb-6">
        <HeaderPage
          title="Proveedores de bienes y servicios"
          btnTitle="Solicitar Petición"
          btnClick={addPeticionBienesServicios}
        />
        <div className="col col-sm-2" />
      </Row>
      <br />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              Obteniendo informacion .....
            </span>
          </Spinner>
        </div>
      ) : (
        <TableProveedoresServiciosCliente
          servicios={solicitudPeticiones}
          updatePeticionBienesServicios={updatePeticionBienesServicios}
          deletePeticionBienesServicios={deletePeticionBienesServicios}
        />
      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </div>
  );
}
