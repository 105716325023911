import { useState } from "react";
import { useAuth } from "./useAuth";
import {
    getBoletinesApi,
    addBoletinApi,
    sendMailBoletinApi
} from "../api/boletin";

export function useBoletin() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [boletin, setBoletin] = useState(true);
    const { auth } = useAuth();

    const getBoletin = async () => {
        try {
            setLoading(true);
            const response = await getBoletinesApi();
            setLoading(false);
            setBoletin(response);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    const addBoletin = async (data) => {
        try {
            setLoading(true);
            await addBoletinApi(data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        };
    }

    const sendMailBoletin = async (data) => {
        try {
            console.log(data)
            await sendMailBoletinApi(data, auth.token);
        } catch (error) {
            setError(error);
        }
    };

    return {
        loading,
        error,
        boletin,
        getBoletin,
        addBoletin,
        sendMailBoletin
    }
} 