import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Header, Footer, CookiePolicy } from "../../components/Client";
import { SearchBasic } from "../../components/Common";
import "./ClientLayout.scss";
import { useLocation } from "react-router-dom";

export function ClientLayout(props) {
  const { children } = props;
  const { pathname } = useLocation();
  const hideSearch = pathname && pathname.includes("/busquedaExterna");

  return (
    <div className="client-layout">
      <div className="layout-navbar">
        <Header />
      </div>
      <div className="social-bar">
        <a
          href="https://www.facebook.com/Observatorio-Tecnol%C3%B3gico-y-Sistemas-de-Informaci%C3%B3n-OTS-102890962535501/"
          className="icon-facebook"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://twitter.com/o_t_s__" className="icon-twitter">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://wa.me/message/4DPSPXJN6I44O1" className="icon-whats">
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
        <a
          href="https://instagram.com/o_t_s___?igshid=ZGUzMzM3NWJiOQ"
          className="icon-instagram"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://youtube.com/@observatoriotecnologicoysi1878"
          className="icon-youtube"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>

      {!hideSearch && (
        <Container>
          <SearchBasic placeholder="Escribir búsqueda..." size="large" />
        </Container>
      )}

      <div>{children}</div>
      <CookiePolicy />
      <div className="footer-position">
        <Footer />
      </div>
    </div>
  );
}
