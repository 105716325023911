import { BASE_API } from "../utils/constants";

export async function getIndicadoresApi() {
  try {
    const url = `${BASE_API}/api/indicadores/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function getIndicadoresIdApi(id) {
  try {
    const url = `${BASE_API}/api/indicadores/${id}/`
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}


export async function getIndicadoresTopApi() {
  try {
    const url = `${BASE_API}/api/indicadores/top/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getIndicadorBuscadorTopApi(filtro) {
  try {
    const url = `${BASE_API}/api/indicadores/indicadoresFiltroBuscadorTOP/${filtro}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getIndicadorBuscadorApi(filtro) {
  try {
    const url = `${BASE_API}/api/indicadores/indicadoresFiltroBuscador/${filtro}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addIndicadorApi(data, token) {
  try {
    const formData = new FormData();
    formData.append("icono", data.icono);
    formData.append("tituloIndicador", data.tituloIndicador);
    formData.append("detalle", data.detalle);
    formData.append("linkIndicador", data.linkIndicador);
    formData.append("descripcionAmplia", data.descripcionAmplia);
    formData.append("fechaModificacion", data.fechaModificacion);
    formData.append("palabrasClave", data.palabrasClave);
    formData.append("sectores", data.sectores);
    const url = `${BASE_API}/api/indicadores/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateIndicadorApi(id, data, token) {
  try {
    const formData = new FormData();
    formData.append("icono", data.icono);
    formData.append("tituloIndicador", data.tituloIndicador);
    formData.append("detalle", data.detalle);
    formData.append("linkIndicador", data.linkIndicador);
    formData.append("descripcionAmplia", data.descripcionAmplia);
    formData.append("palabrasClave", data.palabrasClave);
    formData.append("sectores", data.sectores);
    formData.append("fechaModificacion", obtenerfecha());
    const url = `${BASE_API}/api/indicadores/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteIndicadorApi(id, token) {
  try {
    const url = `${BASE_API}/api/indicadores/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

function obtenerfecha() {
  const fecha = new Date().toISOString();
  return fecha;
}
