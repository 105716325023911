import React from 'react'
import { useParams } from 'react-router-dom'

import { NoticiaList } from "../../components/Client"

export function NoticiaLista() {

  const { clave } = useParams();

  if (clave) return <NoticiaList clave = {clave}/>;
  else return <NoticiaList/>;
}
