import React, { useState, useEffect } from "react";
import { Row, Tabs, Tab } from "react-bootstrap";
import { useB2B } from "../../../../hooks/useB2B";
import { NewList } from "./NewList";
import { useParams } from "react-router-dom";

export function Requerimientos() {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [newItemQuantity, setNewItemQuantity] = useState("");
  const [newItemUnit, setNewItemUnit] = useState("");
  const [refetch, setRefetch] = useState(false);
  const { loading, subCategoria2, getSubcategoria2, getCategorias, b2bCategoria, getCategoriaByUser, categoriab2b, getCatbyMicrosiotiob2b, b2bCatMic } = useB2B();
  const { id_micrositio } = useParams();
  
  useEffect(() => {
    getCategoriaByUser();
    getCategorias();
    getCatbyMicrosiotiob2b(id_micrositio);
  }, [refetch]);

  const handleAddItem = () => {
    if (newItem.trim() !== "" && !isNaN(newItemQuantity)) {
      setItems([
        ...items,
        {
          name: newItem,
          description: "",
          cantidad: newItemQuantity,
          unidadMedida: newItemUnit,
        },
      ]);
      setNewItem("");
      setNewItemQuantity("");
      setNewItemUnit("");
    }
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleDescriptionChange = (index, event) => {
    const updatedItems = [...items];
    updatedItems[index].description = event.target.value;
    setItems(updatedItems);
  };


  useEffect(() => {
    getSubcategoria2();
  }, [refetch]);


  return (
    <>
      <Row className="justify-content-center">
        {loading ? (
          <p>Cargando datos...</p>
        ) : (
          <Tabs
            className="mb-1 p-0 nav-pills flex-column flex-sm-row"
            justify
            variant="pills"
            defaultActiveKey={b2bCatMic && b2bCatMic.length > 0 ? b2bCatMic[0].id.toString() : null}
          >
            {b2bCatMic && b2bCatMic.map((categoria, index) => (
              <Tab key={index} eventKey={categoria.id.toString()} title={categoria.descripcion}>
                <NewList 
                  b2bCatMic={b2bCatMic}
                  catId={categoria.id}
                />
                {/* <NewList 
                  subCategoria2={subCategoria2}
                  catId={categoria.id}     
                /> */}
              </Tab>
            ))}
          </Tabs>
        )}
      </Row>
    </>
  );
}
