import { BASE_API } from "../utils/constants";

export async function getSectoresNoticiasApi() {
  try {
    const url = `${BASE_API}/api/sectoresNoticias/getSectoresNoticia/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getSectoresNoticiasTopApi() {
  try {
    const url = `${BASE_API}/api/sectoresNoticias/top/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addSectorNoticiaApi(data, token) {
  try {
    const formData = new FormData();
    formData.append("descripcion", data.descripcion);
    formData.append("imagen", data.imagen);
    const url = `${BASE_API}/api/sectoresNoticias/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteSectorNoticiaApi(id, token) {
  try {
    const url = `${BASE_API}/api/sectoresNoticias/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateSectorNoticiaApi(id, data, token) {
  try {
    const formData = new FormData();
    if (data.descripcion) formData.append("descripcion", data.descripcion);
    if (data.imagen) formData.append("imagen", data.imagen);
    const url = `${BASE_API}/api/sectoresNoticias/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
