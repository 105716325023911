import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  faNewspaper,
  faEdit,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";

import { useSectoresN } from "../../../hooks";
import {
  HeaderPage,
  AddEditSectorsForm,
  TableSectors,
  DeleteSectorsForm
} from "../../../components/Admin";
import { ModalBasic } from "../../../components/Common";


export function SectoresNoticias() {
  const {loading, getSectoresN, sectoresNoti } = useSectoresN();

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("sm");

  useEffect(() => {
    const fetchData = async () => {
      await getSectoresN();
    };
    fetchData();
  }, [refetch]);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  
  const addNew = () => {
    setTitleModal("Crear Sector Noticias");
    setIconModal(faNewspaper);
    setsizeModal("l");
    setContentModal(
      <AddEditSectorsForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
      />
    );
    openCloseModal();
  };

  const updateSector = (data) => {
    setTitleModal("Actualizar Sector");
    setIconModal(faEdit);
    setsizeModal("l");
    setContentModal(
      <AddEditSectorsForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={data}
      />
    );
    openCloseModal();
  };

  const onDeleteSectors = async (data) => {
    setTitleModal("Borrar Sector");
    setIconModal(faDeleteLeft);
    setsizeModal("m");
    setContentModal(
      <DeleteSectorsForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sector={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
    <HeaderPage title="Sectores Noticias" btnTitle="Nuevo Sector" btnClick={addNew} />


    {loading ? (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
      ) : (
        <TableSectors
          sectores={sectoresNoti}
          updateSector={updateSector}
          deleteSector={onDeleteSectors}
        />
      )}

    <ModalBasic
      show={showModal}
      onClose={openCloseModal}
      icon={iconModal}
      title={titleModal}
      children={contentModal}
      size={sizeModal}
    />
  </>
  )
}
