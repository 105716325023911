import React, { useEffect } from "react";
import { PaginadorForum } from "../../../components/b2b/comprador/forums";
import { useB2B } from "../../../hooks";
import "./B2B_encuentros.scss";
export function B2B_encuentros() {
  const { getDataFormus, companys, loading } = useB2B();

  useEffect(() => {
    const getData = async () => {
      await getDataFormus();
    };
    getData();
  }, []);

  console.log(companys);

  return (
    <article className="conteiner_forum_b2b">
      {loading ? <h1>cargando</h1> : <PaginadorForum datos={companys} />}
    </article>
  );
}
