import React from 'react'
import { Table, Button, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faPencil } from '@fortawesome/free-solid-svg-icons'


import { map } from 'lodash'
import './TableSectors.scss'


export function TableSectors(props) {
  const { sectores, updateSector, deleteSector } = props;
  return (
    <div className="table-responsive-l">
            <Table striped bordered hover className='table-sectors table-sm'>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Imagen</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                {map(sectores, (sector, index) => (
                        <tr key={index}>
                            <td>{sector.descripcion}</td>
                            <td><Image src={sector.imagen} className='foto' /></td>
                            <Actions sector={sector} updateSector={updateSector} deleteSector={deleteSector} />
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
  )

  function Actions(props) {
    const { sector, updateSector, deleteSector } = props;

        return (
            <td>
                <Button
                    size='m'
                    variant='success'
                    onClick={() => updateSector(sector)}
                    className='action-button green btn-boton'
                >
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
                <Button
                    size='m'
                    variant='danger'
                    onClick={() => deleteSector(sector)}
                    className='action-button red btn-boton'
                >
                <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />

                </Button>
            </td>
        )
    }
}
