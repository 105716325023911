import { useState } from "react";
import {
    getLicenciaturasApi,
    getLicenciaturasFiltradoApi
} from "../api/licenciatura";
import { useAuth } from "../hooks";

export function useLicenciaturas() {

    const { auth } = useAuth();
    const [licenciaturas, setLicenciaturas] = useState(undefined)
    const [totalLicenciaturas, setTotalLicenciaturas] = useState(undefined)
    const [loadingLic, setLoagingLic] = useState(false)
    const [errorLic, setErrorLic] = useState(undefined)

    const getInstituciones = async () => {
        try {
            setLoagingLic(true);
            const response = await getLicenciaturasApi()
            setLicenciaturas(response)
            setTotalLicenciaturas(response.marcadores.length)
            setLoagingLic(false)
        }
        catch (error) {
            setErrorLic(error)
            setLoagingLic(false)
        }
    }

    const getLicenciaturasFiltrado = async (tipoFiltrado, filtro) => {
        try {
            setLoagingLic(true);
            const response = await getLicenciaturasFiltradoApi(tipoFiltrado, filtro)
            console.log("response, ", response);
            setLicenciaturas(response)
            setLoagingLic(false)
        }
        catch (error) {
            setErrorLic(error)
            setLoagingLic(false)
            console.log(error);
        }
    }

    return {
        errorLic,
        loadingLic,
        licenciaturas,
        getInstituciones,
        totalLicenciaturas,
        getLicenciaturasFiltrado,
    }

}