import { map } from 'lodash';
import React from 'react';
import { Table } from 'react-bootstrap';

export function InfoPagosCRM(props) {
    const { pagos } = props;
    const estatus = (idStatus) => {
        if (idStatus === 1) {
            return 'Pendiente';
        } else if (idStatus === 2) {
            return 'Pagado';
        } else if (idStatus === 3) {
            return 'Cancelado';
        }
    }
    const token = (idtoken) => {
        if (idtoken === 1) {
            return 'Vigente';
        } else if (idtoken === 2) {
            return 'Vencido';
        }
    }
    return (
        <>
            <h2 className='titulo_CRM'>Datos de Pago</h2>
            <div className="table-responsive-xl">
                <Table striped bordered hover className='table-CRM table-sm'>
                    <thead>
                        <tr>
                            <th>Estatus del Pago</th>
                            <th>Tipo Membresia</th>
                            <th>Monto de Pago</th>
                            <th>Fecha de Pago</th>
                            <th>Estatus del Token</th>
                        </tr>
                    </thead>
                    <tbody>
                        {map(pagos, (pagoss, index) => (
                            <tr key={index}>
                                <td>{estatus(pagoss.estatus_pago)}</td>
                                <td>{pagoss.nombre}</td>
                                <td>{pagoss.monto}</td>
                                <td>{pagoss.fecha_pago}</td>
                                <td>{token(pagoss.estatus_token)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}
