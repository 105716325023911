

import React from 'react'
import { Button } from 'react-bootstrap';

import { useExperienciaUsuario } from '../../../../hooks';

export function PublicarExperienciaCliente(props) {
      const { onClose, onRefetch, experienciaC } = props;
      const { deleteExperienciaCliente } = useExperienciaUsuario();
    
      async function publiExperienciaCliente() {
        try {
          await deleteExperienciaCliente(experienciaC.id);
          onRefetch();
          onClose();
        } catch (error) {
          console.error(error);
        }
      }
    
      function closeModal() {
        onClose();
      }
    
      return (
        <div>
          <h3 className="title-spacing">¿Deseas que esta información se vea reflejada en la parte frontal del OTS? </h3>
          <div className="delete-user-form">
            <Button variant="info" className="btnaling" onClick={publiExperienciaCliente}>
              Publicar
            </Button>
            <Button variant="outline-danger" className="btnaling" onClick={closeModal}>
              Cancelar
            </Button>
          </div>
        </div>
      );
    }
