import React from "react";
import "./DetalleRecurso.scss";
import { Link } from "react-router-dom";
import { Row, Col, Image, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileVideo } from "@fortawesome/free-solid-svg-icons";

import { formatDateMonthName } from "../../../../../metodosGlobales";

export function DetalleRecurso(props) {
  const { dato } = props;
  const obtenPath = (id) => {
    return "/admin/recursos-client/detalle-recurso/" + id;
  };

  return (
    <div className="detalle-recurso">
      <Row className="justify-content-center">
        <Col className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6">
          <Row className="detalle-recurso-imagen">
            <Col className="img-thumbnail mx-auto d-block col-10 col-sm-10 col-md-8 col-lg-8 col-xl-6">
              <Image
                className="img-square-wrapper img-fluid rounded"
                src={`${dato.imagen}`}
              />
            </Col>
            <Col className="ms-0">
              <div className="card-body">
                <h5 className="textoCentrado card-title color-titulo">
                  {dato.titulo}
                </h5>
                <ul className="lista mb-2">
                  <li>
                    {"Tipo de Investigación: " +
                      dato.tipoDeinvestigacion["nombre"]}
                  </li>
                  <li>
                    {"Sector de investigación: " + dato.catalogo["descripcion"]}
                  </li>
                  <li>
                    {"Fecha de subida: " + formatDateMonthName(dato.fecha_alta)}
                  </li>
                  <li>{"Tipo de recurso: " + dato.tipoRecurso["nombre"]}</li>
                </ul>
                <AsignarMuestraDatos data={dato} />
              </div>
              <br />
              <div className="botonInformacion">
                <Link to={obtenPath(dato.id)}>
                  <Button className="bottonInfo" variant="outline-primary">
                    Más información
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

function AsignarMuestraDatos(props) {
  const { data } = props;
  const arrayMethod = [
    null,
    investigaciónVideo(data),
    investigaciónDocumento(data),
    investigaciónDocumentoVideo(data),
  ];
  return arrayMethod[data.tipoDeinvestigacion["id"]];
}

function investigaciónDocumentoVideo(data) {
  return (
    <div className="contenedor">
      <a
        href={data.documento}
        className="btn btn-secondary btn-sm"
        target="_blank"
      >
        <FontAwesomeIcon icon={faFilePdf} className="me-2" />
        Ver Documento
      </a>
      <div>
        <br />
      </div>
      <a href={data.link} className="btn btn-secondary btn-sm" target="_blank">
        <FontAwesomeIcon icon={faFileVideo} className="me-2" />
        Ver video
      </a>
    </div>
  );
}

function investigaciónDocumento(data) {
  return (
    <div className="contenedor">
      <a
        href={data.documento}
        className="btn btn-secondary btn-sm"
        target="_blank"
      >
        <FontAwesomeIcon icon={faFilePdf} className="me-2" />
        Ver Documento
      </a>
    </div>
  );
}

function investigaciónVideo(data) {
  return (
    <div className="contenedor">
      <a href={data.link} className="btn btn-secondary btn-sm" target="_blank">
        <FontAwesomeIcon icon={faFileVideo} className="me-2" />
        Ver video
      </a>
    </div>
  );
}
