import { BASE_API } from "../utils/constants";

export async function getLicenciaturasApi() {
    try {
        const url = `${BASE_API}/api/licenciaturas/conteoInstituciones/`;
        const params = {
            method: "GET",
            headers: {
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function getLicenciaturasFiltradoApi(tipoFiltrado, filtro) {
    try {
        const url = `${BASE_API}/api/licenciaturas/filtroConteoInstituciones/${tipoFiltrado}/${filtro}/`;
        const params = {
            method: "GET",
            headers: {
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}