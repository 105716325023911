import { useState } from "react";
import { useAuth } from "./";
import {
  getNoticiasApi,
  getNoticiasTopApi,
  getNoticiaIdApi,
  addNoticiaApi,
  updateNoticiaApi,
  deleteNoticiaApi,
  getNoticiaFiltradaApi,
  getNoticiasBuscadorApi,
  getNoticiasBuscadorTopApi
} from "../api/noticias";

export function useNoticia() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [noticia, setNoticia] = useState(null);
  const [noticiaId, setNoticiaId] = useState(0);
  const { auth } = useAuth();

  const getNoticias = async () => {
    try {
      setLoading(true);
      const response = await getNoticiasApi();
      setLoading(false);
      setNoticia(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getNoticiasFiltrdas = async (filtro) => {
    try {
      setLoading(true);
      const response = await getNoticiaFiltradaApi(filtro);
      setLoading(false);
      setNoticia(response)
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  const getNoticiasTop = async () => {
    try {
      setLoading(true);
      const response = await getNoticiasTopApi();
      setLoading(false);
      setNoticia(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getNoticiasBuscadorTop = async (filtro) => {
    try {
      setLoading(true);
      const response = await getNoticiasBuscadorTopApi(filtro);
      setLoading(false);
      setNoticia(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getNoticiasBuscador = async (filtro) => {
    try {
      setLoading(true);
      const response = await getNoticiasBuscadorApi(filtro);
      setLoading(false);
      setNoticia(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getNoticiaId = async (id) => {
    try {
      setLoading(true);
      const response = await getNoticiaIdApi(id);
      setLoading(false);
      setNoticiaId(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const addNoticia = async (data) => {
    try {
      setLoading(true);
      await addNoticiaApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updateNoticia = async (id, data) => {
    try {
      setLoading(true);
      await updateNoticiaApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteNew = async (id) => {
    try {
      setLoading(true);
      await deleteNoticiaApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    noticia,
    noticiaId,
    getNoticias,
    getNoticiasTop,
    getNoticiasBuscador,
    getNoticiasBuscadorTop,
    getNoticiaId,
    getNoticiasFiltrdas,
    addNoticia,
    updateNoticia,
    deleteNew,
  };
}
