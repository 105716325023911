import React from "react";

import "./AvisoCookies.scss";
import { CookiePage } from "../../../components/Client";
import { TituloPrincipal } from '../../../components/Common'
export function AvisoCookies() {
  return (
    <div>
      <TituloPrincipal
        titulo='Aviso de Cookies.'
      />
      <CookiePage />
    </div>
  );
}
