import React, { useState, useEffect } from "react";
import { Row, Spinner } from "react-bootstrap";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

import { ModalBasic } from "../../components/Common";
import { HeaderPage, FormBoletin, TableBoletin } from "../../components/Admin";
import { useBoletin, useCatalagos, useUser } from "../../hooks";

export function Boletin() {
  const { boletin, getBoletin, loading } = useBoletin();
  const { users, getUsers } = useUser()
  const { meses, getMeses } = useCatalagos();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(null);
  const onRefetch = () => setRefetch((prev) => !prev);

  const openCloseModal = () => setShowModal((prev) => !prev);

  useEffect(() => {
    getBoletin();
    getMeses();
    getUsers();
  }, [refetch]);

  const newBoletin = () => {
    setTitleModal("Nuevo boletín :D");
    setIconModal(faClipboard);
    setContentModal(
      <FormBoletin
        users={users}
        onClose={openCloseModal}
        onRefetch={onRefetch}
        dateMeses={meses} />
    );
    openCloseModal();
  };

  return (
    <>
      <Row>
        <HeaderPage
          title="Boletines"
          btnTitle="Agregar nuevo boletín"
          btnClick={newBoletin}
        />
      </Row>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        boletin && (
          <Row>
            <TableBoletin boletines={boletin} />
          </Row>
        )

      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={"md"}
      />
    </>
  );
}