import React, { useEffect } from 'react'
import { map } from "lodash";
import { Table, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

import { useCatalagos, useRequestClient } from '../../../../../hooks';

export function TableRequestAdmin(props) {

  const { servicios, updateService } = props
  const { alcanceGeografico, getAlcanceGeografico } = useCatalagos();

  useEffect(() => {
    getAlcanceGeografico();
  }, []);

  return (
    <div className='table-responsive-xl'>
      <Table striped bordered hover className="table-service table-sm">
        <thead>
          <tr>
            <th>N° solicitud</th>
            <th>N° cliente</th>
            <th>Nombre cliente</th>
            <th>Teléfono</th>
            <th>Mail</th>
            <th>Sector de Interés</th>
            <th>Servicio</th>
            <th>Objetivo de la solicitud</th>
            <th>Status</th>
            <th>Editar solicitud</th>
          </tr>
        </thead>
        <tbody>
          {map(servicios, (servicio, index) => (
            <tr key={index}>
              <td>{servicio.id}</td>
              <td>{servicio.idSolicitudServicio.idCliente['id']}</td>
              <td>{asignarNombre(servicio)}</td>
              <td>{servicio.idSolicitudServicio.idCliente['telefono']}</td>
              <td>{servicio.idSolicitudServicio.idCliente.user['email']}</td>
              <td>{servicio.idSolicitudServicio.sectores['descripcion']}</td>
              <td>{servicio.tipoServicio}</td>
              <td>{servicio.idSolicitudServicio.objetivoServicio}</td>
              <td>{servicio.idSolicitudServicio.estatus['nombre']}</td>
              <Actions
                servicio={servicio}
                updateService={updateService}
                alcanceGeografico={alcanceGeografico}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

function Actions(props) {

  const { servicio, updateService, alcanceGeografico } = props;

  return (
    <td>
      <Button
        size="sm"
        variant="success"
        className="table-service__boton"
        onClick={() => updateService(servicio, alcanceGeografico)}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Button>
    </td>
  );
}

function asignarNombre(servicio) {
  return `${servicio.idSolicitudServicio.idCliente.user['nombre']} ${servicio.idSolicitudServicio.idCliente.user['apellido_paterno']} ${servicio.idSolicitudServicio.idCliente.user['apellido_materno']}`
}