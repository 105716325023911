import { AdminLayout } from "../layouts";
import { ContactosPage, SegmentosPage, Campaniapage, ClientesPage, PlantillaPage } from "../pages/Marketing";

const routesMartketingAdmin = [
  {
    path: "/admin/marketing/contactos",
    layout: AdminLayout,
    component: ContactosPage,
  },
  {
    path: "/admin/marketing/segmentos",
    layout: AdminLayout,
    component: SegmentosPage,
  },
  {
    path: "/admin/marketing/campania",
    layout: AdminLayout,
    component: Campaniapage,
  },
  {
    path: "/admin/marketing/clientes",
    layout: AdminLayout,
    component: ClientesPage,
  },
  {
    path: "/admin/marketing/plantillas",
    layout: AdminLayout,
    component: PlantillaPage,
  },
];

export default routesMartketingAdmin;
