import React, { useState, useEffect } from 'react'
import { map } from "lodash";
import { useFormik } from "formik";
import { Button, Form, Table, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

import { formatDateMonthName } from "../../../../../metodosGlobales";
import { useSolicitudServicios } from "../../../../../hooks"
import "./TableProveedoresServicios.scss"

export function TableProveedoresServiciosOperativo(props) {

  const { servicios, onRefetch } = props;
  return (
    <div className="table-responsive-xl">
      <Table striped bordered hover className="table-servicio-proveedores table-sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Tipo de servicio</th>
            <th>Nombre cliente</th>
            <th>Email</th>
            <th>Teléfono</th>
            <th>Sector</th>
            <th>Estatus</th>
            <th>Fecha de alta</th>
            <th>Fecha de termino</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {map(servicios, (servicio, index) => (
            <tr key={index}>
              <td>{servicio.id}</td>
              <td>{servicio.tipoPeticion['nombre']}</td>
              <td>{`${servicio.idSolicitudServicio.idCliente.user['nombre']} ${servicio.idSolicitudServicio.idCliente.user['apellido_paterno']} ${servicio.idSolicitudServicio.idCliente.user['apellido_materno']}`}</td>
              <td>{servicio.idSolicitudServicio.idCliente.user['email']}</td>
              <td>{servicio.idSolicitudServicio.idCliente['telefono']}</td>
              <td>{servicio.idSolicitudServicio.sectores['descripcion']}</td>
              <td>{servicio.idSolicitudServicio.estatus['nombre']}</td>
              <td>{formatDateMonthName(servicio.idSolicitudServicio['fechaAlta'])}</td>
              <th>{
                servicio.idSolicitudServicio['fechaTermino']
                  ? formatDateMonthName(servicio.idSolicitudServicio['fechaTermino'])
                  : "----/--/--"
              }</th>
              <Actions
                servicio={servicio}
                onRefetch={onRefetch}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function Actions(props) {

  const { servicio, onRefetch } = props;
  const { editServicioGeneralAdmin } = useSolicitudServicios();
  const seleccion = servicio.idSolicitudServicio.estatus['id'] == 2 ? true : false;
  const formik = useFormik({
    initialValues: initialValues(seleccion),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      const set = formvalue.estatus
      if (set == true) {
        const data = {
          "estatus": 2,
          "fechaTermino": new Date().toISOString()
        };
        await editServicioGeneralAdmin(data, servicio.idSolicitudServicio.id);
      }
      else {
        const data = {
          "estatus": 1,
          "fechaTermino": "S/N"
        };
        await editServicioGeneralAdmin(data, servicio.idSolicitudServicio.id);
      }
      onRefetch();
    },
  });

  return (
    <td>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="mb-6 mx-2">
          <Form.Group className="mx-2 col col-lg-2">
            <Form.Check
              type="switch"
              name="estatus"
              onChange={formik.handleChange}
              defaultChecked={seleccion}
            />
          </Form.Group>
          <Button variant="success" type="submit" className="mx-2 col-12 col-sm-12 col-lg-8">
            Actualizar Reporte
          </Button>
        </Row>
      </Form>
    </td>
  );
}

function initialValues(data) {
  return {
    estatus: data
  };
}