import React, { useEffect } from "react";
import { Row, Spinner } from "react-bootstrap";

import { RedColaborador } from "../../../components/Client";
import { TituloPrincipal } from "../../../components/Common";
import { useColaborador } from "../../../hooks";
import "./ColaboraRed.scss";

export function ColaboraRed() {
  const { loading, getColaboradoresLandingPage, colaboradores } = useColaborador();

  useEffect(() => {
    getColaboradoresLandingPage();
  }, []);

  return (
    <>
      <Row>
        <TituloPrincipal titulo="Red OTS" />
      </Row>
      <br />
      <Row>
        <h1 className="tituloPrincipal">
          ¡Conoce nuestra red de expertos!
        </h1>
      </Row>
      <Row></Row>
      <Row>
        <h1 className="tituloPrincipal">¡Instituciones aliadas OTS!</h1>
      </Row>
      <Row>
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <RedColaborador colaboradores={colaboradores} />
        )}
      </Row>
    </>
  );
}
