import { useState } from "react";
import {
  addDataHelpToUserApi,
  addMicroSiteB2BApi,
  deleteDataHelpToUserApi,
  editDataHelpToUserApi,
  editMesaAsignadaApi,
  getAgendaAdminApi,
  getAgendaUserApi,
  getCompanysToB2BApi,
  getDataFormusApi,
  getDataHelpToUserApi,
  getSubcategoriaApi,
  getSubcategoria2Api,
  getUnidadDeMedidaApi,
  getCategoriasApi,
  addSubMicrositioApi,
  getCategoriaByUserApi,
  getsubCatbyCatApi,
  getRegistroExternoVendedorApi,
  getExternoVendedorbyIdCatApi,
  addExternoCompradorApi,
  getExternoCompradorbyIdCatApi,
  deleteExternoCompradorApi,
  getSitioWebApi,
  getUsersWebSiteApi,
  postGenerarAgendaApi,
  deleteExternoVendedorApi,
  getCatbyMicrosiotiob2bApi,
} from "../api/b2b";
import { useAuth } from "./";

export function useB2B() {
  const { auth } = useAuth();
  const [loading, setLoadingB2B] = useState(false);
  const [companys, setCompanys] = useState(undefined);
  const [userData, setUserData] = useState(undefined);
  const [agenda, setAgenda] = useState(undefined)
  const [subCategoria, setsubCategoria] = useState(undefined);
  const [subCategoria2, setsubCategoria2] = useState(undefined);
  const [UnidadDeMedida, setUnidadDeMedida] = useState(undefined);
  const [b2bCategoria, setb2bCategoria] = useState(undefined);
  const [categoriab2b, setCategoriab2b] = useState(undefined);
  const [b2bExterno, setb2bExterno] = useState(undefined);
  const [b2bExternoComprador, setb2bExternoComprador] = useState(undefined);
  const [b2bCatMic, setb2bCatMic] = useState(undefined);

  
  
  

  const addMicrositeB2B = async (data) => {
    try {
      setLoadingB2B(true);
      await addMicroSiteB2BApi(data, auth.token);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };
  const getCompanysToB2B = async () => {
    try {
      setLoadingB2B(true);
      const response = await getCompanysToB2BApi(auth.token);
      setCompanys(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const getDataFormus = async () => {
    try {
      setLoadingB2B(true);
      const response = await getDataFormusApi(auth.token);
      setCompanys(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  // B2B Ayuda

  const getDataHelpToUser = async () => {
    try {
      setLoadingB2B(true);
      const response = await getDataHelpToUserApi(auth.me.id, auth.token);
      setUserData(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const addDataHelpToUser = async (data) => {
    try {
      setLoadingB2B(true);
      await addDataHelpToUserApi(data, auth.me.id, auth.token);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const editDataHelpToUser = async (data, id) => {
    try {
      setLoadingB2B(true);
      await editDataHelpToUserApi(data, id, auth.token);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const deleteDataHelpToUser = async (id) => {
    try {
      setLoadingB2B(true);
      await deleteDataHelpToUserApi(id, auth.token);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const getUsersWebSite = async (tipoUsuario) => {
    try {
      setLoadingB2B(true);
      const response = await getUsersWebSiteApi(
        auth.me.id,
        tipoUsuario,
        auth.token
      );
      setUserData(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const editMesaAsignada = async (id, mesa) => {
    try {
      setLoadingB2B(true);
      await editMesaAsignadaApi(id, mesa, auth.token);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  }

  const postGenerarAgenda = async () => {
    try {
      setLoadingB2B(true);
      await postGenerarAgendaApi(auth.me.id, auth.token);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  }

  const getAgendaAdmin = async (id) => {
    try {
      setLoadingB2B(true);
      const response = await getAgendaAdminApi(id, auth.token)
      setAgenda(response)
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  }

  const getAgendaUser = async (idSite, tipoUsuario) => {
    try {
      setLoadingB2B(true);
      const response = await getAgendaUserApi(auth.me.id, idSite, tipoUsuario, auth.token)
      setAgenda(response)
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  }

  const getSitioWeb = async () => {
    try {
      setLoadingB2B(true);
      const response = await getSitioWebApi(auth.me.id, auth.token)
      setAgenda(response)
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  }


  const getSubcategoria = async (id) => {
    try {
      setLoadingB2B(true);
      const response = await getSubcategoriaApi(id, auth.token);
      setsubCategoria(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };
  const getSubcategoria2 = async () => {
    try {
      setLoadingB2B(true);
      const response = await getSubcategoria2Api(auth.token);
      setsubCategoria2(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };
  const getUnidadDeMedida = async () => {
    try {
      setLoadingB2B(true);
      const response = await getUnidadDeMedidaApi(auth.token);
      setUnidadDeMedida(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };
  const getCategorias = async () => {
    try {
      setLoadingB2B(true);
      const response = await getCategoriasApi(auth.token);
      setb2bCategoria(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };
  
  const addSubMicrositio = async (data) => {
    try {
      setLoadingB2B(true);
      await addSubMicrositioApi(data, auth.token, auth.me.id);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };
  
  const getCategoriaByUser = async () => {
    try {
      setLoadingB2B(true);
      const response = await getCategoriaByUserApi(auth.me.id ,auth.token);
      setCategoriab2b(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const getsubCatbyCat = async (idCategoria) => {
    try {
      setLoadingB2B(true);
      const response = await getsubCatbyCatApi(idCategoria ,auth.token);
      setb2bCategoria(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };
  
  const getRegistroExternoVendedor = async () => {
    try {
      setLoadingB2B(true);
      const response = await getRegistroExternoVendedorApi(auth.token);
      setUserData(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const getExternoVendedorbyIdCat = async (idCategoria) => {
    try {
      setLoadingB2B(true);
      const response = await getExternoVendedorbyIdCatApi(idCategoria ,auth.token);
      setb2bExterno(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const addExternoComprador = async (data) => {
    try {
      setLoadingB2B(true);
      await addExternoCompradorApi(data, auth.token, auth.me.id);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };
  
  const getExternoCompradorbyIdCat = async (idCategoria) => {
    try {
      setLoadingB2B(true);
      const response = await getExternoCompradorbyIdCatApi(idCategoria ,auth.token);
      setb2bExternoComprador(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const deleteExternoComprador = async (id) => {
    try {
      setLoadingB2B(true);
      await deleteExternoCompradorApi(id, auth.token);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };
  
  const deleteExternoVendedor = async (id) => {
    try {
      setLoadingB2B(true);
      await deleteExternoVendedorApi(id, auth.token);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };

  const getCatbyMicrosiotiob2b = async (id_micro) => {
    try {
      setLoadingB2B(true);
      const response = await getCatbyMicrosiotiob2bApi(id_micro ,auth.token);
      setb2bCatMic(response);
      setLoadingB2B(false);
    } catch (error) {
      setLoadingB2B(false);
    }
  };



  return {
    loading,
    addMicrositeB2B,
    getCompanysToB2B,
    getDataFormus,
    companys,
    getDataHelpToUser,
    addDataHelpToUser,
    editDataHelpToUser,
    deleteDataHelpToUser,
    userData,
    getUsersWebSite,
    editMesaAsignada,
    postGenerarAgenda,
    getAgendaAdmin,
    getAgendaUser,
    agenda,
    getSitioWeb,
    subCategoria,
    getSubcategoria,
    getSubcategoria2,
    subCategoria2,
    getUnidadDeMedida,
    UnidadDeMedida,
    getCategorias,
    b2bCategoria,
    addSubMicrositio,
    getCategoriaByUser,
    getsubCatbyCat,
    getRegistroExternoVendedor,
    getExternoVendedorbyIdCat,
    b2bExterno,
    addExternoComprador,
    getExternoCompradorbyIdCat,
    b2bExternoComprador,
    deleteExternoComprador,
    categoriab2b,
    deleteExternoVendedor,
    getCatbyMicrosiotiob2b,
    b2bCatMic,
  };
}
