import React from "react";
import "./DatosCompania.scss";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faClock,
  faEnvelope,
  faSquarePhone,
} from "@fortawesome/free-solid-svg-icons";

export function DatosCompania() {
  return (
    <div className="datos-compania col col-sm-8">
      <Row className="mx-3">
        <div className="mt-3">
          <span className="datos-compania-child">
            <FontAwesomeIcon icon={faLocationDot} />
          </span>
          <h6 className="datos-compania-child">Dirección:</h6>
        </div>
        <p className="datos-compania-parrafo">
          Lago Ginebra Ext. 96 Col. Agua Azul Grupo B Super 23,
          Mpio. Nezahualcóyotl, C.P. 57500, Estado de México, México.
        </p>
      </Row>
      <Row className="mx-3 mt-3 ">
        <div>
          <span className="datos-compania-child">
            <FontAwesomeIcon icon={faClock} />
          </span>
          <h6 className="datos-compania-child">Horarios de Oficina:</h6>
        </div>
        <p className="datos-compania-parrafo">
          Lunes a Viernes de 9:00 a.m. a 6:00 p.m.
        </p>
      </Row>
      <Row className="mx-3 mt-3 ">
        <div>
          <span className="datos-compania-child">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
          <h6 className="datos-compania-child">Correo de Contacto:</h6>
        </div>
        <p className="datos-compania-parrafo">informa@code-connect.com.mx</p>
      </Row>
      <Row className="mx-3 mt-3 ">
        <div>
          <span className="datos-compania-child">
            <FontAwesomeIcon icon={faSquarePhone} />
          </span>
          <h6 className="datos-compania-child">Número oficial de OTS (Contacto y Whatsapp):</h6>
        </div>
        <p className="datos-compania-parrafo">
          Numero oficial de OTS (contacto y WhatsApp): 55 7385 3174{" "}
        </p>
      </Row>
    </div>
  );
}
