import { BASE_API } from '../utils/constants'

export async function getAlianzaApi(){
    try {
        const url = `${BASE_API}/api/alianzas/`;
        const response = await fetch(url);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function addAlianzaApi(data, token){
    try {
        const formData = new FormData();
        formData.append("logo", data.logo);
        formData.append("nombre", data.nombre);
        formData.append("descripcion", data.descripcion);
        formData.append("link", data.link);
        console.log("data", formData);
        
        const url = `${BASE_API}/api/alianzas/`;
        const params = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          };

          const response = await fetch(url, params);
          const result = await response.json();
          return result;
    } catch (error) {
        throw error;
        
    }
}

export async function updateAlianzaApi(id, data, token){
    try {
        const formData = new FormData();
        if(data.logo) formData.append("logo", data.logo);
        formData.append("nombre", data.nombre);
        formData.append("descripcion", data.descripcion);
        formData.append("link", data.link);
        console.log("data", formData);
        
        const url = `${BASE_API}/api/alianzas/${id}/`;
        const params = {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          };

          const response = await fetch(url, params);
          const result = await response.json();
          return result;
    } catch (error) {
        throw error;
        
    }
}

export async function deleteAlianzaApi(id, token) {
    try {
      const url = `${BASE_API}/api/alianzas/${id}/`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }