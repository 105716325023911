import { useState } from "react";
import { useAuth } from "./";
import {
  getUrlCheckoutApi,
  getDatosFacturaApi,
  addConfirmPagoApi,
  addDatosFacturaApi,
  getPagosClienteApi,
  getFacturaClienteApi,
  updateDatosFacturaApi,
} from "../api/pago";

export function usePagos() {
  const [loading, setLoading] = useState(true);
  const [datosFactura, setDatosFactura] = useState(true);
  const [error, setError] = useState(false);
  const { auth } = useAuth();

  const getUrlCheckout = async (data, clave) => {
    try {
      setLoading(true);
      const response = await getUrlCheckoutApi(data, clave);
      setLoading(false);
      return response;
    } catch (error) {
      console.log("eror", error);
      setError(error);
      setLoading(false);
    }
  };

  const getDatosFactura = async (id) => {
    try {
      setLoading(true);
      const response = await getDatosFacturaApi(id);
      setLoading(false);
      setDatosFactura(response);
    } catch (error) {
      console.log("eror", error);
      setError(error);
      setLoading(false);
    }
  };

  const addConfirmPago = async (estatus, clave) => {
    try {
      setLoading(true);
      const response = await addConfirmPagoApi(estatus, clave);
      setLoading(false);
      return response;
    } catch (error) {
      console.log("eror", error);
      setError(error);
      setLoading(false);
    }
  };

  const addDatosFactura = async (data, id) => {
    try {
      setLoading(true);
      const response = await addDatosFacturaApi(data, id);
      setLoading(false);
      return response;
    } catch (error) {
      console.log("eror", error);
      setError(error);
      setLoading(false);
    }
  };

  const getPagosCliente = async (cliente_id) => {
    try {
      setLoading(true);
      const response = await getPagosClienteApi(cliente_id);
      setLoading(false);
      return response;
    } catch (error) {
      console.log("error", error);
      setError(error);
      setLoading(false);
    }
  };

  const getFacturaCliente = async (cliente_id) => {
    try {
      setLoading(true);
      const response = await getFacturaClienteApi(cliente_id);
      setLoading(false);
      return response;
    } catch (error) {
      console.log("error", error);
      setError(error);
      setLoading(false);
    }
  };

  const updateDatosFactura = async (data, id, clienteId) => {
    try {
      setLoading(true);
      await updateDatosFacturaApi(data, id, clienteId);
      setLoading(false);
      //return response;
    } catch (error) {
      console.log("eror", error);
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    datosFactura,
    getUrlCheckout,
    getDatosFactura,
    addConfirmPago,
    addDatosFactura,
    getPagosCliente,
    getFacturaCliente,
    updateDatosFactura,
  };
}
