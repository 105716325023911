import React, { useState } from "react";
import {
  Row,
  Tabs,
  Tab,
  Container,
  Col,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import "./NewList";
import { NewList } from "./NewList";
export function EnterpriseDate() {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  const handleAddItem = () => {
    if (newItem.trim() !== "") {
      setItems([...items, { name: newItem, description: "" }]);
      setNewItem("");
    }
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleDescriptionChange = (index, event) => {
    const updatedItems = [...items];
    updatedItems[index].description = event.target.value;
    setItems(updatedItems);
  };
  return (
    <>
      <Row className="justify-content-center">
        <Tabs
          className="mb-1 p-0 nav-pills flex-column flex-sm-row"
          justify
          variant="pills"
          defaultActiveKey="maquinaria"
          // activeKey={key}
          // onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="maquinaria" title="Maquinaria">
            <h2 className="mt-4 mb-4">Productos o servicios ofertados</h2>
            <Row className="mb-3">
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Nuevo producto o servicio"
                  value={newItem}
                  onChange={(e) => setNewItem(e.target.value)}
                />
              </Col>
              <Col col="col-4">
                <Button onClick={handleAddItem}>Agregar</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Producto o Servicio</th>
                      <th>Descripción</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Check type="checkbox" />
                        </td>
                        <td>{item.name}</td>
                        <td>
                          <Form.Control
                            type="text"
                            placeholder="Descripción"
                            value={item.description}
                            onChange={(e) => handleDescriptionChange(index, e)}
                          />
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteItem(index)}
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="sistemas" title="Sistemas de Información">
            <NewList />
          </Tab>
          <Tab eventKey="matematicas" title="Matemáticas">
            <NewList />
          </Tab>
          <Tab eventKey="combustible" title="Combustible">
            <NewList />
          </Tab>
          <Tab eventKey="otra" title="Servicios">
            <NewList />
          </Tab>
        </Tabs>
      </Row>
    </>
  );
}
