import { BASE_API } from "../utils/constants";

export async function getNoticiasApi() {
  try {
    const url = `${BASE_API}/api/noticia/listaNoticia/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getNoticiaFiltradaApi(filtro) {
  try {
    const url = `${BASE_API}/api/noticia/filtrado/${filtro}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getNoticiasTopApi() {
  try {
    const url = `${BASE_API}/api/noticia/top/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getNoticiasBuscadorTopApi(filtro) {
  try {
    const url = `${BASE_API}/api/noticia/noticiasFiltroBuscadorTOP/${filtro}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getNoticiasBuscadorApi(filtro) {
  try {
    const url = `${BASE_API}/api/noticia/noticiasFiltroBuscador/${filtro}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getNoticiaIdApi(id) {
  try {
    const url = `${BASE_API}/api/noticia/${id}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addNoticiaApi(data, token) {
  try {
    const formData = new FormData();
    formData.append("titular_noticia", data.titular_noticia);
    formData.append("autor_institucion", data.autor_institucion);
    formData.append("sectores", data.sectores);
    formData.append("link", data.link_referencia);
    formData.append("keywords", (data.keywords));
    formData.append("resumen", data.resumen);
    formData.append("redaccion_completa", data.redaccion_completa);
    if (data.link_video) formData.append("video", data.link_video);
    if (data.pdf) formData.append("pdf", data.pdf);
    formData.append("imagen", data.imagen);
    const url = `${BASE_API}/api/noticia/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteNoticiaApi(id, token) {
  try {
    const url = `${BASE_API}/api/noticia/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateNoticiaApi(id, data, token) {
  try {
    const formData = new FormData();
    formData.append("titular_noticia", data.titular_noticia);
    formData.append("autor_institucion", data.autor_institucion);
    formData.append("sectores", data.sectores);
    if (data.link_referencia) formData.append("link_referencia", data.link_referencia);
    if (data.keywords) formData.append("keywords", data.keywords);
    if (data.resumen) formData.append("resumen", data.resumen);
    if (data.redaccion_completa)
      formData.append("redaccion_completa", data.redaccion_completa);
    if (data.link_video) formData.append("link_video", data.link_video);
    if (data.imagen) formData.append("imagen", data.imagen);
    if (data.pdf) formData.append("pdf", data.pdf);
    const url = `${BASE_API}/api/noticia/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
