import React from "react";
import { useParams } from "react-router-dom";
import {SectoresNot} from "../../components/Client/SectoresNoticias"
export function SectorNotiVista() {
  const { clave } = useParams();
  if (clave)
    return <SectoresNot clave={clave}/>;
  else
    return <SectoresNot />;
}
