import React, { forwardRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {
    ComposableMap,
    Geographies,
    Geography,
    ZoomableGroup
} from 'react-simple-maps';

import mapaNiv1 from '../../../maps/gadm41_MEX_1.json';
import { reglaDeTres } from '../../../metodosGlobales';
import './Map.scss';

export function Map(props) {

    const {
        colors,
        licenciaturas,
        ruta
    } = props

    const [filtrado, setFilrado] = useState(undefined)
    const [cantidadUni, setCantidadUni] = useState(undefined)

    if (!licenciaturas) return null
    const asignarColor = (estado) => {
        try {
            const filtro = licenciaturas.conteo_estados.filter(item => item["nombre"] === estado)
            if (filtro.length > 0) {
                const maximo = licenciaturas.min_max[0].max
                const color = reglaDeTres(maximo, filtro[0].count)
                if (estado === 'Michoacán') {
                }
                return colors[color].RGB
            }
            else return "grey";
        } catch (error) {
            return "grey";
        }
    }

    const filtradoInsituciones = (estado) => {
        try {

            const filtroEstados = licenciaturas.marcadores.filter(item => item["estado"] === estado)
            const filtroNombre = licenciaturas.conteo_estados.filter(item => item["nombre"] === estado)
            setCantidadUni(filtroNombre[0].count)
            setFilrado(filtroEstados)
        } catch (e) {
            setCantidadUni(0)
            setFilrado([])
        }
    }

    const MyPopover = forwardRef(({ title, content, ...props }, ref) => (
        <Popover ref={ref} id="popover-basic" {...props}>
            <Popover.Header as="h3" className='text-center'>{title}</Popover.Header>
            <Popover.Body>
                <h6>
                    {title} tiene {cantidadUni} carreras registradas.
                </h6>
                <h6>
                    {filtrado.length} instituciones dadas de alta coinciden con su búsqueda:
                </h6>
                <p>
                    {filtrado.slice(0, 5).map((escuela, index) => (
                        <div key={index}>
                            {escuela.plantel}
                            <br />
                        </div>
                    ))}
                </p>
            </Popover.Body>
            PARA VER MÁS INFORMACIÓN DA CLICK EN EL ESTADO.
        </Popover>
    ));

    return (
        <div>
            <ComposableMap
                projection="geoAzimuthalEqualArea"
                projectionConfig={{
                    rotate: [80, 1, 0],
                    center: [-20, 20],
                    scale: 1650,
                }}
            >
                <ZoomableGroup center={[-100.8772424681684, 22.8991011100612]} minZoom={0} maxZoom={5}>
                    <Geographies geography={mapaNiv1}>
                        {({ geographies }) => (
                            <>
                                {geographies.map((geo) => (
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="auto"
                                        overlay={
                                            (props) => (
                                                <MyPopover
                                                    {...props}
                                                    title={geo.properties.NAME_1}
                                                />
                                            )
                                        }
                                        key={geo.rsmKey}
                                    >
                                        <Link to={ruta(geo.properties.NAME_1)}>
                                            <Geography
                                                style={{
                                                    default: { fill: asignarColor(geo.properties.NAME_1) },
                                                    hover: { fill: "#54c2ec" }
                                                }}
                                                geography={geo}
                                                onMouseEnter={() => filtradoInsituciones(geo.properties.NAME_1)}
                                            />
                                        </Link>
                                    </OverlayTrigger>

                                ))}
                            </>
                        )}
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>

        </div >
    )
}
