import React, { useState, useEffect } from "react";

import {
  HeaderPage,
  TableIndicadoresAdmins,
  AddEditIndicadoresForm,
  DeleteIndicadorForm,
} from "../../../components/Admin";
import { useIndicadores, useCatalagos } from "../../../hooks";
import { ModalBasic } from "../../../components/Common";
import { faDeleteLeft, faChartGantt } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";

export function Indicadores() {
  const { loading, indicadores, getIndicadores } = useIndicadores();
  const { sectores, getSectores } = useCatalagos();
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("sm");

  useEffect(() => {
    getIndicadores();
    getSectores();
  }, [refetch]);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateIndicadores = (data) => {
    setTitleModal(" Actualizar Indicadores");
    setIconModal(faChartGantt);
    setsizeModal("xl");
    setContentModal(
      <AddEditIndicadoresForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        indicador={data}
      />
    );
    openCloseModal();
  };

  const addIndicadores = () => {
    setTitleModal(" Crear Indicadores");
    setIconModal(faChartGantt);
    setsizeModal("xl");
    setContentModal(
      <AddEditIndicadoresForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
      />
    );
    openCloseModal();
  };

  const ondeleteIndicadores = async (data) => {
    setTitleModal("Eliminar usuario");
    setIconModal(faDeleteLeft);
    setsizeModal("sm");
    setContentModal(
      <DeleteIndicadorForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        indicador={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <HeaderPage
        title="Indicadores"
        btnTitle="Nuevo Indicador"
        btnClick={addIndicadores}
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner className="spinner" animation="border" />
        </div>
      ) : (
        <TableIndicadoresAdmins
          indicadores={indicadores}
          updateIndicador={updateIndicadores}
          onDeleteIndicador={ondeleteIndicadores}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
