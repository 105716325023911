import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Figure, Image } from "react-bootstrap";
import logo from "../../../images/Logo.png";

import { useAuth } from "../../../hooks";

import "./HomeAdmin.scss";

export function HomeAdmin(props) {
  const { auth } = useAuth();
  const role = auth.me.role;

  if (role === 6) return HomeB2BComprador();
  else if (role === 7) return HomeB2BVendedor();
  else return Home();

  function HomeB2BComprador(props) {
    return (
      <>
        <center>
          <div className="conteinerB2B">
            <Figure>
              <Image className="image" src={logo}></Image>
            </Figure>
            <h1 className="colorTitulos">¡Bienvenido al B2B de OTS!</h1>
            <br />
            <h4 className="colorSubTitulo"></h4>
            <br />
            <FontAwesomeIcon icon={faHandshake} className="icon iconB2B" />
          </div>
        </center>
      </>
    );
  }

  function HomeB2BVendedor(props) {
    return (
      <>
        <center>
          <div className="conteinerB2B">
            <Figure>
              <Image className="image" src={logo}></Image>
            </Figure>
            <h1 className="colorTitulos">¡Bienvenido al B2B de OTS!</h1>
            <br />
            <h4 className="colorSubTitulo"></h4>
            <br />
            <FontAwesomeIcon icon={faHandshake} className="icon iconB2B" />
          </div>
        </center>
      </>
    );
  }

  function Home(props) {
    return (
      <>
        <div className="container0">
          <div className="container1">
            <h1>Bienvenidos</h1>
            <h4>Sistema de Gestión del OT's</h4>
            <div className="text-container">
              <span>
                Estas en el sistema de administracion del portal del Ots de
                acuerdo a tu perfil podras levantar solicitudes, administrar
                solicitudes y todo lo realacionado al portal.
              </span>
            </div>
          </div>
          <div className="container2">
            <Image className="image" src={logo}></Image>
          </div>
        </div>
      </>
    );
  }
}
