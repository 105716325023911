import { BASE_API } from "../utils/constants";

export async function getColaboradoresApi(token) {
  try {
    const url = `${BASE_API}/api/datosCombinados/`;
    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getColaboradoresLandingPageApi() {
  try {
    const url = `${BASE_API}/api/datosCombinados/datosColaborador/`;
    const params = {
      headers: {
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getColaboradoresFiltroSectoresApi(filtro) {
  try {
    const url = `${BASE_API}/api/datosCombinados/filtradoColaborador/${filtro}/`;
    const params = {
      headers: {
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getLastIDColaboradorApi() {
  try {
    const url = `${BASE_API}/api/colaboradores/ultimoID/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getActivosApi() {
  try {
    const url = `${BASE_API}/api/colaboradores/activos/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function AddColboradorApi(data, lastIdUser) {
  try {
    console.log("Funcion ", parseInt(lastIdUser))
    const formData = new FormData();
    formData.append('user', lastIdUser);
    formData.append('institucion', data.institucion);
    formData.append('sitio_web', data.sitio_web);
    formData.append('cargo', data.cargo);
    formData.append('tel_oficina', data.tel_oficina);
    formData.append('tel_celular', data.tel_celular);
    formData.append('pais', data.pais);
    formData.append('ciudad', data.ciudad);
    formData.append('colaboracion', data.colaboracion);
    formData.append('aviso_privacidad', data.aviso_privacidad);
    console.log(formData)
    const url = `${BASE_API}/api/colaboradores/userColaborador/`;
    const params = {
      method: "POST",
      headers: {
      },
      body: formData,
    };

    const response = await fetch(url, params);
    if (response.status !== 200) {
      throw new Error("El colaborador no se pudo dar de alta");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateColaboradorFirstTimeApi(data, token, id){
  try {
    console.log("<><><>");
    console.log(data)
    const formData = new FormData();
    formData.append('estatus', 2);
    formData.append('nombre', data.nombre);
    formData.append('apellido_paterno', data.apellido_paterno);
    formData.append('institucion', data.institucion);
    formData.append('sitio_web', data.sitio_web);
    formData.append('tel_celular', data.tel_celular);
    formData.append('mail', data.email);
    formData.append('pais', data.pais['id']);
    formData.append('ciudad', data.ciudad);
    formData.append('colaboracion', data.colaboracion);
        const url = `${BASE_API}/api/colaboradores/${id}/`;
        const params= {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
  } catch (error) {
    throw error;
  }
}

export async function addColaboradorDatosAdicionalesApi(id){
  try{
    console.log("Datos")
    const formData = new FormData();
    formData.append('id_colaborador', id);
    formData.append('especializacion', "");
    formData.append('sectores', 1);
    formData.append('trayectoria', "");
    formData.append('dispone_dias', "");
    formData.append('dispone_horas', "");
    formData.append('honorarios', 1);
    formData.append('foto', "");
    const url = `${BASE_API}/api/colaboraadicional/colaboraAdicional/`;
    const params = {
        method: "POST",
        headers: {
        },
        body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  }catch(error){
      throw error;
  }
}

export async function updateColaboradorDatosAdicionalesApi(data, id, token){

  try{
    const formData = new FormData();
    if(data.especializacion)formData.append('especializacion', data.especializacion);
    if(data.trayectoria) formData.append('trayectoria', data.trayectoria);
    if(data.dispone_dias) formData.append('dispone_dias', data.dispone_dias);
    if(data.dispone_horas) formData.append('dispone_horas', data.dispone_horas);
    if(data.honorarios) formData.append('honorarios', data.honorarios);
    if(data.foto) formData.append('foto', data.foto);
    const url = `${BASE_API}/api/colaboraadicional/${id}/`;
    const params = {
        method: "PATCH",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  }catch(error){
      throw error;
  }
}

export async function deleteColboradorApi(id, token){
  try {
      const url = `${BASE_API}/api/colaboradores/${id}/`;
      const params= {
          method: "DELETE",
          headers: {
              Authorization: `Bearer ${token}`,
          },

      };
      const response = await fetch(url, params);
      const result = await response.json();
      return result;
  } catch (error) {
      throw error;
  }
}

export async function sendMailUpdateColaboraApi(data, token) {
  try {
    const url = `${BASE_API}/api/notificacionFirstUpdateColaborador/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}