
import React from 'react'
import { Button } from 'react-bootstrap';

import { useExperienciaUsuario } from '../../../../hooks';

export function DeleteExperienciaCliente(props) {
    const { onClose, onRefetch, experienciaC } = props;
    const { deleteExperienciaCliente } = useExperienciaUsuario();
  
    async function deleExperienciaCliente() {
      try {
        await deleteExperienciaCliente(experienciaC.id);
        onRefetch();
        onClose();
      } catch (error) {
        console.error(error);
      }
    }
  
    function closeModal() {
      onClose();
    }
  
    return (
      <div>
        <h3 className="title-spacing">¿Estás seguro qué quieres eliminar esta opinión?</h3>
        <div className="delete-user-form">
          <Button variant="danger" className="btnaling" onClick={deleExperienciaCliente}>
            Eliminar
          </Button>
          <Button variant="outline-danger" className="btnaling" onClick={closeModal}>
            Cancelar
          </Button>
        </div>
      </div>
    );
  }