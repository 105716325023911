import React from "react";
import Button from "react-bootstrap/Button";
import { useIndicadores } from "../../../../hooks";
import "./DeleteIndicadorFrom.scss";

export function DeleteIndicadorForm(props) {
  const { onClose, onRefetch, indicador } = props;
  const { deleteIndicador  } = useIndicadores();

  async function delIndicador() {
    try {
      await deleteIndicador(indicador.id);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h3 className="title-spacing">¿Está seguro de eliminar el usuario?</h3>
      <div className="delete-user-form">
        <Button className="btnaling" onClick={delIndicador}>
          Eliminar
        </Button>
        <Button className="btnaling" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}