import React, { useEffect } from "react";

import { DividerIcon } from "../../../components/Common";
import { TableB2B } from "../../../components/b2b/comprador";
import { useB2B } from "../../../hooks";
import "./B2B_Comprador.scss";

export function B2B_Comprador() {
  const { companys, getCompanysToB2B, loading } = useB2B();

  useEffect(() => {
    const getData = async () => {
      await getCompanysToB2B();
    };
    getData();
  }, []);

  return (
    <div className="conteiner-b2b">
      <DividerIcon titulo="Encuentro de negocios" />
      <br />
      <article>{loading ? null : <TableB2B data={companys} />}</article>
    </div>
  );
}
