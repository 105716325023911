import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";

import { DividerIcon } from '../../../components/Common';
import { ConteinerCards } from '../../../components/b2b';
import { useB2B } from '../../../hooks';

export function CitasAgenda() {

    const { id } = useParams();
    const { getAgendaUser, agenda } = useB2B();
    useEffect(() => {
        const getData = async () => {
            await getAgendaUser(id, 2)
        }
        getData()
    }, [])

    console.log(agenda);

    return (
        <div>
            {
                agenda
                    ?
                    <div>
                        <DividerIcon titulo="Citas generadas" />
                        <ConteinerCards data={agenda} />
                    </div>
                    :
                    <DividerIcon titulo="Sin citas generadas" />
            }
        </div>
    )
}
