import React from "react";
import { map } from "lodash";
import { Card, Row, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faImagePortrait,
  faPersonChalkboard,
  faGlobe,
  faPersonRays,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

import "./RedColaborador.scss";
import userImage from "../../../../images/user.png";

export function RedColaborador(props) {
  const { colaboradores } = props;
  console.log(colaboradores);
  return (
    <>
      {map(colaboradores, (colaborador, index) => (
        <div key={index}>
          <Card className="carta-colaborador">
            <Card.Header>
              <a href={colaborador.id_colaborador["sitio_web"]} target="_blank">
                {colaborador.id_colaborador["institucion"]}{" "}
                <FontAwesomeIcon icon={faLink} />
              </a>
            </Card.Header>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <Row className="mb-6">
                  <div className="col col-sm-2">
                    <Image
                      src={colaborador.foto ? colaborador.foto : userImage}
                      roundedCircle
                      thumbnail
                      fluid
                    />
                  </div>
                  <div className="col col-sm-9">
                    <Row className="mb-6">
                      <p className="col col-sm-7">
                        <FontAwesomeIcon icon={faImagePortrait} />{" "}
                        {` - ${colaborador.id_colaborador.user["nombre"]} ${colaborador.id_colaborador.user["apellido_paterno"]} ${colaborador.id_colaborador.user["apellido_materno"]}`}
                      </p>
                      <p className="col col-sm-5">
                        <FontAwesomeIcon icon={faPersonChalkboard} />
                        {` - Èspecialista en: ${colaborador.especializacion}`}
                      </p>
                    </Row>
                    <Row className="mb-6">
                      <p className="col col-sm-7">
                        <FontAwesomeIcon icon={faPersonRays} />
                        {` - Trayectoria: ${colaborador.trayectoria}`}
                      </p>
                      <p className="col col-sm-5">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                        {` - Sector de trabajo: ${colaborador.sectores}`}
                      </p>
                    </Row>
                  </div>
                </Row>
                <br />
                <footer className="blockquote-footer">
                  <FontAwesomeIcon icon={faGlobe} />
                  <cite title="Source Title">
                    {colaborador.id_colaborador["pais"]} -
                    {colaborador.id_colaborador["ciudad"]}
                  </cite>
                </footer>
              </blockquote>
            </Card.Body>
          </Card>
          <br />
        </div>
      ))}
    </>
  );
}
