import React from 'react'
import { map } from 'lodash'
import { useFormik } from "formik";
import { Form, Button, } from 'react-bootstrap'

import "./FilterDat.scss"
export function FilterEstado(props) {

    const { setEstado, onRefetch } = props;

    const formik = useFormik({
        initialValues: initialValues(),
        validateOnChange: false,
        onSubmit: async (formvalue) => {
            setEstado(formvalue.estado)
            onRefetch();
        },
    });

    return (
        <>
            <Form.Group className="col col-sm-6 col-lg-4 col-xl-3">
                <Form.Label>Buscador de estado</Form.Label>
                <Form className="d-flex" onSubmit={formik.handleSubmit}>
                    <Form.Select
                        className="form-control"
                        name="estado"
                        defaultValue={formik.values.estado}
                        onChange={formik.handleChange}
                    >
                        <option value={0}>Todos</option>
                        <option value={1}>Pendiente</option>
                        <option value={2}>Resuelto</option>
                    </Form.Select>

                    <Button className='btn-Buscador' variant="outline-success" type="submit">Buscar</Button>

                </Form>
            </Form.Group>

        </>
    )
}

function initialValues() {
    return {
        estado: "Selecciona un estado",
    };
}