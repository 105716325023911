import { AdminLayout } from "../layouts";
import { Agendas, CitasAgenda, VendedorB2BHome } from "../pages/b2b/vendedor";
import { RequerimientosVendedor } from "../components/Client/MenuRequerimientosVendedor";

const routes_b2b_vendedor = [
  {
    path: "/b2b/vendedor/home",
    layout: AdminLayout,
    component: VendedorB2BHome,
  },
  {
    path: "/b2b/vendedor/requerimientos/:id_micrositio",
    layout: AdminLayout,
    component: RequerimientosVendedor,
  },
  {
    path: "/b2b/vendedor/agendas",
    layout: AdminLayout,
    component: Agendas,
  },
  {
    path: "/b2b/vendedor/agendas/citas-:id",
    layout: AdminLayout,
    component: CitasAgenda,
  },
];

export default routes_b2b_vendedor;
