import React from 'react'
import './ViewQuestionsFacturacion.scss'

export function ViewQuestionsFacturacion() {
    return (
        <div>
            <div className='container_Factura'>
                <h3 className='questions_title_Factura'>¿Cómo solicito la factura?</h3>
                <p className='description_Factura'>
                    Da clic en ayuda, facturación y llena el formulario correspondiente para que se contacten contigo.
                </p>
            </div>
            <div className='container_Factura'>
                <h3 className='questions_title_Factura'>Mi factura es incorrecta:</h3>
                <p className='description_Factura'>
                    Da clic en ayuda, facturación y llena el formulario correspondiente para que se contacten contigo.
                </p>
            </div>
            <div className='container_Factura'>
                <h3 className='questions_title_Factura'>¿Cómo contacto con OTS?</h3>
                <p className='description_Factura'>
                    Puedes enviar un correo electrónico a hugo.cruz@code-connect.com.mx o al teléfono: 55 6473 6279 para comunicarte para cualquier asunto que desees atender.
                </p>
            </div>
        </div>
    )
}
