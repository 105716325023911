import { BASE_API } from "../utils/constants";
import { cadenaRandom } from "../metodosGlobales/RamdonString";

export async function loginApi(formValue) {
  try {
    const url = `${BASE_API}/api/auth/login/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValue),
    };

    const response = await fetch(url, params);
    if (response.status !== 200) {
      throw new Error("Usuario o contraseña incorrectos");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getMeApi(token) {
  try {
    const url = `${BASE_API}/api/auth/me/`;
    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getUsersApi(token) {
  try {
    const url = `${BASE_API}/api/users/`;
    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function addUserApi(data, role, estatus, is_active, is_staff) {
  const formData = new FormData();
  formData.append("nombre", data.nombre);
  formData.append("username", data.email);
  formData.append("apellido_paterno", data.apellido_paterno);
  formData.append("apellido_materno", data.apellido_materno);
  formData.append("is_active", is_active);
  formData.append("is_staff", is_staff);
  formData.append("password", cadenaRandom(8));
  formData.append("role", role);
  formData.append("estatus", estatus);
  formData.append("email", data.email);
  try {
    const url = `${BASE_API}/api/users/`;
    const params = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateUserApi(id, data, token) {
  try {
    const formData = new FormData();
    if (data.nombre) formData.append("nombre", data.nombre);
    if (data.email) formData.append("username", data.email);
    if (data.apellido_paterno)
      formData.append("apellido_paterno", data.apellido_paterno);
    if (data.apellido_materno)
      formData.append("apellido_materno", data.apellido_materno);
    formData.append("is_active", data.is_active);
    formData.append("is_staff", data.is_staff);
    if (data.password) formData.append("password", data.password);
    if (data.role) formData.append("role", data.role);
    if (data.estatus) formData.append("estatus", data.estatus);
    if (data.email) formData.append("email", data.email);
    const url = `${BASE_API}/api/users/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteUserApi(id, token) {
  try {
    const url = `${BASE_API}/api/users/${id}`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendMailRecApi(data) {
  data.url = window.location.origin;
  try {
    const url = `${BASE_API}/api/notificacion/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updatePassApi(id, data) {
  try {
    const url = `${BASE_API}/api/users/reset-pass/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, params);
    if (response.status !== 200) {
      throw new Error(
        "El token no es valido o ya caduco vuelva a solicitar uno "
      );
    }
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addUserColaboradorApi(
  data,
  role,
  estatus,
  is_active,
  is_staff
) {
  const formData = new FormData();
  formData.append("nombre", data.nombre);
  formData.append("username", data.email);
  formData.append("apellido_paterno", data.apellido_paterno);
  formData.append("apellido_materno", data.apellido_materno);
  formData.append("is_active", is_active);
  formData.append("is_staff", is_staff);
  formData.append("password", data.password);
  formData.append("role", role);
  formData.append("estatus", estatus);
  formData.append("email", data.email);
  try {
    const url = `${BASE_API}/api/users/userColaborador/`;
    const params = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendMailNewUserApi(data, token) {
  console.log("Datos", data);
  const formData = new FormData();
  formData.append("email", data);
  formData.append("url_front", window.location.origin);
  try {
    const url = `${BASE_API}/api/notificacionNuevoUsuario/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}


// Filtramos a los usuarios por el tipo de role (Administrador, colaborabor, etc.)
export async function getUsersFilterApi(token, filter) {
  try {
    const url = `${BASE_API}/api/users/filtradoUser/${filter}/`;
    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
