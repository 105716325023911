import {
  faClapperboard,
  faFileLines,
  faPersonChalkboard,
  faPlusCircle,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { HeaderPage } from "../../../components/Admin";
import { useAuth, usePlanPago } from "../../../hooks";
import "./ServiciosLicenciamiento.scss";
export function ServiciosLicenciamientoCliente() {

  const { auth } = useAuth();
  const { clienteIdUser, getClienteIdUser } = usePlanPago();

  useEffect(() => {
    const fetchData = async () => {
      getClienteIdUser(auth.me['id']);
    }
    fetchData();
  }, [])

  return (
    <>
      <Menu
        clienteIdUser={clienteIdUser}
      />
    </>
  );
}

function Menu(props) {
  const { clienteIdUser } = props;
  if (clienteIdUser?.plan === 1) return menuUsuarioFree();
  else if (clienteIdUser?.plan === 2) return menuUsuarioBásico();
  else if (clienteIdUser?.plan === 3) return menuUsuarioPremium();
  else return null;
}

function menuUsuarioFree() {
  return (
    <>
      <div className="contenedor">
        <Accordion className="acordeon" defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Funciones Bloqueadas para cliente Gratuito</Accordion.Header>
            <Accordion.Body className="text">
              El usuario gratuito tiene limitaciones en los recursos disponibles en los cual tiene acceso,
              si usted desea ingresar a estas funciones actualice su membresía.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

function menuUsuarioBásico() {
  return (
    <>
      <HeaderPage title="Servicios disponibles con licenciamiento de usuario" />
      <Row className="licenciamiento justify-content-center mt-5">
        <Col xs={10}>
          <Card>
            <Card.Body>
              <Row className="my-3">
                <Col className="licenciamiento-menu-izq p-0">
                  <Link to="/admin/serviciosLicenciamiento/EstudiosInteligencia">
                    <Button
                      className="licenciamiento-btn"
                      variant="outline-primary"
                    >
                      <div className="licenciamiento-icon-btn">
                        <FontAwesomeIcon icon={faFileLines} className="icon" />
                      </div>
                      <div className="licenciamiento-text-btn">
                        Catálogo de Estudios de inteligencia
                      </div>
                    </Button>
                  </Link>
                </Col>
                <Col className="licenciamiento-menu-der p-0">
                  <Link to="/admin/serviciosLicenciamiento/ProveedoresBienesServicios">
                    <Button
                      className="licenciamiento-btn"
                      variant="outline-danger"
                    >
                      <div className="licenciamiento-icon-btn">
                        <FontAwesomeIcon
                          icon={faPersonChalkboard}
                          className="icon"
                        />
                      </div>
                      <div className="licenciamiento-text-btn">
                        Proveedores de bienes y servicios
                      </div>
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row className="my-3">
                <Col className="licenciamiento-menu-der p-0">
                  <Link to="/admin/solicitudCliente">
                    <Button
                      className="licenciamiento-btn"
                      variant="outline-secondary"
                    >
                      <div className="licenciamiento-icon-btn">
                        <FontAwesomeIcon icon={faPlusCircle} className="icon" />
                      </div>
                      <div className="licenciamiento-text-btn">
                        Petición de servicio.
                      </div>
                    </Button>
                  </Link>
                </Col>
                <Col className="licenciamiento-menu-der p-0">
                  <Link to="/admin/serviciosLicenciamiento/TalentoEspecializado">
                    <Button
                      className="licenciamiento-btn"
                      variant="outline-success"
                    >
                      <div className="licenciamiento-icon-btn">
                        <FontAwesomeIcon icon={faUserGear} className="icon" />
                      </div>
                      <div className="licenciamiento-text-btn">
                        Perfilamiento y colocación de talento especializado.
                      </div>
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

function menuUsuarioPremium() {
  return (
    <>
      <HeaderPage title="Servicios disponibles con licenciamiento de usuario" />
      <Row className="licenciamiento justify-content-center mt-5">
        <Col xs={10}>
          <Card>
            <Card.Body>
              <Row className="my-3">
                <Col className="licenciamiento-menu-izq p-0">
                  <Link to="/admin/serviciosLicenciamiento/EstudiosInteligencia">
                    <Button
                      className="licenciamiento-btn"
                      variant="outline-primary"
                    >
                      <div className="licenciamiento-icon-btn">
                        <FontAwesomeIcon icon={faFileLines} className="icon" />
                      </div>
                      <div className="licenciamiento-text-btn">
                        Catálogo de Estudios de inteligencia
                      </div>
                    </Button>
                  </Link>
                </Col>
                <Col className="licenciamiento-menu-der p-0">
                  <Link to="/admin/serviciosLicenciamiento/ProveedoresBienesServicios">
                    <Button
                      className="licenciamiento-btn"
                      variant="outline-danger"
                    >
                      <div className="licenciamiento-icon-btn">
                        <FontAwesomeIcon
                          icon={faPersonChalkboard}
                          className="icon"
                        />
                      </div>
                      <div className="licenciamiento-text-btn">
                        Proveedores de bienes y servicios
                      </div>
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row className="my-3">
                <Col className="licenciamiento-menu-izq p-0">
                  <Link to="/admin/serviciosLicenciamiento/TalentoEspecializado">
                    <Button
                      className="licenciamiento-btn"
                      variant="outline-success"
                    >
                      <div className="licenciamiento-icon-btn">
                        <FontAwesomeIcon icon={faUserGear} className="icon" />
                      </div>
                      <div className="licenciamiento-text-btn">
                        Perfilamiento y colocación de talento especializado.
                      </div>
                    </Button>
                  </Link>
                </Col>
                <Col className="licenciamiento-menu-der p-0">
                  <Link to="/admin/serviciosLicenciamiento/Webinars">
                    <Button
                      className="licenciamiento-btn"
                      variant="outline-info"
                    >
                      <div className="licenciamiento-icon-btn">
                        <FontAwesomeIcon
                          icon={faClapperboard}
                          className="icon"
                        />
                      </div>
                      <div className="licenciamiento-text-btn">webinars</div>
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row className="my-3">
                <Col className="licenciamiento-menu-izq p-0">
                  <Link to="/admin/solicitudCliente">
                    <Button
                      className="licenciamiento-btn"
                      variant="outline-secondary"
                    >
                      <div className="licenciamiento-icon-btn">
                        <FontAwesomeIcon icon={faPlusCircle} className="icon" />
                      </div>
                      <div className="licenciamiento-text-btn">
                        Petición de servicio.
                      </div>
                    </Button>
                  </Link>
                </Col>
                <Col className="licenciamiento-menu-der p-0"></Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
