import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { Badge, Button, Form, Nav, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { Divider } from "../../../../components/Common/Divider";
import { usePagos, usePlanPago } from "../../../../hooks";
import { IngresaDatosForm } from "../../../Generales";
export function PlanGratuitoFrom(props) {
  const { onClose, onRefetch, sectores, paises, tipoCliente, plan } = props;
  const { getUrlCheckout } = usePagos();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState([]);

  const {
    addClienteGratis,
    addClienteBasico,
    addClientePremium,
    addUserCliente,
  } = usePlanPago();

  const onChangeSectores = (e) => {
    const select = e.split(',')
    setState([...state, select])
  }

  const deleteSector = (e) => {
    const newArray = []
    state.forEach(sector => {
      if (sector[0] !== e[0]) newArray.push(sector)
    });
    setState(newArray)
  }


  useEffect(() => {
    const fetchData = async () => {
      formik.setFieldValue("sectores", state);
    };
    fetchData();
  }, [loading, state]);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      try {
        //setLoading(true)
        console.log(formvalue);
        const idUser = await addUserCliente(formvalue);
        if (tipoCliente === "Premium") {
          const cliente = await addClientePremium(formvalue, idUser["id"]);
          const response = await getUrlCheckout(plan, cliente.clave);
          window.open(response.url);
        } else if (tipoCliente === "Basico") {
          const cliente = await addClienteBasico(formvalue, idUser["id"]);
          const response = await getUrlCheckout(plan, cliente.clave);
          window.open(response.url, "_self");
          /*window.location.replace(
            "http://localhost:3000/servicios/pagos/success/dsfasdhflkjhqwsd"
          );*/
        } else {
          await addClienteGratis(formvalue, idUser["id"]);
          toast.info("¡Los campos han sido llenados con éxito!.", {
            position: "top-center",
          });
        }
        //setLoading(false)
        //onRefetch();
        //onClose();
      } catch (error) {
        //setLoading(true)
        toast.error("¡No fue posible el llenado de los campos!."
        );
        console.log(error);
      }
    },
  });

  return (
    <Form className="alta-col-form" onSubmit={formik.handleSubmit}>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Nombre(s)"
          formatoLabel="tituloIzquierda"
          nameControl="nombre"
          type="text"
          placeHolderControl="Ingrese su nombre"
          error={formik.errors.nombre}
          touched={formik.touched.nombre}
          valueControl={formik.values.nombre}
          onChangeControl={formik.handleChange}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Apellido Paterno"
          formatoLabel="tituloIzquierda"
          nameControl="apellido_paterno"
          type="text"
          placeHolderControl="Ingrese su apellido paterno"
          valueControl={formik.values.apellido_paterno}
          touched={formik.touched.apellido_paterno}
          error={formik.errors.apellido_paterno}
          onChangeControl={formik.handleChange}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Apellido Materno"
          formatoLabel="tituloIzquierda"
          nameControl="apellido_materno"
          type="text"
          placeHolderControl="Ingrese su apellido materno"
          valueControl={formik.values.apellido_materno}
          touched={formik.touched.apellido_materno}
          error={formik.errors.apellido_materno}
          onChangeControl={formik.handleChange}
        />
      </Row>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Email"
          formatoLabel="tituloIzquierda"
          nameControl="email"
          type="email"
          placeHolderControl="Ingrese su correo"
          valueControl={formik.values.email}
          onChangeControl={formik.handleChange}
          touched={formik.touched.email}
          error={formik.errors.email}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Teléfono de casa u oficina"
          formatoLabel="tituloIzquierda"
          nameControl="telefono"
          type="text"
          placeHolderControl="Ingrese su telefono"
          valueControl={formik.values.telefono}
          onChangeControl={formik.handleChange}
          touched={formik.touched.telefono}
          error={formik.errors.telefono}
        />
      </Row>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Institución de procedencia"
          formatoLabel="tituloIzquierda"
          nameControl="institucion"
          type="text"
          placeHolderControl="Ingrese su institucion"
          valueControl={formik.values.institucion}
          onChangeControl={formik.handleChange}
          touched={formik.touched.institucion}
          error={formik.errors.institucion}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Cargo"
          formatoLabel="tituloIzquierda"
          nameControl="cargo"
          type="text"
          placeHolderControl="Ingrese su cargo"
          valueControl={formik.values.cargo}
          onChangeControl={formik.handleChange}
        />
        <Form.Group controlId="formPais" className="col col-sm-4">
          <Form.Label>Pais</Form.Label>
          <Form.Select
            defaultValue={formik.values.pais}
            className="form-control"
            name="pais"
            onChange={formik.handleChange}
          >
            {map(paises, (pais, index) => (
              <option key={index} value={pais["id"]}>
                {pais["nombre"]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group controlId="formsectores" className="col col-sm-4">
          <Form.Label>Sectores de Interes</Form.Label>
          <Form.Select
            defaultValue={formik.values.sectores}
            name="sectores"
            onChange={(e) => onChangeSectores(e.target.value)}
          >
            <option value={"0"}>Seleccione una opcion</option>
            {map(sectores, (sector, index) => (
              <option key={index} value={[sector.id, sector.descripcion]}>
                {sector["descripcion"]}
              </option>
            ))}
          </Form.Select>
          <Form.Text className="text-danger">
            {formik.touched.sectores && formik.errors.sectores ? (
              <div className="text-danger">{formik.errors.sectores}</div>
            ) : null}
          </Form.Text>
        </Form.Group>
        <Form.Group className="col col-sm-4">
          <TagSector
            deleteSector={deleteSector}
            sectores={state}
          />
        </Form.Group>
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Qué información es de tu interés"
          formatoLabel="tituloIzquierda"
          nameControl="interes"
          type="text"
          placeHolderControl="Ingrese su interes"
          valueControl={formik.values.interes}
          onChangeControl={formik.handleChange}
          touched={formik.touched.interes}
          error={formik.errors.interes}
        />
      </Row>
      <Divider />
      <Row className="mb-3">
        <Form.Group
          controlId="formCheckbox"
          className="col col-sm-12 d-flex flex-row"
        >
          <Form.Check
            name="aviso_privacidad"
            type="checkbox"
            label="Aceptar Aviso de Privacidad"
            onChange={formik.handleChange}
            checked={formik.values.aviso_privacidad}
            error={formik.errors.aviso_privacidad}
          />
          <Nav.Link className="ms-2 text-primary"
            as={Link}
            to={"/aviso/privacidad"}
            active={pathname === "/aviso/privacidad"}
          >
            Ver aviso
          </Nav.Link>
        </Form.Group>
        <Form.Text className="text-danger mb-3">
          {formik.touched.aviso_privacidad && formik.errors.aviso_privacidad ? <div className="text-danger">{formik.errors.aviso_privacidad}</div> : null}
        </Form.Text>
        <Form.Group controlId="formCheckbox" className="col col-sm-6">
          {
            loading
              ?
              <Button variant="primary" type="submit" className="me-4 btn-block mb-3" disabled>
                Creando cliente, por favor espere...
              </Button>
              :
              <Button variant="primary" type="submit" className="me-4 btn-block mb-3">
                Aceptar
              </Button>
          }

          <Button
            variant="secondary"
            className="me-4 btn-block"
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </Button>
        </Form.Group>
        <Form.Text className="text-danger text-center"></Form.Text>
      </Row>
    </Form>
  );
}

function initialValues() {
  return {
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    email: "",
    password: "456456",
    telefono: "",
    institucion: "",
    pais: "1",
    cargo: "",
    sectores: "0",
    interes: "",
    aviso_privacidad: false,
  };
}
function newSchema() {
  return {
    nombre: Yup.string("El nombre no puede ser vacio").required(
      "El usuario es requerido"
    ),
    apellido_paterno: Yup.string(
      "El apellido paterno no puede ser vacio"
    ).required("El apellido paterno es requerido"),
    apellido_materno: Yup.string(
      "El apellido materno no puede ser vacio"
    ).required("El apellido materno es requerido"),
    email: Yup.string("El mail no puede ser vacio")
      .email("No es un formato de mail valido")
      .required("El mail es requerido"),
    telefono: Yup.string("Este campo no puede ser vacio").required(
      "El telefono es requerido"
    ),
    institucion: Yup.string("Este campo no puede ser vacio").required(
      "La institucion es requerida"
    ),
    interes: Yup.string("Este campo no puede ser vacio").required(
      "El interes es requerido"
    ),
    aviso_privacidad: Yup.bool().oneOf([true], "Debes de aceptar el aviso de privacidad")
  };
}

function TagSector(props) {

  const { deleteSector, sectores } = props;
  if (sectores.length === 0) return null
  return (
    sectores.map((sector, index) => (
      <h5 key={index}>
        <Badge style={{ marginLeft: "10px" }}>
          {sector[1]}
          {' '}
          <a onClick={() => deleteSector(sector)}>
            <FontAwesomeIcon icon={faCircleXmark} size='sm' />
          </a>
        </Badge>
      </h5>
    ))

  )

} 