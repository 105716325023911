import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { colorAssign } from '../../../metodosGlobales'
import { GeographyCluster, TitleCluster, DescriptionCluster, AccordionCluster } from "../../../components/Admin"


export function MapCluster() {

    const colors = colorAssign("lime")

    return (
        <div>

            <Row>
                {/* esto es para el responsive :3 */}
                <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={4}>
                    <TitleCluster

                    />
                    <DescriptionCluster
                        colors={colors}
                    />
                    <AccordionCluster />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={8}>
                    <GeographyCluster
                        colors={colors}
                    />
                </Col>
            </Row>


        </div>
    )
}
