import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form, Button } from "react-bootstrap";
import { useUser } from "../../../hooks";

import { ButtonLoad } from "../../../components/Common";
import "./RecoveryPass.scss";

export function RecoveryPass(props) {
  const [loading, setLoading] = useState(false);
  const { onClose } = props;
  const { sendMailRecover } = useUser();
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      try {
        setLoading(true);
        await sendMailRecover(formValue);
        setLoading(false);
        toast.info(
          "Se ha enviado un mail indicando los pasos a seguir para la recuperacion de su contraseña... ",
          { position: "top-center" }
        );
        onClose();
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    },
  });

  return (
    <Form className="recovery-pass" onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>
          Ingresa tu correo electrónico para recuperar la contraseña
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Correo Electrónico"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={
            formik.touched.email && formik.errors.email ? "error" : null
          }
        />
        <Form.Text className="text-danger">
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </Form.Text>
      </Form.Group>
      {loading ? (
        <ButtonLoad></ButtonLoad>
      ) : (
        <Button variant="primary" type="submit">
          Enviar solicitud
        </Button>
      )}
    </Form>
  );
}

function initialValues() {
  return {
    email: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string()
      .email("El formato del mail es incorrecto")
      .required("El mail es requerido"),
  };
}
