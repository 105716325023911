import React from 'react'
import {
    Geography
} from 'react-simple-maps'


export function StateCluster(props) {

    const { geog, colors, setData, setDataC, dataC } = props

    const setColor = () => {
        const color = colors[(Math.floor(Math.random() * 10))]
        return `hsl(${color['hsl']['h']}, ${color['hsl']['s']}%, ${color['hsl']['l']}%)`
    }

    const handleOnClick = (e, state) => {
        setDataC(state.NAME_1)
        setData(null)
    }

    const handleMouseLeave = () => {
        setData(null)


    };

    const handleMouseEnter = (event, state) => {
        if (dataC === state.NAME_1) {
            setData(null)
            return null
        }
        setData(state.NAME_1);



    };

    if (geog)
        return (
            <Geography
                geography={geog}
                key={geog.rsmKey}
                style={{
                    default: { fill: setColor() },
                    hover: { fill: "#04D", },
                    pressed: { fill: "#000" },

                }}

                onClick={(e) => handleOnClick(e, geog.properties)}
                onMouseEnter={(event) => handleMouseEnter(event, geog.properties)}
                onMouseLeave={handleMouseLeave}


            />
        )
    else
        return
}
