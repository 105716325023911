import React from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

import { map } from "lodash";
import { formatDate } from "../../../../metodosGlobales/FormatoFecha";
import { useAuth } from "../../../../hooks";
import { ModalVerMas } from "../../../Common/ModalVerMas/ModalVerMas";

import "./TableAyudaCambio.scss";

export function TableAyudaCambio(props) {
  const { cambios, updateAyudaServicio, onDeleteAyudaServicio } = props;

  const { auth } = useAuth();

  const desc_membresia = (planId) => {
    if (planId === 1) {
      return "Gratuita";
    } else if (planId === 2) {
      return "Basica";
    } else if (planId === 3) {
      return "Premium";
    }
  };
  const estado = (idestado) => {
    if (idestado === 2) {
      return <FontAwesomeIcon icon={faCheck} />;
    } else if (idestado === 1) {
      return <FontAwesomeIcon icon={faXmark} />;
    }
  };
  return (
    <div className="table-responsive-xl">
      <Table striped bordered hover className="table-users-admin table-sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha de solicitud</th>
            <th>Teléfono de contácto</th>
            <th>Email</th>
            <th>Membresia actual</th>
            <th>Membresial solicitada</th>
            <th>Fecha de atención</th>
            <th>Comentario</th>
            <th>Estatus</th>
            <th>Solución</th>
          </tr>
        </thead>
        <tbody>
          {map(cambios, (servicio, index) => (
            <tr key={index}>
              <td>{servicio.id}</td>
              <td>{formatDate(servicio.fecha_solicitud)}</td>
              <td>{servicio.telefono}</td>
              <td>{servicio.email}</td>
              <td>{desc_membresia(servicio.membresia_actual)}</td>
              <td>{desc_membresia(servicio.membresia_solicitada)}</td>
              <td>{formatDate(servicio.fecha_atencion)}</td>
              <td>
                <ModalVerMas title="Comentario" content={servicio.comentario} />
              </td>
              <td className="status">{estado(servicio.estatus)}</td>
              <td>
                {servicio.solucion ? (
                  <ModalVerMas title="Solución" content={servicio.solucion} />
                ) : null}
              </td>
              <Actions
                servicio={servicio}
                updateAyudaServicio={updateAyudaServicio}
                onDeleteAyudaServicio={onDeleteAyudaServicio}
                permiso={auth.me.role}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
function Actions(props) {
  const { servicio, updateAyudaServicio, onDeleteAyudaServicio, permiso } =
    props;
  return (
    <td>
      {permiso === 5 ? (
        <Button
          size="sm"
          variant="danger"
          onClick={() => onDeleteAyudaServicio(servicio)}
          className="table-users-admin__boton btn-boton"
        >
<FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
        </Button>
      ) : (
        <Button
          size="sm"
          variant="success"
          onClick={() => updateAyudaServicio(servicio)}
          className="table-users-admin__boton btn-boton"
        >
          <FontAwesomeIcon icon={faPencil} />
        </Button>
      )}
    </td>
  );
}
