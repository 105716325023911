import React from 'react'
import Button from "react-bootstrap/Button";

import { useRecurso } from '../../../../../hooks';
import "./DeleteRecursoForm.scss"

export function DeleteRecursoForm(props) {
  const { onClose, onRefetch, recurso } = props;
  const { deleteRecurso } = useRecurso();

  async function delRecurso() {
    try {
      await deleteRecurso(recurso.id);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h3 className="title-spacing">¿Está seguro de eliminar este reccurso?</h3>
      <div className="delete-recursos-form">
        <Button variant="danger" className="btnaling" onClick={delRecurso}>
          Eliminar
        </Button>
        <Button variant="outline-danger" className="btnaling" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}
