import React, { useEffect } from "react";
import { Row, Spinner } from "react-bootstrap";

import {
  Descripcion,
  HeaderPage,
  PaginacionRecursos
} from "../../../components/Admin";
import { BackButton } from '../../../components/Common';
import { useAuth, useRecurso } from "../../../hooks";

export function EstudiosInteligenciaCliente() {


  const { loading, getRecursoInvestigacion, recursos } = useRecurso();
  const { auth } = useAuth()

  useEffect(() => {
    getRecursoInvestigacion(auth.me['id']);
  }, []);


  return (
    <div>
      <br />
      <Row className="mb-6">
        <BackButton
          btnTitle="Regresar a los servicios de Membresía"
          link="/admin/serviciosLicenciamiento"
        />
      </Row>
      <br />
      <Row className="mb-6">
        <HeaderPage
          title="Estudios de inteligencia"
        />
        <div style={{ maxWidth: 1300, margin: '0 auto' }}>
          <Descripcion />
        </div>
      </Row>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Obteniendo informacion .....</span>
        </Spinner>
      ) : (
        <PaginacionRecursos
          datos={recursos}
        />
      )}
      <br />
    </div>
  )
}
