import React from 'react'

import { ChatIAComponent } from '../../../components/Admin/chatIAComponent/ChatIAComponent'

export function ChatIA() {
    return (
        <div>
            <ChatIAComponent />
        </div>
    )
}
