import { useFormik } from "formik";
import React from 'react';
import { Form } from 'react-bootstrap';
import './FormCluster.scss';


export function FormCluster(props) {

    let year = 1998;
    let years = []
    while (year <= 2023) {
        years.push(year)
        year += 1;
    }

    const indicators = ['Tasa de desempleo', 'Mayores inversiones']


    const formik = useFormik({
        initialValues: {
            indicator: indicators[0],
            starYear: '1998',
            finalYear: '2017'
        },
        onSubmit: (data) => {
            console.log(data);
        }
    })


    //const {indicatos}=props

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" >
                <Form.Label>Indicador economico</Form.Label>
                <Form.Select aria-label="Default select example" name="indicator" value={formik.values.initialValues} onChange={formik.handleChange}
                    onClickCapture={formik.handleSubmit}>
                    {indicators.map((i, index) => (
                        <option key={index} value={i}>{i}</option>
                    ))}
                </Form.Select>
            </Form.Group>


        </Form>
    )
}
