import React from "react";
import Button from "react-bootstrap/Button";
import "./DeleteAyudaNavegacion.scss";
import { useAyuda } from "../../../../hooks";
export function DeleteAyudaNavegacion(props) {
  const { onClose, onRefetch, ayudanavegacion } = props;
  const { deleteAyudaNavegacion } = useAyuda();

  async function delNavegacionAyuda() {
    try {
      await deleteAyudaNavegacion(ayudanavegacion.id);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h3 className="title-spacing">
        ¿Está seguro de eliminar el solictud de ayuda?
      </h3>
      <div className="delete-user-form">
        <Button variant="danger" className="btnaling" onClick={delNavegacionAyuda}>
          Eliminar
        </Button>
        <Button  variant="outline-danger" className="btnaling" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}
