import React from "react";
import { Row } from "react-bootstrap";
import { TituloPrincipal, DividerIcon } from "../../components/Common";
import { Oferta } from "../../components/Client";

export function Servicios() {
  return (
    <>
      <Row>
        <TituloPrincipal titulo={"Planes y Precios"} />
      </Row>
      <Row>
        <DividerIcon titulo={"Nuestros Planes"} />
        <Oferta />
      </Row>
    </>
  );
}
