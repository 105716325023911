import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";

import {
  AddEditTalentoEspecializadoCliente,
  DeleteTalentoEspecializadoCliente,
  HeaderPage,
  TableTalentoespecializadoCliente,
} from "../../../components/Admin";
import { Principal } from "../../../components/Client";
import { BackButton, DividerIcon, ModalBasic } from "../../../components/Common";
import {
  useAuth,
  useCatalagos,
  useLicenciaturas,
  usePlanPago,
  useSolicitudServicios,
} from "../../../hooks";
import './TalentoEspecializado.scss';

export function TalentoEspecializadoCliente() {

  const [selectFilter, setSelectFilter] = useState(0)
  const [filter, setFilter] = useState(undefined)
  const {
    getInstituciones,
    getLicenciaturasFiltrado,
    licenciaturas
  } = useLicenciaturas()

  const { getSolicitudPeticion, solicitudPeticiones, loading } =
    useSolicitudServicios();
  const { returnClienteIdUser, clienteIdUser } = usePlanPago();
  const { auth } = useAuth();

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("sm");
  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const {
    getCarreras, carreras,
    getEstado, estados,
    getSectores, sectores,
  } = useCatalagos()


  useEffect(() => {
    const getData = async () => {
      await returnClienteIdUser(auth.me["id"]).then((idUser) => {
        getSolicitudPeticion("2", idUser.id);
      });
      await getSectores()
      await getCarreras()
      await getEstado()
      await getInstituciones()
    }
    getData()
  }, [refetch])

  useEffect(() => {
    if (filter != 0) {
      const getData = async () => {
        await getLicenciaturasFiltrado(selectFilter, filter)
      }
      getData()
    }
  }, [filter])

  const addPeticionTalentoEspecializado = () => {
    setTitleModal("Crear peticion de Talento Especializado");
    setIconModal(faCirclePlus);
    setsizeModal("xl");
    setContentModal(
      <AddEditTalentoEspecializadoCliente
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        cliente={clienteIdUser}
      />
    );
    openCloseModal();
  };

  const updatePeticionTalentoEspecializado = (data) => {
    setTitleModal("Modificación peticion de Talento Especializado");
    setIconModal(faCirclePlus);
    setsizeModal("xl");
    setContentModal(
      <AddEditTalentoEspecializadoCliente
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        cliente={clienteIdUser}
        talentoEspecializado={data}
      />
    );
    openCloseModal();
  };

  const deletePeticionTalentoEspecializado = (data) => {
    setTitleModal("Borrar peticion de Talento Especializado");
    setIconModal(faCirclePlus);
    setsizeModal("xl");
    setContentModal(
      <DeleteTalentoEspecializadoCliente
        onClose={openCloseModal}
        onRefetch={onRefetch}
        talentoEspecializado={data}
      />
    );
    openCloseModal();
  };

  return (
    <div>
      <br />
      <Row className="mb-6">
        <BackButton
          btnTitle="Regresar a los servicios de membresía"
          link="/admin/serviciosLicenciamiento"
        />
      </Row>
      <br />
      <Row className="mb-6">
        <HeaderPage
          title="Perfilamiento y colocación de talento especializado"
          btnTitle="Solicitar Petición"
          btnClick={addPeticionTalentoEspecializado}
        />
        <div className="col col-sm-2" />
      </Row>
      <br />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              Obteniendo información .....
            </span>
          </Spinner>
        </div>
      ) : (
        <TableTalentoespecializadoCliente
          servicios={solicitudPeticiones}
          updatePeticionTalentoEspecializado={
            updatePeticionTalentoEspecializado
          }
          deletePeticionTalentoEspecializado={
            deletePeticionTalentoEspecializado
          }
        />
      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
      <br />
      <DividerIcon titulo="¿Quiéres localizar un perfil en alguna IES?" />
      <br />
      <Row className="selectTalento">
        <Col md={1} />
        <Col md={2} className="text-center">
          <Form.Group>
            <Form.Label>
              <p>
                Tipo de filtrado
              </p>
            </Form.Label>
            <Form.Select
              className="selectTalento"
              onChange={(e) => setSelectFilter(e.target.value)}
            >
              <option>
                Selecciona un tipo de filtro
              </option>
              <option value={1}>
                Carrera
              </option>
              <option value={2}>
                Estado
              </option>
              <option value={3}>
                Tipo de institución
              </option>
              <option value={4}>
                Rango salarial
              </option>
              <option value={5}>
                Competencias
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={9} >
          <FiltradoInstitucional
            filtro={selectFilter}
            carreras={carreras}
            estados={estados}
            setFilter={setFilter}
          />
        </Col>
      </Row>
      <br />
      <Principal licenciaturas={licenciaturas} />
      <br />
    </div >
  );
}

function FiltradoInstitucional(props) {
  const { filtro, carreras, estados, setFilter } = props;
  if (filtro === 0) return <DividerIcon titulo="Selecciona un tipo de filtrado" />
  const filtros = [
    null,
    <FiltroCarrera carreras={carreras} setFilter={setFilter} />,
    <FiltroEstado estados={estados} setFilter={setFilter} />,
  ]
  return filtros[filtro]
}

function FiltroCarrera(props) {
  const { carreras, setFilter } = props

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>
            <p>
              Escoge la carrera universitaria.
            </p>
          </Form.Label>
          <Form.Select
            className="selectTalento"
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value={0}>
              Selecciona un tipo de filtro
            </option>
            {
              carreras.map((carrera, index) => (
                <option value={carrera.id} key={index}>
                  {carrera.nombre}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={6} />
    </Row>
  )
}


function FiltroEstado(props) {
  const { estados, setFilter } = props

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>
            <p>
              Escoge el estado.
            </p>
          </Form.Label>
          <Form.Select
            className="selectTalento"
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value={0}>
              Selecciona un tipo de filtro
            </option>
            {
              estados.map((estado, index) => (
                <option value={estado.id} key={index}>
                  {estado.nombre}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={6} />
    </Row>
  )
}
