import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faFileLines,
  faPersonChalkboard,
  faUserGear,
  faClapperboard,
} from "@fortawesome/free-solid-svg-icons";

import { HeaderPage } from "../../../components/Admin";
import "./ServiciosLicenciamiento.scss";
export function ServiciosLicenciamientoOperativo() {
  return (
    <>
      <HeaderPage title="Administración de los Servicios disponibles de usuarios" />
      <Row className="licenciaope justify-content-center mt-5">
        <Col xs={10}>
          <Card>
            <Card.Body>
              <Row className="my-3">
                <Col className="licenciaope-menu-izq p-0">
                  <Link to="/admin/serviciosLicenciamiento/EstudiosInteligenciaOperativo">
                    <Button
                      className="licenciaope-btn"
                      variant="outline-primary"
                    >
                      <div className="licenciaope-icon-btn">
                        <FontAwesomeIcon icon={faFileLines} className="icon" />
                      </div>
                      <div className="licenciaope-text-btn">
                        Catálogo de Estudios de inteligencia
                      </div>
                    </Button>
                  </Link>
                </Col>
                <Col className="licenciaope-menu-der p-0">
                  <Link to="/admin/serviciosLicenciamiento/ProveedoresBienesServiciosOperativo">
                    <Button
                      className="licenciaope-btn"
                      variant="outline-danger"
                    >
                      <div className="licenciaope-icon-btn">
                        <FontAwesomeIcon
                          icon={faPersonChalkboard}
                          className="icon"
                        />
                      </div>
                      <div className="licenciaope-text-btn">
                        Solicitudes de{" "}
                      </div>
                      <div className="licenciaope-text-btn">
                        Proveedores de bienes y servicios
                      </div>
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row className="my-3">
                <Col className="licenciaope-menu-izq p-0">
                  <Link to="/admin/serviciosLicenciamiento/TalentoEspecializadoOperativo">
                    <Button
                      className="licenciaope-btn "
                      variant="outline-success"
                    >
                      <div className="licenciaope-icon-btn">
                        <FontAwesomeIcon icon={faUserGear} className="icon" />
                      </div>
                      <div className="licenciaope-text-btn">
                        Solicitudes de{" "}
                      </div>
                      <div className="licenciaope-text-btn">
                        Perfilamiento y colocación de talento especializado.
                      </div>
                    </Button>
                  </Link>
                </Col>
                <Col className="licenciaope-menu-der p-0">
                  <Link to="/admin/serviciosLicenciamiento/WebinarsOperativo">
                    <Button className="licenciaope-btn" variant="outline-info">
                      <div className="licenciaope-icon-btn">
                        <FontAwesomeIcon
                          icon={faClapperboard}
                          className="icon"
                        />
                      </div>
                      <div className="licenciaope-text-btn">webinars</div>
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
