import { BASE_API } from "../utils/constants";
import { formatDateNow, formatDate } from "../metodosGlobales/FormatoFecha";

export async function getContactanosApi() {
  try {
    const url = `${BASE_API}/api/contactanos/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addContactanosApi(data) {
  try {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("apellidos", data.apellidos);
    formData.append("email", data.email);
    formData.append("celular", data.celular);
    formData.append("telOficina", data.telOficina);
    formData.append("empresa", data.empresa);
    formData.append("cargo", data.cargo);
    formData.append("asunto", data.asunto);
    formData.append("fechaRegistro", formatDate());
    console.log("data", formData);

    const url = `${BASE_API}/api/contactanos/envio/`;
    const params = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateContactanosApi(id, data, token, estatus){
    try {
        const formData = new FormData();
        formData.append("observacion", data.observacion);
        formData.append("fechaAtencion", formatDateNow());
        /* if (data.estatus) formData.append('estatus', data.estatus); */
         formData.append('estatus', data.estatus);
         

        const url = `${BASE_API}/api/contactanos/${id}/`;
        const params = {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteContactanosApi(id, token) {
  try {
    const url = `${BASE_API}/api/contactanos/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
