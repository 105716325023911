import React, { useCallback, useState } from "react";
import { Form, Button, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import "./AddEditAlianzasForm.scss";
import { useAlianzas } from "../../../../hooks/useAlianzas";
import { toast } from "react-toastify";

export function AddEditAlianzasForm(props) {
  const { onClose, onRefetch, alianza } = props;
  const [previewImage, setPreviewImage] = useState(alianza?.logo || null);
  const { addAlianza, updateAlianza } = useAlianzas();

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("logo", file);
    setPreviewImage(URL.createObjectURL(file));
    console.log(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [".jpeg", ".jpg", ".png"] },
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const formik = useFormik({
    initialValues: initialValues(alianza),
    validationSchema: Yup.object(alianza ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (alianza) {
          await updateAlianza(alianza.id, formValue);
          toast.success("Alianza actualizado");
        }
        else {
          await addAlianza(formValue);
          toast.success("Se dio de alta una alianza");

        }
        onRefetch();
        onClose();
        console.log("Datos Enviados");
        console.log(formValue);
      } catch (error) {
        console.error(error);
        toast.error(
          "Hubo problemas para el envio de datos por favor vuelva a intentarlo."
        );

      }
    },
  });
  return (
    <>
      <Form className="add-edit-alianzas-form" onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Image className="add-edit-alianzas-foto" />
          <div className="add-edit-alianzas-contenedorFoto">
            <Form.Label>Logo alianza</Form.Label>
            <Image className="foto-tamanio" src={previewImage} />
            <Button
              className="add-edit-alianzas-input"
              variant={formik.errors.logo && "danger"}
              {...getRootProps()}
            >
              <FontAwesomeIcon icon={faUpload} className="me-2" />
              {previewImage ? "Cambiar Logo" : "Ingresar Logo"}
            </Button>
          </div>
          <input {...getInputProps()} />

          <Form.Label>Nombre(s)</Form.Label>
          <Form.Control
            className="add-edit-alianzas-input"
            name="nombre"
            placeholder="Ingresar nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={formik.errors.nombre}
          />
          <Form.Label>Descripción de la alianza</Form.Label>
          <Form.Control
            className="add-edit-alianzas-input"
            name="descripcion"
            as="textarea"
            placeholder="Ingresar descripción"
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            error={formik.errors.descripcion}
          />
          <Form.Label>Sitio web</Form.Label>
          <Form.Control
            className="add-edit-alianzas-input"
            name="link"
            placeholder="Ingresar URL"
            value={formik.values.link}
            onChange={formik.handleChange}
            error={formik.errors.link}
          />
          <div className="d-grid gap-2">
            <Button
              className="add-edit-alianzas-bc"
              type="submit"
              variant="outline-primary"
              size="lg"
            >
              {alianza ? "Actualizar" : "Crear"}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </>
  );
}

function initialValues(data) {
  return {
    logo: "",
    nombre: data?.nombre || "",
    descripcion: data?.descripcion || "",
    link: data?.link || "",
  };
}

function newSchema() {
  return {
    logo: Yup.string().required(true),
    nombre: Yup.string().required(true),
    descripcion: Yup.string().required(true),
    link: Yup.string().required(true),
  };
}

function updateSchema() {
  return {
    logo: Yup.string(),
    nombre: Yup.string().required(true),
    descripcion: Yup.string().required(true),
    link: Yup.string().required(true),
  };
}
