import React from "react";
import { Form, Dropdown, Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./RegistroComprador.scss";

export function Selector() {
  const opciones = [
    "Maquinarias",
    "Sistema de información",
    "Metalmetolico",
    "Combustibles",
    "Servicios",
  ];

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <div className="navbar-collapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link active" href="#maquinarias">
                <div className="nav-box p-3" style={{ backgroundColor: '#233B7C', width: '200px' }}>
                  <span style={{ color: 'white', fontWeight: 'bold' }}>Maquinarias</span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#sistema-informacion">
                <div className="nav-box p-3" style={{ backgroundColor: 'lightgray', width: '200px' }}>
                  <span>Sistema de Información</span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#metalmetolico">
                <div className="nav-box p-3" style={{ backgroundColor: 'lightgray', width: '200px' }}>
                  <span>Metalmetolico</span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#combustibles">
                <div className="nav-box p-3" style={{ backgroundColor: 'lightgray', width: '200px' }}>
                  <span>Combustibles</span>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#servicios">
                <div className="nav-box p-3" style={{ backgroundColor: 'lightgray', width: '150px' }}>
                  <span>Servicios</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
