import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { faEdit, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

import { useColaborador, useCatalagos } from "../../../hooks";
import {
  HeaderPage,
  AddEditColaboradoresForm,
  DeleteColaboradoresForm,
  TableColaboradores,
} from "../../../components/Admin";
import { ModalBasic } from "../../../components/Common";

export function Colaboradores() {
  const { loading, colaboradores, getColaboradores } = useColaborador();

  const { getSectores, sectores, getHonorarios, honorarios } = useCatalagos();

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("sm");

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    getColaboradores();
    getSectores();
    getHonorarios();
  }, [refetch]);

  const updateColaborador = (data) => {
    setTitleModal("Actualizar Colaborador");
    setIconModal(faEdit);
    setsizeModal("lg");
    setContentModal(
      <AddEditColaboradoresForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        colaborador={data}
        sectores={sectores}
        honorarios={honorarios}
      />
    );
    openCloseModal();
  };

  const onDeleteColaborador = async (data) => {
    setTitleModal("Borrar Colaborador");
    setIconModal(faDeleteLeft);
    setsizeModal("sm");
    setContentModal(
      <DeleteColaboradoresForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        colaborador={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <HeaderPage title="Colaboradores" />

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              Obteniendo informacion .....
            </span>
          </Spinner>
        </div>
      ) : (
        <TableColaboradores
          colaboradores={colaboradores}
          updateColaborador={updateColaborador}
          onDeleteColaborador={onDeleteColaborador}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
