import React, {useEffect} from "react";
import "./MisionPage.scss";
import { Row, Col, Image } from "react-bootstrap";

import logo from "../../../../images/logo-solo.svg";
import { ReactComponent as IconIntColectiva } from "../../../../images/icon-inteligencia-colectiva.svg";
import { ReactComponent as IconIntnegocio } from "../../../../images/icon-inteligencia-negocio.svg";
import { ReactComponent as IconRedExpertos } from "../../../../images/icon-red-expertos.svg";
import { ReactComponent as IconTecnologia } from "../../../../images/icon-tecnologia.svg";

import {initAOS} from '../../../Common/AOS'

export function MisionPage() {  
  // Animaciones
  useEffect(() => {
    initAOS();
  }, [])
  

  return (
    <div className="mision-page">
      <Row className="justify-content-center mt-4 mb-5">
        <Col className="mision-item">
          <div className="mt-3">
            <Row className="text-right ms-auto">
              <h4 className="mision-page-subtitulo" data-aos="fade-left" data-aos-delay="200">Nuestra Misión</h4>
            </Row>
            <Row className="justify-content-md-center">
              <p className="mision-page_p" data-aos="zoom-in" data-aos-delay="200">
                Generamos el click entre la inteligencia colectiva de un red de
                expertos con las necesidades de información de las empresas de
                diversos sectores, utilizando la inteligencia de negocios basada
                en tecnología y análisis de datos.​
              </p>
            </Row>
          </div>
        </Col>
        <Col className="mision-item">
          <div className="text-center animacionSomn_arriba">
            <Image data-aos="zoom-in" data-aos-delay="200" src={logo} className="img-mision" />
          </div>
        </Col>
        <Col className="mision-item">
          <div className="mt-3">
            <Row className="text-left">
              <h4 className="mision-page-subtitulo2">Nuestra Visión</h4>
            </Row>
            <Row className="justify-content-md-center">
              <p className="mision-page_pizq" data-aos="zoom-in" data-aos-delay="200">
                Ser un referente en México sobre tendencias, indicadores y datos
                de las tecnologías y el desarrollo tecnológico, para poder
                contribuir con mejores prácticas para el desarrollo económico,
                académico y social​
              </p>
            </Row>
          </div>
        </Col>
      </Row>

      <Row  data-aos="fade-up" className="mision-page-cont">
        <Col sm={3}>
          <div  className="mision-page-card text-center">
            <IconIntColectiva className="mision-page-icon" />
            <p className="mision-page-cardTit">Inteligencia colectiva</p>
            <p className="mision-page-cardText">
              La inteligencia colectiva es una forma de inteligencia que surge a
              partir de la colaboración de diversos individuos
            </p>
          </div>
        </Col>
        <Col sm={3}>
          <div  className="mision-page-card text-center">
            <IconRedExpertos className="mision-page-icon" />
            <p className="mision-page-cardTit">Red de expertos</p>
            <p className="mision-page-cardText">
              En Red de Expertos conectamos líderes tomadores de decisiones o
              empresas, con los mejores expertos en distintas industrias
            </p>
          </div>
        </Col>
        <Col sm={3}>
          <div className="mision-page-card text-center">
            <IconIntnegocio className="mision-page-icon" />
            <p className="mision-page-cardTit">Inteligencia de negocios</p>
            <p className="mision-page-cardText">
              La inteligencia de negocios consiste en un conjunto de
              estrategias, procedimientos, actividades y soluciones cuyo reto
              principal es presentar datos relevantes para los gestores
              corporativos.
            </p>
          </div>
        </Col>
        <Col sm={3}>
          <div  className="mision-page-card text-center">
            <IconTecnologia className="mision-page-icon" />
            <p className="mision-page-cardTit">
              Tecnologia y analisis de datos
            </p>
            <p className="mision-page-cardText">
              El análisis de datos es el proceso de exploración, transformación
              y examinación de datos para identificar tendencias y patrones que
              revelen insights importantes y aumenten la eficiencia para
              respaldar la toma de decisiones.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
