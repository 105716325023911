import { faL } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import {
  getExperienciaClienteApi,
  addExperienciaClienteApi,
  deleteExperienciaClienteApi,
} from "../api/experienciaCliente";
import { useAuth } from "./";

export function useExperienciaUsuario() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [experienciaC, setExperienciaC] = useState(null);
  const { auth } = useAuth();

  const getExperienciaCliente = async () => {
    try {
      setLoading(true);
      const response = await getExperienciaClienteApi();
      setLoading(false);
      setExperienciaC(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const addExperienciaCliente  = async (data) => {
    try {
      setLoading(true);
      await addExperienciaClienteApi(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };


  const deleteExperienciaCliente  = async (id) => {
    try {
      setLoading(true);
      await deleteExperienciaClienteApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
 };
  return {
    loading,
    error,
    experienciaC,
    getExperienciaCliente,
    addExperienciaCliente,
    deleteExperienciaCliente
  }
}
