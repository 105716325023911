import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { useB2B } from "../../../../hooks";
import { FilterInput } from "../../../Common";
import "./TablaUbicacionesMesas.scss";

export function TablaUbicacionesMesas(props) {
    const { usuarios, onRefetch } = props;
    const { editMesaAsignada } = useB2B()

    const getArraay = () => {
        const arreglo = [];
        for (let i = 1; usuarios[0]?.id_micrositio_cliente?.mesas_disponibles >= i; i++)
            arreglo.push(i);
        return arreglo
    };

    const guardarConfiguracion = async (mesa, id) => {
        await editMesaAsignada(id, mesa);
        onRefetch()
    }


    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Nombre del vendedor",
            selector: (row) =>
                `${row.id_registro_externo.user.nombre} 
             ${row.id_registro_externo.user.apellido_paterno} 
             ${row.id_registro_externo.user.apellido_materno}`,
        },
        {
            name: "Sector",
            selector: (row) => row.id_registro_externo.id_sector.descripcion,
        },
        {
            sortable: true,
            name: "Mesa asignada",
            selector: (row) =>
                row.mesa_asignada
                    ? row.mesa_asignada
                    : "Sin asignar",
        },
        {

            name: "Asignar mesa",
            cell: (row) => (
                <Form.Select
                    onChange={(e) => guardarConfiguracion(e.target.value, row.id)}
                >
                    <option key={0}>Asigna mesa</option>
                    {
                        getArraay().map((mesa, index) => (
                            <option key={index}>{mesa}</option>
                        ))
                    }
                </Form.Select>
            ),
        },
    ];

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = usuarios?.filter(
        (item) =>
            JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
            -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterInput
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponent}
        />
    );
}
