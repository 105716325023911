import React, { useState, useEffect } from "react";
import { Row, Form, Button } from "react-bootstrap";
import {
  TableAyudaServicio,
  TableAyudaPagos,
  TableAyudaNavegacion,
  HeaderPage,
  FormAyudaServicio,
  FormAyudaPago,
  FormAyudaNavegacion,
} from "../../../components/Admin";
import { ModalBasic, FilterEstado, BackButton } from "../../../components/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faArrowsRotate
} from "@fortawesome/free-solid-svg-icons";
import { useAyuda } from "../../../hooks";

export function SoporteS() {
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [tipoServ, setTipoServ] = useState(null);
  const [estado, setEstado] = useState(0);
  const [refetch, setRefetch] = useState(false);

  const {
    servicioayuda,
    getAyudaServicio,
    getServicioEstadoFiltrado,
    pagosayuda,
    getAyudaPagos,
    getPagosEstadoFiltro,
    navegacionayuda,
    getAyudaNavegacion,
    getNavegacionEstadoFiltro
  } = useAyuda();

  useEffect(() => {
    const fetchData = async () => {
      if (estado == 0)
        await getAyudaServicio();
      else
        await getServicioEstadoFiltrado(estado);
    };
    fetchData()

    const fetchDataNav = async () => {
      if (estado == 0)
        await getAyudaNavegacion();
      else
        await getNavegacionEstadoFiltro(estado);
    };
    fetchDataNav()
    const fetchDataSer = async () => {
      if (estado == 0)
        await getAyudaPagos();
      else
        await getPagosEstadoFiltro(estado);
    };
    fetchDataSer()
  }, [refetch]);


  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateAyudaServicio = (data) => {
    setTitleModal("Actualizar Registro de Ayuda");
    setIconModal(faEdit);
    setContentModal(
      <FormAyudaServicio
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ayudaservicio={data}
      />
    );
    openCloseModal();
  };



  const updateAyudaNavegacion = (data) => {
    setTitleModal("Actualizar Registro de Ayuda");
    setIconModal(faEdit);
    setContentModal(
      <FormAyudaNavegacion
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ayudanavegacion={data}
      />
    );
    openCloseModal();
  };


  const updateAyudaPagos = (data) => {
    setTitleModal("Actualizar Registro de Ayuda");
    setIconModal(faEdit);
    setContentModal(
      <FormAyudaPago
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ayudapagos={data}
      />
    );
    openCloseModal();
  };

  const f_cargatabla = (event) => {
    setTipoServ(event.target.value);
    if (event.target.value == 1) {

    } else if (event.target.value == 2) {
    } else if (event.target.value == 3) {
    }
  };


  return (
    <div>
      <br />
      <Row className="mb-6">
        <BackButton
          btnTitle="Regresar a Administración soporte"
          link="/admin/soporteNavegacaion"
        />
      </Row>
      <br />
      <Row className="mb-6">
        <HeaderPage title="Solicitudes de soporte" />
        <div className="col col-sm-2" />
      </Row>
      <Row>
        <Form.Group className="col-8 col-sm-7 col-lg-4 col-xl-3">
          <Form.Label>Selecciona la opción de búsqueda</Form.Label>
          <Form.Select
            className="form-control"
            name="tipoSol"
            onChange={f_cargatabla}
          >
            <option value={0}>Opciones</option>
            <option value={1}>Servicios</option>
            <option value={2}>Pago y Facturación</option>
            <option value={3}>Navegación</option>
          </Form.Select>
        </Form.Group>



        <Form.Group className="col-4 col-sm-4 col-lg-2 col-xl-1">
          <Form.Label>Actualizar</Form.Label>
          <Button
            className="form-control"
            variant="outline-info"
            onClick={() => onRefetch()}
          >
            <FontAwesomeIcon icon={faArrowsRotate} />

          </Button>
        </Form.Group>

        <FilterEstado
          setEstado={setEstado}
          onRefetch={onRefetch}
        />
      </Row>

      {parseInt(tipoServ) === 1 && (
        <TableAyudaServicio
          ayudaservicio={servicioayuda}
          updateAyudaServicio={updateAyudaServicio}
        />
      )}
      {parseInt(tipoServ) === 2 && (
        <TableAyudaPagos
          ayudapagos={pagosayuda}
          updateAyudaPagos={updateAyudaPagos}
        />
      )}
      {parseInt(tipoServ) === 3 && (
        <TableAyudaNavegacion
          navegaciones={navegacionayuda}
          updateAyudaNavegacion={updateAyudaNavegacion}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size="xl"
      />
    </div>
  );
}
