import React from 'react'
import { TarjetasAgenda } from '../tarjetasAgenda'

import './ConteinerCards.scss'

export function ConteinerCards(props) {

    const { data } = props

    const dividirArreglos = (arreglo, cantidad) => {
        const datos = []
        for (let i = 0; i < arreglo.length; i += cantidad) {
            const porcion = arreglo.slice(i, i + cantidad);
            datos.push(porcion)
        }
        return datos
    }

    const nuevoArreglo = dividirArreglos(data, 3);
    console.log(nuevoArreglo);

    return (
        nuevoArreglo.map((arreglo, index) => (
            <div key={index}>
                <div className='conteiner'>
                    {
                        arreglo.map((cita, index) => (
                            <TarjetasAgenda datos={cita} key={index} />
                        ))
                    }
                </div>
                <br />
            </div>
        ))
    )
}
