import { useFormik } from "formik";
import React from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useAuth, useB2B } from "../../../../../hooks";
import { IngresaDatosForm } from "../../../../Generales";

import "./FormAyuda.scss";

export function FormAyuda(props) {
  const { data } = props;
  const { auth } = useAuth();
  const { addDataHelpToUser, loading } = useB2B();

  const formik = useFormik({
    initialValues: initialValues(data, auth),
    validationSchema: Yup.object(newSchema()),
    onSubmit: async (formvalue) => {
      await addDataHelpToUser(formvalue);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Nombre"
          formatoLabel="tituloCentrado"
          nameControl="nombre"
          type="text"
          valueControl={formik.values.nombre}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Apellido Paterno"
          formatoLabel="tituloCentrado"
          nameControl="apellido_paterno"
          type="text"
          valueControl={formik.values.apellido_paterno}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Apellido Materno"
          formatoLabel="tituloCentrado"
          nameControl="apellido_materno"
          type="text"
          valueControl={formik.values.apellido_materno}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
      </Row>
      <br />
      <Row>
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Fecha de Solicitud"
          formatoLabel="tituloCentrado"
          nameControl="fecha_solicitud"
          type="text"
          valueControl={formik.values.fecha_solicitud}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Teléfono"
          formatoLabel="tituloCentrado"
          nameControl="apellido_materno"
          type="text"
          valueControl={formik.values.telefono}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Correo electrónico"
          formatoLabel="tituloCentrado"
          nameControl="email"
          type="text"
          valueControl={formik.values.email}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
      </Row>
      <br />
      <br />
      <Row>
        <Col className="sm-6 ">
          <Form.Check
            type="checkbox"
            label="¿Tienes problemas para ingresar a OTS?"
            value={formik.values.problema_acceder}
            name="problema_acceder"
            onChange={formik.handleChange}
          />
        </Col>
        <Col className="sm-6 ">
          <Form.Check
            type="checkbox"
            label="¿Necesitas asesoria para navegar en OTS?"
            value={formik.values.problema_navegacion}
            name="problema_navegacion"
            onChange={formik.handleChange}
          />
        </Col>
      </Row>
      <br />
      <br />
      <IngresaDatosForm
        tituloLabel="¡Descríbenos tu problema para saber como ayudarte!"
        formatoLabel="tituloCentrado"
        nameControl="descripcion"
        type="text"
        rows={5}
        valueControl={formik.values.descripcion}
        onChangeControl={formik.handleChange}
        rounded="30px"
        error={formik.errors.descripcion}
        touched={formik.touched.descripcion}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {loading ? (
          <Button
            variant="primary"
            type="submit"
            style={{ width: "auto" }}
            disabled
          >
            <Spinner animation="border" variant="primary" />
            Mandando solicitud...
          </Button>
        ) : (
          <Button variant="primary" type="submit" style={{ width: "auto" }}>
            {data ? "Actualizar" : "Mandar solicitud"}
          </Button>
        )}
      </div>
    </Form>
  );
}

function initialValues(data, auth) {
  const fecha = new Date();
  return {
    nombre: auth.me.nombre,
    apellido_paterno: auth.me.apellido_paterno,
    apellido_materno: auth.me.apellido_materno,
    fecha_solicitud:
      data?.fecha_solicitud ||
      `${fecha.getDate()} / ${fecha.getMonth()} / ${fecha.getFullYear()}`,
    telefono: data?.telefono || "",
    email: auth.me.email,
    descripcion: data?.descripcion || "",
    problema_acceder: data?.problema_acceder || false,
    problema_navegacion: data?.problema_navegacion || false,
  };
}
function newSchema() {
  return {
    descripcion: Yup.string(
      "Debes agregar una descripción de tu problema para ayudarte"
    ).required("Debes agregar una descripción de tu problema para ayudarte"),
  };
}
