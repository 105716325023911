import React, { useState, useEffect } from "react";
import {
  TableAyudaNavegacion,
  HeaderPage,
  FormAyudaNavegacion,
  DeleteAyudaNavegacion,
} from "../../../components/Admin";
import { Spinner } from "react-bootstrap";
import { ModalBasic } from "../../../components/Common";
import { faUserPen, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAyuda, useAuth } from "../../../hooks";

export function AyudaNavegacion() {
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("g");
  const { auth } = useAuth();

  const { loading, navegacionayuda, getAyudaNavegacion, navegacionuser, getNavegacionClienteFiltro } = useAyuda();

  useEffect(() => {
    if (auth.me.role === 5) {
      getNavegacionClienteFiltro(auth.me.id);
    } else {
      getAyudaNavegacion();
    }
  }, [refetch]);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateAyudaNavegacion = (data) => {
    setTitleModal("Actualizar registro de navegación");
    setIconModal(faEdit);
    setContentModal(
      <FormAyudaNavegacion
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ayudanavegacion={data}
      />
    );
    openCloseModal();
  };

  const onDeleteAyudaNavegacion = async (data) => {
    setTitleModal("Eliminar registro de navegación");
    setIconModal(faTrash);
    setsizeModal("s");
    setContentModal(
      <DeleteAyudaNavegacion
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ayudanavegacion={data}
      />
    );
    openCloseModal();
  };

  const addAyuda = () => {
    setTitleModal(" Solicitar ayuda en navegación");
    setIconModal(faUserPen);
    setsizeModal("xl");
    setContentModal(
      <FormAyudaNavegacion onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  return (
    <>
      <HeaderPage
        title="Ayuda Navegación"
        btnTitle="Solicitar Ayuda"
        btnClick={addAyuda}
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner className="spinner" animation="border" />
        </div>
      ) : (
        <TableAyudaNavegacion
          navegaciones={auth.me.role === 5 ? navegacionuser : navegacionayuda}
          updateAyudaNavegacion={updateAyudaNavegacion}
          onDeleteAyudaNavegacion={onDeleteAyudaNavegacion}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
