import React, { useEffect, useState } from "react";
import { HeaderPage } from "../../../components/Admin";
import {
  DeleteExperienciaCliente,
  TableExperienciaCliente,
  PublicarExperienciaCliente
} from "../../../components/Admin/ExperienciaCliente";
import { useExperienciaUsuario } from "../../../hooks/useExperienciaUsuario";
import { ModalBasic } from "../../../components/Common";
import { Spinner, Row, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTrash,
  faPaperPlane,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";

//import "./ExperienciaClienteAdmin.scss";

export function ExperienciaClienteAdmin() {
  const { loading, experienciaC, getExperienciaCliente } =
    useExperienciaUsuario();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [sizeModal, setsizeModal] = useState("g");
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    getExperienciaCliente();
  }, [refetch]);

  const publicarExperienciaCliente = () => {
    setTitleModal("Publicar experiencia del cliente");
    setIconModal(faPaperPlane);
    setsizeModal("xl");
    setContentModal(
      <PublicarExperienciaCliente
        onClose={openCloseModal}
        onRefetch={onRefetch}
        experienciaC={experienciaC}
      />
    );
    openCloseModal();
  };


  const deleteExperienciaCliente = async (data) => {
    setTitleModal("Borrar");
    setIconModal(faTrash);
    setsizeModal("s");
    setContentModal(
      <DeleteExperienciaCliente
        onClose={openCloseModal}
        onRefetch={onRefetch}
        experienciaC={data}
      />
    );
    openCloseModal();
  };
  return (
    <>
      <Row className="mb-6">
        <HeaderPage title="Experiencia del Cliente" />
        <div className="col col-sm-2" />
      </Row>
      <Form.Group className="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-1">
        <Form.Label>Actualizar</Form.Label>
        <Button
          className="form-control"
          variant="outline-info"
          onClick={() => onRefetch()}
        >
          <FontAwesomeIcon icon={faArrowsRotate} />
        </Button>
      </Form.Group>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <TableExperienciaCliente
          experienciaC={experienciaC}
          deleteExperienciaCliente={deleteExperienciaCliente}
          publicarExperienciaCliente={publicarExperienciaCliente}
        />
      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
