import { useState } from "react";
import {
  getContactanosApi,
  addContactanosApi,
  deleteContactanosApi,
  updateContactanosApi,
} from "../api/contactanos";
import { useAuth } from "./";

export function useContactanos() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contactanos, setContactanos] = useState(null);
  const { auth } = useAuth();

  const getContactanos = async () => {
    try {
      setLoading(true);
      const response = await getContactanosApi();
      setLoading(false);
      setContactanos(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const addContactanos = async (data) => {
    try {
      setLoading(true);
      await addContactanosApi(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updateContactanos = async (id, data) => {
    try {
      setLoading(true);
      await updateContactanosApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteContactanos = async (id) => {
    try {
      setLoading(true);
      await deleteContactanosApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    contactanos,
    getContactanos,
    addContactanos,
    deleteContactanos,
    updateContactanos,
  };
}
