import React, { useState } from "react";
import "./LoginForm.scss";
import { Form, Button, Nav } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginApi } from "../../../api/user";
import { toast } from "react-toastify";
import { useAuth } from "../../../hooks";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { ModalBasic } from "../../../components/Common";

import { RecoveryPass } from "../RecoveryPass";

export function LoginForm(props) {
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const sendMailRecover = () => {
    setTitleModal(" Olvide mi contraseña ");
    setIconModal(faEnvelopeOpen);
    setContentModal(<RecoveryPass onClose={openCloseModal} />);
    openCloseModal();
  };

  const { login } = useAuth();
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      try {
        const response = await loginApi(formValue);
        const { access } = response;
        login(access);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  return (
    <>
      <Form className="login-form-admin" onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Ingresa tu correo electrónico</Form.Label>
          <Form.Control
            type="email"
            placeholder="Correo Electrónico"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.touched.email && formik.errors.email ? "error" : null
            }
          />
          <Form.Text className="text-danger">
            {formik.touched.email && formik.errors.email ? (
              <div className="text-danger">{formik.errors.email}</div>
            ) : null}
          </Form.Text>
          <Form.Text className="text-muted">
            No compartas la información de tu correo.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Ingresa tu contraseña</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.touched.password && formik.errors.password ? "error" : null
            }
          />
          <Form.Text className="text-danger">
            {formik.touched.password && formik.errors.password ? (
              <div className="text-danger">{formik.errors.password}</div>
            ) : null}
          </Form.Text>
        </Form.Group>
        <Form.Group
          className="mb-3 login-form-admin__link"
          controlId="formBasicPassword"
        >
          <Nav.Link onClick={sendMailRecover}>¿Olvide mi contraseña?</Nav.Link>
        </Form.Group>

        <Button variant="primary" type="submit">
          Ingresar
        </Button>
      </Form>
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={"large"}
      />
    </>
  );
}

function initialValues() {
  return {
    email: "",
    password: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string()
      .email("El formato del mail es incorrecto")
      .required("El mail es requerido"),
    password: Yup.string().required("La contraseña es requerida"),
  };
}
