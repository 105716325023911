import React, { useState, useCallback, Component } from 'react'
import * as Yup from "yup";
import { map } from "lodash";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import { Button, Form, Row, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFilm, faCalendar } from "@fortawesome/free-solid-svg-icons";

import { formatDate } from "../../../../../metodosGlobales"
import { useRecurso } from '../../../../../hooks';
import "./EditNewRecursoForm.scss"
export function EditNewRecursoForm(props) {

  const { onClose, onRefetch, recurso, sectores, tipoRecurso } = props;
  const [previewImage, setPreviewImage] = useState(recurso?.imagen || null);
  const [previewDocument, setPreviewDocument] = useState(recurso?.documento || null);
  const { addRecurso, updateRecurso } = useRecurso();

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("imagen", file);
    setPreviewImage(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  async function handleUpload(event) {
    setPreviewDocument(event.target.files[0]);
    const file = event.target.files[0]
    await formik.setFieldValue("documento", file);
  }

  const formik = useFormik({
    initialValues: initialValues(recurso),
    //validationSchema: Yup.object(recurso ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      console.log(formvalue);
      const tipoDocumento = asignarTipoDocumento(previewDocument, formvalue.link);
      if (recurso) await updateRecurso(recurso.id, formvalue, tipoDocumento);
      else await addRecurso(formvalue, tipoDocumento);
      onRefetch();
      onClose();
    }

  });

  return (
    <Form className="add-edit-recurso-form" onSubmit={formik.handleSubmit}>
      <Row className="mb-6">
        <Form.Group widths="equal" className="col-12 col-sm-12 col-lg-6 col-xl-4">
          <Form.Label className='titulo'>Título del estudio de inteligencia.</Form.Label>
          <Form.Control
            className="col col-sm-4"
            name="titulo"
            placeholder="Ingresa el titular"
            value={formik.values.titulo}
            onChange={formik.handleChange}
          />
          <Form.Text className="text-danger">
            {formik.touched.titulo && formik.errors.titulo ? (
              <div className="text-danger">{formik.errors.titulo}</div>
            ) : null}
          </Form.Text>
        </Form.Group>

        <Form.Group widths="equal" className="col-6 col-sm-4 col-lg-3 col-xl-2">
          <Form.Label className='titulo'>Catálogo.</Form.Label>
          <Form.Select
            name="catalogo"
            defaultValue={formik.values.catalogo}
            onChange={formik.handleChange}
          >
            {map(sectores, (sector, index) => (
              <option key={index} value={sector['id']}>{sector['descripcion']}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group widths="equal" className="col-6 col-sm-3 col-lg-3 col-xl-2">
          <Form.Label className='titulo'>Tipo Recurso.</Form.Label>
          <Form.Select
            name="tipoRecurso"
            defaultValue={formik.values.tipoRecurso}
            onChange={formik.handleChange}
          >
            {map(tipoRecurso, (recurso, index) => (
              <option key={index} value={recurso['id']}>{recurso['nombre']}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group widths="equal" className="col-12 col-sm-6 col-lg-12 col-xl-4">
          <Form.Label className='titulo'>Fecha de publicación.</Form.Label>
          <FontAwesomeIcon icon={faCalendar} className="iconTitle" />
          <Form.Text className='titulo'>
            {formatDate(formik.values.fecha_alta) + ""}
          </Form.Text>
        </Form.Group>
      </Row>

      <div>
        <Row className="mb-6">
          <div className='contenedor'>
            <Form.Group widths="equal" className="col-8 col-sm-8 col-lg-8">
              <input{...getInputProps()} />
              <Form.Label className='titulo'>Imagen de presentación.</Form.Label>
              <Image src={previewImage} className="imagen" />
              <Button type="button" {...getRootProps()}>
                {previewImage ? "Cambiar imagen" : "Subir imagen"}
              </Button>
            </Form.Group>
          </div>
          <Form.Group widths="equal" className="mt-4 col-12 col-sm-12 col-lg-12">
            <Form.Label className='titulo'>Carga de archivos.</Form.Label>
            <br />
            <Row className="mb-6">
              <Form.Group widths="equal" className="mt-2 col-12 col-sm-12 col-lg-6">
                <Form.Label className='titulo'>Documento.</Form.Label>
                <FontAwesomeIcon className='icon' icon={faFile} />
                {recurso && recurso.documento ?
                  (
                    <a
                      className="btn btn-primary"
                      role="button"
                      href={recurso.documento}
                      download={nombreDocumento(recurso.documento) + ""}
                      target="_blank"
                    >
                      {nombreDocumento(recurso.documento + "")}
                    </a>
                  ) : null}
                <input
                  type="file"
                  onChange={handleUpload}
                  className="text"
                  accept=".pdf, .docx, .doc"
                />
              </Form.Group>
              <Form.Group widths="equal" className="mt-2 col-12 col-sm-12 col-lg-6">
                <Form.Label className='titulo'>Video. </Form.Label>
                <FontAwesomeIcon className='icon' icon={faFilm} />
                <Form.Control
                  className="mt-2 col-12 col-sm-12 col-lg-6"
                  name="link"
                  placeholder="Ingresa el link"
                  value={formik.values.link}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Row>
          </Form.Group>
        </Row>
      </div>


      <Form.Group>
        <Form.Label className='titulo mt-4'>Descripción de la investigación.</Form.Label>
        <Form.Control
          name="descripcionTotal"
          as="textarea"
          placeholder="Ingrese aqui su descripción"
          rows={3}
          value={formik.values.descripcionTotal}
          onChange={formik.handleChange}
        />
        <Form.Text className="text-danger">
          {formik.touched.descripcionTotal && formik.errors.descripcionTotal ? (
            <div className="text-danger">{formik.errors.descripcionTotal}</div>
          ) : null}
        </Form.Text>
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
      >
        {recurso ? "Actualizar" : "Crear"}
      </Button>

    </Form>
  )
}

function initialValues(data) {
  return {
    titulo: data?.titulo || "",
    descripcionTotal: data?.descripcionTotal || "",
    catalogo: data?.catalogo['id'] || 1,
    link: data?.link || "",
    fecha_alta: data?.fecha_alta || new Date().toISOString(),
    tipoDeinvestigacion: data?.tipoDeinvestigacion || "",
    tipoRecurso: data?.tipoRecurso['id'] || 1,
    documento: "",
    imagen: "",

  };
}

function newSchema() {
  return {
    descripcionTotal: Yup.string("Debe de haber una descripción del recurso")
      .required("La descripción es requerida"),
    titulo: Yup.string("El titulo no puede estar vacío")
      .required("El titular es requerido"),
    catalogo: Yup.string("Selecciona un catálogo")
      .required("El catálogo es requerido"),
  };
}

function updateSchema() {
  return {
    descripcionTotal: Yup.string("Debe de haber una descripción del recurso")
      .required("La descripción es requerida"),
    titulo: Yup.string("El titulo no puede estar vacío")
      .required("El titular es requerido"),
    catalogo: Yup.string("Selecciona un catálogo")
      .required("El catálogo es requerido"),
  };
}

function nombreDocumento(documento) {
  const sep = documento.split('/')
  return sep[sep.length - 1]
}

function asignarTipoDocumento(previewDocument, link) {
  if (previewDocument && link)
    return 3;
  else if (previewDocument && !link)
    return 2;
  else {
    return 1;
  }
}