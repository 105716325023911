import { BASE_API } from "../utils/constants";

// Solicitud de servicio

export async function getServicioGeneralApi(token) {
    try {
        const url = `${BASE_API}/api/solicitudServicio/`;
        const params = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    };
}

export async function setServicioGeneralApi(data, idCliente, token) {
    try {
        const indicadores = JSON.stringify({ ...data.indicadores });
        const formData = new FormData();
        formData.append("idCliente", idCliente);
        formData.append("indicadores", indicadores);
        formData.append("sectores", data.sectores);
        formData.append("objetivoServicio", data.objetivoServicio);
        const url = `${BASE_API}/api/solicitudServicio/nuevaSolicitud/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function editServicioGeneralApi(data, idServicio, token) {
    console.log(data);
    try {
        const formData = new FormData();
        if (data.indicadores) {
            const indicadores = JSON.stringify({ ...data.indicadores });
            formData.append("indicadores", indicadores);
        }
        if (data.sectores) formData.append("sectores", data.sectores);
        if (data.objetivoServicio) formData.append("objetivoServicio", data.objetivoServicio);
        if (data.estatus) formData.append("estatus", data.estatus);
        if (data.fechaTermino) {
            if (data.fechaTermino == "S/N")
                formData.append("fechaTermino", "");
            else
                formData.append("fechaTermino", data.fechaTermino);
        }
        console.log("FormData:\n", formData);
        const url = `${BASE_API}/api/solicitudServicio/${idServicio}/`;
        const params = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function editServicioGeneralAdminApi(data, idServicio, token) {
    try {
        const formData = new FormData();
        console.log("Estatus viejo0", data.estatus);
        if (data.estatus) formData.append("estatus", data.estatus);
        if (data.fechaTermino) {
            if (data.fechaTermino == "S/N")
                formData.append("fechaTermino", "");
            else
                formData.append("fechaTermino", data.fechaTermino);
        }
        const url = `${BASE_API}/api/solicitudServicio/editarSolicitudAdmin/${idServicio}/`;
        const params = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function deleteServicioGeneralApi(id, token) {
    try {
        const url = `${BASE_API}/api/solicitudServicio/${id}/`;
        const params = {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

// solicitud de peticion ======================


export async function getSolicitudPeticionApi(tipoPeticion, idCliente, token) {
    try {
        const url = `${BASE_API}/api/solicitudPeticiones/filtro/${tipoPeticion}/${idCliente}/`;
        const params = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
        console.log(error);
    };
}

export async function getSolicitudPeticionOperativoApi(tipoPeticion, token) {
    try {
        const url = `${BASE_API}/api/solicitudPeticiones/filtro/${tipoPeticion}/`;
        const params = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
        console.log(error);
    };
}

export async function setSolicitudPeticionApi(idUser, tipoPeticion, data, token) {
    try {
        const indicadores = JSON.stringify({ ...data.indicadores });
        const formData = new FormData();
        formData.append("tipoPeticion", tipoPeticion);
        formData.append("objetivoServicio", data.objetivoServicio);
        formData.append("indicadores", indicadores);
        formData.append("sectores", data.sectores);
        formData.append("idUser", idUser);
        const url = `${BASE_API}/api/solicitudPeticiones/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function deleteSolicitudPeticionApi(id, token) {
    try {
        const url = `${BASE_API}/api/solicitudPeticiones/${id}/`;
        const params = {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

// Petición

export async function sendMailPeticionServicioApi(data, token) {
    try {
        const url = `${BASE_API}/api/notificacionPeticionServicio/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}
// Webinar 

export async function getWebinarApi(token) {
    try {
        const url = `${BASE_API}/api/webinar/`;
        const params = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    };
}

export async function setWebinarApi(data, token) {
    try {
        const formData = new FormData();
        formData.append("tituloWebinar", data.tituloWebinar);
        formData.append("autorWebinar", data.autorWebinar);
        formData.append("descripcionWebinar", data.descripcionWebinar);
        if (data.video) formData.append("video", data.video);
        formData.append("link", data.link);
        const url = `${BASE_API}/api/webinar/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function editWebinarApi(data, idWebinar, token) {
    try {
        const formData = new FormData();
        if (data.tituloWebinar) formData.append("tituloWebinar", data.tituloWebinar);
        if (data.autorWebinar) formData.append("autorWebinar", data.autorWebinar);
        if (data.descripcionWebinar) formData.append("descripcionWebinar", data.descripcionWebinar);
        if (data.link) formData.append("link", data.link);
        const url = `${BASE_API}/api/webinar/${idWebinar}/`;
        const params = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function deleteWebinarApi(id, token) {
    try {
        const url = `${BASE_API}/api/webinar/${id}/`;
        const params = {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

// Estudio de inteligencia

export async function getEstudioInteligenciaApi(token) {
    try {
        const url = `${BASE_API}/api/estudioInteligencia/`;
        const params = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    };
}

export async function setEstudioInteligenciaApi(data, token) {
    try {
        const formData = new FormData();
        formData.append("tituloEstudio", data.tituloEstudio);
        formData.append("autorEstudio", data.autorEstudio);
        formData.append("descripcionEstudio", data.descripcionEstudio);
        formData.append("link", data.link);
        formData.append("sector", data.sector);
        formData.append("imagen", data.imagen);
        formData.append("documento", data.documento);
        const url = `${BASE_API}/api/estudioInteligencia/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function editEstudioInteligenciaApi(data, idEstudio, token) {
    try {
        const formData = new FormData();
        if (data.tituloEstudio) formData.append("tituloEstudio", data.tituloEstudio);
        if (data.autorEstudio) formData.append("autorEstudio", data.autorEstudio);
        if (data.descripcionEstudio) formData.append("descripcionEstudio", data.descripcionEstudio);
        if (data.link) formData.append("link", data.link);
        if (data.sector) formData.append("sector", data.sector);
        if (data.imagen) formData.append("imagen", data.imagen);
        if (data.documento) formData.append("documento", data.documento);
        const url = `${BASE_API}/api/estudioInteligencia/${idEstudio}`;
        const params = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function deleteEstudioInteligenciaApi(id, token) {
    try {
        const url = `${BASE_API}/api/estudioInteligencia/${id}/`;
        const params = {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}