import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";

import { IngresaDatosForm } from '../../Generales';
import './ChatIA.scss';

export function ChatIAComponent() {

    const [chat, setChat] = useState([{
        mensaje: "¡Hola, gracias por usar el sistema de IA de OTS, en qué puedo ayudarte!", rol: "bot"
    }])
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null);

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(newSchema()),
        onSubmit: async (formValue) => {
            console.log(formValue);
            crearRespuesta(formValue.input, "usuario")
            setIsTyping(true)
            setTimeout(() => {

                crearRespuesta(respuesta, "bot")
                setIsTyping(false)
            }, 5000)
        }
    })

    const crearRespuesta = (mensaje, rol) => {
        setChat(chat => [...chat, { mensaje: mensaje, rol: rol }]);
    }

    const respuesta = "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas \"Letraset\", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum."

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chat]);

    return (
        <div>
            <section className="conteiner-chat">
                <div className='respuesta-conteiner'>
                    {chat.map((mensaje, index) => (
                        <div key={index} className={mensaje.rol === "usuario" ? "usuario" : "bot"}>
                            {
                                index === chat.length - 1 && isTyping ? (
                                    <p>{mensaje.mensaje}</p>
                                ) : (
                                    <div>
                                        {mensaje.mensaje}<span className='cursor'>&#160;</span>
                                    </div>
                                )
                            }
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
                <Form onSubmit={formik.handleSubmit}>
                    <IngresaDatosForm
                        tituloLabel="¡Preguntame algo!"
                        formatoLabel="tituloIzquierda"
                        nameControl="input"
                        type="text"
                        rows={3}
                        placeHolderControl="Redacción"
                        valueControl={formik.values.input}
                        onChangeControl={formik.handleChange}
                        error={formik.errors.input}
                        touched={formik.touched.input}
                    />
                    <div style={{ width: "150px" }}>
                        <Button variant="primary" type="submit">c: </Button>
                    </div>
                </Form>
                <div >
                </div>
            </section >
        </div >
    )
}

function initialValues(data) {
    return {
        input: ""
    }
}

function newSchema() {
    return {
        input: Yup.string(
            "Debes de ingresar tú pregunta"
        ).required("Se requiere saber tú pregunta"),
    }
}