import React from "react";
import { useParams } from "react-router-dom";

import { DetalleCancel } from "../../../components/Client";
import "./Pagos.scss";
export function PagoCancel() {
  const { clave } = useParams();

  return <DetalleCancel clave={clave}></DetalleCancel>;
}
