import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { IngresaDatosForm } from "../../../../../components/Generales/IngresaDatosForm";

import "./BannerInformativo.scss";

export function BannerInformativo(props) {
  const { bannerInfo, setbannerInfo } = props;
  const [switchActive, setSwitchActive] = useState(true);
  const [isChecked1, setIsChecked1] = useState(!!bannerInfo.colorBanner);
  const [isChecked2, setIsChecked2] = useState(
    !!bannerInfo.imagen && !!bannerInfo.imagen.name
  );
  const [showCamposContacto, setShowCamposContacto] = useState(true);
  const [showImagen, setShowImagen] = useState(false);
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (bannerInfo.imagen) {
      const objectURL = URL.createObjectURL(bannerInfo.imagen);
      setImage(objectURL);
    } else {
      setImage(null);
    }
  }, [bannerInfo.imagen]);

  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
    setIsChecked2(isChecked1);
    setbannerInfo({
      ...bannerInfo,
      ["imagen"]: "",
    });
  };

  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
    setIsChecked1(isChecked2);
    setbannerInfo({
      ...bannerInfo,
      ["colorBanner"]: "",
    });
  };

  const handleButtonClick = () => {};

  const handleInputChange = (event) => {
    setbannerInfo({
      ...bannerInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckChange = (valor) => {
    setbannerInfo({
      ...bannerInfo,
      [valor]: !bannerInfo[valor],
    });
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file);
    setImage(objectURL);
    setbannerInfo({
      ...bannerInfo,
      ["imagen"]: file,
    });
  };

  return (
    <Row>
      <Row className="row-banner">
        <Col xs={6} md={4} className="col-label">
          <Form.Label className="d-flex justify-content-start text-bold text-resal mb-3">
            Banner Informativo
          </Form.Label>
        </Col>
      </Row>

      <Row className="row-inf">
        <Col className="col-banner" xs={7}>
          <Form.Label className="d-flex text-bold text-resal mb-3">
            Color de banner
          </Form.Label>
          <div className="input-group">
            <div className="input-check input-group-text">
              <input
                className="form-check-input mt-0"
                type="checkbox"
                aria-label="Radio button for following text input"
                checked={isChecked1}
                onChange={handleCheckboxChange1}
              />
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Text input with radio button"
              name="colorBanner"
              value={bannerInfo.colorBanner}
              onChange={handleInputChange}
              disabled={!isChecked1}
            />
            <button
              className="Btn-Color"
              type="button"
              id="button-addon2"
              onClick={handleButtonClick}
              disabled={!isChecked1}
            >
              Escoge Color
            </button>
          </div>
          <Form.Label className="d-flex text-bold text-resal mb-3">
            Imagen de fondo
          </Form.Label>
          <div className="input-group">
            <div className="row">
              <div className="col-md-12">
                <div className="input-group">
                  <div className="input-check input-group-text">
                    <input
                      className="form-check-input mt-0"
                      type="checkbox"
                      aria-label="Radio button for following text input"
                      checked={isChecked2}
                      onChange={handleCheckboxChange2}
                    />
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    id="inputGroupFile03"
                    aria-describedby="inputGroupFileAddon03"
                    aria-label="Upload"
                    onChange={handleChange}
                    disabled={!isChecked2}
                  />
                </div>
              </div>
              {bannerInfo.imagen && bannerInfo.imagen.name && (
                <div className="col-md-12 mt-3">
                  <div
                    style={{
                      background: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <p style={{ margin: "0", fontWeight: "bold" }}>
                      Imagen seleccionada actualmente:
                    </p>
                    <img
                      src={image}
                      alt={
                        bannerInfo.imagen
                          ? bannerInfo.imagen.name
                          : "Imagen seleccionada"
                      }
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <IngresaDatosForm
              col="col-sm-12 col-md-12 col-xl-12 mb-3"
              formatoLabel="tituloIzquierda"
              nameControl="titulo"
              type="text"
              placeHolderControl="Titulo de seccion (100 caracteres)"
              valueControl={bannerInfo.titulo}
              onChangeControl={handleInputChange}
              floating
            />
            <IngresaDatosForm
              col="col-sm-12 col-md-12 col-xl-12 mb-3"
              formatoLabel="tituloIzquierda"
              nameControl="texto"
              type="text"
              placeHolderControl="Texto (500 caracteres)"
              valueControl={bannerInfo.texto}
              onChangeControl={handleInputChange}
              rows={3}
              floating
            />
          </div>
        </Col>
        <Col className="col-banner" xs={4}>
          <div style={{ display: "flex" }}>
            <div className="input-group">
              <Form.Label className="text-bold text-resal">
                Campos contacto
              </Form.Label>
            </div>
            <div className="input-group" style={{ display: "flex" }}></div>
          </div>

          <div class="row-cont input-group-append">
            <div className="row-contac">
              <div>
                <IngresaDatosForm
                  col="col-sm-12 col-md-12 col-xl-12 mb-3"
                  formatoLabel="tituloIzquierda"
                  nameControl="tituloContacto"
                  type="text"
                  placeHolderControl="Titulo de contacto (50 caracteres)"
                  valueControl={bannerInfo.tituloContacto}
                  onChangeControl={handleInputChange}
                  floating
                />
                <IngresaDatosForm
                  col="col-sm-12 col-md-12 col-xl-12 mb-3"
                  formatoLabel="tituloIzquierda"
                  nameControl="subtituloConacto"
                  type="text"
                  placeHolderControl="Subtitulo (100 caracteres)"
                  valueControl={bannerInfo.subtituloConacto}
                  onChangeControl={handleInputChange}
                  floating
                />
                <Form.Label className="d-flex text-bold text-resal mb-3">
                  Obligatorios
                </Form.Label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={bannerInfo.asunto}
                    onChange={() => handleCheckChange("asunto")}
                  />
                  <IngresaDatosForm
                    col="col-sm-12 col-md-12 col-xl-12 mb-3"
                    formatoLabel="tituloIzquierda"
                    nameControl="tituloEvento"
                    type="text"
                    placeHolderControl="Asunto"
                    floating
                  />
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={bannerInfo.nombre}
                    onChange={() => handleCheckChange("nombre")}
                  />
                  <IngresaDatosForm
                    col="col-sm-12 col-md-12 col-xl-12 mb-3"
                    formatoLabel="tituloIzquierda"
                    nameControl="tituloEvento"
                    type="text"
                    placeHolderControl="Nombre"
                    floating
                  />
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={bannerInfo.telefono}
                    onChange={() => handleCheckChange("telefono")}
                  />
                  <IngresaDatosForm
                    col="col-sm-12 col-md-12 col-xl-12 mb-3"
                    formatoLabel="tituloIzquierda"
                    nameControl="tituloEvento"
                    type="text"
                    placeHolderControl="Telefono"
                    floating
                  />
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={bannerInfo.correo}
                    disabled
                  />
                  <IngresaDatosForm
                    col="col-sm-12 col-md-12 col-xl-12 mb-3"
                    formatoLabel="tituloIzquierda"
                    nameControl="tituloEvento"
                    type="text"
                    placeHolderControl="Correo"
                    floating
                  />
                </div>
                <div className="d-flex justify-content-start text-bold text-resal mb-3">
                  <Button
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "#8ec93d",
                      borderColor: "#8ec93d",
                      fontSize: "15px",
                      width: "70px",
                    }}
                  >
                    Enviar
                  </Button>
                  <Form.Label className="label-campos">
                    *Campos obligatorios
                  </Form.Label>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Row>
  );
}
