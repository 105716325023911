import React from "react";
import Button from "react-bootstrap/Button";
import { useEquipoOTS } from "../../../../hooks/useEquipoOTS";
import "./DeleteEquipoOTS.scss";

export function DeleteEquipoOTS(props) {
  const { onClose, onRefetch, equipo } = props;
  const { deleteEquipoOTS } = useEquipoOTS();

  async function deleEquipoOTS() {
    try {
      await deleteEquipoOTS(equipo.id);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h3 className="title-spacing">¿Está seguro de eliminar al integrante?</h3>
      <div className="delete-user-form">
        <Button variant="danger" className="btnaling" onClick={deleEquipoOTS}>
          Eliminar
        </Button>
        <Button variant="outline-danger" className="btnaling" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}
