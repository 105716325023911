import {
  faBookOpen,
  faBriefcase,
  faBuilding,
  faCalendarCheck,
  faCaretRight,
  faChartLine,
  faCircleQuestion,
  faComments,
  faEnvelopeOpenText,
  faFileAlt,
  faFileLines,
  faHandshake,
  faHouseUser,
  faIdCard,
  faLink,
  faMapLocation,
  faNewspaper,
  faPeopleGroup,
  faPeopleLine,
  faPeopleRoof,
  faPhotoFilm,
  faQuestionCircle,
  faToolbox,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Image, Nav, NavDropdown } from "react-bootstrap";
import logo from "../../../images/Logo.png";
import { BotonSidebar } from "./BotonSidebar";
import "./LeftMenu.scss";

import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks";

export function LeftMenu(props) {
  const { children } = props;
  const { pathname } = useLocation();

  return (
    <div className="side-menu-admin">
      <MenuLeft pathname={pathname} />
      <div className="content">{children}</div>
    </div>
  );
}

function MenuLeft(props) {
  const { pathname } = props;
  const { auth } = useAuth();
  const r = auth.me["role"];

  return (
    <>
      <Menu pathname={pathname} role={r} />
    </>
  );
}

function Menu(props) {
  const { pathname, role } = props;
  if (role === 1 || role === null) return MenuAdmin(pathname);
  else if (role === 2) return MenuOperativo(pathname);
  else if (role === 3) return MenuColaborador(pathname);
  else if (role === 4) return MenuCustomerService(pathname);
  else if (role === 6) return MenuClienteCompradorB2B(pathname);
  else if (role === 7) return MenuClienteVendedorB2B(pathname);
  else if (role === 8) return MenuClienteAdminB2B(pathname);
  else return MenuCliente(pathname);
}

function MenuAdmin(props) {
  const [navVisible, showNavbar] = useState(false);
  const { pathname } = props;

  return (
    <>
      <BotonSidebar btnSidebar={() => showNavbar(!navVisible)} />

      <Nav className={!navVisible ? "col-md-12 d-none d-md-block bg-light sidebar" : ""} activeKey="/admin">
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin"}
          >
            <FontAwesomeIcon icon={faHouseUser} className="icon" /> Inicio
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/users"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/users"}
          >
            <FontAwesomeIcon icon={faUsers} className="icon" /> Usuarios
            {pathname === "/admin/users" ? <FontAwesomeIcon icon={faCaretRight} className="mark" /> : null}
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/equipo-ots"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/equipo-ots"}
          >
            <FontAwesomeIcon icon={faPeopleGroup} className="icon" /> Equipo OTS
          </Nav.Link>
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/alianzas"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/alianzas"}
          >
            <FontAwesomeIcon icon={faHandshake} className="icon" /> Alianzas
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/colaboradores"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/colaboradores"}
          >
            <FontAwesomeIcon icon={faPeopleLine} className="icon" /> Colaboradores
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/soporte/crm"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/soporte/crm"}
          >
            <FontAwesomeIcon icon={faToolbox} className="icon" /> CRM
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/solicitudCliente-admin"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/solicitudCliente-admin"}
          >
            <FontAwesomeIcon icon={faFileLines} className="icon" /> Solicitudes de servicios
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/serviciosLicenciamientoOperativo"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/serviciosLicenciamientoOperativo"}
          >
            <FontAwesomeIcon icon={faIdCard} className="icon" /> Publicaciones con licenciamiento
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/mapCluster"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/mapCluster"}
          >
            <FontAwesomeIcon icon={faMapLocation} className="icon" /> Mapa Cluster
          </Nav.Link>
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/b2b"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/b2b"}
          >
            <FontAwesomeIcon icon={faLink} className="icon" /> B2B
          </Nav.Link>
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/catalogosb2b"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/catalogosb2b"}
          >
            <FontAwesomeIcon icon={faBookOpen} className="icon" /> CatalogosB2B
          </Nav.Link>
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/chatIA"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/chatIA"}
          >
            <FontAwesomeIcon icon={faBuilding} className="icon" /> Preuba chat IA Con base de datos
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}
function MenuOperativo(props) {
  const [navVisible, showNavbar] = useState(false);
  const { pathname } = props;

  return (
    <>
      <BotonSidebar btnSidebar={() => showNavbar(!navVisible)} />

      <Nav className={!navVisible ? "col-md-12 d-none d-md-block bg-light sidebar" : ""} activeKey="/admin">
        <Nav.Item className="item-side1">
          <Image className="logo" src={logo} />
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin"}
          >
            <FontAwesomeIcon icon={faHouseUser} className="icon" /> Inicio
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/recursos-admin"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/recursos-admin"}
          >
            <FontAwesomeIcon icon={faEnvelopeOpenText} className="icon" /> Administración de Recursos
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/noticias"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/noticias"}
          >
            <FontAwesomeIcon icon={faNewspaper} className="icon" /> Noticias
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/indicadores"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/indicadores"}
          >
            <FontAwesomeIcon icon={faChartLine} className="icon" /> Indicadores
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/eventos"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/eventos"}
          >
            <FontAwesomeIcon icon={faCalendarCheck} className="icon" /> Eventos
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/operativo/top-sector-noticia"}
            N
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/operativo/top-sector-noticia"}
          >
            <FontAwesomeIcon icon={faNewspaper} className="icon" /> Top Sectores Noticias
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/sectoresNoticias"}
            N
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/sectoresNoticias"}
          >
            <FontAwesomeIcon icon={faNewspaper} className="icon" /> Sectores Noticias
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/boletin"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/boletin"}
          >
            <FontAwesomeIcon icon={faBookOpen} className="icon" /> Boletines
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/solicitudCliente-operativo"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/solicitudCliente-operativo"}
          >
            <FontAwesomeIcon icon={faFileLines} className="icon" /> Reportes Colaborador
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/editorCarousel"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/editorCarousel"}
          >
            <FontAwesomeIcon icon={faPhotoFilm} className="icon" /> Carrusel
          </Nav.Link>
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/serviciosLicenciamientoOperativo"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/serviciosLicenciamientoOperativo"}
          >
            <FontAwesomeIcon icon={faIdCard} className="icon" /> Publicaciones con licenciamiento
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}

function MenuColaborador(props) {
  const [navVisible, showNavbar] = useState(false);

  const { pathname } = props;
  return (
    <>
      <BotonSidebar btnSidebar={() => showNavbar(!navVisible)} />

      <Nav className={!navVisible ? "col-md-12 d-none d-md-block bg-light sidebar" : ""} activeKey="/admin">
        <Nav.Item className="item-side1">
          <Image className="logo" src={logo} />
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin"}
          >
            <FontAwesomeIcon icon={faHouseUser} className="icon" /> Inicio
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/asignacionesInvestigacion"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/asignacionesInvestigacion"}
          >
            <FontAwesomeIcon icon={faFileLines} className="icon" /> Asignación investigativa
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}

function MenuCustomerService(props) {
  const [navVisible, showNavbar] = useState(false);
  const { pathname } = props;
  return (
    <>
      <BotonSidebar btnSidebar={() => showNavbar(!navVisible)} />

      <Nav className={!navVisible ? "col-md-12 d-none d-md-block bg-light sidebar" : ""} activeKey="/admin">
        <Nav.Item className="item-side1">
          <Image className="logo" src={logo} />
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin"}
          >
            <FontAwesomeIcon icon={faHouseUser} className="icon" /> Inicio
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/soporteNavegacaion"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/soporteNavegacaion"}
          >
            <FontAwesomeIcon icon={faCircleQuestion} className="icon" /> Administración soporte
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/experienciaCLiente"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/experienciaCLiente"}
          >
            <FontAwesomeIcon icon={faComments} className="icon" /> Experiencia Cliente
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}

function MenuCliente(props) {
  const [navVisible, showNavbar] = useState(false);

  const { pathname } = props;
  return (
    <>
      <BotonSidebar btnSidebar={() => showNavbar(!navVisible)} />

      <Nav className={!navVisible ? "col-md-12 d-none d-md-block bg-light sidebar" : ""} activeKey="/admin">
        <Nav.Item className="item-side1">
          <Image className="logo" src={logo} />
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin"}
          >
            <FontAwesomeIcon icon={faHouseUser} className="icon" /> Inicio
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/serviciosLicenciamiento"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/serviciosLicenciamiento"}
          >
            <FontAwesomeIcon icon={faIdCard} className="icon" /> Servicios de membresia
          </Nav.Link>

          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/recursos-client"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/recursos-client"}
          >
            <FontAwesomeIcon icon={faEnvelopeOpenText} className="icon" /> Recursos
          </Nav.Link>
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/ayuda"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/ayuda"}
          >
            <FontAwesomeIcon icon={faFileLines} className="icon" /> Ayuda
          </Nav.Link>
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin/questions"}
            onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin/questions"}
          >
            <FontAwesomeIcon icon={faQuestionCircle} className="icon" /> Preguntas Frecuentes
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}

function MenuClienteCompradorB2B(props) {
  const [navVisible, showNavbar] = useState(false);
  const { pathname } = props;
  return (
    <>
      <BotonSidebar btnSidebar={() => showNavbar(!navVisible)} />
      <Nav className={!navVisible ? "col-md-12 d-none d-md-block bg-light sidebar" : ""} activeKey="/admin">
        <Nav.Item className="item-side1">
          <Image className="logo" src={logo} />
        </Nav.Item>

        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin"}
          >
            <FontAwesomeIcon icon={faHouseUser} className="icon" /> Inicio
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/b2b"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/b2b"}
          >
            <FontAwesomeIcon icon={faBriefcase} className="icon" /> B2B
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/foros-b2b"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/foros-b2b"}
          >
            <FontAwesomeIcon icon={faPeopleRoof} className="icon" /> Encuentros
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/b2b/comprador/agendas"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/b2b/comprador/agendas"}
          >
            <FontAwesomeIcon icon={faPeopleRoof} className="icon" /> Agendas
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/ayuda"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/ayuda"}
          >
            <FontAwesomeIcon icon={faCircleQuestion} className="icon" /> Ayuda
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/requerimientos-comprador"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/requerimientos-comprador"}
          >
            <FontAwesomeIcon icon={faFileAlt} className="icon" /> Requerimientos
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}

function MenuClienteVendedorB2B(props) {
  const [navVisible, showNavbar] = useState(false);
  const { pathname } = props;
  return (
    <>
      <BotonSidebar btnSidebar={() => showNavbar(!navVisible)} />
      <Nav className={!navVisible ? "col-md-12 d-none d-md-block bg-light sidebar" : ""} activeKey="/admin">
        <Nav.Item className="item-side1">
          <Image className="logo" src={logo} />
        </Nav.Item>

        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin"}
            ////onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin"}
          >
            <FontAwesomeIcon icon={faHouseUser} className="icon" /> Inicio
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/b2b/vendedor/home"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/b2b/vendedor/home"}
          >
            <FontAwesomeIcon icon={faEnvelopeOpenText} className="icon" /> Productos/Servicio
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/b2b/vendedor/agendas"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/b2b/vendedor/agendas"}
          >
            <FontAwesomeIcon icon={faBriefcase} className="icon" /> Agendas
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/b2b/vendedor/home"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/b2b/vendedor/home"}
          >
            <FontAwesomeIcon icon={faCircleQuestion} className="icon" /> Ayuda
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}

function MenuClienteAdminB2B(props) {
  const [navVisible, showNavbar] = useState(false);
  const { pathname } = props;
  return (
    <>
      <BotonSidebar btnSidebar={() => showNavbar(!navVisible)} />
      <Nav className={!navVisible ? "col-md-12 d-none d-md-block bg-light sidebar" : ""} activeKey="/admin">
        <Nav.Item className="item-side1">
          <Image className="logo" src={logo} />
        </Nav.Item>

        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/admin"}
            ////onClick={() => showNavbar(!navVisible)}
            active={pathname === "/admin"}
          >
            <FontAwesomeIcon icon={faHouseUser} className="icon" /> Inicio
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="item-side2">
          <Nav.Link
            className="text-nav"
            as={Link}
            to={"/b2b/admin/agendas/"}
            //onClick={() => showNavbar(!navVisible)}
            active={pathname === "/b2b/admin/agendas/"}
          >
            <FontAwesomeIcon icon={faEnvelopeOpenText} className="icon" /> Agendas
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}
