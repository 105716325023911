import { AdminLayout } from "../layouts";
import { AgendasB2B } from "../pages/b2b/admin";

const routes_b2b_vendedor = [
  {
    path: "/b2b/admin/agendas",
    layout: AdminLayout,
    component: AgendasB2B,
  },
];

export default routes_b2b_vendedor;
