//import { set } from "lodash";
import { useState } from "react";
import {
  editTopSectorApi,
  getCarrerassApi,
  getEstadosApi,
  getGeographicalScopeApi,
  getHonorariosApi,
  getMesesApi,
  getPaisesApi,
  getRegimenFiscalApi,
  getSectoresApi,
  getStatusApi,
  getTipoPlanApi,
  getTipoRecursoApi,
  getTipoServicioApi,
  getTopSectoresApi,
} from "../api/tablasCatalogo";
import { useAuth } from './useAuth';

export function useCatalagos() {

  const { auth } = useAuth()
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [sectores, setSectores] = useState(null);
  const [topsectores, setTopSectores] = useState(null);
  const [paises, setPaises] = useState(null);
  const [honorarios, setHonorario] = useState(null);
  const [status, setStatus] = useState(null);
  const [servicios, setServicios] = useState(null);
  const [tiposPlan, setTiposPlan] = useState(null);
  const [alcanceGeografico, setAlcanceGeografico] = useState(null);
  const [tipoRecurso, setTipoRecurso] = useState(null);
  const [meses, setMeses] = useState(null);
  const [regimenFiscal, setRegimenFiscal] = useState(null);
  const [estados, setEstados] = useState(undefined);
  const [carreras, setCarreras] = useState(undefined);

  const getSectores = async () => {
    try {
      setLoading(true);
      const response = await getSectoresApi();
      setLoading(false);
      setSectores(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getPaises = async () => {
    try {
      setLoading(true);
      const response = await getPaisesApi();
      setLoading(false);
      setPaises(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getHonorarios = async () => {
    try {
      setLoading(true);
      const response = await getHonorariosApi();
      setLoading(false);
      setHonorario(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getAlcanceGeografico = async () => {
    try {
      setLoading(true);
      const response = await getGeographicalScopeApi();
      setLoading(false);
      setAlcanceGeografico(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getStatus = async () => {
    try {
      setLoading(true);
      const response = await getStatusApi();
      setLoading(false);
      setStatus(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getTipoServicio = async () => {
    try {
      setLoading(true);
      const response = await getTipoServicioApi();
      setLoading(false);
      setServicios(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getTipoPlan = async () => {
    try {
      setLoading(true);
      const response = await getTipoPlanApi();
      setLoading(false);
      setTiposPlan(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getMeses = async () => {
    try {
      setLoading(true);
      const response = await getMesesApi();
      setLoading(false);
      setMeses(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getTipoRecurso = async () => {
    try {
      setLoading(true);
      const response = await getTipoRecursoApi();
      setLoading(false);
      setTipoRecurso(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getRegimenFiscal = async () => {
    try {
      setLoading(true);
      const response = await getRegimenFiscalApi();
      setLoading(false);
      setRegimenFiscal(response);
    } catch (error) {
      throw error;
    }
  }

  const getCarreras = async () => {
    try {
      setLoading(true);
      const response = await getCarrerassApi();
      setLoading(false);
      setCarreras(response);
    } catch (error) {
      throw error;
    }
  }
  const getEstado = async () => {
    try {
      setLoading(true);
      const response = await getEstadosApi();
      setLoading(false);
      setEstados(response);
    } catch (error) {
      throw error;
    }
  }
  const editTopSector = async (idTop, idSector) => {
    try {
      setLoading(true);
      const response = await editTopSectorApi(idTop, idSector, auth.token);
      setLoading(false);
      setTopSectores(response);
    } catch (error) {
      throw error;
    }
  }
  const getTopSectores = async () => {
    try {
      setLoading(true);
      const response = await getTopSectoresApi();
      setLoading(false);
      setTopSectores(response);
    } catch (error) {
      throw error;
    }
  }



  return {
    loading,
    error,
    paises,
    honorarios,
    sectores,
    status,
    servicios,
    meses,
    alcanceGeografico,
    tiposPlan,
    tipoRecurso,
    regimenFiscal,
    getSectores,
    getPaises,
    getStatus,
    getTipoServicio,
    getHonorarios,
    getAlcanceGeografico,
    getTipoPlan,
    getTipoRecurso,
    getMeses,
    getRegimenFiscal,
    getCarreras,
    getEstado,
    estados,
    carreras,
    editTopSector,
    getTopSectores,
    topsectores,
  };
}
