import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { HeaderPage } from "../../HeaderPage";

import { VistaCarousel } from "./VistaCarousel";
import "./MuestraCarousel.scss";
import { add, set } from "lodash";
import { toast } from "react-toastify";

export function MuestraCarousel(props) {
  const { updateFormCarousel, carousels, addCarousel } = props;
  const messagesEndRef = useRef(null);
  const [refetch, setRefetch] = useState(false);

  const onRefetch = () => setRefetch((prev) => !prev);
  const [arrayCarousel, setArrayCarousel] = useState([]);

  const addImagen = () => {
    if (arrayCarousel.length >= 10) {
      toast.error("No se pueden agregar más de 6 imagenes");
      return;
    }
    const newCarousel = {
      imagen: "",
      descripcion: "titulo",
      fecha_alta: "",
      nuevo: true,
    };
    setArrayCarousel([...arrayCarousel, newCarousel]);
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setArrayCarousel(carousels);
  }, [refetch]);

  return (
    <>
      <Row className="mb-4">
        <Col>
          <HeaderPage btnTitle="Agregar Imagen" btnClick={addImagen} />
        </Col>
      </Row>
      <Row className="cartaPrincipal mb-4">
        {arrayCarousel
          ? arrayCarousel.map((carrusel, index) => (
              <Col sm={12} md={6} lg={4} key={index} className="mb-4">
                <VistaCarousel
                  index={index}
                  carousel={carrusel}
                  updateFormCarousel={updateFormCarousel}
                  addCarousel={addCarousel}
                />
              </Col>
            ))
          : null}
        <div ref={messagesEndRef} />
      </Row>
    </>
  );
}
