import React, { useEffect, useState } from "react";
import { Row, Tabs, Tab } from "react-bootstrap";
import { NewList } from "./NewList";
import { useB2B } from "../../../hooks";
import { AddRequerimientosVendedor } from "./addRequerimientosVendedor";
import { useParams } from "react-router-dom";

export function RequerimientosVendedor() {
  const [refetch, setRefetch] = useState(false);
  const { loading, subCategoria2, getsubCatbyCat, getCategorias, b2bCategoria, getCategoriaByUser, categoriab2b, getCatbyMicrosiotiob2b, b2bCatMic } = useB2B();
  const { id_micrositio } = useParams();

  useEffect(() => {
    getCategoriaByUser();
    getCatbyMicrosiotiob2b(id_micrositio);
  }, [refetch]);

  return (
    <>
      <Row className="justify-content-center">
        
        {loading ? (
          <p>Cargando datos...</p>
        ) : (
          <Tabs
            className="mb-1 p-0 nav-pills flex-column flex-sm-row"
            justify
            variant="pills"
            defaultActiveKey={b2bCatMic && b2bCatMic.length > 0 ? b2bCatMic[0].id.toString() : null}
          >
            {b2bCatMic && b2bCatMic.map((categoria, index) => (
              <Tab key={index} eventKey={categoria.id.toString()} title={categoria.descripcion}>
                <NewList 
                  // subCategoria2={subCategoria2}     
                  catId={categoria.id}             
                />
              </Tab>
            ))}
          </Tabs>
        )}
      </Row>
      <AddRequerimientosVendedor 
        subCategoria2={subCategoria2}
      />
    </>
  );
}
