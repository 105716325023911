import React, { useState, useEffect } from "react";
import { Row, Spinner } from "react-bootstrap";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";

import { ModalBasic } from "../../components/Common";
import {
  HeaderPage,
  TableRequestClient,
  NewFormRequestClient,
} from "../../components/Admin";
import {
  useAuth,
  usePlanPago,
  useRequestClient,
  useCatalagos,
} from "../../hooks";

export function RequestClient_Client() {
  const { auth } = useAuth();
  const { getClienteIdUser, clienteIdUser } = usePlanPago();
  const { getSectores, sectores } = useCatalagos();
  const {
    getServiciosSolicitadosIdCliente,
    serviciosSolicitadosIdCliente,
    loading,
  } = useRequestClient();
  const [idCliente, setIdCliente] = useState(null);

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("xl");
  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);
  
  useEffect(() => {
    const fetchData = async () => {
      await getClienteIdUser(auth.me["id"]);
      await getSectores();
      setIdCliente(AsignarIDCliente(clienteIdUser));
      if (idCliente) await getServiciosSolicitadosIdCliente(idCliente);
    };
    fetchData();
  }, [idCliente, refetch]);

  const addRequestClient = () => {
    setTitleModal("Crear solicitud de trabajo");
    setIconModal(faNewspaper);
    setsizeModal("xl");
    setContentModal(
      <NewFormRequestClient
        onClose={openCloseModal}
        onRefetch={onRefetch}
        membresia={clienteIdUser}
        sectores={sectores}
      />
    );
    openCloseModal();
  };
  return (
    <>
      <Row>
        <HeaderPage
          title="Petición de servicios"
          btnTitle="Nueva solicitud de trabajo"
          btnClick={addRequestClient}
        />
      </Row>
      <Row>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">
                Obteniendo información .....
              </span>
            </Spinner>
          </div>
        ) : (
          <TableRequestClient
            serviciosSolicitadosIdCliente={serviciosSolicitadosIdCliente}
            onRefetch={onRefetch}
          />
        )}
      </Row>
      <Row></Row>

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );

  function AsignarIDCliente(clienteIdUser) {
    if (clienteIdUser) return clienteIdUser["id"];
    else return 0;
  }
}
