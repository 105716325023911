import { AdminLayout } from "../layouts";
import {
  Agendas,
  B2B_Ayuda,
  B2B_Comprador,
  B2B_encuentros,
  CitasAgenda,
  MenuRequerimiento,
} from "../pages/b2b/comprador";
import { Requerimientos } from "../components/Client/MenuRequerimientoCliente";

const routes_b2b_comprador = [
  {
    path: "/b2b",
    layout: AdminLayout,
    component: B2B_Comprador,
  },
  {
    path: "/foros-b2b",
    layout: AdminLayout,
    component: B2B_encuentros,
  },
  {
    path: "/ayuda",
    layout: AdminLayout,
    component: B2B_Ayuda,
  },
  {
    path: "/b2b/comprador/agendas",
    layout: AdminLayout,
    component: Agendas,
  },
  {
    path: "/b2b/comprador/agendas/citas-:id",
    layout: AdminLayout,
    component: CitasAgenda,
  },
  {
    path: "/requerimientos-comprador",
    layout: AdminLayout,
    component: MenuRequerimiento,
  },
  {
    path: "/requerimientos-comprador-registro/:id_micrositio",
    layout: AdminLayout,
    component: Requerimientos,
  },
];

export default routes_b2b_comprador;
