import React from "react";
import * as Yup from "yup";
import { map } from "lodash";
import { Button, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useAuth } from "../../../../hooks";

import "./FormAyudaServicio.scss";
import {
  formatDate,
  formatDateNow,
} from "../../../../metodosGlobales/FormatoFecha";
import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";
import { useAyuda } from "../../../../hooks";
import { toast } from "react-toastify";


export function FormAyudaServicio(props) {

  const { auth } = useAuth();
  const { onClose, onRefetch, tiposervicio, ayudaservicio } = props;
  const { addAyudaServicio, updateAyudaServicio } = useAyuda();

  const formik = useFormik({
    initialValues: initialValues(ayudaservicio, auth),
    validationSchema: Yup.object(ayudaservicio ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      if (ayudaservicio)
        await updateAyudaServicio(ayudaservicio.id, formvalue);

      else
        try {
          await addAyudaServicio(formvalue);
          toast.success(
            "Los datos fueron enviados correctamente a soporte, nosotros nos comunicaremos con usted, gracias."
          );
        } catch (error) {
          toast.error(
            "Hubo problemas para el envio de datos por favor vuelva a intentarlo."
          );
          console.error(error);
        }
      onRefetch();
      onClose();
    },
  });



  return (
    <div className="servicio">
      <Form className="add-edit-new-form" onSubmit={formik.handleSubmit}>
        <Row className="mb-6">
          <IngresaDatosForm
            col="col-4 col-sm-4 col-lg-2"
            tituloLabel="No. membresia."
            formatoLabel="tituloCentrado"
            nameControl="user"
            type="text"
            valueControl={formik.values.user}
            onChangeControl={formik.handleChange}
            disabled={true}
          />

          <IngresaDatosForm
            col="col-8 col-sm-8 col-lg-4"
            tituloLabel="Nombre."
            formatoLabel="tituloCentrado"
            nameControl="nombre"
            type="text"
            valueControl={formik.values.nombre}
            onChangeControl={formik.handleChange}
            disabled={true}
          />

          <IngresaDatosForm
            col="col-6 col-sm-6 col-lg-3"
            tituloLabel="Apellido paterno."
            formatoLabel="tituloCentrado"
            nameControl="apellido_paterno"
            type="text"
            valueControl={formik.values.apellido_paterno}
            onChangeControl={formik.handleChange}
            disabled={true}
          />
          <IngresaDatosForm
            col="col-6 col-sm-6 col-lg-3"
            tituloLabel="Apellido materno."
            formatoLabel="tituloCentrado"
            nameControl="apellido_materno"
            type="text"
            valueControl={formik.values.apellido_materno}
            onChangeControl={formik.handleChange}
            disabled={true}
          />

          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-4 col-xl-3">
            <IngresaDatosForm
              tituloLabel="Tipo de servicio:"
              formatoLabel="tituloCentrado"
            />
            <Form.Select
              name="tipo_servicio"
              defaultValue={formik.values.tipo_servicio}
              onChange={formik.handleChange}
              disabled={ayudaservicio}
            >
              <option value={0}>Seleccione una opción</option>
              {map(tiposervicio, (tipo, index) => (
                <option key={index} value={tipo.id}>
                  {tipo.nombre}
                </option>
              ))}
            </Form.Select>
            <Form.Text className="text-danger">
              {formik.touched.tipo_servicio && formik.errors.tipo_servicio ? (
                <div className="text-danger">{formik.errors.tipo_servicio}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
          <IngresaDatosForm
            col="col-6 col-sm-6 col-lg-4 col-xl-3"
            tituloLabel="Fecha de Solicitud."
            formatoLabel="tituloCentrado"
            nameControl="fecha_solicitud"
            type="text"
            placeHolderControl="aaaa/mm/dd"
            valueControl={formik.values.fecha_solicitud}
            onChangeControl={formik.handleChange}
            error={formik.errors.fecha_solicitud}
            touched={formik.touched.fecha_solicitud}
            disabled={true}
          />
          <IngresaDatosForm
            col="col-6 col-sm-6 col-lg-4 col-xl-3"
            tituloLabel="Teléfono."
            formatoLabel="tituloCentrado"
            nameControl="telefono"
            type="text"
            placeHolderControl="Telefono de contacto"
            valueControl={formik.values.telefono}
            onChangeControl={formik.handleChange}
            error={formik.errors.telefono}
            touched={formik.touched.telefono}
            disabled={ayudaservicio}
          />
          <IngresaDatosForm
            col="col-6 col-sm-6 col-lg-6 col-xl-3"
            tituloLabel="Email."
            formatoLabel="tituloCentrado"
            nameControl="email"
            type="email"
            placeHolderControl="Email de contacto"
            valueControl={formik.values.email}
            onChangeControl={formik.handleChange}
            error={formik.errors.email}
            touched={formik.touched.email}
            disabled={ayudaservicio}
          />
        </Row>
        <Row className="mb-6">
          <Form.Group className="col-6 col-sm-6 servicio-grid mt-3">
            <Form.Label className="me-3">
              ¿Has contratado recientemente algún servicio?:
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Si"
              defaultChecked={formik.values.tiene_servicios}
              onChange={(data) =>
                formik.setFieldValue("tiene_servicios", data.target.checked)
              }
              disabled={ayudaservicio}
            />
          </Form.Group>
          <Form.Group className="col-6 col-sm-6 servicio-grid mt-3">
            <Form.Label className="me-3">
              ¿Se entrego en tiempo y forma?:
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Si"
              defaultChecked={formik.values.tiempo_forma}
              onChange={(data) =>
                formik.setFieldValue("tiempo_forma", data.target.checked)
              }
              disabled={ayudaservicio}
            />
          </Form.Group>
        </Row>
        <Row className="mb-6">
          <Form.Group className="col-6 col-sm-6 servicio-grid mt-3">
            <Form.Label className="me-3">¿Cumplió tus expectativas?:</Form.Label>
            <Form.Check
              type="checkbox"
              label="Si"
              defaultChecked={formik.values.cumplio}
              onChange={(data) =>
                formik.setFieldValue("cumplio", data.target.checked)
              }
              disabled={ayudaservicio}
            />
          </Form.Group>
          <Form.Group className="col-6 col-sm-6 servicio-grid mt-3 mb-3">
            <Form.Label className="me-3">
              ¿Puedes acceder a los servicios?:
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Si"
              defaultChecked={formik.values.accede}
              onChange={(data) =>
                formik.setFieldValue("accede", data.target.checked)
              }
              disabled={ayudaservicio}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group>
            <Form.Label>Descripción del problema.</Form.Label>
            <Form.Control
              name="descripcion"
              as="textarea"
              placeholder="Describa con detalle cuál es el problema"
              rows={3}
              value={formik.values.descripcion}
              onChange={formik.handleChange}
              disabled={ayudaservicio}
            />
            <Form.Text className="text-danger">
              {formik.touched.descripcion && formik.errors.descripcion ? (
                <div className="text-danger">{formik.errors.descripcion}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
        </Row>
        {ayudaservicio ? (
          <Row>
            <Form.Group controlId="frmEstatus" className="mt-3 col-6 col-sm-6 col-lg-4 col-xl-3">
              <Form.Label>¿El problema está solucionado?</Form.Label>
              <Form.Select
                defaultValue={formik.values.estatus}
                className="form-control"
                name="estatus"
                onChange={formik.handleChange}
              >
                <option value={1}>Pendiente</option>
                <option value={2}>Atendida</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label> Describa la solución al problema </Form.Label>
              <Form.Control
                name="solucion"
                as="textarea"
                placeholder="¿Cómo se solucionó el problema?"
                rows={3}
                value={formik.values.solucion}
                onChange={formik.handleChange}
              />

            </Form.Group>
          </Row>
        ) : null}

        <Button variant="primary" type="submit">
          {ayudaservicio ? "Actualizar" : "Crear solicitud"}
        </Button>
      </Form>
    </div>
  );
}

function initialValues(data, auth) {
  return {
    user: auth.me?.id || "",
    nombre: auth.me?.nombre || "",
    apellido_paterno: auth.me?.apellido_paterno || "",
    apellido_materno: auth.me?.apellido_materno || "",
    tipo_servicio: data?.tipo_servicio || "",
    fecha_solicitud: formatDate(data?.fecha_solicitud) || formatDateNow(),
    telefono: data?.telefono || "",
    email: data?.email || auth.me?.email,
    tiene_servicios: data?.tiene_servicios || false,
    tiempo_forma: data?.tiempo_forma || false,
    cumplio: data?.cumplio || false,
    accede: data?.accede || false,
    descripcion: data?.descripcion || "",
    solucion: data?.solucion || "",
    estatus: data?.estatus || "",
  };
}
function newSchema() {
  return {
    tipo_servicio: Yup.string("Debe seleccionar el tipo de servicio")
      .required()
      .notOneOf(["0"]),
    telefono: Yup.string("Debe ingresar el teléfono").required(
      "El teléfono es requerido"
    ),
    email: Yup.string("El email no puede ir vacío").required(
      "El email es requerido"
    ),
    descripcion: Yup.string(
      "Debe ingresar la descripción del problema o ayuda"
    ).required("La descripción es requerida"),
  };
}

function updateSchema() {
  return {
    tipo_servicio: Yup.string(
      "El tipo de servicio no puede estar vacío"
    ).required("El tipo de servicio es requerido"),
    telefono: Yup.string("Debe ingresar el teléfono").required(
      "El teléfono es requerido"
    ),
    email: Yup.string("El email no puede ir vacío").required(
      "El email es requerido"
    ),
    descripcion: Yup.string(
      "Debe ingresar la descripción del problema o ayuda"
    ).required("La descripción es requerida"),
  };
}
