import React, { useState, useRef, useEffect } from "react";
import { Row, Image, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faSquareTwitter,
  faSquareInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { ModalBasic } from "../../../../../components/Common";
import { EditarRedesSocial } from "./EditarRedesSociales";
import "./Encabezado.scss";

import imag from "../../../../../images/logo-2.png";

export function Encabezado({
  logo,
  setLogo,
  social,
  setSocial,
  showEditMessages = true,
  datos,
}) {
  const color = "green";
  console.log(datos);
  const fileInputRef1 = useRef(null);
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const openCloseModal = () => setshowModal((prev) => !prev);
  const [img, setImg] = useState();

  useEffect(() => {
    if (logo) {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(logo);
    } else {
      setImg(null);
    }
  }, [logo]);

  const editSocial = () => {
    setTitleModal("Editar redes sociales");
    setIconModal(faEnvelopeOpen);
    setContentModal(
      <EditarRedesSocial
        onClose={openCloseModal}
        social={social}
        setSocial={setSocial}
      />
    );
    openCloseModal();
  };

  const handleFileSelect = (fileInputRef) => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogo(file);
        setImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Row>
        <Col
          xs={1}
          md={2}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <Row>
            <Image
              style={{ height: 70, width: 70 }}
              src={img || imag}
              thumbnail
            />
            <input
              type="file"
              ref={fileInputRef1}
              style={{ display: "none" }}
              accept="image/*"
              onChange={(event) => handleImageUpload(event)}
            />
          </Row>
          {showEditMessages && (
            <Row>
              <Button
                variant="link"
                onClick={() => handleFileSelect(fileInputRef1)}
              >
                Editar logo
              </Button>
            </Row>
          )}
        </Col>
        <Col className="d-flex flex-column justify-content-center aling-items-center">
          <Row className="info-data">
            <Col
              md={1}
              className="d-flex flex-row align-items-center border-left border-right"
            >
              <label className="text-bold" style={{ color: color }}>
                Inicio
              </label>
            </Col>
            <Col
              md={2}
              className="d-flex flex-row align-items-center border-left border-right"
            >
              <label className="text-bold">Informacion</label>
            </Col>
            <Col
              md={1}
              className="d-flex flex-row align-items-center border-left border-right"
            >
              <label className="text-bold">Registro</label>
            </Col>
            <Col
              md={1}
              className="d-flex flex-row align-items-center border-left border-right"
            >
              <label className="text-bold">Contacto</label>
            </Col>
            <Col
              md={3}
              className="d-flex flex-column align-items-center border-left border-right"
            >
              <div className="d-flex flex-row mb-2 dev-icon">
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="mr-5 fa-icon"
                  color={color}
                />
                <FontAwesomeIcon
                  icon={faSquareTwitter}
                  className="mr-5 fa-icon"
                  color={color}
                />
                <FontAwesomeIcon
                  icon={faSquareInstagram}
                  className="mr-5 fa-icon"
                  color={color}
                />
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="mr-5 fa-icon"
                  color={color}
                />
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="mr-5 fa-icon"
                  color={color}
                />
              </div>
              {showEditMessages && (
                <Button variant="link" onClick={editSocial}>
                  Editar redes
                </Button>
              )}
            </Col>
            <Col md={2} className="d-flex flex-row align-items-center">
              <div>
                <FontAwesomeIcon
                  icon={faPhone}
                  className="phone-label"
                  color={color}
                />
              </div>
              <div className="d-flex flex-column">
                <label className="text-bold contacto-label">Contacto</label>
                <label className="text-bold phone-number">
                  {datos?.telefono}
                </label>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={"large"}
      />
    </>
  );
}
