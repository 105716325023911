import React from 'react'
import { Table, Button, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faPencil } from '@fortawesome/free-solid-svg-icons'

import { map } from 'lodash'
import './TableEquipoOTS.scss'

export function TableEquipoOTS(props) {
    const { equipoOTS, updateEquipoOTS, deleteEquipoOTS } = props;
    return (
        <div className="table-responsive-xl">
            <Table striped bordered hover className='table-equipo-ots table-sm'>
                <thead>
                    <tr>
                        <th>Foto</th>
                        <th>Nombre</th>
                        <th>Apellido Paterno</th>
                        <th>Apellido Materno</th>
                        <th>Descripción</th>
                        <th>Características</th>
                        <th>E-mail</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {map(equipoOTS, (equipo, index) => (
                        <tr key={index}>
                            <td><Image src={equipo.foto} className='foto' /></td>
                            <td>{equipo.nombre}</td>
                            <td>{equipo.apellido_paterno}</td>
                            <td>{equipo.apellido_materno}</td>
                            <td>{equipo.descripcion_profesion}</td>
                            <td>{equipo.caracteristicas_persona}</td>
                            <td>{equipo.email}</td>
                            <Actions equipo={equipo} updateEquipoOTS={updateEquipoOTS} deleteEquipoOTS={deleteEquipoOTS} />
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )

    function Actions(props) {
        const { equipo, updateEquipoOTS, deleteEquipoOTS } = props;

        return (
            <td>
                <Button
                    size='m'
                    variant='success'
                    onClick={() => updateEquipoOTS(equipo)}
                    className='action-button green btn-boton'
                >
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
                <Button
                    size='m'
                    variant='danger'
                    onClick={() => deleteEquipoOTS(equipo)}
                    className='action-button red btn-boton'
                >
                   <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
                </Button>
            </td>
        )
    }
}
