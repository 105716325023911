
import React from 'react'
import { useParams } from "react-router-dom";
import {SectoresNList} from "../../components/Client"

export function SectoresNoLista() {
 const {clave} = useParams();
 if (clave) return  <SectoresNList clave = {clave}/>;
 else return <SectoresNList/>;
}
