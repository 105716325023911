import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { IngresaDatosForm } from "../../../../../components/Generales/IngresaDatosForm";

import "./GaleriaInformativa.scss";

export function GaleriaInformativa(props) {
  const {
    colElements,
    setColElements,
    tituloGale,
    setTituloGale
  } = props;

  const [images, setImages] = useState(colElements.map((col) => (col.imagen ? URL.createObjectURL(col.imagen) : '')));

  useEffect(() => {
    // Actualizar la vista previa de la imagen cuando cambia el estado
    const updatedImages = images.map((_, index) =>
      colElements[index]?.imagen ? URL.createObjectURL(colElements[index].imagen) : ''
    );
    setImages(updatedImages);

    // Limpiar objetos URL antiguos al desmontar el componente
    return () => {
      updatedImages.forEach((imgUrl) => URL.revokeObjectURL(imgUrl));
    };
  }, [colElements]);

  const handleChange = (event, index) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const updatedColElements = [...colElements];
        updatedColElements[index] = {
          ...updatedColElements[index],
          imagen: file,
        };
        setColElements(updatedColElements);

        const updatedImages = [...images];
        updatedImages[index] = reader.result;
        setImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddCol = () => {
    if (colElements.length < 6) {
      setColElements([...colElements, {}]);
    }

  };

  const handleDeleteCol = (index) => {
    const updatedColElements = [...colElements];
    updatedColElements.splice(index, 1);
    setColElements(updatedColElements);

  };

  const handleInputChange = (index, event) => {
    const updatedColElements = [...colElements];
    updatedColElements[index] = {
      ...updatedColElements[index],
      [event.target.name]: event.target.value
    };
    setColElements(updatedColElements);
  }

  const handleInputTitleChange = (event) => {
    setTituloGale({
      ...tituloGale,
      [event.target.name]: event.target.value
    })


  }

  return (
    <Row>
      <Row className="row-banner">
        <Col xs={6} md={4} className="col-label">
          <Form.Label className="d-flex justify-content-start text-bold text-resal mb-3">
            Galeria informativa
          </Form.Label>
        </Col>
      </Row>

      <Row className="gal-inf">
        <Row>
          <div className="titulo-seccion">
            <IngresaDatosForm
              col="col-sm-8 col-md-8 col-xl-4 mb-3"
              formatoLabel="tituloIzquierda"
              nameControl="titulo"
              type="text"
              placeHolderControl="Titulo de seccion (300 caracteres)"
              valueControl={tituloGale.titulo}
              onChangeControl={handleInputTitleChange}
              floating
            />
          </div>
        </Row>
        <Row className="Agregar-imagen">
          {colElements.map((col, index) => (
            <Col className="imagen" key={index} xs={12} md={4} lg={2}>
              <div className="input-group mb-3">
                <input
                  type="file"
                  className="esc-imagen"
                  id={`inputGroupFile${index + 1}`}
                  name={`imagen${index + 1}`}
                  onChange={(event) => handleChange(event, index)}
                />
              </div>
              <div className="imagen-recuadro input-group-append">
                <img
                  src={images[index]}
                  alt="Imagen seleccionada"
                  width="100%"
                  height="100%"
                />
              </div>
              <IngresaDatosForm
                col="col-12"
                nameControl={`titulo${index + 1}`}
                type="text"
                placeHolderControl="Titulo (100 caracteres)"
                valueControl={col[`titulo${index + 1}`] || ''}
                onChangeControl={(e) => handleInputChange(index, e)}
                rounded="15px"
              />
              <IngresaDatosForm
                col="col-12"
                nameControl={`texto${index + 1}`}
                type="text"
                placeHolderControl="Texto (300 caracteres)"
                valueControl={col[`texto${index + 1}`] || ''}
                onChangeControl={(e) => handleInputChange(index, e)}
                rounded="15px"
                rows={4}
              />
              {index > 0 && (
                <Button
                  variant="danger"
                  onClick={() => handleDeleteCol(index)}
                >
                  Eliminar
                </Button>
              )}
            </Col>
          ))}
          {colElements.length < 6 && (
            <Col className="Agrega-Mas" xs={12} md={4} lg={2}>
              <Col>
                <Button className="boton-agregar-mas" onClick={handleAddCol}>
                  + Agregar más
                </Button>
                <Form.Label>Puedes agregar hasta 6 elementos</Form.Label>
              </Col>
            </Col>
          )}
        </Row>
      </Row>

    </Row>
  );
}
