import React, { useState } from "react";
import { map } from "lodash";
import { useFormik } from "formik";
import { Table, Button, Form, Alert } from "react-bootstrap";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import { useRequestClient, useSolicitudServicios } from "../../../../../hooks";
import { formatDateMonthName } from "../../../../../metodosGlobales";

import "../../solicitudesOperativo/TableRequestOperativo/TableRequestOperativo.scss"

export function TableRequestColaborador(props) {
  const { serviciosSolicitados, onRefetch } = props;

  return (
    <div className="table-responsive-xl">
      <Table striped bordered hover className="table-service table-sm">
        <thead>
          <tr>
            <th>Investigación</th>
            <th>Tipo de reporte</th>
            <th>Objetivo del reporte</th>
            <th>Requerimientos</th>
            <th>Alcance geográfico</th>
            <th>Status</th>
            <th>Fecha de cargada</th>
            <th>Horas de trabajo</th>
            <th>Sector</th>
            <th>Subir reporte</th>
          </tr>
        </thead>
        <tbody>
          {map(serviciosSolicitados, (servicio, index) => (
            <tr key={index}>
              <td>{servicio.nombreReporte}</td>
              <td>{servicio.tipoReporte}</td>
              <td>{servicio.idSolicitudServicio["objetivoServicio"]}</td>
              <td>{servicio.requerimientos}</td>
              <td>{servicio.alcanceGeografico["nombre"]}</td>
              <td>{servicio.idSolicitudServicio.estatus["nombre"]}</td>
              <td>
                {servicio.archivoReporte
                  ? formatDateMonthName(
                      servicio.idSolicitudServicio["fechaTermino"]
                    )
                  : "----/--/--"}
              </td>
              <td>{servicio.horasEstimadas}</td>
              <td>{servicio.idSolicitudServicio.sectores["descripcion"]}</td>
              <Actions servicio={servicio} onRefetch={onRefetch} />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function Actions(props) {
  const { servicio, onRefetch } = props;
  const { editReporteOperativoColaborador, loading } = useRequestClient();
  const { editServicioGeneral, sendMailSolicitudPeticion } =
    useSolicitudServicios();
  const [previewDocument, setPreviewDocument] = useState(
    servicio?.archivoReporte || null
  );

  async function handleUpload(event) {
    setPreviewDocument(event.target.files[0]);
    const file = event.target.files[0];
    await formik.setFieldValue("archivoReporte", file);
    console.log(previewDocument);
  }
  function obtenerCorreoCliente(servicio) {
    return servicio.idSolicitudServicio.idCliente.user["email"];
  }
  const correoCliente = obtenerCorreoCliente(servicio);

  const formik = useFormik({
    initialValues: initialValues(),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      console.log();
      await editReporteOperativoColaborador(formvalue, servicio.id);
      await editServicioGeneral(
        valoresTermino(),
        servicio.idSolicitudServicio["id"]
      );
      await sendMailSolicitudPeticion(correoCliente);
      await onRefetch();
        toast.success(
        "El documento se ha enviado por correo electronico al cliente"
      );
    },
  });
  return (
    <td>
      {loading ? (
        <>
          <Alert variant="info">
            <Alert.Heading>Tu archivo se esta subiendo</Alert.Heading>
            <p>Espera un momento "{previewDocument.name}" esta en proceso</p>
          </Alert>
        </>
      ) : (
        <>
          <input
            type="file"
            onChange={handleUpload}
            className="text"
            accept=".pdf, .docx, .doc"
          />
          <br />
          {servicio.archivoReporte ? (
            <a
              className="btn btn-primary btnDocument"
              role="button"
              href={servicio.archivoReporte}
              target="_blank"
            >
              ver Documento <FontAwesomeIcon className="icon" icon={faFile} />
            </a>
          ) : null}
          <Form
            className="add-edit-recurso-form container-btnPublicarArchivo"
            onSubmit={formik.handleSubmit}
          >
            <Button  className='btnPublicarArchivo ' size="sm" variant="success" type="submit">
              Publicar archivo
            </Button>
          </Form>
          <br />
        </>
      )}
    </td>
  );
}

function asignarNombre(servicio) {
  return `${servicio.cliente.user["nombre"]} ${servicio.cliente.user["apellido_paterno"]} ${servicio.cliente.user["apellido_materno"]}`;
}

function initialValues() {
  return {
    archivoReporte: "",
  };
}

function valoresTermino() {
  const data = {
    estatus: 2,
    fechaTermino: new Date().toISOString(),
  };
  return data;
}
