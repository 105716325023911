import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import './TableQuestions.scss'
import { ModalBasic } from "../../../Common";
import { ViewQuestionsServicios, ViewQuestionsFacturacion } from '../../../Admin'
import { faQuestionCircle, faCircleInfo, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function TableQuestions() {

    const [showModal, setshowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [iconModal, setIconModal] = useState(null);
    const [contentModal, setContentModal] = useState(null)
    const [sizeModal, setsizeModal] = useState("xl");

    const openCloseModal = () => setshowModal((prev) => !prev);

    const viewServicios = () => {
        setTitleModal('Servicios');
        setIconModal(faQuestionCircle);
        setContentModal(<ViewQuestionsServicios onClose={openCloseModal} />);
        setsizeModal("xl");
        openCloseModal();
    }

    const viewFacturacion = () => {
        setTitleModal('Facturación');
        setIconModal(faQuestionCircle);
        setContentModal(<ViewQuestionsFacturacion onClose={openCloseModal} />);
        setsizeModal("xl");
        openCloseModal();
    }

    return (
        <div className='container'>
            <h2 className='titulo_menu'>Preguntas Frecuentes</h2>
            <Row className="container_row">
                <div className='container_Button'>
                    <button className='button typeOne' onClick={viewServicios}>
                        <FontAwesomeIcon icon={faCircleInfo} className="question_icon fa-3x" />
                        <h2 className='texto'>Servicio</h2>
                    </button>
                    <button className='button typeOne' onClick={viewFacturacion}>
                        <FontAwesomeIcon icon={faFileInvoiceDollar} className="question_icon fa-3x" />
                        <h2 className='texto'>Facturación</h2>
                    </button>
                </div>
            </Row>
            <ModalBasic
                show={showModal}
                onClose={openCloseModal}
                icon={iconModal}
                title={titleModal}
                children={contentModal}
                size={sizeModal}
            />
        </div>
    )
}
