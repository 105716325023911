import { useState } from "react";
import {
  getColaboradoresApi,
  getColaboradoresLandingPageApi,
  AddColboradorApi,
  getActivosApi,
  deleteColboradorApi,
  updateColaboradorFirstTimeApi,
  addColaboradorDatosAdicionalesApi,
  updateColaboradorDatosAdicionalesApi,
  sendMailUpdateColaboraApi,
  getColaboradoresFiltroSectoresApi,
} from "../api/colaborador";
import { useAuth } from "./";

export function useColaborador() {
  const [loading, setloading] = useState(true);
  const [error, setError] = useState(false);
  const [colaboradores, setColaboradores] = useState(null);
  const [activos, setActivos] = useState(null);
  const { auth } = useAuth();

  const getColaboradores = async () => {
    try {
      setloading(true);
      const response = await getColaboradoresApi(auth.token);
      setloading(false);
      setColaboradores(response);
    } catch (error) {
      setloading(false);
      setError(error);
    }
  };

  const getColaboradoresLandingPage = async () => {
    try {
      setloading(true);
      const response = await getColaboradoresLandingPageApi();
      setloading(false);
      setColaboradores(response);
    } catch (error) {
      setloading(false);
      setError(error);
    }
  };

  const getColaboradoresFiltroSector = async (filtro) => {
    try {
      setloading(true);
      const response = await getColaboradoresFiltroSectoresApi(filtro);
      setloading(false);
      setColaboradores(response);
    } catch (error) {
      setloading(false);
      setError(error);
    }
  };

  const getActivos = async () => {
    try {
      setloading(true);
      const response = await getActivosApi();
      setloading(false);
      setActivos(response);
    } catch (error) {
      setloading(false);
      setError(error);
    }
  };

  const addColaborador = async (data, lastIdUser) => {
    try {
      setloading(true);
      const response = await AddColboradorApi(data, lastIdUser);
      setloading(false);
      return response
    } catch (error) {
      setloading(false);
      setError(error);
    }
  };

  const deleteColaborador = async (id) => {
    try {
      setloading(true);
      await deleteColboradorApi(id, auth.token);
      setloading(false);
    } catch (error){
      setError(error);
      setloading(false);
    }
  };

  const updateColaboradorFirstTime = async (data, id) => {
    try {
      setloading(true);
      await updateColaboradorFirstTimeApi(data, auth.token, id);
      setloading(false);
    } catch (error){
      setError(error);
      setloading(false);
    }
  };

  const addColaboradorDatosAdicionales = async (id) => {
    try {
      setloading(true);
      await addColaboradorDatosAdicionalesApi(id);
      setloading(false);
    } catch (error){
      setError(error);
      setloading(false);
    }
  };

  const updateColaboradorDatosAdicionales = async (data, idColaborador) => {
    try {
      setloading(true);
      const response = await updateColaboradorDatosAdicionalesApi(data, idColaborador, auth.token);
      setloading(false);
      setColaboradores(response);
    } catch (error) {
      setloading(false);
      setError(error);
    }
  };

  const sendMailFirstUpdateColaborador = async (data) => {
    try {
      console.log(data)
      await sendMailUpdateColaboraApi(data, auth.token);
    } catch (error) {
      setError(error);
    }
  };

  return {
    loading,
    error,
    colaboradores,
    activos,
    getColaboradores,
    getColaboradoresLandingPage,
    getActivos,
    getColaboradoresFiltroSector,
    addColaborador,
    deleteColaborador,
    updateColaboradorFirstTime,
    addColaboradorDatosAdicionales,
    updateColaboradorDatosAdicionales,
    sendMailFirstUpdateColaborador,
  };
}