import React from "react";
import { Row, Col } from "react-bootstrap";

import "./Privacidad.scss";
export function Privacidad() {
  return (
    <Row className="justify-content-center">
      <Col xs={8}>
        <h5 className="text-center mb-4">
          <strong>
            AVISO DE PRIVACIDAD INTEGRAL DEL OBSERVATORIO TECNOLÓGICO Y SISTEMAS
            DE INFORMACIÓN
          </strong>
        </h5>
        <h6>
          <strong>
            I. Denominación y domicilio del responsable Code Connect{" "}
          </strong>
        </h6>
        <p>
          Empresarial con domicilio ubicado en Lago Guanacacha 127-Int. 3, Agua
          Azul Sección Pirules, 57510 Nezahualcóyotl, C.P. 57510, reconoce el
          derecho fundamental a la protección de datos personales, con el
          propósito de garantizar la privacidad y la prerrogativa a la
          autodeterminación informativa de las personas, en consecuencia es
          responsable de la obtención, uso, registro, organización,
          conservación, elaboración, utilización, comunicación, difusión,
          almacenamiento, posesión, acceso, manejo, aprovechamiento,
          divulgación, transferencia, protección o disposición (tratamiento) de
          los datos personales que nos proporcione el titular de los mismos y/o
          representante legal y/o quien ejerce la patria potestad, los cuales
          serán protegidos conforme a lo dispuestos por la Ley General de
          Protección de Datos Personales en Posesión de Sujetos Obligados, Ley
          General de Transparencia y Acceso a la Información Pública, Ley de
          Protección de Datos Personales en Posesión de Sujetos Obligados para
          el Estado de México, y Ley de Transparencia y Acceso a la Información
          Pública para el Estado de México, actuando siempre en un marco
          irrestricto de respeto a los derechos humanos y al principio de
          interés superior del menor y demás normativa que resulte aplicable.
        </p>
        <h6>
          <strong>
            II. Finalidades del tratamiento de datos personales y datos
            personales sensibles tratados por OTS
          </strong>
        </h6>
        <div>
          El propósito del trato de datos por parte de OTS, se encuentra
          fundamentado la Ley Federal de protección de datos personales tiene
          los siguientes fines:
          <ul>
            <li className="mt-2">
              La investigación: Organizar, realizar, fomentar y orientar la
              investigación científica, humanística y tecnológica en sus formas
              básicas y aplicadas, de manera que permita la vinculación del
              conocimiento humano al ámbito productivo y responda a las
              necesidades del desarrollo integral de la sociedad.
            </li>
            <li className="mt-2">
              La vinculación: Relacionarse eficientemente y de manera ética con
              otras instituciones e instancias de la sociedad, empresas,
              proveedores, colaboradores con la finalidad de fortalecer el
              sector empresarial.
            </li>
            <li className="mt-2">
              El fomento de la legalidad, transparencia y protección de los
              derechos humanos: Realizar sus actividades conforme a las leyes
              fundamentales de la nación, promover la cultura de la legalidad y
              rendición de cuentas con transparencia.
            </li>
            <li className="mt-2">
              La promoción de la calidad y excelencia: Realizar todas sus
              actividades de planeación, operación y gestión administrativas,
              con la mayor calidad, susceptible de ser evaluada y comparada con
              indicadores de excelencia globales.
            </li>
          </ul>
          Para el cumplimiento de los fines aludidos, así como para la
          realización de trámites, servicios o gestiones el OTS podrá utilizar
          los siguientes datos:
          <Row className="border border-dark mt-1">
            <Col>TIPOS DE DATOS</Col>
            <Col>DESGLOSE</Col>
          </Row>
          <Row className="border border-dark">
            <Col>Identificación</Col>
            <Col>
              <ul className="privacidad-li">
                <li>Nombre</li>
                <li>Apellidos</li>
                <li>Domicilio</li>
                <li>Teléfono celular</li>
                <li>Teléfono fijo</li>
                <li>Correo electrónico</li>
                <li>Registro Federal de Contribuyentes (R.F.C)</li>
                <li>Clave Única de Registro de Población (C.U.R.P)</li>
                <li>Nacionalidad</li>
                <li>Edad</li>
                <li>Idioma/s</li>
                <li>Fotografías, videos y audios</li>
              </ul>
            </Col>
          </Row>
          <Row className="border border-dark mb-4">
            <Col>Laborales</Col>
            <Col>
              <ul className="privacidad-li">
                <li>Ocupación actual</li>
                <li>Domicilio del centro de trabajo</li>
                <li>Curriculum Vitae</li>
                <li>Carta de referencia laboral y/o académica</li>
                <li>Constancias de cursos, talleres o diplomados recibidos</li>
                <li>Cargo o nombramiento asignado</li>
                <li>Nivel de puesto</li>
                <li>Teléfono y extensión</li>
                <li>Institucional</li>
                <li>Domicilio del centro de trabajo</li>
              </ul>
            </Col>
          </Row>
          Para el cumplimento del aviso de privacidad y demás legislación
          aplicable en la materia, le informamos que los datos antes mencionados
          son obtenidos a través de las siguientes formas:
          <ul>
            <li className="mb-2">
              Personalmente: Cuando usted acude directamente a las oficinas de
              Code Connect para solicitar o contratar de nuestros trámites y
              servicios.
            </li>
            <li className="mb-2">
              Directa: Cuando nos proporciona sus datos personales para
              solicitar o contratar servicios a través de nuestra página en
              Internet, vía telefónica, correo electrónico o cualquier otro
              medio tecnológico.
            </li>
            <li className="mb-2">
              Indirecta: De cualquier otra fuente de información que sean
              permitidas por la Ley. El OBSERVATORIO TECNOLÓGICO Y SISTEMAS DE
              INFORMACIÓN le informa que los datos personales que se
              proporcionan y que contengan información sensible en términos de
              la normatividad aplicable, son de carácter confidencial por lo
              tanto no se distribuyen, difunden ni comercializan, recibiendo un
              tratamiento especial para su resguardo y protección.
            </li>
          </ul>
        </div>
        <h6>
          <strong>
            III. Fundamento legal que faculta el tratamiento de las finalidades
          </strong>
        </h6>
        <p>
          En cumplimiento del Artículo 1. La presente Ley es de orden público,
          interés social y observancia obligatoria en el Estado de México y sus
          Municipios. Es reglamentaria de las disposiciones en materia de
          protección de datos personales previstas en la Constitución Política
          del Estado Libre y Soberano de México.
        </p>
        <p>
          Artículo 9. Para los efectos de la presente Ley, se consideran fuentes
          de acceso público:
        </p>
        <p>
          I. Los portales informativos o medios remotos y locales de
          comunicación electrónica, óptica y de otra tecnología, siempre que el
          sitio donde se encuentren los datos personales esté concebido para
          facilitar información al público y esté abierto a la consulta general.
        </p>
        <h6>
          <strong>IV. Finalidades de las Transferencia</strong>
        </h6>
        <div className="mb-3">
          El OBSERVATORIO TECNOLÓGICO Y SISTEMAS DE INFORMACIÓN para cumplir las
          finalidades detalladas en el punto III de este instrumento legal, así
          como las referidas de conformidad a lo establecido en los artículos 1,
          5 y 6, 10, 11 de la Ley de Protección de Datos Personales en Posesión
          de Sujetos Obligados para el Estado de México, y 22, 66 y 70 de la Ley
          General de Protección de Datos Personales en Posesión de Sujetos
          Obligados o la requerida por autoridades competentes, transferirá solo
          aquellos datos personales indispensables en los términos que a
          continuación se citan:
          <ul>
            <li className="mt-2">
              Cuando una ley así lo disponga, debiendo dichos supuestos ser
              acordes con las bases, principios y disposiciones establecidas en
              la Ley de Protección de Datos Personales en Posesión de Sujetos
              Obligados para el Estado de México así como por los previstos en
              la Ley General de Protección de Datos Personales en Posesión de
              Sujetos Obligados, en ningún caso, podrán contravenirla.
            </li>
            <li className="mt-2">
              Cuando instituciones, organizaciones y compañías con las que se
              haya celebrado convenios generales y específicos de colaboración
              con la finalidad de ofertar los productos y servicios de las
              mismas.
            </li>
          </ul>
          Con el objeto de que pueda restringir la transferencia de sus datos
          personales para aquellos casos en que es necesario su consentimiento y
          no esté de acuerdo, podrá realizar su requerimiento al correo
          electrónico contacto@ots.mx Dicha solicitud debe de cumplir con lo
          estipulado en el siguiente punto.
        </div>
        <h6>
          <strong>
            V. Mecanismos, medios y procedimientos para el ejercicio de Derechos
            ARCO (Acceso, Rectificación, Cancelación y Oposición)
          </strong>
        </h6>

        <div>
          En todo momento usted o su representante legal podrán solicitar, el
          acceso, rectificación, cancelación u oposición (derechos ARCO) al
          tratamiento de los datos personales que le conciernen de conformidad
          con lo establecido en la ley en la materia.
          <ul className="privacidad-li mb-4">
            <li className="mt-2">
              <strong>I.</strong> Derecho de acceso: tiene derecho de acceder a
              sus datos personales que obran en nuestra posesión, así como
              conocer la información relacionada con las condiciones y
              generalidades de su tratamiento.
            </li>
            <li className="mt-2">
              <strong>II.</strong> Derecho de rectificación: posee el derecho a
              solicitarnos la rectificación o corrección de sus datos
              personales, cuando estos resulten ser inexactos, incompletos o no
              se encuentren actualizados.
            </li>
            <li className="mt-2">
              <strong>III.</strong> Derecho de cancelación: es su derecho
              solicitar la cancelación de sus datos personales de nuestros
              archivos, registros, expedientes y sistemas, a fin de que los
              mismos ya no estén en nuestra posesión y dejemos de tratarlos.
            </li>
            <li className="mt-2">
              <strong>IV.</strong> Derecho de oposición: finalmente también es
              su derecho oponerse al tratamiento de sus datos personales o
              exigir que cese el mismo.
            </li>
          </ul>
          <p>
            Para el ejercicio de los derechos antes señalados, usted deberá de
            cumplir con lo siguiente:
          </p>
          <div className="privacidad-ident1">
            1. Presentar solicitud ante la administración general del
            OBSERVATORIO TECNOLÓGICO Y SISTEMAS DE INFORMACIÓN:
          </div>
          <ul>
            <li>Escrito libre</li>
            <li>Correo electrónico: contacto@ots.mx</li>
            <li>Al teléfono: XXXX</li>
            <li>
              Por conducto de la Plataforma Nacional de
              Transparencia (http://www.plataformadetransparencia.org.mx/).
            </li>
          </ul>
          <div className="privacidad-ident2">
            <p>
              1.1 La solicitud deberá de contener los siguientes requisitos:
            </p>
            <ul>
              <li>
                Nombre del titular y domicilio o cualquier otro medio para
                recibir notificaciones.
              </li>
              <li>
                Los documentos que acrediten la identidad del titular y, en su
                caso, la personería e identidad de su representante legal.
              </li>
              <li>
                De ser posible el área responsable que trata los datos
                personales y ante cual se presenta la solicitud.
              </li>
              <li>
                La descripción clara y precisa de los datos personales respecto
                de los que se busca ejercer alguno de los derechos ARCO, salvo
                que se trate del derecho de acceso.
              </li>
              <li>
                La descripción del derecho ARCO que se pretende ejercer, lo que
                solicita el titular,
              </li>
              <li>
                Cualquier otro elemento o documento que facilite la localización
                de los datos personales.
              </li>
            </ul>
            <p>1.2 Dependiendo del tipo de solicitud debe considerar:</p>
            <ul>
              <li>
                Tratándose de una solicitud de acceso a datos personales, deberá
                señalar la modalidad en la que prefiere que éstos se
                reproduzcan.
              </li>
              <li>
                En solicitudes de rectificación de datos personales, deberá
                precisar las modificaciones a realizarse.
              </li>
              <li>
                Con relación a una solicitud de cancelación, deberá señalar las
                causas que lo motiven a solicitar la supresión de sus datos
                personales en los archivos, registros o bases de datos del
                responsable.
              </li>
              <li>
                En el caso de la solicitud de oposición, deberá manifestar las
                causas legítimas o la situación específica que lo llevan a
                solicitar el cese del tratamiento, así como el daño o perjuicio
                que le causaría la persistencia del tratamiento, o en su caso,
                las finalidades especificas respecto de las cuales requiere
                ejercer el derecho de oposición.
              </li>
            </ul>
            <p>
              1.3 Medios para acreditar identidad del titular e identidad y
              personalidad del representante legal.
            </p>
            <ul>
              <li>
                Titular por conducto de alguna identificación oficial vigente
                (de las referidas en el punto II; datos de identificación del
                presente aviso).
              </li>
              <li>
                Representante legal o apoderado legal mediante carta poder o
                instrumento notarial (de los referidos en el punto II; datos de
                identificación del presente aviso).
              </li>
              <li>
                Persona distinta a usted o su representante legal, en aquellos
                supuestos previstos por disposición legal, o en su caso, por
                mandato judicial.
              </li>
              <li>
                Respecto de datos personales concernientes a personas
                fallecidas, la persona que acredite tener un interés jurídico,
                de conformidad con las leyes aplicables, siempre que el titular
                de los derechos hubiere expresado su voluntad o que exista un
                mandato judicial.
              </li>
            </ul>
          </div>
          <div className="privacidad-ident1">
            2. Aclaración de la solicitud. En el caso de que la solicitud de
            protección de datos no satisfaga algunos de los requisitos antes
            señalados y no se cuente con elementos para subsanarla se le
            prevendrá para que en término de 5 días hábiles siguientes a la
            presentación de la solicitud, por una sola ocasión, subsane las
            omisiones dentro de un plazo de 10 días hábiles contados a partir
            del día siguiente al de la notificación. Transcurrido el plazo sin
            desahogar la prevención se tendrá por no presentada la solicitud de
            ejercicio de los derechos ARCO.
          </div>
          <div className="privacidad-ident1 my-3">
            3. Lapso de respuesta de solicitud. El plazo de respuesta no
            excederá de 20 días hábiles, contados a partir del día siguiente a
            la recepción de la solicitud. Plazo que podrá ser ampliado hasta por
            10 días hábiles cuando así lo justifiquen las circunstancias.
          </div>
        </div>
      </Col>
    </Row>
  );
}
