import React, { useMemo, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { map } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { FilterInput } from "../../../Common";
import "./EventosTable.scss";

export function EventosTable(props) {
  const { eventos, updateEvento, onDeleteEvento } = props;
  const [loading, setLoading] = useState(false);

  const modalidad = ['', 'En linea', 'Presencial']

  const changeFormat = async () => {
    map(eventos, (evento, index) => {
      evento.modalidad = modalidad[evento.modalidad];
    })
    setLoading(true)
  }

  useEffect(() => {
    changeFormat();
  }, [])

  const changeFormatOriginal = (evento) => {
    switch (evento.modalidad) {
      case 'En linea':
        evento.modalidad = 1;
        break;
      case 'Presencial':
        evento.modalidad = 2;
        break;
      default:
        break;
    }
    return evento;
  }

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      grow: 0,
      width: "50px"

    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
      grow: 0,
      compact: true,
      width: "70px"

    },
    {
      name: "Fecha de evento",
      selector: (row) => row.fecha_evento,
      sortable: true,
      grow: 0,
      compact: true

    },
    {
      name: "Hora de evento",
      selector: (row) => row.hora_evento,
      sortable: true,
      grow: 0,
      compact: true,
      width: "70px"

    },
    {
      name: "Autor",
      selector: (row) => row.autor,
      sortable: true,
      grow: 0,
      compact: true,
      width: "60px"
    },
    {
      name: "Keywords",
      selector: (row) => row.keywords,
      sortable: true,
      grow: 0,
      cell: (row) => <div style={{ textAlign: 'justify' }}>{row.keywords}</div>,
    },
    
    {
      name: "Modalidad",
      selector: (row) => row.modalidad,
      sortable: true,
      grow: 0,
      compact: true,
      width: "75px",

    },
    {
      name: "Sede",
      selector: (row) => row.sede,
      sortable: true,
      grow: 0,
      compact: false,
      width: "90px"


    },
    {
      name: "Calle",
      selector: (row) => row.calle,
      sortable: true,
      grow: 0,
      compact: true,
      width: "70px"

    },
    {
      name: "Numero",
      selector: (row) => row.numero,
      sortable: true,
      grow: 0,
      compact: true,
      width: "65px"
    },
    {
      name: "Colonia",
      selector: (row) => row.colonia,
      sortable: true,
      grow: 0,
      compact: true,
      width: "70px"
    },
    {
      name: "Ciudad",
      selector: (row) => row.ciudad,
      sortable: true,
      grow: 0,
      compact: true,
      width: "65px"
    },
    {
      name: "Pais",
      selector: (row) => row.pais.nombre,
      sortable: true,
      grow: 0,
      compact: true,
      width: "60px"
    },
    {
      name: "Codigo postal",
      selector: (row) => row.cp,
      sortable: true,
      grow: 0,
      compact: true,
      width: "80px"
    },
    {
      name: "url",
      selector: (row) => row.url,
      sortable: true,
      compact: true,
      width: "320px",
      cell: (row) => <div style={{ textAlign: 'justify' }}>{row.url}</div>,
    },    
    {
      name: "Acciones",
      button: true,
      width: "105px",
      cell: (row) => (
        <>
          <div>
            <Button
              size="sm"
              variant="success"
              onClick={() => updateEvento(changeFormatOriginal(row))}
              className="me-2 btn-boton"
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </div>
          <div>
            <Button
              size="sm"
              variant="danger"
              onClick={() => onDeleteEvento(changeFormatOriginal(row))}
              className="d-inline-block btn-boton"
            >
              <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
            </Button>
          </div>
        </>
      )
    },

  ]

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.eventos.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterInput
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      {loading && (
        <DataTable
          columns={columns}
          data={filteredItems}
          defaultSortField="name"
          striped
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
        />
      )}
    </>
  );

}
