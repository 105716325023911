import { BASE_API } from "../utils/constants";

export async function getBoletinesApi() {
    try {
        const url = `${BASE_API}/api/boletin/`;
        const response = await fetch(url);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    };
};

export async function addBoletinApi(data) {
    try {
        const formData = new FormData();
        formData.append("mesBoletin", data.mesBoletin);
        formData.append("boletin", data.boletin);
        const url = `${BASE_API}/api/boletin/`;
        const params = {
            method: "POST",
            headers: {},
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    };
};

export async function sendMailBoletinApi(data, token) {
    try {
        const url = `${BASE_API}/api/notificacionBoletin/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}
