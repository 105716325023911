import React from 'react';
import { Link } from "react-router-dom";
import { Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import "./BackButton.scss";

/*
    Este compomente lo considero incompleto, sin embargo, si lo necesito lo actualizaré,
    pero con lo que tiene funciona. 
    
    Su función en ir de una página a otra con un botón, y su sintaxis es: 

    <BackButton
        btnTitle = 
        link = 
      />

    Si consideran necesario actualizarlo pongan por favor sus actualizaciones, gracias :D.
*/

export function BackButton(props) {

    const { btnTitle, link, } = props;

    return (
        <div>
            <Row className="mb-6">
                <div>
                    <Link to={link}>
                        <Button variant="outline-primary">
                            <FontAwesomeIcon
                                icon={faArrowLeftLong}
                            />
                            {' '}
                            {btnTitle}
                        </Button>
                    </Link>
                </div>
            </Row>
        </div>
    )
}
