import React from "react";
import { map } from "lodash";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

import { formatDateMonthName } from "../../../../../metodosGlobales";
import "./TableTalentoespecializado.scss"

export function TableTalentoespecializadoCliente(props) {
  const { servicios, updatePeticionTalentoEspecializado, deletePeticionTalentoEspecializado } = props; 

  return (
    <div className="table-responsive-xl">
      <Table striped bordered hover className="table-servicio-proveedores table-sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Tipo de petición</th>
            <th>Sector</th>
            <th>Estatus</th>
            <th>Fecha de alta</th>
            <th>Fecha de termino</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {map(servicios, (servicio, index) => (
            <tr key={index}>
              <td>{servicio.id}</td>
              <td>{servicio.tipoPeticion['nombre']}</td>
              <td>{servicio.idSolicitudServicio.sectores['descripcion']}</td>
              <td>{servicio.idSolicitudServicio.estatus['nombre']}</td>
              <td>{formatDateMonthName(servicio.idSolicitudServicio['fechaAlta'])}</td>
              <th>{
                servicio.idSolicitudServicio['fechaTermino']
                  ? formatDateMonthName(servicio.idSolicitudServicio['fechaTermino'])
                  : "----/--/--"
              }</th>
              <Actions
                servicio={servicio}
                updateServicio = {updatePeticionTalentoEspecializado}
                onDeleteServicio = {deletePeticionTalentoEspecializado}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function Actions(props) {
  const { servicio, updateServicio, onDeleteServicio } = props;
  return (
    <td>
      <Button
        size="sm"
        variant="success"
        className="table-users-new__boton"
        onClick={() => updateServicio(servicio)}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Button>
      <Button
        size="sm"
        variant="danger"
        className="table-users-new__boton"
        onClick={() => onDeleteServicio(servicio)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </Button>
    </td>
  );
}
