import { useState } from "react";
import { useAuth } from "./";
import {
  getEventosApi,
  getEventosTopApi,
  getEventoBuscadorApi,
  getEventoBuscadorTopApi,
  addEventoApi,
  updateEventoApi,
  deleteEventoApi,
} from "../api/eventos";

export function useEventos() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [eventos, setEventos] = useState(null);
  const { auth } = useAuth();

  const getEventos = async () => {
    try {
      setLoading(true);
      const response = await getEventosApi();
      setLoading(false);
      setEventos(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getEventosBuscadorTop = async (filtro) => {
    try {
      setLoading(true);
      const response = await getEventoBuscadorTopApi(filtro);
      setLoading(false);
      setEventos(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getEventosBuscador = async (filtro) => {
    try {
      setLoading(true);
      const response = await getEventoBuscadorApi(filtro);
      setLoading(false);
      setEventos(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getEventosTop = async () => {
    try {
      setLoading(true);
      const response = await getEventosTopApi();
      setLoading(false);
      setEventos(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const addEvento = async (data) => {
    try {
      setLoading(true);
      await addEventoApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updateEvento = async (id, data) => {
    try {
      setLoading(true);
      await updateEventoApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const deleteEvento = async (id) => {
    try {
      setLoading(true);
      await deleteEventoApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    loading,
    error,
    eventos,
    getEventos,
    getEventosTop,
    getEventosBuscador,
    getEventosBuscadorTop,
    addEvento,
    updateEvento,
    deleteEvento,
  };
}
