import React from "react";
import { map } from "lodash";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

import { formatDate } from "../../../../../metodosGlobales/FormatoFecha";

export function TableWebinarsOperativo(props) {

  const { onDeleteWebinars, updateWebinars, webinars } = props

  return (
    <div className="table-responsive-xl">
      <Table striped bordered hover className="table-users-new table-sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Titular</th>
            <th>Autor / Institución</th>
            <th>Sector</th>
            <th>Fecha de alta</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {map(webinars, (webinar, index) => (
            <tr key={index}>
              <td>{webinar.id}</td>
              <td>{webinar.tituloWebinar}</td>
              <td>{webinar.autorWebinar}</td>
              <th>{webinar.link}</th>
              <td>{formatDate(webinar.fecha_alta)}</td>
              <Actions
                webinar={webinar}
                updateWebinars={updateWebinars}
                onDeleteWebinars={onDeleteWebinars}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function Actions(props) {
  const { webinar, updateWebinars, onDeleteWebinars } = props;
  return (
    <td>
      <Button
        size="sm"
        variant="success"
        className="table-webinar-admin__boton btn-boton"
        onClick={() => updateWebinars(webinar)}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Button>
      <Button
        size="sm"
        variant="danger"
        className="table-webinar-admin__boton btn-boton"
        onClick={() => onDeleteWebinars(webinar)}
      >
        <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
      </Button>
    </td>
  );
}
