import {
    faMoneyBill
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { FilterInput } from "../../../Common";

export function TableAgendas(props) {

    const columns = [
        {
            name: "N° sitio web",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Logo",
            cell: (row) => (
                <img src={row.id_micrositio_cliente.logo} style={{ width: 50, height: 50 }} />
            ),
        },
        {
            name: "Nombre",
            selector: (row) => row.id_micrositio_cliente.nombre_micrositio,
        },
        {
            name: "Teléfono de contacto",
            selector: (row) => row.id_micrositio_cliente.telefono,
        },
        {
            name: "Mesa asignada",
            selector: (row) => row.mesa_asignada,
        },
        {
            name: "Ver agenda",
            button: true,
            cell: (row) => (
                <Link to={`/b2b/${props.tipoUsuario}/agendas/citas-${row.id_micrositio_cliente.id}`}>
                    <Button
                        size="sm"
                        variant="secondary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ver Pagos"
                        onClick={() => console.log(row)}
                    >
                        <FontAwesomeIcon icon={faMoneyBill} />
                    </Button>
                </Link >
            ),
        },
    ];

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = props.data?.filter(
        (item) =>
            JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
            -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterInput
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <DataTable
            title="Lista de agendas por sitio web"
            columns={columns}
            data={filteredItems}
            defaultSortField="name"
            striped
            pagination
            subHeader
            subHeaderComponent={subHeaderComponent}
        />
    );
}