import React, { useState, useEffect } from "react";
import {
  TableAyudaPagos,
  HeaderPage,
  FormAyudaPago,
  DeleteAyudaPagos,
} from "../../../components/Admin";
import { Spinner } from "react-bootstrap";
import { ModalBasic } from "../../../components/Common";
import {
  faUserPen,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useCatalagos, useAyuda, useAuth } from "../../../hooks";

export function AyudaPagos() {
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("g");
  const { auth } = useAuth();

  const { servicios, getTipoServicio } = useCatalagos();
  const { loading, pagosayuda, getAyudaPagos, pagosuser, getPagosClienteFiltro } = useAyuda();

  useEffect(() => {
    getTipoServicio();
    if (auth.me.role === 5) {
      getPagosClienteFiltro(auth.me.id);
    } else {
      getAyudaPagos();
    }
  }, [refetch]);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateAyudaPagos = (data) => {
    setTitleModal("Actualizar Registro de Ayuda");
    setIconModal(faEdit);
    setContentModal(
      <FormAyudaPago
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ayudapagos={data}
      />
    );
    openCloseModal();
  };

  const onDeleteAyudaPagos = async (data) => {
    setTitleModal("Eliminar Registro de Ayuda");
    setIconModal(faTrash);
    setsizeModal("s");
    setContentModal(
      <DeleteAyudaPagos
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ayudapagos={data}
      />
    );
    openCloseModal();
  };

  const addAyuda = () => {
    setTitleModal(" Solicitar Ayuda en Pagos");
    setIconModal(faUserPen);
    setsizeModal("xl");
    setContentModal(
      <FormAyudaPago
        onClose={openCloseModal}
        onRefetch={onRefetch}
        tiposervicio={servicios}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <HeaderPage
        title="Ayuda Pagos"
        btnTitle="Solicitar Ayuda"
        btnClick={addAyuda}
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner className="spinner" animation="border" />
        </div>
      ) : (

        <TableAyudaPagos
          ayudapagos={auth.me.role === 5 ? pagosuser : pagosayuda}
          updateAyudaPagos={updateAyudaPagos}
          onDeleteAyudaPagos={onDeleteAyudaPagos}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}

      />
    </>
  );
}