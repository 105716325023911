import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faCircleCheck,
  faCircleXmark,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { FormFacturacion } from "../../../../components/Client";
import { ModalBasic } from "../../../../components/Common";
import "./DetalleSuccess.scss";
import { usePagos, useAuth, useCatalagos } from "../../../../hooks";

export function DetalleSuccess(props) {
  const { clave } = props;
  const nav = useNavigate();

  const { loading, addConfirmPago, datosFactura, getDatosFactura } = usePagos();
  const { getRegimenFiscal, regimenFiscal } = useCatalagos()
  const { logout } = useAuth();

  const [respuesta, setRespuesta] = useState("");
  const [estatus, setEstatus] = useState("");
  const [clienteId, setClienteId] = useState("");

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);

  const openCloseModal = () => setshowModal((prev) => !prev);

  const salirSesion = () => {
    logout();
    nav("/");
  };

  useEffect(() => {
    addConfirmPago(2, clave).then((data) => {
      setRespuesta(data.respuesta);
      setEstatus(data.estatus);
      if (parseInt(data.estatus) === 1) {
        setClienteId(data.id);
        getRegimenFiscal();
      }
      if (parseInt(data.estatus) === 3) {
        getDatosFactura(data.id);
      }
    });// eslint-disable-next-line
  }, []);

  const addFacturacion = () => {
    setTitleModal("Datos de facturación");
    setIconModal(faEdit);
    setContentModal(
      <FormFacturacion
        onClose={openCloseModal}
        clienteId={clienteId}
        regimenFiscal={regimenFiscal}
      />
    );
    openCloseModal();
  };

  const updateFacturacion = () => {
    setTitleModal("Actualizar datos de facturación");
    setIconModal(faEdit);
    setContentModal(
      <FormFacturacion
        onClose={openCloseModal}
        clienteId={clienteId}
        datosFactura={datosFactura}
      />
    );
    openCloseModal();
  };

  return (
    <>
      {loading ? (
        <Row className="justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              Obteniendo informacion .....
            </span>
          </Spinner>
        </Row>
      ) : (
        <Row className="success justify-content-center">
          <Col xs={6}>
            <Card>
              <Card.Header
                variant="top"
                className="success-header text-center pt-4"
              >
                <h4>Resultado de Pago</h4>
              </Card.Header>
              <Card.Body>
                {estatus === 1 && (
                  <>
                    <Card.Title>{respuesta}</Card.Title>
                    <Card.Text>
                      Le damos la bienvenida al servicio especializado del
                      Observatorio tecnológico, a partir de ahora podra contar
                      con los servicios de:
                    </Card.Text>
                    <ul className="my-4">
                      <li>Boletines especializados</li>
                      <li>Solicitud de servicios</li>
                      <li>Atención especializada de nuestros colaboradores</li>
                    </ul>
                    <Card.Text>
                      Hemos enviado un mail con las instrucciones para acceder a
                      nuestro servicios.
                    </Card.Text>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="success-icon"
                      />
                    </div>
                  </>
                )}
                {(estatus === 2 || estatus === 3) && (
                  <>
                    <Card.Title>{respuesta}</Card.Title>
                    <Card.Text>
                      Hemos recibido su pago, muchas gracias por renovar con
                      nosotros le recordamos que puede contar con los servicios
                      de:
                    </Card.Text>
                    <ul className="my-4">
                      <li>Boletines especializados</li>
                      <li>Solicitud de servicios</li>
                      <li>Atención especializada de nuestros colaboradores</li>
                    </ul>
                    <Card.Text>
                      A continuacion puede agregar o modificar los datos de
                      facturación o regresar al menu principal
                    </Card.Text>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="success-icon"
                      />
                    </div>
                  </>
                )}
                {estatus === 99 && (
                  <>
                    <Card.Title>El token Utilizado ya esta vencido</Card.Title>
                    <Card.Text>
                      Hemos detectado que intenta ingresar con una clave ya
                      antes utilizada por favor envianos una solicitud a traves
                      de la seccion de contactanos para revisar su problema
                    </Card.Text>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="success-icon"
                      />
                    </div>
                  </>
                )}
                {estatus === 98 && (
                  <>
                    <Card.Title>El cliente no existe</Card.Title>
                    <Card.Text>
                      No hemos encontrado el cliente en nuestro sistema si tiene
                      dudas de accesos por favor envienos una solicitud a traves
                      de la seccion de contactanos para revisar su problema
                    </Card.Text>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="success-icon"
                      />
                    </div>
                  </>
                )}
              </Card.Body>
              {estatus === 1 && (
                <div className="w-100 text-center">
                  <Button
                    variant="info"
                    className="my-2 w-50"
                    onClick={addFacturacion}
                  >
                    Agregar datos para factura
                  </Button>
                </div>
              )}
              {(estatus === 2 || estatus === 3) && (
                <div className="w-100 text-center">
                  <Button
                    variant="info"
                    className="my-2 w-50"
                    onClick={updateFacturacion}
                  >
                    Modificar datos para factura
                  </Button>
                </div>
              )}
              {(estatus === 1 || estatus === 2 || estatus === 3) && (
                <div className="w-100 text-center">
                  <Button
                    variant="info"
                    className="mb-5 w-50"
                    onClick={salirSesion}
                  >
                    Regresar al menu principal
                  </Button>
                </div>
              )}
            </Card>
          </Col>

          <ModalBasic
            show={showModal}
            onClose={openCloseModal}
            icon={iconModal}
            title={titleModal}
            children={contentModal}
            size="xl"
          />
        </Row>
      )}
    </>
  );
}
