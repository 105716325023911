import React, { useState, useMemo } from "react";
import { FilterInput } from "../../../Common";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

import "./TableIndicadores.scss";

export function TableIndicadoresAdmins(props) {
  const { indicadores, updateIndicador, onDeleteIndicador } = props;
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "60px"
    },
    {
      name: "Icono",
      selector: (row) => row.icono,
      sortable: true,
      compact: true,
      width: "100px"
    },
    {
      name: "Titulo",
      selector: (row) => row.tituloIndicador,
      sortable: true,
      compact: true,
      width: "110px"
    },
    {
      name: "Fecha Modificacion",
      selector: (row) => row.fechaModificacion,
      sortable: true,
      compact: true,
      width: "150px",
      cell: (row) => (
        <span>{formatDate(row.fechaModificacion)}</span>
      )
    },
    {
      name: "Detalle",
      selector: (row) => row.detalle,
      sortable: true,
      compact: true,
      width: "90px"
    },
    {
      name: "Link",
      selector: (row) => row.linkIndicador,
      sortable: true,
      grow: 3
    },
    {
      name: "Palabras Clave",
      selector: (row) => row.palabrasClave,
      sortable: true,

    },
    {
      name: "Sector",
      selector: (row) => row.sectores,
      sortable: true,
      width: "100px"
    },
    {
      name: "Editar o Eliminar",
      button: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="success"
            onClick={() => updateIndicador(row)}
            className="table-indicadores-admin__boton btn-boton"
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => onDeleteIndicador(row)}
            className="table-indicadores-admin__boton btn-boton"
          >
            <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
          </Button>
        </>
      )
    }
  ]

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.indicadores.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterInput
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      {loading && (
        <DataTable
          columns={columns}
          data={filteredItems}
          defaultSortField="name"
          striped
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
        />
      )}
    </>
  );
}

function formatDate(date) {
  const dateConvert = date.split(/([-TZ])+/);
  return `${dateConvert[4]}/${dateConvert[2]}/${dateConvert[0]}`;
}
