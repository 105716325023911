import React, { useEffect } from "react";
import { initAOS } from "../../../Common/AOS";
import "./ColaboradorPage.scss";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faMagnifyingGlassChart,
  faRankingStar,
  faCircleDollarToSlot,
  faArrowUpRightDots,
  faPeopleLine,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as IconContact } from "../../../../images/icon-contacto.svg";
import { ReactComponent as IconFormulario } from "../../../../images/icon-formulario.svg";
import { ReactComponent as IconInformar } from "../../../../images/icon-informar.svg";
import { ReactComponent as IconSomos } from "../../../../images/icon-somos.svg";

export function ColaboraPage(props) {
  const { onAdd, activos } = props;
  useEffect(() => {
    initAOS();
  }, []);

  return (
    <div className="colabora">
      <Container>
        <h2 className="colabora-banner-tit animacionSomn">
          ¡Gracias por tu interés!
        </h2>
        <div className="colabora__descripcion text-center" data-aos="fade-up">
          <Container>
            <Row className="justify-content-center">
              <Col sm={7}>
                <h2 className="colabora__titulo ">¿Por qué ser parte?</h2>
                <p className="colabora-banner-text">
                  Las redes de colaboración en ciencia y tecnología son ejes
                  fundamentales para el crecimiento de las empresas, las
                  instituciones y las personas.​
                </p>
                <p className="colabora-banner-text">
                  Apostamos por generar inteligencia colectiva que acerque a las
                  empresas soluciones efectivas para la toma de decisiones.​
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="colabora-cardBen">
          <h2 className="colabora-banner-tit animacionSomn">
            Conoce tus beneficios:​ ​
          </h2>
          <Row className="pt-3">
            <Col>
              <Card
                data-aos="flip-right"
                data-aos-delay="200"
                border="light animacionSomn-cards"
              >
                <Card.Body>
                  <Card.Title>
                    <FontAwesomeIcon
                      icon={faMagnifyingGlassChart}
                      className="colabora__iconCardBen"
                    />
                  </Card.Title>
                  <Card.Subtitle>Productividad</Card.Subtitle>
                  <Card.Text className="colabora__text">
                    ​Aumentar su actividad y participación en i+D
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                data-aos="flip-right"
                data-aos-delay="200"
                border="light animacionSomn-cards"
              >
                <Card.Body>
                  <Card.Title>
                    <FontAwesomeIcon
                      icon={faRankingStar}
                      className="colabora__iconCardBen"
                    />
                  </Card.Title>
                  <Card.Subtitle>Reconocimiento</Card.Subtitle>
                  <Card.Text className="colabora__text">
                    Reconocimiento en sus áreas de investigación
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                data-aos="flip-left"
                data-aos-delay="200"
                border="light animacionSomn-cards2"
              >
                <Card.Body>
                  <Card.Title>
                    <FontAwesomeIcon
                      icon={faCircleDollarToSlot}
                      className="colabora__iconCardBen"
                    />
                  </Card.Title>
                  <Card.Subtitle>Ingresos</Card.Subtitle>
                  <Card.Text className="colabora__text">
                    Ingresos extras a su actividad de investigación
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                data-aos="flip-left"
                data-aos-delay="200"
                border="light animacionSomn-cards2"
              >
                <Card.Body>
                  <Card.Title>
                    <FontAwesomeIcon
                      icon={faArrowUpRightDots}
                      className="colabora__iconCardBen"
                    />
                  </Card.Title>
                  <Card.Subtitle>Impulso</Card.Subtitle>
                  <Card.Text className="colabora__text">
                    Impulso de sus proyectos de cooperación tecnológica e
                    innovación
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="colabora__como">
          <h2 className="colabora-banner-tit animacionSomn">
            ¿Cómo ser parte?​ ​
          </h2>
        </div>
        <Row className="mb-5">
          <Col>
            <div
              className="card-horizontal colabora-card-registro animacionSomn-hover"
              data-aos="fade-right"
            >
              <div className="img-square-wrapper">
                <IconFormulario fill="" />
              </div>
              <div className="card-body">
                <h4 className="colabora-card-titulo vertical-center letra-azul">
                  Llena el{" "}
                  <Button onClick={onAdd} className="colabora-boton">
                    Registro
                  </Button>
                </h4>
              </div>
            </div>
          </Col>
       
          <Col>
            <div
              className="card-horizontal colabora-card-contacto animacionSomn-hover"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="img-square-wrapper">
                <IconContact fill="" />
              </div>
              <div className="card-body">
                <h4 className="colabora-card-titulo vertical-center">
                  Nos pondremos en contacto
                </h4>
              </div>
            </div>
          </Col>
          
          <Col>
            <div
              className="card-horizontal colabora-card-alta animacionSomn-hover"
              data-aos="fade-left"
            >
              <div className="img-square-wrapper">
                <IconInformar fill="" />
              </div>
              <div className="card-body">
                <h4 className="colabora-card-titulo vertical-center">
                  Damos de alta tu perfil
                </h4>
              </div>
            </div>
          </Col>
        </Row>
 

        <Row className="justify-content-center my-5">
          <Col md={5}>
            <div className="card-horizontal-end animacionSomn-hover" data-aos="zoom-in-down">
              <div className="content-center">
                <h2 className="titulo">
                  Ya somos <span className="marcador">{activos}</span>
                  <div className="botonConoceRed">
                    <Link to="/colabora/red">
                      <Button className="boton-red">Conoce la Red</Button>
                    </Link>
                  </div>
                </h2>
              </div>
              <div className="content-aling-end">
                <IconSomos />
              </div>
            </div>
          </Col>
        </Row>
        

      </Container>
    </div>
  );
}
