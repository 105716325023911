import React, { useEffect, useState } from "react";
import { Row, Spinner } from "react-bootstrap";

import {
  HeaderPage,
  EventosTable,
  DeleteEventoForm,
} from "../../../components/Admin";
import { useEventos, useCatalagos } from "../../../hooks";
import { ModalBasic } from "../../../components/Common";
import {
  faNewspaper,
  faEdit,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";

import { AddEditEventoform } from "../../../components/Admin";
export function Eventos() {
  const { loading, eventos, getEventos } = useEventos();
  const { paises, getPaises } = useCatalagos();
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("lg");

  useEffect(() => {
    getEventos();
    getPaises();
  }, [refetch]);


  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateEventos = (data) => {
    setTitleModal("Actualizar evento");
    setIconModal(faEdit);
    setContentModal(
      <AddEditEventoform
        onClose={openCloseModal}
        onRefetch={onRefetch}
        evento={data}
        paises={paises}
      />
    );
    openCloseModal();
  };

  const onDeleteEvento = async (data) => {
    setTitleModal("Eliminar Evento");
    setIconModal(faDeleteLeft);
    setContentModal(
      <DeleteEventoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        evento={data}
      />
    );
    openCloseModal();
  };


  const addNew = () => {
    setTitleModal("Crear Evento");
    setIconModal(faNewspaper);
    setsizeModal("xl");
    setContentModal(
      <AddEditEventoform
        paises={paises}
        onClose={openCloseModal}
        onRefetch={onRefetch}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <Row className="mb-3">
        <HeaderPage title="Eventos" btnTitle="Nuevo Evento" btnClick={addNew} />
      </Row>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner className="spinner" animation="border" />
        </div>
      ) : (

        <Row className="horizontal-scrollable">
          <EventosTable
            eventos={eventos}
            updateEvento={updateEventos}
            onDeleteEvento={onDeleteEvento}
          />
        </Row>
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
