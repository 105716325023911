import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CatalogosB2B.scss';
import { AddEditCatalogoForm, TableCatalogos } from "../../../components/Admin";
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';

export function CatalogosB2B() {
  const [categories, setCategories] = useState([]);

  const handleCategoryAdd = (newCategory) => {
    setCategories([...categories, newCategory]);
  };
  const handleCategoryDelete = (index) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(index, 1);
    setCategories(updatedCategories);
  };
  
  return (
    <div className="catalogos-container">
        <h1>
          Catálogos B2B{' '}
          <FontAwesomeIcon icon={faFolderPlus} className="catalogos-icon" />
        </h1>
      <div style={{ marginBottom: '50px' }}>
        <AddEditCatalogoForm onCategoryAdd={handleCategoryAdd} />
      </div>
      <TableCatalogos categories={categories} onDelete={handleCategoryDelete} />

    </div>
  );
  
}
