import React from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faIndustry } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks";
import "./TopMenu.scss";

export function TopMenu(props) {
  const { auth, logout } = useAuth();

  const nav = useNavigate();
  const renderName = () => {
    if (auth.me?.first_name && auth.me?.last_name) {
      return `${auth.me.first_name.toUpperCase()} ${auth.me.last_name.toUpperCase()}`;
    }
    return auth.me?.email;
  };

  const letterAvatar = () => {
    if (auth.me?.first_name && auth.me?.last_name) {
      return Array.from(auth.me.first_name)[0].toUpperCase();
    }
    return Array.from(auth.me.email)[0].toUpperCase();
  };

  const regresar = () => {
    logout();
    nav("/");
  };

  return (
    <Navbar bg="light" variant="light" className="top-menu-admin">
      <Nav className="justify-content-end w-50 ">
        <NavDropdown
          title={
            <>
              <FontAwesomeIcon icon={faIndustry} className="me-1" /> Modulo Marketing
            </>
          }
          className="text-white"
        >
          <NavDropdown.Item as={Link} to="/admin/marketing/clientes" className="font-media">
            Clientes
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/marketing/contactos" className="font-media">
            Contactos
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/marketing/segmentos" className="font-media">
            Segmentos
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/marketing/campania" className="font-media">
            Campañas
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/marketing/plantillas" className="font-media">
            Plantillas
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Navbar.Toggle />
      <Container>
        <Navbar.Collapse className="justify-content-end">
          <div className="avatar">{letterAvatar()}</div>
          <div className="name-descrip">{renderName()}</div>
          <Nav.Item>
            <FontAwesomeIcon icon={faRightFromBracket} />
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={regresar} className="cerrar-sesion">
              CERRAR SESIÓN
            </Nav.Link>
          </Nav.Item>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    /*
    <Menu fixed="top" borderless className="top-menu-admin">
      <Menu.Item className="top-menu-admin__logo">
        <p>Logo</p>
      </Menu.Item>

      <Menu.Menu position="right">
        <Menu.Item>
          <div className="avatar">{letterAvatar()}</div>
          <div className="name-descrip">{renderName()}</div>
        </Menu.Item>
        <Menu.Item onClick={logout}>
          <div className="cerrar-sesion">Cerrar sesion</div>
          <Icon name="sign-out"></Icon>
        </Menu.Item>
      </Menu.Menu>
    </Menu>*/
  );
}
