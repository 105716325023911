import React, { useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "lodash";
import {
    faArrowUpRightFromSquare,
    faChartArea,
    faChartLine,
    faChartPie,
    faChartSimple,

} from "@fortawesome/free-solid-svg-icons";
import "./IndicadorVista.scss";
import { useIndicadores } from "../../../../hooks";
import { TituloPrincipal } from "../../../Common";

export function IndicadorVistaPage(props) {
    const { indicadorID } = props;
    const { indicadorId, getIndicadorId } = useIndicadores();


    useEffect(() => {
        getIndicadorId(indicadorID);

    }, [indicadorID]);

    const tipoIcon = (icono) => {
        switch (icono) {
            case "faChartArea":
                return faChartArea;
            case "faChartLine":
                return faChartLine;
            case "faChartBar":
                return faChartSimple;
            default:
                return faChartPie;
        }
    };

    return (
        <div className="indicador-page ">
            <TituloPrincipal titulo={"Indicadores"} />
            <Row className="justify-content-center indicador-page-card">
                <Col md={11}>
                    <Card className="card-container">
                        <Row className="card-header">
                            <h1 className="card-title justify-content-center">
                                {indicadorId.tituloIndicador}</h1>
                        </Row>


                        <div className="card-body">
                            <Row>
                                <h6>
                                    <p className="card-text">{indicadorId.descripcionAmplia}</p>
                                </h6>
                            </Row>

                            <Row className="justify-content-center mb-3">


                            </Row>

                            <Row>
                                <Col className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <Button variant="btn btn-danger" size="md">
                                        PDF
                                    </Button>{' '}
                                    <Button variant="btn btn-success">
                                        Desacargable
                                    </Button>{' '}
                                </Col>
                            </Row>
                        </div>

                    </Card>
                </Col>
            </Row>

        </div >
    );
}
