import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCircleCheck,
  faCircleXmark,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { useCatalagos, usePlanPago, useAuth } from "../../../hooks";
import { ModalBasic } from "../../../components/Common/";
import { Renovacion } from "../../../components/Client/Planes";

export function RenuevaMembresia(props) {
  const { usuario } = props;
  const [showModal, setshowModal] = useState(false);
  const [iconModal, setIconModal] = useState(null);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("sm");
  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const { getSectores, sectores, getPaises, paises } = useCatalagos();
  const { getClienteIdUser, clienteIdUser } = usePlanPago();
  const { logout } = useAuth();

  useEffect(() => {
    getSectores();
    getPaises();
    getClienteIdUser(usuario.id);
  }, [refetch]);

  const addRenovacion = () => {
    setTitleModal("Renovacion de Plan");
    setIconModal(faCircleCheck);
    setsizeModal("xl");
    setContentModal(
      <Renovacion
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        paises={paises}
        usuario={usuario}
        cliente={clienteIdUser}
      />
    );
    openCloseModal();
  };

  const regresar = () => {
    logout();
    window.open("/", "_self");
  };

  return (
    <Row className="success justify-content-center">
      <Col xs={6}>
        <Card>
          <Card.Header
            variant="top"
            className="success-header text-center pt-4"
          >
            <h4>Cuenta Vencida</h4>
          </Card.Header>
          <Card.Body>
            <>
              <Card.Title>
                ¡Lo sentimos, tu cuenta se encuentra suspendida!
              </Card.Title>
              <Card.Text>
                Si deseas seguir disfrutando de los beneficios que el OT's pone
                a tu disposicion, Pudes hacerlo realizando el pago vencido
              </Card.Text>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="success-icon"
                />
              </div>
            </>
          </Card.Body>
          <div className="w-100 text-center">
            <Button variant="info" className="my-5 me-4" onClick={regresar}>
              Cancelar
            </Button>
            <Button variant="info" className="my-5" onClick={addRenovacion}>
              Renovar Membresia
            </Button>
          </div>
        </Card>
      </Col>
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </Row>
  );
}
