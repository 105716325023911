import React from "react";
import { Form } from "react-bootstrap";

import "./IngresaDatosForm.scss";

/*
  Formato para usar el componente
    <IngresaDatosForm
      col= // NOTA si utilizas un row y necesitas columnas usa este
      tituloLabel=
      formatoLabel= // tituloIzquierda || tituloCentrado || tituloDerecha || tituloJustificado || tituloRecortado
      nameControl= // Nota Si no hay un nameControl solo se pone un título
      type = 
      rows = // NOTA solo si la caja de texto es mayor a un renglón poner aqui la cantidad de renglones
      placeHolderControl=
      valueControl=
      onChangeControl=
      error= // NOTA solo si tienes un error asignado al formulario
      touched= // NOTA solo si tienes un error
      rounded = // Si quieres que el input sea redondeado aplicalo
    />
*/
export function IngresaDatosForm(props) {
  const {
    col,
    tituloLabel,
    formatoLabel,
    nameControl,
    type,
    placeHolderControl,
    valueControl,
    onChangeControl,
    rows,
    error,
    touched,
    disabled,
    floating,
    rounded,
    acceptControl,
  } = props;

  function formularioConColsRowsYnameControl() {
    return (
      <Form.Group className={col}>
        <Form.Label className={formatoLabel}>{tituloLabel}</Form.Label>
        <Form.Control
          name={nameControl}
          type={type}
          as="textarea"
          accept={acceptControl}
          placeholder={placeHolderControl}
          rows={rows}
          value={valueControl}
          onChange={onChangeControl}
          disabled={disabled}
          style={{ borderRadius: rounded || "0px" }}
        />
        <Form.Text className="text-danger">{touched && error ? <div className="text-danger">{error}</div> : null}</Form.Text>
      </Form.Group>
    );
  }

  function formularioFloatColsRowsYnameControl() {
    return (
      <Form.Group className={col}>
        <Form.FloatingLabel className={formatoLabel}>{tituloLabel}</Form.FloatingLabel>
        <Form.Control
          name={nameControl}
          type={type}
          placeholder={placeHolderControl}
          accept={acceptControl}
          rows={rows}
          value={valueControl}
          onChange={onChangeControl}
          disabled={disabled}
          style={{ borderRadius: rounded || "0px" }}
        />
        <Form.Text className="text-danger">{touched && error ? <div className="text-danger">{error}</div> : null}</Form.Text>
      </Form.Group>
    );
  }

  function formularioConColsYnameControl() {
    return (
      <Form.Group className={col}>
        <Form.Label className={formatoLabel}>{tituloLabel}</Form.Label>
        <Form.Control
          name={nameControl}
          type={type}
          placeholder={placeHolderControl}
          accept={acceptControl}
          value={valueControl}
          onChange={onChangeControl}
          disabled={disabled}
          style={{ borderRadius: rounded || "0px" }}
        />
        <Form.Text className="text-danger">{touched && error ? <div className="text-danger">{error}</div> : null}</Form.Text>
      </Form.Group>
    );
  }
  function formularioConCols() {
    return (
      <Form.Group className={col}>
        <Form.Label className={formatoLabel}>{tituloLabel}</Form.Label>
      </Form.Group>
    );
  }

  function formularioRowsYnameControl() {
    return (
      <Form.Group>
        <Form.Label className={formatoLabel}>{tituloLabel}</Form.Label>
        <Form.Control
          name={nameControl}
          type={type}
          as="textarea"
          accept={acceptControl}
          placeholder={placeHolderControl}
          rows={rows}
          value={valueControl}
          onChange={onChangeControl}
          disabled={disabled}
          style={{ borderRadius: rounded || "0px" }}
        />
        <Form.Text className="text-danger">{touched && error ? <div className="text-danger">{error}</div> : null}</Form.Text>
      </Form.Group>
    );
  }

  function formularioConNameControl() {
    return (
      <Form.Group>
        <Form.Label className={formatoLabel}>{tituloLabel}</Form.Label>
        <Form.Control
          name={nameControl}
          type={type}
          accept={acceptControl}
          placeholder={placeHolderControl}
          value={valueControl}
          onChange={onChangeControl}
          disabled={disabled}
          style={{ borderRadius: rounded || "0px" }}
        />
        <Form.Text className="text-danger">{touched && error ? <div className="text-danger">{error}</div> : null}</Form.Text>
      </Form.Group>
    );
  }
  function formulario() {
    return (
      <Form.Group>
        <Form.Label className={formatoLabel}>{tituloLabel}</Form.Label>
      </Form.Group>
    );
  }

  if (col)
    if (nameControl)
      if (rows) return formularioConColsRowsYnameControl();
      else if (floating) return formularioFloatColsRowsYnameControl();
      else return formularioConColsYnameControl();
    else return formularioConCols();
  else if (nameControl)
    if (rows) return formularioRowsYnameControl();
    else return formularioConNameControl();
  else return formulario();
}
