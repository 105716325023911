// Los datos estaticos son un complemento a las tablas catalagos 
// faltantes

export class datosEstaticosOTS{
    static estatusColaborador = [
        "",
        "Pendiente",
        "Activo",
        "Rechazado",
        "Dado de baja",
        "Bloqueado"
    ]
}