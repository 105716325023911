import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { format, getYear, getMonth, parseISO } from "date-fns";
import { range } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import "./Calendario.scss";

export function Calendario(props) {
  const { name, fcformik, fecha } = props;
  const [startDate, setStartDate] = useState(
    fecha ? parseISO(fecha) : new Date()
  );

  const CambioFecha = (date) => {
    setStartDate(date);
    fcformik.setFieldValue(name, format(date, "yyyy-MM-dd"));
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <FormControl
      name={name}
      onClick={onClick}
      ref={ref}
      value={value}
      readOnly
    />
  ));

  const years = range(2018, getYear(new Date()) + 1, 1);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Augosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Deciembre",
  ];

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={startDate}
      onChange={(date) => CambioFecha(date)}
      customInput={<CustomInput />}
    />
  );
}
