import {
  faArrowTrendUp,
  faChartArea,
  faChartLine,
  faChartPie,
  faChartSimple,
  faSquarePollVertical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef } from "react";
import { Col, Container, Form, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Divider } from "../../../Common";
import { Principal } from "../../mapCluster";
import "./Indicator.scss";

export function Indicator(props) {
  const {
    licenciaturas,
    carreras,
    estados,
    selectFilter,
    setSelectFilter,
    setFilter,
    totalLicenciaturas,
  } = props;


  const obtenPath = (id) => {
    return "/indicador/vista/" + id;
  };

  const MyPopover = forwardRef(({ title, content, ...props }, ref) => (
    <Popover ref={ref} id="popover-basic" {...props}>
      <Popover.Header as="h3" className='text-center'>{title}</Popover.Header>
      <Popover.Body>
        <h6>
          Tiene un total de {totalLicenciaturas} licenciaturas universitarias.
        </h6>
        <h6>
          Con un total de {carreras?.length} carreras registradas.
        </h6>
      </Popover.Body>
    </Popover>
  ));

  const tipoIcon = (icono) => {
    switch (icono) {
      case "faChartArea":
        return faChartArea;
      case "faChartLine":
        return faChartLine;
      case "faChartBar":
        return faChartSimple;
      default:
        return faChartPie;
    }
  };

  const ruta = (id) => {
    return "/servicios"
  }

  return (
    <Container className="indicator-page">
      <div className="animacionSomn">
        <FontAwesomeIcon
          icon={faSquarePollVertical}
          className="indicator-page-inline indicator-page-iconTit"
        />
        <h4 className="indicator-page-inline">Indicadores</h4>
      </div>
      <Divider />
      <Row>
        <Col sm={6}>
          <b>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="auto"
              overlay={
                (props) => (
                  <MyPopover
                    {...props}
                    title={"La educación en México"}
                  />
                )
              }
            >
              <h2>
                Educación en México
              </h2>

            </OverlayTrigger>
          </b>
        </Col>
        <Col sm={6}>
          <Form.Group>
            <Form.Select
              className="selectTalento"
              onChange={(e) => setSelectFilter(e.target.value)}
            >
              <option>
                Selecciona un tipo de filtro
              </option>
              <option value={1}>
                Carrera
              </option>
              <option value={2}>
                Estado
              </option>
            </Form.Select>
          </Form.Group>
          <FiltradoInstitucional
            filtro={selectFilter}
            carreras={carreras}
            estados={estados}
            setFilter={setFilter}
          />
        </Col>
      </Row>
      <Principal ruta={ruta} licenciaturas={licenciaturas} />
      <center>
        <p className="card-title" style={{ fontSize: '18px' }}>
          Para profundizar en las competencias y habilidades por carreras puedes suscribirte.
        </p>
      </center>
      <Row className="indicator-page__divButtond">
        <Link
          to="/servicios"
          className="btn btn-primary indicator-page__buttonMas"
          size="sm"
        >
          {' '}
          <FontAwesomeIcon icon={faArrowTrendUp} />
          {' '}
          Suscríbete
          {' '}
        </Link>
      </Row>
      <Divider />
    </Container>
  );
}


function FiltradoInstitucional(props) {
  const { filtro, carreras, estados, setFilter } = props;
  if (filtro === 0) return <p>Selecciona un tipo de filtro</p>
  const filtros = [
    null,
    <FiltroCarrera carreras={carreras} setFilter={setFilter} />,
    <FiltroEstado estados={estados} setFilter={setFilter} />,
  ]
  return filtros[filtro]
}

function FiltroCarrera(props) {
  const { carreras, setFilter } = props

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>
            <p>
              Escoge la carrera universitaria.
            </p>
          </Form.Label>
          <Form.Select
            className="selectTalento"
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value={0}>
              Selecciona un tipo de filtro
            </option>
            {
              carreras.map((carrera, index) => (
                <option value={carrera.id} key={index}>
                  {carrera.nombre}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={6} />
    </Row>
  )
}


function FiltroEstado(props) {
  const { estados, setFilter } = props

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>
            <p>
              Escoge el estado.
            </p>
          </Form.Label>
          <Form.Select
            className="selectTalento"
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value={0}>
              Selecciona un tipo de filtro
            </option>
            {
              estados.map((estado, index) => (
                <option value={estado.id} key={index}>
                  {estado.nombre}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={6} />
    </Row>
  )
}


/**
 * 
 * {map(indicadores, (indicador, index) => (
        <Row key={index} className="flex-column">
          <Card className="card-hover animacionSom-indicadores">
            <div className="card-horizontal">
              <div className="card-body1">
                <div className="container-indicador1 flex-raw">
                  <Col xs={2} className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon
                      className="indicator-page-iconBody mr-3"
                      icon={tipoIcon(indicador.icono)}
                    />
                  </Col>
                </div>
                <div className="container-indicador2 flex-column">
                  <Col className="mt-3">
                    <div className="card-title">
                      {indicador.tituloIndicador}
                    </div>
                    <p className="card-text">{indicador.descripcionAmplia}</p>
                  </Col>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">
                Última actualización{" "}
                {formatDateMonthName(indicador.fechaModificacion)}
              </small>

              <Link
                to={obtenPath(indicador.id)}
                className="btn btn-secondary btn-sm"
              >
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                Ver Indicador
              </Link>
            </div>
          </Card>
        </Row>
      ))}
 */