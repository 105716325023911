import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { map } from "lodash";
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'

import "./AddEditIndicadorForm.scss";
import { useIndicadores } from "../../../../hooks";
import {
  formatDateNow,
  formatDate,
} from "../../../../metodosGlobales/FormatoFecha";
import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";

export function AddEditIndicadoresForm(props) {
  const { onClose, onRefetch, indicador, sectores } = props;
  const [state, setState] = useState(indicador?.palabrasClave || []);
  const { addIndicador, updateIndicador } = useIndicadores();

  useEffect(() => {
    const fetchData = async () => {
      formik.setFieldValue("palabrasClave", state);
    };
    fetchData()
  }, [state]);

  const formik = useFormik({
    initialValues: initialValues(indicador),
    validationSchema: Yup.object(indicador ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      console.log(formValue);
      try {
        if (indicador) await updateIndicador(indicador.id, formValue);
        else await addIndicador(formValue);
        onRefetch();
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Form className="add-edit-user-form" onSubmit={formik.handleSubmit}>
      <Row className="mb-3">
        <Form.Group className="col-6 col-sm-6 col-lg-3 col-xl-3">
          <Form.Label>Sectores</Form.Label>

          <Form.Select
            name="sectores"
            value={formik.values.sectores}
            onChange={formik.handleChange}
          >
            <option value={"0"}>Seleccione un sector</option>
            {map(sectores, (sector, index) => (
              <option key={index} value={sector.id}>
                {sector.descripcion}
              </option>
            ))}
          </Form.Select>
          <Form.Text className="text-danger">
            {formik.touched.icono && formik.errors.icono ? (
              <div className="text-danger">{formik.errors.icono}</div>
            ) : null}
          </Form.Text>
        </Form.Group>

        <IngresaDatosForm
          col="col-6 col-sm-6 col-lg-6 col-xl-6"
          tituloLabel="Título del indicador."
          nameControl="tituloIndicador"
          type="text"
          placeHolderControl="titulo del indicador"
          valueControl={formik.values.tituloIndicador}
          onChangeControl={formik.handleChange}
          error={formik.errors.tituloIndicador}
          touched={formik.touched.tituloIndicador}
        />
        <IngresaDatosForm
          col="col-12 col-sm-6 col-lg-3 col-xl-3"
          tituloLabel="Fecha de Modificacion."
          nameControl="fechaModificacion"
          type="text"
          valueControl={formik.values.fechaModificacion}
          onChangeControl={formik.handleChange}
          error={formik.errors.fechaModificacion}
          touched={formik.touched.fechaModificacion}
          disabled={true}
        />
      </Row>

      <Row className="mb-3">
        <IngresaDatosForm
          col="col-12 col-sm-12"
          tituloLabel="Detalle."
          nameControl="detalle"
          type="text"
          placeHolderControl="Detalle"
          valueControl={formik.values.detalle}
          onChangeControl={formik.handleChange}
          error={formik.errors.detalle}
          touched={formik.touched.detalle}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6"
          tituloLabel="link del indicador."
          nameControl="linkIndicador"
          type="text"
          placeHolderControl="link del indicador"
          valueControl={formik.values.linkIndicador}
          onChangeControl={formik.handleChange}
          error={formik.errors.linkIndicador}
          touched={formik.touched.linkIndicador}
        />
        <Form.Group className="col-6 col-sm-6">
          <IngresaDatosForm
            tituloLabel="Palabras clave:"
            formatoLabel="tituloCentrado"
          />
          <div style={{ margin: 10 }}>
            <div className='input-group'>
              <InputTags
                values={state}
                onTags={(value) => setState(value.values)}
              />
            </div>
          </div>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group className="col-6 col-sm-6 col-lg-3">
          <Form.Label>Icono</Form.Label>

          <Form.Select
            name="icono"
            placeholder="icono"
            value={formik.values.icono}
            onChange={formik.handleChange}
          >
            <option value={"0"}>Seleccione un icono</option>
            <option>faChartArea</option>
            <option>faChartLine</option>
            <option>faChartPie</option>
            <option>faChartBar</option>
          </Form.Select>
          <Form.Text className="text-danger">
            {formik.touched.icono && formik.errors.icono ? (
              <div className="text-danger">{formik.errors.icono}</div>
            ) : null}
          </Form.Text>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Label>Descripción completa del indicador</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="descripcionAmplia"
          placeholder="Descripción"
          value={formik.values.descripcionAmplia}
          onChange={formik.handleChange}
        />
        <Form.Text className="text-danger">
          {formik.touched.descripcionAmplia &&
            formik.errors.descripcionAmplia ? (
            <div className="text-danger">{formik.errors.descripcionAmplia}</div>
          ) : null}
        </Form.Text>
      </Form.Group>
      <Button variant="primary" type="submit">
        {indicador ? "Actualizar" : "Crear"}
      </Button>
    </Form>
  );
}

function initialValues(data) {
  return {
    icono: data?.icono || "0",
    tituloIndicador: data?.tituloIndicador || "",
    detalle: data?.detalle || "",
    linkIndicador: data?.linkIndicador || "",
    descripcionAmplia: data?.descripcionAmplia || "",
    fechaModificacion: formatDate(data?.fechaModificacion) || formatDateNow(),
    palabrasClave: data?.palabrasClave || "",
    sectores: data?.sectores || 0,
  };
}

function newSchema() {
  return {
    icono: Yup.string("Debe seleccionar un icono")
      .required("El icono es requerido")
      .notOneOf(["0"]),
    tituloIndicador: Yup.string("El titulo no debe ser vacío").required(
      "El titulo es requerido"
    ),
    detalle: Yup.string("El detalle no debe ser vacío").required(
      "El detalle es requerido"
    ),
    linkIndicador: Yup.string("El link no debe ser vacío").required(
      "El link es requerido"
    ),
    descripcionAmplia: Yup.string("La decripcion no debe estar vacío").required(
      "La descripcion es requerida"
    ),
    sectores: Yup.string("Debe seleccionar el sector")
      .required()
      .notOneOf(["0"]),
  };
}

function updateSchema() {
  return {
    icono: Yup.string("El icono no puede ser vacío").required(
      "Este campo es requerido"
    ),
    tituloIndicador: Yup.string("El titulo no puede ser vacío").required(
      "Este campo es requerido"
    ),
    detalle: Yup.string("El detalle no puede ser vacío").required(
      "Este campo es requerido"
    ),
    linkIndicador: Yup.string("El link no puede ser vacío").required(
      "Este campo es requerido"
    ),
    descripcionAmplia: Yup.string("La decripcion no debe estar vacío").required(
      "La descripcion es requerida"
    ),
    sectores: Yup.string("Debe seleccionar el sector")
      .required()
      .notOneOf(["0"]),
  };
} 