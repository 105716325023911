import React, { useMemo, useState } from "react";

import { Pagination } from "../../../Common";
import { CardForum } from "./CardForum";

export function PaginadorForum(props) {
  const { datos } = props;
  const PageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (datos) return datos.slice(firstPageIndex, lastPageIndex);
    else return;
  }, [currentPage]);

  if (!datos) return null;
  return (
    <>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={datos.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      {currentTableData.map((item) => {
        return <CardForum key={item.id} data={item} />;
      })}

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={datos.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
}
