import React from "react";
import "./Publicity.scss";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardUser,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

import { Divider } from "../../../../components/Common";
import logo from "../../../../images/logo-bn.png";

export function Publicity() {
  return (
    <>
      <Container className="publicity-page">
        <Row>
          <Col className="text-center">
            <Image src={logo} />
          </Col>
          <Col className="text-left">
            <p className="publicity-page-servicio">
              OT'S Servicios de investigacion empresarial:
            </p>
            <Divider />
            <ul className="publicity-page-lista">
              <li>Servicios de estudios tecnologicos avanzados</li>
              <li>
                Servicios de estudios en areas especificas Tendencias en
                tecnologias aplicadas
              </li>
            </ul>
            <Container className="text-left">
              <Link to={"/servicios"}>
                <Button
                  variant="danger"
                  size="sm"
                  className="publicity-page__button"
                >
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="me-2"
                  />
                  Mas información
                </Button>
              </Link>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className="publicity-page-quiero">
        <h2>Adelántate a tus competidores con nuestro boletín</h2>
        <Link to={"/servicios"}>
          <button className="subscribe-button">
            <FontAwesomeIcon icon={faClipboardUser} className="me-2" />
            <span>Quiero Subscribirme</span>
            <div className="animation-circle"></div>
            <div className="animation-circle"></div>
            <div className="animation-circle"></div>
          </button>
        </Link>
      </Container>
    </>
  );
}
