import React from "react";
import "./SearchBasic.scss";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Form, Button, InputGroup } from "react-bootstrap";

export function SearchBasic(props) {
  const { placeholder } = props;
  const obtenPath = (clave) => {
    return "/search/" + clave;
  };

  const formik = useFormik({
    initialValues: initialValues(),
    onSubmit: async (formvalue) => {
    },
  });

  return (
    <div className="search-basic">
      <Form onSubmit={formik.handleSubmit}>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder={placeholder}
            aria-label={placeholder}
            name="busqueda"
            onChange={formik.handleChange}
          />
          <Link to={obtenPath(formik.values.busqueda)}>
            <Button variant="secondary" id="btn-buscar" type="submit">
              Buscar
            </Button>
          </Link>
        </InputGroup>
      </Form>
    </div>
  );
}

function initialValues() {
  return {
    busqueda: ""
  }
}