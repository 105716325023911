import React, { useEffect } from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "lodash";
import { faMapLocation, faLink } from "@fortawesome/free-solid-svg-icons";
import { useHolderjs } from "use-holderjs";

import { useEventos } from "../../../../hooks";
import { TituloPrincipal } from "../../../Common";
import "./EventosList.scss";

export function EventList(props) {
  useHolderjs();

  const { clave } = props;
  const { eventos, getEventos, getEventosBuscador } = useEventos();
  console.log(eventos);

  useEffect(() => {
    if (clave) getEventosBuscador(clave);
    else getEventos();
  }, []);

  return (
    <div className="eventos-list">
      <TituloPrincipal titulo={"Eventos"} />

      {map(eventos, (evento, index) => (
        <Row key={index} className="justify-content-center my-5">
          <Col xs={8}>
            <Card>
              <div className="card-horizontal">
                <div className="card-body">
                  <Row>
                    <Col xs={8}>
                      <Row className="mb-2">
                        <h6>
                          <strong>
                            Fecha del Evento: {evento.fecha_evento} Hora:{" "}
                            {evento.hora_evento}
                          </strong>
                        </h6>
                      </Row>
                      <div className="card-title">
                        <strong>{evento.nombre}</strong>
                      </div>
                      <Row className="mb-2">
                        <Col xs={6}>
                          <p className="card-text"> Autor: {evento.autor}</p>
                        </Col>
                        <Col xs={6}>
                          <p>
                            Modalidad:{" "}
                            {evento.modalidad === 1 ? "En linea" : "Presencial"}
                          </p>
                        </Col>
                      </Row>
                      {evento.modalidad === 2 ? (
                        <>
                          <Row>
                            <Col xs={6}>
                              <p> Sede:{evento.sede} </p>
                            </Col>
                            <Col xs={6}>
                              <p>
                                {" "}
                                Calle:{evento.calle} Numero: {evento.numero}{" "}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <p> Colonia:{evento.colonia} </p>
                            </Col>
                            <Col xs={6}>
                              <p>
                                Ciudad:{evento.ciudad} CP: {evento.cp}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <p> Pais: {evento.pais["nombre"]} </p>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                      <Row>
                        <p>{evento.descripcion}</p>
                      </Row>
                    </Col>
                    <Col xs={4}>
                      <Image
                        className="img-thumbnail eventos-list-img"
                        src={
                          evento.imagen
                            ? clave
                              ? `${evento.imagen}`
                              : `${evento.imagen}`
                            : "holder.js/100px200"
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card-footer">
                <span className="location">
                  {evento.sede ? (
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        <FontAwesomeIcon icon={faMapLocation} /> {evento.sede}
                      </Col>
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        className="tituloDerecha"
                      >
                        <a
                          href={evento.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faLink} /> Para más información
                          da click aquí.
                        </a>
                      </Col>
                    </Row>
                  ) : (
                    <a
                      href={evento.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLink} /> Ir a la pagina del
                      evento Ir a la página del evento
                    </a>
                  )}
                </span>
              </div>
            </Card>
          </Col>
        </Row>
      ))}
    </div>
  );
}
