import { useEffect } from "react"; 
import { useParams } from "react-router-dom";
import {useNoticia} from "../../../../hooks";
import { map } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faPersonChalkboard,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Card, Image, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatDateMonthNameWYear} from "../../../../../src/metodosGlobales";

import "./SectoresNot.scss"

export function SectoresNot(props) {
    const { clave } = props;
    const { noticia, getNoticiasFiltrdas } = useNoticia();
    const { id } = useParams();
    const obtenPath = (id) => {
        return `/noticia/detalle/${id}`;
      };
    useEffect(() => {
        getNoticiasFiltrdas(clave);
      }, []);

    if (noticia && noticia?.length != 0){
    return (
        <div className="sec-page ">
            <h3 className="cb">Noticias/{noticia[0]?.sectores?.descripcion}</h3>
            {map(noticia, (news, index) => (
              <Link
              to={obtenPath(news.id)}
              className="text">
                <Row key={index} className="justify-content-center sec-page-card">
                    <Col xs={6}>
                        <Card>
                        <div className="card-horizontal">
                            <div className="card-body">
                                <Row>
                                    <Col xs={1} className="d-flex justify-content-center align-items-center">
                                      {formatDateMonthNameWYear(news.fecha_alta)}                                
                                    </Col>
                                    <Col xs={2} className="d-flex justify-content-center align-items-center">
                                      <Image src={news.imagen} style={{ maxWidth: '115%', }} />
                                    </Col>
                                    <Col>
                                        <span className="cb">{news.titular_noticia}</span>
                                        <br/>
                                        <Card.Text className="texto-recortado"as="h6">{news.resumen}</Card.Text>
                                    </Col>

                                </Row>
                            </div>
                        </div>                
                        </Card>
                    </Col>
                </Row>
              </Link>  
            ))}
        </div>
    ); 
    } 
  else
    return (
      <div className="sec-p">
      <h3 className="cb">Noticias</h3>
      <div className="noticias-container">
        <h4>No hay noticias de ese sector</h4>
      </div>
    </div>
    );

}
