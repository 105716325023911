import React, { useState, useEffect } from "react";
import {
  TableAyudaServicio,
  HeaderPage,
  FormAyudaServicio,
  DeleteAyudaServicio,
} from "../../../components/Admin";
import { Spinner } from "react-bootstrap";
import { ModalBasic } from "../../../components/Common";
import {
  faUserPen,
  faTrash,
  faFilePen
} from "@fortawesome/free-solid-svg-icons";
import { useCatalagos, useAyuda, useAuth } from "../../../hooks";

export function AyudaServicio() {
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("g");
  const { auth } = useAuth();

  const { servicios, getTipoServicio } = useCatalagos();
  const { loading, servicioayuda, getAyudaServicio, serviciouser, getServicioClienteFiltro } = useAyuda();

  useEffect(() => {
    getTipoServicio();
    if (auth.me.role === 5) {
      getServicioClienteFiltro(auth.me.id);
    } else {
      getAyudaServicio();
    }
  }, [refetch]);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateAyudaServicio = (data) => {
    setTitleModal("Actualizar Registro de Ayuda");
    setIconModal(faFilePen);
    setContentModal(
      <FormAyudaServicio
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ayudaservicio={data}
      />
    );
    openCloseModal();
  };

  const onDeleteAyudaServicio = async (data) => {
    setTitleModal("Eliminar Registro de Ayuda");
    setIconModal(faTrash);
    setsizeModal("s");
    setContentModal(
      <DeleteAyudaServicio
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ayudaservicio={data}
      />
    );
    openCloseModal();
  };

  const addAyuda = () => {
    setTitleModal(" Solicitar Ayuda en Servicios");
    setIconModal(faUserPen);
    setsizeModal("xl");
    setContentModal(
      <FormAyudaServicio
        onClose={openCloseModal}
        onRefetch={onRefetch}
        tiposervicio={servicios}
      />
    );
    openCloseModal();
  };


  return (
    <>
      <HeaderPage
        title="Ayuda Servicios"
        btnTitle="Solicitar Ayuda"
        btnClick={addAyuda}
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner className="spinner" animation="border" />
        </div>
      ) : (

        <TableAyudaServicio
          ayudaservicio={auth.me.role === 5 ? serviciouser : servicioayuda}
          updateAyudaServicio={updateAyudaServicio}
          onDeleteAyudaServicio={onDeleteAyudaServicio}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
