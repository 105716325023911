import React from "react";
import { Row, Container, Button } from "react-bootstrap";
import { Encabezado } from "../Inicio/Encabezado";
import { FormaContacto } from "./FormaContacto";

export function Contacto(props) {
  const { informacionContacto, datos, inicio } = props;
  return (
    <Container>
      <Row>
        <label className="fs-5-5 text-resal text-start">
          *Si no llena algun campo no aparecera en la publicacion
        </label>
      </Row>
      <div className="Container-general">
        <Encabezado
          color="green"
          showEditMessages={false}
          datos={datos}
          logo={inicio.logo}
        />
      </div>
      <Row className="Container-general">
        <FormaContacto informacionContacto={informacionContacto} />
      </Row>
    </Container>
  );
}
