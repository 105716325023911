import { ClientLayout } from "../layouts";
import {
  Home,
  Colabora,
  ColaboraRed,
  Acerca,
  Servicios,
  Contactanos,
  NoticiaDetail,
  IndicadorLista,
  EventosLista,
  AvisoCookies,
  AvisoPrivacidad,
  PagoSuccess,
  PagoCancel,
  IndicadorVista,
  ResultadoBuscador,
  NoticiaLista,
  BusquedaExterna,
  SectoresNoLista,
  SectorNotiVista,
} from "../pages/Client";

const routesClient = [
  {
    path: "/",
    layout: ClientLayout,
    component: Home,
  },
  {
    path: "/colabora",
    layout: ClientLayout,
    component: Colabora,
  },
  {
    path: "/colabora/red",
    layout: ClientLayout,
    component: ColaboraRed,
  },
  {
    path: "/servicios",
    layout: ClientLayout,
    component: Servicios,
  },
  {
    path: "/acerca",
    layout: ClientLayout,
    component: Acerca,
  },
  {
    path: "/contactanos",
    layout: ClientLayout,
    component: Contactanos,
  },
  {
    path: "/busquedaExterna",
    layout: ClientLayout,
    component: BusquedaExterna,
  },
  {
    path: "/eventos/lista",
    layout: ClientLayout,
    component: EventosLista,
  },
  {
    path: "/eventos/lista/busqueda-:clave",
    layout: ClientLayout,
    component: EventosLista,
  },
  {
    path: "/noticia/lista",
    layout: ClientLayout,
    component: NoticiaLista,
  },
  {
    path: "/noticia/lista/busqueda-:clave",
    layout: ClientLayout,
    component: NoticiaLista,
  },
  {
    path: "/noticia/detalle/:id",
    layout: ClientLayout,
    component: NoticiaDetail,
  },
  {
    path: "/indicador/lista",
    layout: ClientLayout,
    component: IndicadorLista,
  },
  {
    path: "/indicador/lista/busqueda-:clave",
    layout: ClientLayout,
    component: IndicadorLista,
  },
  {
    path: "/indicador/vista/:id",
    layout: ClientLayout,
    component: IndicadorVista,
  },
  {
    path: "/aviso/privacidad",
    layout: ClientLayout,
    component: AvisoPrivacidad,
  },
  {
    path: "/sector/lista",
    layout: ClientLayout,
    component: SectoresNoLista,
  },
  {
    path: "/sectorNotis/:clave",
    layout: ClientLayout,
    component: SectorNotiVista,
  },
  {
    path: "/aviso/cookies",
    layout: ClientLayout,
    component: AvisoCookies,
  },
  //http://localhost:3000/servicios/pago/success/6
  {
    path: "/servicios/pagos/success/:clave",
    layout: ClientLayout,
    component: PagoSuccess,
  },
  {
    path: "/servicios/pagos/cancel/:clave",
    layout: ClientLayout,
    component: PagoCancel,
  },
  {
    path: "/search/:clave",
    layout: ClientLayout,
    component: ResultadoBuscador,
  },

];

export default routesClient;
