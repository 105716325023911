import React from 'react'
import { Table } from 'react-bootstrap'
import { map } from 'lodash'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArchive,
  faArrowUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";

import { formatDateMonthName } from '../../../../../metodosGlobales'


export function TableRequestClient(props) {

  const { serviciosSolicitadosIdCliente } = props;

  return (
    <div className='table-responsive-xl'>
      <Table striped bordered hover className="table-service table-sm">
        <thead>
          <tr>
            <th>N° solicitud</th>
            <th>Sector</th>
            <th>Status</th>
            <th>Fecha de alta</th>
            <th>Documento de solicitud</th>
          </tr>
        </thead>
        <tbody>
          {map(serviciosSolicitadosIdCliente, (servicio, index) => (
            <tr key={index}>
              <td>{servicio.id}</td>
              <td>{servicio.idSolicitudServicio.sectores['descripcion']}</td>
              <td>{servicio.idSolicitudServicio.estatus['nombre']}</td>
              <td>{formatDateMonthName(servicio.idSolicitudServicio['fechaAlta'])}</td>
              <td>{
                servicio.archivoReporte
                  ? <a
                    href={servicio.archivoReporte}
                    target='_blank'
                  >
                    <FontAwesomeIcon
                      icon = {faFileArchive}
                    />
                    {' '} ver documento {' '}
                    <FontAwesomeIcon
                      icon = {faArrowUpRightFromSquare}
                    />
                  </a>
                  : ""
              }</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}