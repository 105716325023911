import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

export function TarjetasAgenda(props) {

    const { datos } = props

    return (
        <Card
            style={{ width: '18rem' }}
        >
            <Card.Img variant="top" src={datos.vendedor.id_registro_externo.image} style={{ height: "14rem" }} />
            <Card.Header>
                <center>
                    <Card.Title>{datos.vendedor.id_registro_externo.empresa}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Mesa asignada: {datos.vendedor.mesa_asignada}</Card.Subtitle>
                </center>
            </Card.Header>
            <Card.Body>
                <center>
                    <Card.Text>
                        <Row>
                            <Col sm={6}>
                                Hora de inicio
                                <br />
                                {datos.hora_inicio}
                            </Col>
                            <Col sm={6}>
                                Hora de fin
                                <br />
                                {datos.hora_final}
                            </Col>
                        </Row>
                    </Card.Text>
                </center>
            </Card.Body>
            <Card.Footer>
                <center>
                    Comprador: {datos.comprador.id_registro_externo.empresa}
                </center>
            </Card.Footer>
        </Card >
    )
}
