import React, { useEffect } from "react";
import { Container, Carousel } from "react-bootstrap";
import { map } from "lodash";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carrusel.scss";
import { useCarousel } from "../../../../../hooks";
import { ImageCarosuel } from "../../../../Admin";

export function Carrusel() {
  const { carousel, getCarousel } = useCarousel();

  useEffect(() => {
    getCarousel();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="carousel-client">
      <div className="carousel-client-margen">
        <Carousel autoPlay interval="5000">
          {map(carousel, (imagen, index) => (
            <Carousel.Item key={index}>
              <ImageCarosuel imagen={imagen} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Container>
  );
}
