import React, { useState, useRef } from "react";
import { Table, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil, faCheck } from "@fortawesome/free-solid-svg-icons";

import { formatDateNow, formatDate } from "../../../../metodosGlobales";
import { ModalVerMas } from "../../../Common/ModalVerMas/ModalVerMas";

import { map } from "lodash";
import "./TableContactanos.scss";

export function TableContactanos(props) {
  const { contactanos, deleteContactanos, updateContacto } = props;

  return (
    <div className="table-responsive-xl">
      <Table striped bordered hover className="table-contactanos table-sm">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Correo</th>
            <th>Celular</th>
            <th>Teléfono oficina</th>
            <th>Empresa</th>
            <th>Cargo</th>
            <th>Asunto</th>
            <th>Fecha registro</th>
            <th>Observaciones</th>
            <th>Fecha atención</th>
            <th>Estatus</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {map(contactanos, (contacto, index) => (
            <tr key={index}>
              <td>{contacto.name}</td>
              <td>{contacto.apellidos}</td>
              <td>{contacto.email}</td>
              <td>{contacto.celular}</td>
              <td>{contacto.telOficina}</td>
              <td>{contacto.empresa}</td>
              <td>{contacto.cargo}</td>
              <td>{formatDate(contacto.fechaRegistro)}</td>
              <td>
                <ModalVerMas title="Asunto" content={contacto.asunto} />
              </td>
              <td>
                {contacto.observacion ? (
                  <ModalVerMas title="Solución" content={contacto.observacion} />
                ) : null}
              </td>
              <td>{formatDate(contacto.fechaAtencion)}</td>

              <td className="status">
                {contacto.estatus ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <FontAwesomeIcon icon={faXmark} />
                )}
              </td>
              <Actions
                contacto={contacto}
                updateContacto={updateContacto}
                deleteContactanos={deleteContactanos}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
  function Actions(props) {
    const { contacto, deleteContactanos, updateContacto } = props;

    return (
      <td>
        <Button
          size="m"
          variant="success"
          onClick={() => updateContacto(contacto)}
          className="action-button green btn-boton"
        >
          <FontAwesomeIcon icon={faPencil} />
        </Button>
        <Button
          size="m"
          variant="danger"
          onClick={() => deleteContactanos(contacto)}
          className="action-button red btn-boton"
        >
       <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
        </Button>
      </td>
    );
  }
}
