import React from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

import { map } from "lodash";
import "./TableAyudaServicio.scss";
import { formatDate } from "../../../../metodosGlobales/FormatoFecha";
import { useAuth } from "../../../../hooks";

import { ModalVerMas } from "../../../Common/ModalVerMas/ModalVerMas";

export function TableAyudaServicio(props) {
  const { ayudaservicio, updateAyudaServicio, onDeleteAyudaServicio } = props;
  const { auth } = useAuth();

  const estado = (idestado) => {
    if (idestado === 2) {
      return <FontAwesomeIcon icon={faCheck} />;
    } else if (idestado === 1) {
      return <FontAwesomeIcon icon={faXmark} />;
    }
  };
  const servicioTipo = (idTipoSer) => {
    if (idTipoSer === 1) {
      return "Servicios";
    }
  };

  return (
    <div className="table-responsive-xl">
      <Table striped bordered hover className="table-users-admin table-sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>No. membresia</th>
            <th>Nombre</th>
            <th>Apellido paterno</th>
            <th>Apellido materno</th>
            <th>Tipo de servicio</th>
            <th>Fecha de solicitud</th>
            <th>Teléfono de contacto</th>
            <th>Email</th>
            <th>Descripción</th>
            <th>Fecha de atención</th>
            <th>Estatus</th>
            <th>Solución</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {map(ayudaservicio, (servicio, index) => (
            <tr key={index}>
              <td>{servicio.id}</td>
              <td>{servicio.user.id}</td>
              <td>{servicio.user.nombre}</td>
              <td>{servicio.user.apellido_paterno}</td>
              <td>{servicio.user.apellido_materno}</td>
              <td>{servicioTipo(servicio.tipo_servicio)}</td>
              <td>{formatDate(servicio.fecha_solicitud)}</td>
              <td>{servicio.telefono}</td>
              <td>{servicio.email}</td>
              <td>
                <ModalVerMas
                  title="Descripción"
                  content={servicio.descripcion}
                />
              </td>
              <td>{formatDate(servicio.fecha_atencion)}</td>
              <td className="status">{estado(servicio.estatus)}</td>

              <td>
                {servicio.solucion ? (
                  <ModalVerMas title="Solución" content={servicio.solucion} />
                ) : null}
              </td>

              <Actions
                servicio={servicio}
                updateAyudaServicio={updateAyudaServicio}
                onDeleteAyudaServicio={onDeleteAyudaServicio}
                permiso={auth.me.role}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
function Actions(props) {
  const { servicio, updateAyudaServicio, onDeleteAyudaServicio, permiso } =
    props;
  return (
    <td>
      {permiso === 4 || permiso === 1 ? (
        <Button
          size="sm"
          variant="success"
          onClick={() => updateAyudaServicio(servicio)}
          className="table-users-admin__boton btn-boton"
        >
          <FontAwesomeIcon icon={faPencil} />
        </Button>
      ) : (
        <Button
          size="sm"
          variant="danger"
          onClick={() => onDeleteAyudaServicio(servicio)}
          className="table-users-admin__boton btn-boton"
        >
          <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
        </Button>
      )}
    </td>
  );
}
