import React, { useEffect } from "react";
import { map } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faPersonChalkboard,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Card, Image, Button } from "react-bootstrap";

import { formatDateMonthName } from "../../../../metodosGlobales";
import { useNoticia } from "../../../../hooks";
import { TituloPrincipal, DividerIcon } from "../../../Common";
import "./NoticiaList.scss";

export function NoticiaList(props) {
  const { clave } = props;
  const { getNoticias, getNoticiasBuscador, noticia } = useNoticia();
  const obtenPath = (id) => {
    return `/noticia/detalle/${id}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (clave) getNoticiasBuscador(clave);
      else getNoticias();
    };
    fetchData();
  }, []);

  if (noticia?.length != 0)
    return (
      <div>
        <TituloPrincipal
          titulo={clave ? `Noticias busqueda: ${clave}` : "Noticias"}
        />
        {map(noticia, (news, index) => (
          <div key={index} className="contenedor">
            <Card>
              <Card.Header as="h5">
                <FontAwesomeIcon icon={faNewspaper} /> {news.titular_noticia}
              </Card.Header>
              <Row>
                <div className="col-6">
                  <Image src={news.imagen} className="imagen" />
                </div>
                <div className="col-6">
                  <Card.Body>
                    <FontAwesomeIcon
                      className="centradoIcono"
                      icon={faPersonChalkboard}
                    />
                    <Card.Title className="centrado">
                      Noticia realizada por:
                      <br />
                      {news.autor_institucion}
                    </Card.Title>
                    <br />
                    <Card.Text as="h6" className="centrado">
                      Sector de noticia: {news.sectores["descripcion"]}
                    </Card.Text>
                    <Card.Text as="h6" className="centrado">
                      Palabras clave: {news.keywords}
                    </Card.Text>
                    <br />
                    <Card.Text as="h6">{news.resumen}</Card.Text>
                    <br />
                    <Button variant="primary" href={obtenPath(news.id)}>
                      Ver noticia completa
                    </Button>
                  </Card.Body>
                </div>
              </Row>
              <div>
                <br />
              </div>
              <Card.Footer className="derecha" as="h6">
                <small className="text-muted">
                  Fecha de alta: {formatDateMonthName(news.fecha_alta)}
                </small>
              </Card.Footer>
            </Card>
          </div>
        ))}
        ;
      </div>
    );
  else
    return (
      <div>
        <TituloPrincipal
          titulo={clave ? `Noticias busqueda: ${clave}` : "Noticias"}
        />
        <DividerIcon titulo={`Sin resultados de la palabra: ${clave}`} />
        <br />
      </div>
    );
}
