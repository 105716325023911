import React, { useState, useEffect } from "react";
import { Row, Tabs, Tab, Container } from "react-bootstrap";
import "./FormaEdit.scss";

import {
  Inicio,
  Informacion,
  RegistroComprador,
  RegistroVendedor,
  Contacto,
} from "../../../../pages/Admin/B2b";

export const FormaEdit = (props) => {
  const { keywords, inicio, informacion, informacionContacto, datos } = props;
  const [key, setKey] = useState("inicio");

  useEffect(() => {
    console.log(key);
  }, [key]);

  return (
    <Row className="mt-4">
      <label className="text-start text-bold fs-5-5 text-resal mb-3">
        Aquí podrás editar cada sección.
      </label>
      <Container className="py-4">
        <Row className="justify-content-center">
          <Tabs
            className="mb-1 p-0 nav-pills flex-column flex-sm-row"
            justify
            variant="pills"
            defaultActiveKey="inicio"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="inicio" title="Inicio">
              <Inicio inicio={inicio} datos={datos} />
            </Tab>
            {keywords[4].check && (
              <Tab eventKey="informacion1" title="Información 1">
                <Informacion
                  informacion={informacion.paginas}
                  setInformacion={informacion.setPaginas}
                  index={0}
                  inicio={inicio}
                  datos={datos}
                />
              </Tab>
            )}
            {keywords[5].check && (
              <Tab eventKey="informacion2" title="Información 2">
                <Informacion
                  informacion={informacion.paginas}
                  setInformacion={informacion.setPaginas}
                  index={1}
                  inicio={inicio}
                  datos={datos}
                />
              </Tab>
            )}
            {keywords[6].check && (
              <Tab eventKey="informacion3" title="Información 3">
                <Informacion
                  informacion={informacion.paginas}
                  setInformacion={informacion.setPaginas}
                  index={2}
                  inicio={inicio}
                  datos={datos}
                />
              </Tab>
            )}
            <Tab eventKey="registroComprador" title="Registro Comprador">
              <RegistroComprador />
            </Tab>
            <Tab eventKey="registroVendedor" title="Registro Vendedor">
              <RegistroVendedor />
            </Tab>
            <Tab eventKey="contacto" title="Contacto">
              <Contacto
                informacionContacto={informacionContacto}
                inicio={inicio}
                datos={datos}
              />
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </Row>
  );
};
