import { faNewspaper, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "lodash";
import React, { useEffect } from 'react';
import { Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Divider, initAOS } from "../../../../components/Common";
import "./SectoresN.scss";

export function SectoresN(props) {

  const { sectorN } = props;
  const obtenPath = (clave) => {
    return "/sectorNotis/" + clave;
  };
  //Animaciones
  useEffect(() => {
    initAOS();
  }, []);

  return (
    <div className="notice-page">
      <div data-aos="zoom-out-right">
        <FontAwesomeIcon
          icon={faNewspaper}
          className="notice-page-inline notice-page-iconTit"
        />
        <h4 className="notice-page-inline">Noticias</h4>
      </div>
      <Divider />
      {map(sectorN, (sectores, index) => (
        <div className="notice-page-card animacionSom-card" key={index}>
          <Link to={obtenPath(sectores.id_sector_noticia.id)}>
            <div className="notice-page-container">
              <Image src={`${sectores.id_sector_noticia.imagen}`} className="notice-page-image" />
              <div className="fondo"></div>
              <div className="centered">
                <FontAwesomeIcon
                  icon={faPaperclip}
                />
                {sectores.id_sector_noticia.descripcion}
              </div>
            </div>
          </Link>
        </div>

      ))}
      <Row className="indicator-page__divButtond">
        <Link
          to="/sector/lista"
          className="btn btn-primary indicator-page__buttonMas"
          size="sm"
        >
          Ver más sectores
        </Link>
      </Row>
    </div>
  )
}
