import React from "react";
import { useFormik } from "formik";
import { Form, Button } from "react-bootstrap";


export function EditarRedesSocial(props) {
  
  const { onClose, social, setSocial} = props;
  
  const formik = useFormik({
    initialValues: initialValues(social),
    
    onSubmit: async (formValue) => {
     setSocial(formValue);
     onClose();
    },
  });

  return (
    <Form className="recovery-pass" onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>
          Ingresa las redes sociales relacionadas al evento
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="facebook"
          name="facebook"
          value={formik.values.facebook}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          
        />
         <Form.Control
          type="text"
          placeholder="twitter"
          name="twitter"
          value={formik.values.twitter}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          
        />
         <Form.Control
          type="text"
          placeholder="instagram"
          name="instagram"
          value={formik.values.instagram}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          
        />
        <Form.Control
          type="text"
          placeholder="linkedin"
          name="linkedin"
          value={formik.values.linkedin}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          
        />
        <Form.Control
          type="text"
          placeholder="youtube"
          name="youtube"
          value={formik.values.youtube}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          
        />
      </Form.Group>
        <Button variant="primary" type="submit">
          Guardar
        </Button>
      
    </Form>
  );
}

function initialValues(social) {
  return {
    facebook: social.facebook? social.facebook:"",
    twitter: social.twitter? social.twitter:"",
    instagram:social.instagram?social.instagram:"",
    linkedin:social.linkedin? social.linkedin : "",
    youtube: social.youtube? social.youtube:""
  };
}


