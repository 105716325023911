import { BASE_API } from "../utils/constants";

export async function getExperienciaClienteApi() {
  try {
    const url = `${BASE_API}/api/experienciaCliente/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addExperienciaClienteApi(data) {
  try {
    const formData = new FormData();
    formData.append("nombre", data.nombre);
    formData.append("apellidoPaterno", data.apellidoPaterno);
    formData.append("apellidoMaterno", data.apellidoMaterno);
    formData.append("empresa", data.empresa);
    formData.append("puesto", data.puesto);
    formData.append("comentario", data.comentario);
    formData.append("logo", data.logo);
    console.log("data", formData);

    const url = `${BASE_API}/api/experienciaCliente/envio/`;
    const params = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteExperienciaClienteApi(id, token) {
  try {
    const url = `${BASE_API}/api/experienciaCliente/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
