import { AdminLayout } from "../layouts";
import { TopSectoresNoticias } from "../pages/operativo";


const routesOperativo = [
    {
        path: "/operativo/top-sector-noticia",
        layout: AdminLayout,
        component: TopSectoresNoticias,
    },
]

export default routesOperativo