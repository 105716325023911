import React from 'react'
import { Row, Card } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonChalkboard, faCalendar } from "@fortawesome/free-solid-svg-icons";

import { formatDateMonthName } from '../../../../../metodosGlobales';
import "./DetalleWebinar.scss"

export function DetalleWebinar(props) {

  const { dato } = props;
  console.log(dato)

  return (
    <div className='contenedor'>
      <Card>
        <Card.Body className='bodyCard'>

          <Row className="mb-6">
            <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
              {
                dato?.link
                  ?
                  <div className="player-wrapper">
                    <ReactPlayer
                      className='react-player'
                      url={dato?.link}
                      controls
                      width='100%'
                      height='100%'
                    />
                  </div>
                  : null
              }
              {
                dato?.video
                  ?
                  <div>
                    <br />
                    <h5 className='centrado'>Video exclusivo OTS</h5>
                    <div className="player-wrapper">
                      <ReactPlayer
                        className='react-player'
                        url={dato?.video}
                        controls
                        width='100%'
                        height='100%'
                      />
                    </div>
                  </div>
                  : null
              }
            </div>

            <div className="col-12" />
            <div className="col">
              <h2 className='centrado'>{dato.tituloWebinar}</h2>
              <br />
              <Row className="mb-6">
                <div className="col">
                  <FontAwesomeIcon className='centradoIcono' icon={faPersonChalkboard} />
                  <br />
                  <br />
                  <h5 className='centrado'>Autor del Webinar</h5>
                  <h6 className='centrado'>{dato.autorWebinar}</h6>

                </div>
                <div className="col">
                  <FontAwesomeIcon className='centradoIcono' icon={faCalendar} />
                  <br />
                  <br />
                  <h5 className='centrado'>fecha de publicación</h5>
                  <h6 className='centrado'>{formatDateMonthName(dato.fecha_alta)}</h6>
                </div>
              </Row>
              <br />
              <h5 className='centrado'>Descripción del webinar</h5>
              <p className='formatoTexto'>
                {dato.descripcionWebinar}
              </p>
            </div>
          </Row>
        </Card.Body>

      </Card>
      <br />
      <hr className='linea'></hr>
    </div>
  )
}
