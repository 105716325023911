import React, { useEffect, useState, useCallback } from "react";
import { Form, Row, Nav, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { map } from "lodash";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";

import "./AltaColaboraForm.scss";
import { IngresaDatosForm } from "../../../Generales"
import { useColaborador, useUser, useCatalagos } from "../../../../hooks";
import { Divider } from "../../../../components/Common/Divider";

export function AltaColaboraForm(props) {
  const { onClose } = props;
  const { pathname } = useLocation();
  const {
    addColaborador,
    addColaboradorDatosAdicionales,
  } = useColaborador();
  const { addUserColaborador } = useUser()
  const { getPaises, paises } = useCatalagos()

  useEffect(() => {
    getPaises()
  }, []);


  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(newSchema()),
    onSubmit: async (formvalue) => {
      try {
        const lastIdUser = await addUserColaborador(formvalue, 3, 1, false, false);
        const lastIdColaborador = await addColaborador(formvalue, lastIdUser['id']);
        await addColaboradorDatosAdicionales(lastIdColaborador['id']);
        toast.info(
          "Se ha dado de alta correctamente, estaremos en contacto con uds para continuar con el proceso",
          { position: "top-center" }
        );
        onClose()
      } catch (error) {
        toast.error(
          "No fue posible darlo de alta por favor intentelo nuevamente"
        );
      };
      onClose();
    },
  });

  return (
    <Form className="alta-col-form" onSubmit={formik.handleSubmit}>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Nombre"
          formatoLabel="tituloIzquierda"
          nameControl="nombre"
          placeHolderControl="Ingresa el nombre del usuario"
          valueControl={formik.values.nombre}
          onChangeControl={formik.handleChange}
          error={formik.errors.nombre}
          touched={formik.touched.nombre}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Apellido Paterno"
          formatoLabel="tituloIzquierda"
          nameControl="apellido_paterno"
          placeHolderControl="Ingresa el apellido paterno del usuario"
          valueControl={formik.values.apellido_paterno}
          onChangeControl={formik.handleChange}
          error={formik.errors.apellido_paterno}
          touched={formik.touched.apellido_paterno}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Apellido Materno"
          formatoLabel="tituloIzquierda"
          nameControl="apellido_materno"
          placeHolderControl="Ingresa el apellido materno del usuario"
          valueControl={formik.values.apellido_materno}
          onChangeControl={formik.handleChange}
          error={formik.errors.apellido_materno}
          touched={formik.touched.apellido_materno}
        />
      </Row>

      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Institución de procedencia"
          formatoLabel="tituloIzquierda"
          nameControl="institucion"
          placeHolderControl="Ingresa la institucion"
          valueControl={formik.values.institucion}
          onChangeControl={formik.handleChange}
          error={formik.errors.institucion}
          touched={formik.touched.institucion}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Sitio Web de la Institución"
          formatoLabel="tituloIzquierda"
          nameControl="sitio_web"
          placeHolderControl="Ingresa el sitio web"
          valueControl={formik.values.sitio_web}
          onChangeControl={formik.handleChange}
          error={formik.errors.sitio_web}
          touched={formik.touched.sitio_web}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Cargo"
          formatoLabel="tituloIzquierda"
          nameControl="cargo"
          placeHolderControl="Ingresa el cargo"
          valueControl={formik.values.cargo}
          onChangeControl={formik.handleChange}
          error={formik.errors.cargo}
          touched={formik.touched.cargo}
        />
      </Row>

      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-3"
          tituloLabel="Teléfono de oficina"
          formatoLabel="tituloIzquierda"
          nameControl="tel_oficina"
          type="mobile"
          placeHolderControl="Ingresa el tel. oficina"
          valueControl={formik.values.tel_oficina}
          onChangeControl={formik.handleChange}
          error={formik.errors.tel_oficina}
          touched={formik.touched.tel_oficina}
        />

        <IngresaDatosForm
          col="col col-sm-3"
          tituloLabel="Teléfono Celular"
          formatoLabel="tituloIzquierda"
          nameControl="tel_celular"
          type="mobile"
          placeHolderControl="Ingresa el tel. celular"
          valueControl={formik.values.tel_celular}
          onChangeControl={formik.handleChange}
          error={formik.errors.tel_celular}
          touched={formik.touched.tel_celular}
        />

        <Form.Group controlId="formPais" className="col col-sm-3">
          <Form.Label>País</Form.Label>
          <Form.Select
            name="pais"
            defaultValue={formik.values.pais}
            onChange={formik.handleChange}
          >
            {map(paises, (pais, index) => (
              <option key={index} value={pais['id']}>{pais['nombre']}</option>
            ))}
          </Form.Select>
          <Form.Text className="text-danger">
            {formik.touched.pais && formik.errors.pais ? (
              <div className="text-danger">{formik.errors.pais}</div>
            ) : null}
          </Form.Text>
        </Form.Group>

        <IngresaDatosForm
          col="col col-sm-3"
          tituloLabel="Ciudad"
          formatoLabel="tituloIzquierda"
          nameControl="ciudad"
          placeHolderControl="Ingresa la ciudad"
          valueControl={formik.values.ciudad}
          onChangeControl={formik.handleChange}
          error={formik.errors.ciudad}
          touched={formik.touched.ciudad}
        />
      </Row>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Correo electrónico"
          formatoLabel="tituloIzquierda"
          nameControl="email"
          placeHolderControl="Ingresa el correo del usuario"
          valueControl={formik.values.email}
          onChangeControl={formik.handleChange}
          error={formik.errors.email}
          touched={formik.touched.email}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Contraseña"
          formatoLabel="tituloIzquierda"
          nameControl="password"
          type="password"
          placeHolderControl="Ingresa tu contraseña"
          valueControl={formik.values.password}
          onChangeControl={formik.handleChange}
          error={formik.errors.password}
          touched={formik.touched.password}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Confirma tú Contraseña"
          formatoLabel="tituloIzquierda"
          nameControl="passwordCon"
          type="password"
          placeHolderControl="confirma tu contraseña"
          valueControl={formik.values.passwordCon}
          onChangeControl={formik.handleChange}
          error={formik.errors.passwordCon}
          touched={formik.touched.passwordCon}
        />
      </Row>
      <Row>
        <IngresaDatosForm
          tituloLabel="¿En qué te gustaría colaborar?"
          formatoLabel="tituloIzquierda"
          nameControl="colaboracion"
          placeHolderControl=""
          rows={3}
          valueControl={formik.values.colaboracion}
          onChangeControl={formik.handleChange}
          error={formik.errors.colaboracion}
          touched={formik.touched.colaboracion}
        />
      </Row>
      <Divider />
      <Row className="mb-3">
        <Form.Group controlId="formCheckbox" className="col col-sm-6">
          <Button
            variant="primary"
            type="submit"
            className="me-4 btn-block"
          >
            Aceptar
          </Button>
          <Button
            variant="secondary"
            className="me-4 btn-block"
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </Button>
        </Form.Group>
        <Form.Group
          controlId="formCheckbox"
          className="col col-sm-6 d-flex flex-row"
        >
          <Form.Check
            name="aviso_privacidad"
            type="checkbox"
            label="Aceptar"
            defaultChecked={formik.values.aviso_privacidad}
            onChange={(data) =>
              formik.setFieldValue("aviso_privacidad", data.target.checked)
            }
          />
          <Nav.Link className="ms-2 text-primary"
            as={Link}
            to={"/aviso/privacidad"}
            active={pathname === "/aviso/privacidad"}
          >
            {` Aviso de Privacidad`}
          </Nav.Link>
        </Form.Group>
        <Form.Text className="text-danger text-center">
          {formik.touched.aviso_privacidad && formik.errors.aviso_privacidad ? (
            <div className="text-danger">{formik.errors.aviso_privacidad}</div>
          ) : null}
        </Form.Text>
      </Row>
    </Form>
  );
}

function initialValues() {
  return {
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    institucion: "",
    sitio_web: "",
    cargo: "",
    tel_oficina: "",
    tel_celular: "",
    email: "",
    pais: 1,
    ciudad: "",
    colaboracion: "",
    aviso_privacidad: false,
    password: "",
    passwordCon: ""
  };
}
function newSchema() {
  return {
    nombre: Yup.string("El nombre no puede ser vacio").required(
      "El nombre es requerido"
    ),
    apellido_paterno: Yup.string("El apellido no puede ser vacio").required(
      "El apellido es requerido"
    ),
    institucion: Yup.string("La institucion no puede ser vacia").required(
      "Institucion es requerido"
    ),
    sitio_web: Yup.string(
      "Debe ingresar el sitio web de la institucion"
    ).required("El sitio web es requerido"),
    tel_celular: Yup.string(
      "El telefono particular no puede ser vacio"
    ).required("El telefono particular es requerido"),
    email: Yup.string("El mail no puede ser vacio")
      .email("No es un formato de mail valido")
      .required("El mail es requerido"),
    pais: Yup.string("Debe seleccionar el pais").required(
      "El pais es requerido"
    ),
    ciudad: Yup.string("Debe ingresar el nombre de la ciudad").required(
      "La ciudad es requerida"
    ),
    colaboracion: Yup.string(
      "Debe indicar en que le gustaria colaborar"
    ).required("Este campo es requerido"),
    aviso_privacidad: Yup.bool()
      .oneOf([true], "Debe aceptar el aviso de privacidad")
      .required("Debe aceptar el aviso de privacidad"),
    password: Yup.string()
      .required("La contraseña es requerida")
      .min(8, "Debe contener un mínimo de 8 carácteres")
      .matches(/^(?=.*[a-z])/, " Debe tener como mínimo una letra en minúscula")
      .matches(/^(?=.*[A-Z])/, " Debe tener como mínimo una letra en mayúscula")
      .matches(/^(?=.*[0-9])/, " Debe tener como mínimo un número")
      .matches(/^(?=.*[!@#$%^&*()\-_=+{};:,<.>'])/, " Debe tener como mínimo un carácter"),
    passwordCon: Yup.string()
      .oneOf([Yup.ref("password"), null], "La confirmacion de password no es igual al proporcionado")
      .required("La contraseña de confirmación requerida"),
  };
}
