import React from "react";
import "./ExtraLayout.scss";
import { Container, Row, Col, Image, Nav } from "react-bootstrap";

import logo from "../../images/Logo.png";

export function ExtraLayout(props) {
  const { children } = props;
  return (
    <>
      <Row className="extra-layout">
        <Nav.Link href="/">
          <Image className="logo d-inline-block align-top" src={logo} />
        </Nav.Link>
      </Row>
      <Container>
        <Row>
          <Col />
          <Col>
            <div>{children}</div>
          </Col>
          <Col />
        </Row>
      </Container>
    </>
  );
}
