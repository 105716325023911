import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "lodash";
import {
  faSquarePollVertical,
  faArrowUpRightFromSquare,
  faChartArea,
  faChartLine,
  faChartPie,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";
import "./IndicadorListaPage.scss";
import { useIndicadores } from "../../../../hooks";
import { TituloPrincipal } from "../../../../components/Common";
import { formatDateMonthName } from "../../../../metodosGlobales/FormatoFecha";

export function IndicadorListaPage(props) {
  const { clave } = props;
  const { indicadores, getIndicadores, getIndicadoresBuscador } =
    useIndicadores();
  const obtenPath = (id) => {
    return "/indicador/vista/" + id;
  };
  useEffect(() => {
    if (clave) getIndicadoresBuscador(clave);
    else getIndicadores();
  }, []);

  const tipoIcon = (icono) => {
    switch (icono) {
      case "faChartArea":
        return faChartArea;
      case "faChartLine":
        return faChartLine;
      case "faChartBar":
        return faChartSimple;
      default:
        return faChartPie;
    }
  };

  console.log(clave);

  return (
    <div className="indicador-page">
      <TituloPrincipal titulo={"Indicadores"} />
      {map(indicadores, (indicador, index) => (
        <Row key={index} className="justify-content-center indicador-page-card">
          <Col xs={6}>
            <Card>
              <div className="card-horizontal">
                <div className="card-body">
                  <Row>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        className="indicator-page-iconBody"
                        icon={tipoIcon(indicador.icono)}
                      />
                    </Col>
                    <Col>
                      <div className="card-title">
                        {indicador.tituloIndicador}
                      </div>
                      <p className="card-text">{indicador.descripcionAmplia}</p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card-footer">
                <div>
                  <small className="text-muted">
                    Última actualización{" "}
                    {formatDateMonthName(indicador.fechaModificacion)}
                  </small>
                </div>
                <div>
                  <Link
                    to={obtenPath(indicador.id)}
                    className="btn btn-secondary btn-sm"
                  >
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    Ver Indicador
                  </Link>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      ))}
    </div>
  );
}
