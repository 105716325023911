import React, { useEffect } from "react";
import { Row, Spinner } from "react-bootstrap";

import {
  Descripcion,
  PaginacionRecursos,
  HeaderPage,
} from "../../components/Admin";
import { useRecurso } from "../../hooks";

export function RecursosClient() {
  const { loading, getRecurso, recursos } = useRecurso();

  useEffect(() => {
    getRecurso();
  }, []);

  return (
    <>
      <Row className="col">
        <HeaderPage title="Recursos de cliente" />
      </Row>
      <Row>
        <Descripcion />
      </Row>
      <Row>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">
                Obteniendo información .....
              </span>
            </Spinner>
          </div>
        ) : (
          <PaginacionRecursos datos={recursos} />
        )}
      </Row>
    </>
  );
}
