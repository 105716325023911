import React, { useEffect } from 'react'

import { TableAgendas } from '../../../components/b2b'
import { useB2B } from '../../../hooks'

export function Agendas() {

    const { agenda, getSitioWeb, loading } = useB2B()

    useEffect(() => {
        const getDate = async () => {
            await getSitioWeb()
        }
        getDate()
    }, [])

    console.log(agenda);

    return (
        <div>
            {
                loading ? null : <TableAgendas data={agenda} tipoUsuario="comprador" />
            }
        </div>
    )
}
