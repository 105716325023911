import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import "./FormaCategorias.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck, faTimes, faEdit } from "@fortawesome/free-solid-svg-icons"; 

export function FormaCategorias(props) {

  const {categorias,setCategorias,editingName,setEditingName} = props;

  const agregarCategoria = () => {
    const nuevaCategoria = {
      nombre: `Categoría ${categorias.length + 1}`,
      subcategorias: [],
    };
    setCategorias([...categorias, nuevaCategoria]);
  };

  const agregarSubcategoria = (indice) => {
    const nuevasCategorias = [...categorias];
    nuevasCategorias[indice].subcategorias.push(`Subcategoría ${nuevasCategorias[indice].subcategorias.length + 1}`);
    setCategorias(nuevasCategorias);
  };

  const handleEditName = (type, index, name) => {
    setEditingName({ type, index, name });
  };

  const handleSaveName = () => {
    const newCategories = [...categorias];
    if (editingName.type === "categoria") {
      newCategories[editingName.index].nombre = editingName.name;
    } else if (editingName.type === "subcategoria") {
      const subIndex = editingName.index.subIndex;
      newCategories[editingName.index.categoriaIndex].subcategorias[subIndex] = editingName.name;
    }
    setCategorias(newCategories);
    setEditingName({ type: null, index: null, name: "" });
  };

  const handleCancelEdit = () => {
    setEditingName({ type: null, index: null, name: "" });
  };

  return (
    <Row className="mt-4">
      <Col sm={12} md={6}>
        <label className="d-flex justify-content-start text-bold fs-5-5 text-resal mb-3">
          Agrega categorías para el evento
        </label>

        <label className="text-start fs-5-5 text-resal mb-3" style={{ whiteSpace: 'nowrap' }}>
          Puedes agregar hasta X categorías con X subcategorías para cada una
          <FontAwesomeIcon
              icon={faEdit}
              style={{ paddingLeft: '15px'}}
            />
            Editar
        </label>
  
             

        {categorias.map((categoria, indice) => (
          <div key={indice} className="category-container">
            <div className="category-header text-start fs-5-5 text-resal mb-3">
              <FontAwesomeIcon
                icon={faEdit}
                style={{ paddingRight: '15px', cursor: 'pointer' }}
                onClick={() => handleEditName("categoria", indice, categoria.nombre)}
              />

              {editingName.type === "categoria" && editingName.index === indice ? (
                <>
                  <input
                    type="text" 
                    value={editingName.name}
                    onChange={(e) => setEditingName({ ...editingName, name: e.target.value })}
                    style={{
                      border: '2px solid blue', borderRadius: '15px'      
                    }}
                  />
                  <Button className="btn-save" onClick={handleSaveName}>
                    <FontAwesomeIcon icon={faCheck} style={{ padding: '0px' }} />
                  </Button>
                  
                  <Button className="btn-cancel" onClick={handleCancelEdit}>
                    <FontAwesomeIcon icon={faTimes} style={{ padding: '0px' }} />
                  </Button>
                </>
              ) : (
                <>
                  <p style={{ color: 'skyblue', fontWeight: 'bold' }}>{categoria.nombre}</p>

                  <Button className="btn-subcategories" onClick={() => agregarSubcategoria(indice)}>
                    <FontAwesomeIcon icon={faPlus} style={{ padding: '0px' }} />
                    Subcategoría
                  </Button>
                  
                </>
              )}
            </div>
            <ul className="text-start fs-5-5 text-resal mb-3">
              {categoria.subcategorias.map((subcategoria, subIndice) => (
                <li key={subIndice}>
                  {editingName.type === "subcategoria" &&
                  editingName.index.categoriaIndex === indice &&
                  editingName.index.subIndex === subIndice ? (
                    <>
                      <input
                        type="text"
                        value={editingName.name}
                        onChange={(e) =>
                          setEditingName({
                            ...editingName,
                            name: e.target.value,
                          })
                        }
                        style={{
                          border: '2px solid blue', borderRadius: '15px'      
                        }}
                      />
                      
                      <Button className="btn-save" onClick={handleSaveName}>
                        <FontAwesomeIcon icon={faCheck} style={{ padding: '0px' }} />
                      </Button>

                      <Button className="btn-cancel" onClick={handleCancelEdit}>
                        <FontAwesomeIcon icon={faTimes} style={{ padding: '0px' }} />
                      </Button>
                    </>
                  ) : (
                    <>
                      <span style={{ color: 'skyblue' }}>{subcategoria}</span>
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ paddingLeft: '15px', cursor: 'pointer' }}
                        onClick={() =>
                          handleEditName("subcategoria", { categoriaIndex: indice, subIndex: subIndice }, subcategoria)
                        }
                      />
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
))}
       
<Button className="btn-categories" onClick={agregarCategoria}>
          <FontAwesomeIcon icon={faPlus} style={{ padding: '0px' }} />
          Agregar categoría
        </Button>
        
      </Col>
    </Row>
  );
}
