import React, { useState, useEffect } from "react";
import { Row, Spinner } from "react-bootstrap";

import {
  HeaderPage,
  TableProveedoresServiciosOperativo,
  DeleteProveedoresServiciosOperativo,
} from "../../../components/Admin";
import { useSolicitudServicios } from "../../../hooks";
import { BackButton } from "../../../components/Common";

export function ProveedoresBienesServiciosOperativo() {
  const { getSolicitudPeticionOperativo, solicitudPeticiones, loading } =
    useSolicitudServicios();

  const [refetch, setRefetch] = useState(false);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    const fetchData = async () => {
      getSolicitudPeticionOperativo(1);
    };
    fetchData();
  }, [refetch]);

  return (
    <div>
      <br />
      <Row className="mb-6">
        <BackButton
          btnTitle="Regresar a los servicios de membresía"
          link="/admin/serviciosLicenciamientoOperativo"
        />
      </Row>
      <br />
      <Row className="mb-6">
        <HeaderPage title="Administración de proveedores de bienes y servicios" />
      </Row>
      <br />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              Obteniendo información .....
            </span>
          </Spinner>
        </div>
      ) : (
        <TableProveedoresServiciosOperativo
          servicios={solicitudPeticiones}
          onRefetch={onRefetch}
        />
      )}
    </div>
  );
}
