import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { faIcons } from "@fortawesome/free-solid-svg-icons";

import { PaginationIconsB2B } from "./PaginationIconsB2B";
import { ModalBasic } from "../ModalBasic";

/**
 * Componente que muestra la seleccion de 91 iconos.
 * 
 * Si lo quieres usar debes mandar un setIcon, este componente
 * 
 * te muestra los iconos en un modal 
 * 
 * con la finalidad de que el setIcon guarde el icono seleccionado
 * 
 * 
 * Por Ulises :D
 * 
 * ⠀⢀⣤⠶⠒⠲⢦⣄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⢰⡏⣡⠞⠛⠳⣦⠙⣇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⢸⣀⡏⠀⠀⠀⠸⡇⢷⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣠⡄⠀⠀⠀⠀⠀
⠈⠉⠁⠀⠀⠀⠀⢷⡘⢷⣄⣤⣤⠤⢤⣤⣄⡀⠀⠀⢀⡶⠛⠹⡇⣀⣤⣤⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⣻⢶⣶⣶⡶⠀⠀⠀⠈⠙⠳⣴⣟⠁⠀⠀⢿⣿⣤⡏⠀⠀
⠀⠀⠀⠀⠀⠀⢠⡾⠃⠀⣤⣤⣄⠀⠀⠀⠀⠀⠀⠈⢻⣧⣀⣀⠀⠈⢻⣅⠀⠀
⠀⠀⠀⠀⠀⢠⡟⠀⠀⠀⢿⣿⣿⠀⠀⠀⠀⠀⠀⠀⠀⢻⣿⠟⣀⣀⣤⣿⡆⠀
⠀⠀⠀⠀⠀⣼⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⢀⠛⠿⠋⢹⡇⠀
⠀⠀⠀⠀⠀⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣤⡄⠀⠀⣿⡆⣀⣤⣿⣷⠆
⠀⠀⠀⠀⠀⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⡀⠀⠀⠀⣿⡇⢀⣤⡟⠋⠉⠀⠀⠉⠀
⠀⠀⠀⠀⠀⢹⡀⠰⣶⣤⣀⣀⣀⣀⣸⣿⠀⠀⢠⣿⣡⣾⣿⠃⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠘⣧⠀⠈⣧⠀⠉⠉⠉⢹⡇⠀⢠⡿⠛⣟⢡⡏⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠘⢷⡀⢹⡄⠀⠀⢀⡿⠀⢠⡟⠁⠀⢻⣌⣿⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠙⠛⠁⠀⠀⠈⠓⠚⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.setIcono - State que permite guardar el icono seleccionado.
 * @returns {JSX.Element} El componente ModalIconsB2B.
 */

export function ModalIconsB2B(props) {
  const { setIcono } = props;
  const [showModal, setshowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);

  const openCloseModal = () => setshowModal((prev) => !prev);

  const abrirModal = () => {
    setContentModal(
      <PaginationIconsB2B setIcono={setIcono} onClose={openCloseModal} />
    );
    openCloseModal();
  };

  return (
    <>
      <div className="text-center">
        <Button
          style={{ width: 150, borderRadius: 50 }}
          onClick={() => abrirModal()}
        >
          Seleccionar icono
        </Button>
      </div>
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={faIcons}
        title={"Selección de Icono"}
        children={contentModal}
        size={"lg"}
      />
    </>
  );
}
