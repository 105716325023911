import React from "react";
import { Card, Col, Row } from "react-bootstrap";

import logo from "../../../../images/Logo.png";
import "./CardForum.scss";

export function CardForum(props) {
  const { data } = props;
  const info_inicio = data.pagina_inicio.banner_principal;
  console.log(data);
  console.log(info_inicio);
  return (
    <section className="conteinerForums">
      <br />
      <Card>
        <Row className="conteinerForums">
          <Col className=" xs-12 sm-12 md-6 lg-6 xl-6 xxl-6 image-conteiner">
            <center>
              <picture>
                <source
                  srcset={
                    info_inicio?.image_banner ||
                    info_inicio?.image_banner !== ""
                      ? info_inicio?.image_banner
                      : logo
                  }
                  type="image/svg+xml"
                />
                <img
                  style={{
                    maxHeight: "600px",
                  }}
                  class="img-fluid"
                  alt="image desc"
                />
              </picture>
            </center>
          </Col>
          <Col className=" xs-12 sm-12 md-6 lg-6 xl-6 xxl-6 ">
            <center>
              <header>
                <h4>{info_inicio?.titulo_evento}</h4>
                <br />
                <Row>
                  <Col className=" xs-6 sm-6 md-6 lg-6 xl-6 xxl-6 ">
                    <h6>Fecha de evento:</h6>
                    <p>{info_inicio?.fecha_evento}</p>
                  </Col>
                  <Col className=" xs-6 sm-6 md-6 lg-6 xl-6 xxl-6 ">
                    <h6>Hora de evento:</h6>
                    <p>{info_inicio?.horario_evento}</p>
                  </Col>
                </Row>
                <p>Anfitrión:</p>
                <p>{info_inicio?.ponente_evento}</p>
                <p>Descripción</p>
                <p>{info_inicio?.descripcion}</p>
              </header>
            </center>
          </Col>
        </Row>
      </Card>
      <br />
    </section>
  );
}
