import React from "react";
import * as Yup from "yup";
import { Button, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useAuth } from "../../../../hooks";
import { useAyuda } from "../../../../hooks";
import "./FormAyudaNavegacion.scss";
import {
  formatDate,
  formatDateNow,
} from "../../../../metodosGlobales/FormatoFecha";
import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";
import { toast } from "react-toastify";

export function FormAyudaNavegacion(props) {
  const { auth } = useAuth();
  const { onClose, onRefetch, ayudanavegacion } = props;
  const { addAyudaNavegacion, updateAyudaNavegacion } = useAyuda();

  const formik = useFormik({
    initialValues: initialValues(ayudanavegacion, auth),
    validationSchema: Yup.object(
      ayudanavegacion ? updateSchema() : newSchema()
    ),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      if (ayudanavegacion)
        await updateAyudaNavegacion(ayudanavegacion.id, formvalue);
      else
        try {
          await addAyudaNavegacion(formvalue);
          toast.success(
            "Los datos fueron enviados correctamente a soporte, nosotros nos comunicaremos con usted, gracias."
          );
        } catch (error) {
          toast.error(
            "Hubo problemas para el envio de datos por favor vuelva a intentarlo."
          );
          console.error(error);
        }
      onRefetch();
      onClose();
    },
  });

  return (
    <Form className="add-edit-new-form" onSubmit={formik.handleSubmit}>
      <Row className="mb-6">
        <IngresaDatosForm
          col="col-4 col-sm-4 col-lg-2"
          tituloLabel="No. membresia."
          formatoLabel="tituloCentrado"
          nameControl="user"
          type="text"
          valueControl={formik.values.user}
          onChangeControl={formik.handleChange}
          disabled={true}
        />

        <IngresaDatosForm
          col="col-8 col-sm-8 col-lg-4"
          tituloLabel="Nombre."
          formatoLabel="tituloCentrado"
          nameControl="nombre"
          type="text"
          valueControl={formik.values.nombre}
          onChangeControl={formik.handleChange}
          disabled={true}
        />

        <IngresaDatosForm
          col="col-6 col-sm-6 col-lg-3"
          tituloLabel="Apellido paterno."
          formatoLabel="tituloCentrado"
          nameControl="apellido_paterno"
          type="text"
          valueControl={formik.values.apellido_paterno}
          onChangeControl={formik.handleChange}
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-lg-3"
          tituloLabel="Apellido materno."
          formatoLabel="tituloCentrado"
          nameControl="apellido_materno"
          type="text"
          valueControl={formik.values.apellido_materno}
          onChangeControl={formik.handleChange}
          disabled={true}
        />



        <IngresaDatosForm
          col="col-6 col-sm-6 col-lg-3 col-xl-4"
          tituloLabel="Fecha de solicitud."
          formatoLabel="tituloCentrado"
          nameControl="fecha_solicitud"
          type="text"
          placeHolderControl="aaaa/mm/dd"
          valueControl={formik.values.fecha_solicitud}
          onChangeControl={formik.handleChange}
          error={formik.errors.fecha_solicitud}
          touched={formik.touched.fecha_solicitud}
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-lg-4 col-xl-4"
          tituloLabel="Teléfono."
          formatoLabel="tituloCentrado"
          nameControl="telefono"
          type="text"
          placeHolderControl="Telefono de contacto"
          valueControl={formik.values.telefono}
          onChangeControl={formik.handleChange}
          error={formik.errors.telefono}
          touched={formik.touched.telefono}
          disabled={ayudanavegacion}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-lg-5 col-xl-4"
          tituloLabel="Email."
          formatoLabel="tituloCentrado"
          nameControl="email"
          type="email"
          placeHolderControl="Email de contacto"
          valueControl={formik.values.email}
          onChangeControl={formik.handleChange}
          error={formik.errors.email}
          touched={formik.touched.email}
          disabled={ayudanavegacion}
        />
      </Row>
      <Row className="mb-6">
        <Form.Group className="col col-sm-6 servicio-grid mt-3">
          <Form.Label className="me-3">
            ¿Tienes problema para ingresar a OTS?:
          </Form.Label>
          <Form.Check
            type="checkbox"
            label="Si"
            defaultChecked={formik.values.problema_ots}
            onChange={(data) =>
              formik.setFieldValue("problema_ots", data.target.checked)
            }
            disabled={ayudanavegacion}
          />
        </Form.Group>
        <Form.Group className="col col-sm-6 servicio-grid mt-3">
          <Form.Label className="me-3">
            ¿Necesitas asesoria para navegar en tu sesión?:
          </Form.Label>
          <Form.Check
            type="checkbox"
            label="Si"
            defaultChecked={formik.values.asesorias}
            onChange={(data) =>
              formik.setFieldValue("asesorias", data.target.checked)
            }
            disabled={ayudanavegacion}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mt-3">
          <Form.Label>Descripción del problema.</Form.Label>
          <Form.Control
            name="descripcion"
            as="textarea"
            placeholder="Describa con detalle cuál es el problema"
            rows={3}
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            disabled={ayudanavegacion}
          />
          <Form.Text className="text-danger">
            {formik.touched.descripcion && formik.errors.descripcion ? (
              <div className="text-danger">{formik.errors.descripcion}</div>
            ) : null}
          </Form.Text>
        </Form.Group>
      </Row>
      {ayudanavegacion ? (
        <Row>
          <Form.Group controlId="frmEstatus" className="mt-3 col-6 col-sm-6 col-lg-4 col-xl-3">
            <Form.Label>¿El problema está solucionado?:</Form.Label>
            <Form.Select
              defaultValue={formik.values.estatus}
              className="form-control"
              name="estatus"
              onChange={formik.handleChange}
            >
              <option value={1}>Pendiente</option>
              <option value={2}>Atendida</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label> Describa la solución al problema  </Form.Label>
            <Form.Control
              name="solucion"
              as="textarea"
              placeholder="¿Cómo se solucionó el problema?"
              rows={3}
              value={formik.values.solucion}
              onChange={formik.handleChange}
            />
          </Form.Group>

        </Row>
      ) : null}

      <Button variant="primary" type="submit">
        {ayudanavegacion ? "Actualizar" : "Crear solicitud"}
      </Button>
    </Form>
  );
}

function initialValues(data, auth) {
  return {
    user: auth.me?.id || "",
    nombre: auth.me?.nombre || "",
    apellido_paterno: auth.me?.apellido_paterno || "",
    apellido_materno: auth.me?.apellido_materno || "",
    fecha_solicitud: formatDate(data?.fecha_solicitud) || formatDateNow(),
    telefono: data?.telefono || "",
    email: data?.email || auth.me?.email,
    problema_ots: data?.problema_ots || false,
    asesorias: data?.asesorias || false,
    descripcion: data?.descripcion || "",
    solucion: data?.solucion || "",
    estatus: data?.estatus || "",
  };
}
function newSchema() {
  return {
    fecha_solicitud: Yup.string("Debe ingresar el telefono").required(
      "El telefono es requerido"
    ),
    email: Yup.string("El email no puede ir vacío").required(
      "El email es requerido"
    ),
    descripcion: Yup.string(
      "Debe ingresar la descripcion del problema o ayuda"
    ).required("La descripcion es requerida"),
    telefono: Yup.string("Debe ingresar el telefono").required(
      "El telefono es requerido"
    ),
  };
}

function updateSchema() {
  return {
    telefono: Yup.string("Debe ingresar el telefono").required(
      "El telefono es requerido"
    ),
    email: Yup.string("El email no puede ir vacío").required(
      "El email es requerido"
    ),
    descripcion: Yup.string(
      "Debe ingresar la descripcion del problema o ayuda"
    ).required("La descripcion es requerida"),
  };
}
