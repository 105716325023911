import { BASE_API } from "../utils/constants";

export async function getClienteApi() {
  try {
    const url = `${BASE_API}/api/clientes/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function getClienteIdUserApi(idUser) {
  try {
    const url = `${BASE_API}/api/clientes/busquedaCliente/${idUser}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addUserClienteApi(data) {
  const formData = new FormData();
  formData.append("nombre", data.nombre);
  formData.append("username", data.email);
  formData.append("apellido_paterno", data.apellido_paterno);
  formData.append("apellido_materno", data.apellido_materno);
  formData.append("is_active", false);
  formData.append("is_staff", false);
  formData.append("password", data.password);
  formData.append("estatus", 1);
  formData.append("role", 5);
  formData.append("email", data.email);
  try {
    const url = `${BASE_API}/api/users/userCliente/`;
    const params = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function addClienteGratisApi(data, idUser) {
  try {
    const formData = new FormData();
    formData.append("user", idUser);
    formData.append("telefono", data.telefono);
    formData.append("institucion", data.institucion);
    formData.append("pais", data.pais);
    formData.append("cargo", data.cargo);
    formData.append("sectores", data.sectores);
    formData.append("interes", data.interes);
    formData.append("plan", 1);
    formData.append("fecha_pago", null);
    formData.append("url_front", window.location.origin);
    const url = `${BASE_API}/api/clientes/`;
    const params = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateClienteGratisApi(id, token) {
  try {
    const formData = new FormData();
    formData.append("plan", 1);
    const url = `${BASE_API}/api/clientes/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addClienteBasicoApi(data, idUser) {
  try {
    const formData = new FormData();
    formData.append("user", idUser);
    formData.append("telefono", data.telefono);
    formData.append("institucion", data.institucion);
    formData.append("pais", data.pais);
    formData.append("cargo", data.cargo);
    formData.append("sectores", data.sectores);
    formData.append("interes", data.interes);
    formData.append("plan", 2);
    formData.append("estatus_pago", 1);
    formData.append("fecha_pago", null);
    const url = `${BASE_API}/api/clientes/`;
    const params = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addClientePremiumApi(data, idUser) {
  try {
    const formData = new FormData();
    formData.append("user", idUser);
    formData.append("telefono", data.telefono);
    formData.append("institucion", data.institucion);
    formData.append("pais", data.pais);
    formData.append("cargo", data.cargo);
    formData.append("sectores", data.sectores);
    formData.append("interes", data.interes);
    formData.append("plan", 3);
    formData.append("estatus_pago", 1);
    formData.append("fecha_pago", null);

    const url = `${BASE_API}/api/clientes/`;
    const params = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateClientePlanApi(data, clienteId, idUser) {
  try {
    const formData = new FormData();
    formData.append("user", idUser);
    formData.append("telefono", data.telefono);
    formData.append("institucion", data.institucion);
    formData.append("pais", data.pais);
    formData.append("cargo", data.cargo);
    formData.append("sectores", data.sectores);
    formData.append("interes", data.interes);
    formData.append("plan", data.plan);
    formData.append("estatus_pago", "1");
    formData.append("fecha_pago", null);
    const url = `${BASE_API}/api/clientes/${clienteId}/`;
    const params = {
      method: "PATCH",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteClienteApi(id, token) {
  try {
    const url = `${BASE_API}/api/clientes/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getBusquedaClienteApi() {
  try {
    const url = `${BASE_API}/api/clientesUser/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
