import React, { useEffect, useState } from "react";
import { Spinner, Row } from "react-bootstrap";
import {
  faNewspaper,
  faEdit,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";

import { useRecurso, useCatalagos, useAuth } from "../../../hooks";
import {
  HeaderPage,
  TableRecursos,
  DeleteRecursoForm,
  EditNewRecursoForm,
} from "../../../components/Admin";
import { BackButton, ModalBasic } from "../../../components/Common";

export function EstudiosInteligenciaOperativo() {
  const { loading, recursos, getRecursoInvestigacion } = useRecurso();
  const {auth} = useAuth()
  const { 
    getSectores, sectores,
    getTipoRecurso, tipoRecurso
  } = useCatalagos();
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("xl");

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    getRecursoInvestigacion(auth.me['id']);
    getSectores();
    getTipoRecurso();
  }, [refetch]);

  const addRecurso = () => {
    setTitleModal("Crear estudio de inteligencia");
    setIconModal(faNewspaper);
    setsizeModal("xl");
    setContentModal(
      <EditNewRecursoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        tipoRecurso={tipoRecurso}
      />
    );
    openCloseModal();
  };

  const updateRecurso = (data) => {
    setTitleModal("Actualizar estudio de inteligencia");
    setIconModal(faEdit);
    setsizeModal("xl");
    setContentModal(
      <EditNewRecursoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        recurso={data}
        sectores={sectores}
        tipoRecurso={tipoRecurso}
      />
    );
    openCloseModal();
  };

  const onDeleteRecurso = async (data) => {
    setTitleModal("Borrar estudio de inteligencia");
    setIconModal(faDeleteLeft);
    setsizeModal("sm");
    setContentModal(
      <DeleteRecursoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        recurso={data}
      />
    );
    openCloseModal();
  };

  return (
    <div>
      <br />
      <Row className="mb-6 col-12">
        <BackButton
          btnTitle="Regresar a los servicios de membresía"
          link="/admin/serviciosLicenciamientoOperativo"
        />
      </Row>
      <br />
      <Row className="mb-6 col-12">
        <HeaderPage
          title="Administración de estudios de inteligencia"
          btnTitle="Subir Estudio de inteligencia"
          btnClick={addRecurso}
        />
        <div className="col col-sm-2" />
      </Row>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              Obteniendo información .....
            </span>
          </Spinner>
        </div>
      ) : (
        <TableRecursos
          recursos={recursos}
          updateRecurso={updateRecurso}
          onDeleteRecurso={onDeleteRecurso}
        />
      )}
      <br />
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </div>
  );
}
