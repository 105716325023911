import React, { useEffect } from "react";
import { map } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faCirclePlus
} from "@fortawesome/free-solid-svg-icons";
import { Table,Row, Card, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHolderjs } from "use-holderjs";
import {useSectoresN} from "../../../../hooks";
import { TituloPrincipal, DividerIcon } from "../../../Common";
import "./SectoresNList.scss"

import { Divider, initAOS } from "../../../../components/Common";

export function SectoresNList(props) {
    useHolderjs();
    const btnTitle="Agregar Sector"
    //const { clave } = props;
    const {getSectoresN,sectoresNoti} = useSectoresN();
    //path
    const obtenPath = (id) => {
  
      return "/sectorNotis/" + id;
    };
    useEffect(() => {
        const fetchData = async () => {
          getSectoresN();
        };
        fetchData();
      }, []);
      
      if (sectoresNoti?.length != 0)
      return (
        <div className="sector-page"> 
         <TituloPrincipal
          titulo={"Sectores"}
        />
        <Divider />
        
        {map(sectoresNoti, (sectores, index) => (
        <div className="sector-page-card animacionSom-card" key={index}>
          <Link to={obtenPath(sectores.id)}>
            <div className="sector-page-container">
              <Image src={`${sectores.imagen}`} className="sector-page-image" />
              <div className="fondo"></div>
              <div className="centered">
                <FontAwesomeIcon
                  icon={faPaperclip}
                />
                  {sectores.descripcion}
                  
              </div>
            </div>
          </Link>
        </div>
      
      ))}
        
      </div>
      );
    else
      return (
        <div className="sector-page">
          <TituloPrincipal
          titulo={"Sectores"}
          />
          <h3>No hay, no existe </h3>
        </div>
      );
}
