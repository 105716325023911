import React from "react";
import "./TituloPrincipal.scss";

export function TituloPrincipal(props) {
  const { titulo } = props;
  return (
    <div className="titulo-principal">
      <h3 className="titulo-principal__header">{titulo}</h3>
    </div>
  );
}
