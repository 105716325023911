import React from 'react';
import { colorAssign } from '../../../metodosGlobales';
import { Map, } from '../../Common';

import './PrincipalCluster.scss';

export function Principal(props) {

    const { licenciaturas, ruta } = props;
    const colors = colorAssign('#ff4545', '#06d128')

    return (
        <div>
            <Map
                colors={colors}
                licenciaturas={licenciaturas}
                ruta={ruta}
            />
        </div>
    )
}
