import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FilterInput } from "../../../Common";
import { map } from "lodash";
import "./TableUsers.scss";

export function TableUsers(props) {

  const { users, updateUser, onDeleteUser } = props;
  const rol = ['', 'Administrativo', 'Operativo', 'Colaborador', 'Customer Services', 'Cliente'];
  const estatus = ['', 'Nuevo', 'Activo', 'Baja']
  const [loading, setLoading] = useState(false);

  const changeFormat = async () => {
    map(users, (user, index) => {
      user.role = rol[user.role];
      user.estatus = estatus[user.estatus];
    })
    setLoading(true)
  }

  const changeFormatOriginal = (user) => {

    switch (user.role) {
      case "Administrativo":
        user.role = 1
        break;
      case "Operativo":
        user.role = 2
        break;
      case "Colaborador":
        user.role = 3
        break;
      case "Customer Services":
        user.role = 4
        break;
      case "Cliente":
        user.role = 5
        break;
      default:
        break;
    }

    switch (user.estatus) {
      case "Nuevo":
        user.estatus = 1
        break;
      case "Activo":
        user.estatus = 2
        break;
      case "Baja":
        user.estatus = 3
        break;
      default:

        break;
    }

    return user;
  }

  useEffect(() => {
    changeFormat();
  }, [])

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
      grow: 2,
    },
    {
      name: "Apellido Paterno",
      selector: (row) => row.apellido_paterno,
      sortable: true,
      grow: 2,
    },
    {
      name: "Apellido Materno",
      selector: (row) => row.apellido_materno,
      sortable: true,
      grow: 2,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      grow: 3,
    },
    {
      name: "Rol",
      selector: (row) => row.role,
      sortable: true,
      grow: 2,
      cell: (row) => (
        typeof row.role === "string" ? (<p>{row.role}</p>) : (<p>{rol[row.role]}</p>)
      )
    },
    {
      name: "Estatus",
      selector: (row) => row.estatus,
      sortable: true,
      cell: (row) => (
        typeof row.estatus === "string" ? (<p>{row.estatus}</p>) : (<p>{estatus[row.estatus]}</p>)
      )

    },
    {
      name: "Activo",
      selector: (row) => row.is_active,
      sortable: true,
      cell: (row) => (

        row.is_active ? (
          <FontAwesomeIcon
            icon={faCheck}
            color="green"
            data-toggle="tooltip"
            data-placement="top"
            title="Usuario activo"
          />
        ) : (
          <FontAwesomeIcon
            icon={faXmark}
            color="red"
            data-toggle="tooltip"
            data-placement="top"
            title="Usuario Inactivo"
          />
        )
      )
    },
    {
      name: "Administrador",
      selector: (row) => row.is_staff,
      sortable: true,
      cell: (row) => (
        row.is_staff ? (
          <FontAwesomeIcon
            icon={faCheck}
            color="green"
            data-toggle="tooltip"
            data-placement="top"
            title="Administrador"
          />
        ) : (
          <FontAwesomeIcon
            icon={faXmark}
            color="red"
            data-toggle="tooltip"
            data-placement="top"
            title="No administrador"
          />
        )
      )
    },
    {
      name: "acciones",

      button: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="success"
            onClick={() => updateUser(changeFormatOriginal(row))}
            className="table-users-admin__boton btn-boton"
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => onDeleteUser(changeFormatOriginal(row))}
            className="table-users-admin__boton btn-boton"
          >
            <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />

          </Button>
        </>
      )
    }
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.users.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterInput
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      {loading && (
        <DataTable
          columns={columns}
          data={filteredItems}
          defaultSortField="name"
          striped
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
        />
      )}
    </>
  );
}
