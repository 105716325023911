import React, { useState } from "react";
import * as Yup from "yup";
import { map } from "lodash";
import { Button, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  formatDate,
  formatDateNow,
} from "../../../../metodosGlobales/FormatoFecha";
import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";
import { useAyuda, useAuth } from "../../../../hooks";
export function FormAyudaCambio(props) {
  const { onClose, onRefetch, cambios, planes } = props;
  const { addAyudaCambio, updateAyudaCambio } = useAyuda();
  const { auth } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(cambios, auth),
    validationSchema: Yup.object(cambios ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      if (cambios)
        try {
          await updateAyudaCambio(cambios.id, formvalue);
          toast.success(
            "Los datos fueron actualizados correctamente,"
          );
        } catch (error) {
          toast.error(
            "Hubo problemas para actualizar datos por favor vuelva a intentarlo."
          );
          console.error(error);
        }
      else
        try {
          await addAyudaCambio(auth.me["id"], formvalue);
          toast.success(
            "Los datos fueron enviados correctamente, nosotros nos comunicaremos con usted, gracias."
          );
        } catch (error) {
          toast.error(
            "Hubo problemas para el envio de datos por favor vuelva a intentarlo."
          );
          console.error(error);
        }
      onRefetch();
      onClose();
    },
  });

  return (
    <Form className="add-edit-new-form" onSubmit={formik.handleSubmit}>
      <Row className="mb-6">
        <IngresaDatosForm
          col="col-6 col-sm-6 col-lg-3 col-xl-4"
          tituloLabel="Fecha de solicitud."
          formatoLabel="tituloCentrado"
          nameControl="fecha_solicitud"
          type="text"
          placeHolderControl="aaaa/mm/dd"
          valueControl={formik.values.fecha_solicitud}
          onChangeControl={formik.handleChange}
          error={formik.errors.fecha_solicitud}
          touched={formik.touched.fecha_solicitud}
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-lg-3 col-xl-4"
          tituloLabel="Teléfono."
          formatoLabel="tituloCentrado"
          nameControl="telefono"
          type="text"
          placeHolderControl="Teléfono de contacto"
          valueControl={formik.values.telefono}
          onChangeControl={formik.handleChange}
          error={formik.errors.telefono}
          touched={formik.touched.telefono}
          disabled={cambios}
        />
        <IngresaDatosForm
          col="col-12 col-sm-12 col-lg-6 col-xl-4"
          tituloLabel="Email."
          formatoLabel="tituloCentrado"
          nameControl="email"
          type="email"
          placeHolderControl="Email de contacto"
          valueControl={formik.values.email}
          onChangeControl={formik.handleChange}
          error={formik.errors.email}
          touched={formik.touched.email}
          disabled={cambios}
        />
      </Row>
      <Row className="mb-6 mt-3">
        <Form.Group controlId="frmActual" className="col-6 col-sm-6 col-lg-2 col-xl-2">
          <Form.Label>Membresia actual</Form.Label>
          <Form.Select
            defaultValue={formik.values.membresia_actual}
            className="form-control"
            name="membresia_actual"
            onChange={formik.handleChange}
            disabled={cambios}
          >
            {map(planes, (plan, index) => (
              <option key={index} value={plan["id"]}>
                {plan["nombre"]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="frmNueva" className="col-6 col-sm-6 col-lg-3 col-xl-2">
          <Form.Label>Membresia solicitada</Form.Label>
          <Form.Select
            defaultValue={formik.values.membresia_solicitada}
            className="form-control"
            name="membresia_solicitada"
            onChange={formik.handleChange}
            disabled={cambios}
          >
            {map(planes, (plan, index) => (
              <option key={index} value={plan["id"]}>
                {plan["nombre"]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="mt-3">
          <Form.Label>Comentarios.</Form.Label>
          <Form.Control
            name="comentario"
            as="textarea"
            placeholder="Comentarios"
            rows={3}
            value={formik.values.comentario}
            onChange={formik.handleChange}
            disabled={cambios}
          />
          <Form.Text className="text-danger">
            {formik.touched.comentario && formik.errors.comentario ? (
              <div className="text-danger">{formik.errors.comentario}</div>
            ) : null}
          </Form.Text>
        </Form.Group>
      </Row>
      {cambios ? (
        <>
          <Row className="mt-3">
            <IngresaDatosForm
              col="col-7 col-sm-6 col-lg-4 col-xl-3"
              tituloLabel="Fecha de atención."
              formatoLabel="tituloCentrado"
              nameControl="fecha_atencion"
              type="text"
              placeHolderControl="Fecha de atención"
              valueControl={formik.values.fecha_atencion}
              onChangeControl={formik.handleChange}
              error={formik.errors.fecha_atencion}
              touched={formik.touched.fecha_atencion}
              disabled={cambios}
            />
            <Form.Group controlId="frmEstatus" className="col-4 col-sm-6 col-lg-2 col-xl-2">
              <Form.Label>Estatus</Form.Label>
              <Form.Select
                defaultValue={formik.values.estatus}
                className="form-control"
                name="estatus"
                onChange={formik.handleChange}
              >
                <option value={1}>Pendiente</option>
                <option value={2}>Atendida</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="frmEstatus" className="col-12 col-sm-12 col-lg-6">
              <Form.Text className="text-primary">
                Recuerde modificar el estatus de la solicitud si la solicitud ya
                este atendida.
              </Form.Text>
            </Form.Group>
          </Row>
          <Row className="mt-3">
            <Form.Group>
              <Form.Label> Describa la solución al problema </Form.Label>
              <Form.Control
                name="solucion"
                as="textarea"
                placeholder="¿Cómo se solucionó el problema?"
                rows={3}
                value={formik.values.solucion}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Row>
        </>
      ) : null}

      <Button variant="primary" type="submit">
        {cambios ? "Actualizar" : "Solicitar cambio de plan"}
      </Button>
    </Form>
  );
}

function initialValues(data, auth) {
  return {
    fecha_solicitud: formatDate(data?.fecha_solicitud) || formatDateNow(),
    telefono: data?.telefono || "",
    email: data?.email || auth.me?.email,
    membresia_actual: data?.membresia_actual || 1,
    membresia_solicitada: data?.membresia_solicitada || 1,
    fecha_atencion: data?.fecha_atencion || "",
    comentario: data?.comentario || "",
    solucion: data?.solucion || "",
    estatus: data?.estatus || "",
  };
}
function newSchema() {
  return {
    telefono: Yup.string("Debe ingresar el telefono").required(
      "El telefono es requerido"
    ),
    email: Yup.string("El email no puede ir vacío").required(
      "El email es requerido"
    ),
    membresia_actual: Yup.string("Debe seleccionar la menbresia actual")
      .required("La membresia es requerida")
      .notOneOf(["0"]),
    membresia_solicitada: Yup.string(
      "Debe seleccionar la membresia que solicita"
    )
      .required("La membresia es requerida")
      .notOneOf(["0"]),
  };
}

function updateSchema() {
  return {
    telefono: Yup.string("Debe ingresar el telefono").required(
      "El telefono es requerido"
    ),
    email: Yup.string("El email no puede ir vacío").required(
      "El email es requerido"
    ),
    membresia_actual: Yup.string("Debe seleccionar la menbresia actual")
      .required("La membresia es requerida")
      .notOneOf(["0"]),
    membresia_solicitada: Yup.string(
      "Debe seleccionar la membresia que solicita"
    )
      .required("La membresia es requerida")
      .notOneOf(["0"]),
    solucion: Yup.string("Debe detallar la solucion").required(
      "La solucion es requerido"
    ),
  };
}
