import React from "react";
import { Row } from "react-bootstrap";

import "./AvisoPrivacidad.scss";
import { Privacidad } from "../../../components/Client";
import { TituloPrincipal } from "../../../components/Common";
export function AvisoPrivacidad() {
  return (
    <div>
      <Row>
        <TituloPrincipal titulo="Aviso de Privacidad" />
      </Row>
      <Row className="mt-5">
        <Privacidad />
      </Row>
    </div>
  );
}
