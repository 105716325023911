import { assignIn } from "lodash";
import { useState } from "react";
import {
  addClienteBasicoApi,
  addClienteGratisApi,
  updateClienteGratisApi,
  addClientePremiumApi,
  deleteClienteApi,
  getClienteApi,
  updateClientePlanApi,
  addUserClienteApi,
  getClienteIdUserApi,
  getBusquedaClienteApi,
} from "../api/planGratis";
import { useAuth } from ".";

export function usePlanPago() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [clienteIdUser, setClienteIdUser] = useState(null);
  const [busquedaCliente, setBusquedaCliente] = useState(null);
  const { auth } = useAuth();

  const getCliente = async () => {
    try {
      setLoading(true);
      const response = await getClienteApi();
      setLoading(false);
      setCliente(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getClienteIdUser = async (idUser) => {
    try {
      setLoading(true);
      const response = await getClienteIdUserApi(idUser);
      setLoading(false);
      setClienteIdUser(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const returnClienteIdUser = async (idUser) => {
    try {
      setLoading(true);
      const response = await getClienteIdUserApi(idUser);
      setClienteIdUser(response);
      setLoading(false);
      return response;
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const addClienteGratis = async (data, idUser) => {
    try {
      setLoading(true);
      await addClienteGratisApi(data, idUser);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const updateClienteGratis = async (id) => {
    try {
      setLoading(true);
      await updateClienteGratisApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const addClienteBasico = async (data, idUser) => {
    try {
      setLoading(true);
      const response = await addClienteBasicoApi(data, idUser);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const updateClientePlan = async (data, clienteId, idUser) => {
    try {
      setLoading(true);
      const response = await updateClientePlanApi(data, clienteId, idUser);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const addClientePremium = async (data, idUser) => {
    try {
      setLoading(true);
      const response = await addClientePremiumApi(data, idUser);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const deleteClientePago = async (id) => {
    try {
      setLoading(true);
      await deleteClienteApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const addUserCliente = async (data) => {
    try {
      const response = await addUserClienteApi(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getBusquedaPorCliente = async () => {
    try {
      setLoading(true);
      const response = await getBusquedaClienteApi();
      setLoading(false);
      setBusquedaCliente(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    cliente,
    busquedaCliente,
    getCliente,
    clienteIdUser,
    getClienteIdUser,
    addUserCliente,
    addClienteGratis,
    updateClienteGratis,
    returnClienteIdUser,
    addClienteBasico,
    updateClientePlan,
    addClientePremium,
    deleteClientePago,
    getBusquedaPorCliente,
  };
}
