import { useFormik } from "formik";
import React from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";

import { IngresaDatosForm } from "../../../../Generales";

export function DescripcionAyuda(props) {
  const { onClose, data, estatus_ayuda } = props;

  const colorBadge = ["", "danger", "primary", "success"];

  const formik = useFormik({
    initialValues: initialValues(data),
    onSubmit: async (formvalue) => {
      onClose();
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Nombre"
          formatoLabel="tituloCentrado"
          nameControl="nombre"
          type="text"
          valueControl={formik.values.nombre}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Apellido Paterno"
          formatoLabel="tituloCentrado"
          nameControl="apellido_paterno"
          type="text"
          valueControl={formik.values.apellido_paterno}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Apellido Materno"
          formatoLabel="tituloCentrado"
          nameControl="apellido_materno"
          type="text"
          valueControl={formik.values.apellido_materno}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
      </Row>
      <br />
      <Row>
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Fecha de Solicitud"
          formatoLabel="tituloCentrado"
          nameControl="fecha_solicitud"
          type="text"
          valueControl={formik.values.fecha_solicitud}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Teléfono"
          formatoLabel="tituloCentrado"
          nameControl="apellido_materno"
          type="text"
          valueControl={formik.values.telefono}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
        <IngresaDatosForm
          col="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          tituloLabel="Correo electrónico"
          formatoLabel="tituloCentrado"
          nameControl="email"
          type="text"
          valueControl={formik.values.email}
          onChangeControl={formik.handleChange}
          rounded="30px"
          disabled={true}
        />
      </Row>
      <br />
      <Row>
        <Col className="sm-6">
          <center>
            <IngresaDatosForm
              tituloLabel="Estatus"
              formatoLabel="tituloCentrado"
            />
            <h5>
              <Badge bg={colorBadge[data.estatus]}>
                {estatus_ayuda[data.estatus]}
              </Badge>
            </h5>
          </center>
        </Col>
        <Col className="sm-6">
          <center>
            <IngresaDatosForm
              tituloLabel="Fecha de revisión"
              formatoLabel="tituloCentrado"
            />
            <h5>
              {data.fecha_respuesta ? data.fecha_respuesta : "--/--/----"}
            </h5>
          </center>
        </Col>
      </Row>
      <br />
      <Row>
        <Col className="sm-6 ">
          <Form.Check
            type="checkbox"
            label="¿Tienes problemas para ingresar a OTS?"
            value={formik.values.problema_acceder}
            name="problema_acceder"
            onChange={formik.handleChange}
            disabled={true}
            checked={formik.values.problema_acceder}
          />
        </Col>
        <Col className="sm-6 ">
          <Form.Check
            type="checkbox"
            label="¿Necesitas asesoria para navegar en OTS?"
            value={formik.values.problema_navegacion}
            name="problema_navegacion"
            onChange={formik.handleChange}
            disabled={true}
            checked={formik.values.problema_navegacion}
          />
        </Col>
      </Row>
      <br />
      <br />
      <IngresaDatosForm
        tituloLabel="¡Descríbenos tu problema para saber como ayudarte!"
        formatoLabel="tituloCentrado"
        nameControl="descripcion"
        type="text"
        rows={5}
        valueControl={formik.values.descripcion}
        onChangeControl={formik.handleChange}
        rounded="30px"
        error={formik.errors.descripcion}
        touched={formik.touched.descripcion}
        disabled={true}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="primary" type="submit" style={{ width: "auto" }}>
          Cerrar
        </Button>
      </div>
    </Form>
  );
}

function initialValues(data) {
  const fecha = new Date();
  const fechaData = data?.fecha_solicitud.split("T")[0] || null;
  return {
    nombre: data?.id_registro_externo?.user?.nombre,
    apellido_paterno: data?.id_registro_externo?.user?.apellido_paterno,
    apellido_materno: data?.id_registro_externo?.user?.apellido_materno,
    fecha_solicitud:
      fechaData ||
      `${fecha.getDate()} / ${fecha.getMonth()} / ${fecha.getFullYear()}`,
    telefono: data?.telefono || "",
    email: data?.id_registro_externo?.user?.email,
    descripcion: data?.descripcion || "",
    problema_acceder: data?.problema_acceder,
    problema_navegacion: data?.problema_navegacion,
  };
}
