import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useNoticia } from "../../../../hooks";
import { formatDateMonthName } from "../../../../metodosGlobales";
import "./NoticiaDetalle.scss";

export function NoticiaDetalle(props) {
  const { noticiaID } = props;
  const { noticiaId, getNoticiaId } = useNoticia();

  useEffect(() => {
    getNoticiaId(noticiaID);
    window.scrollTo(0, 0);
  }, [noticiaID]);


  return (
    <>
      <section className="noticia-detalle">
        <div className="contenedor-centrado">
          <div className="noticia-detalle-img">
            <img className="imagen" src={noticiaId.imagen} />
          </div>
        </div>
        <div className="contenedor-centrado">
          <div className="noticia-detalle-titulo">
            <h2 className="noticia-titular">{noticiaId.titular_noticia}</h2>
          </div>
          <div className="noticia-detalle-contenido">
            <div>
              <p>
                <strong>Fecha de subida:</strong>
                <br />
                {formatDateMonthName(noticiaId.fecha_alta)}
              </p>
            </div>
            <div>
              <p>
                <strong>Autor:</strong>
                <br />
                {noticiaId.autor_institucion}
              </p>
            </div>
            {
              noticiaId.pdf
                ? (
                  <div>
                    <p>
                      <strong>Ver archivo</strong>
                      <br />
                      <a className="text-color" href={noticiaId.pdf} target="_blank">
                        <FontAwesomeIcon icon={faFilePdf} size='2xl' />
                      </a>
                    </p>
                  </div>
                )
                : null
            }
          </div>
          <div>
            <a className="text-color text-center" href={noticiaId.link}>Más Información</a>
          </div>
          <br />
          <div className="noticia-redaccion">
            <p className="formatoTexto">{noticiaId.redaccion_completa}</p>
          </div>
        </div>
      </section>
    </>
  );
}
