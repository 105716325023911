import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import "./DetalleCancel.scss";
import { usePagos } from "../../../../hooks";
export function DetalleCancel(props) {
  const { clave } = props;
  const { addConfirmPago } = usePagos();
  const [respuesta, setRespuesta] = useState("");
  const [resutado, setResultado] = useState("");

  useEffect(() => {
    const result = addConfirmPago(3, clave);
    setRespuesta(result.respuesta);
    setResultado(result.resultado);
  }, []);

  return (
    <Row className="cancel justify-content-center">
      <Col xs={6}>
        <Card>
          {respuesta === "ok" ? (
            <>
              <Card.Header
                variant="top"
                className="cancel-header text-center pt-4"
              >
                <h4>¡Hubo un problema en su pago!</h4>
              </Card.Header>
              <Card.Body>
                <Card.Title>{resutado}</Card.Title>
                <Card.Text>
                  Le informamos que tuvimos un problema al recibir su pago por
                  favor vuelva a intentarlo o en caso de tener dudas puede
                  contactarnos a traves del menu de contactos, y nosotros lo
                  contactaremos lo antes posible.
                </Card.Text>
                <div className="text-center">
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className="cancel-icon"
                  />
                </div>
              </Card.Body>
            </>
          ) : (
            <>
              <Card.Title>El token Utilizado ya esta vencido</Card.Title>
              <Card.Text>
                Hemos detectado que intenta ingresar utilizando una clave ya
                antes utilizada por favor contactenos a traves de la seccion de
                contactanos para revisar su problema
              </Card.Text>
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="success-icon"
                />
              </div>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
}
