import React, { useState, useCallback } from "react";

import * as Yup from "yup";
import { map } from "lodash";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import { Button, Form, Row, Image } from "react-bootstrap";

import userImage from "../../../../images/user.png";
import { useColaborador, useUser } from "../../../../hooks";
import { datosEstaticosOTS, formatDateMonthName } from "../../../../metodosGlobales";
import "./AddEditColaboradoresForm.scss";

export function AddEditColaboradoresForm(props) {

  const { onClose, onRefetch, colaborador, sectores, honorarios } = props;
  const [previewImage, setPreviewImage] = useState(colaborador?.foto || userImage);
  const {
    updateColaboradorFirstTime,
    updateColaboradorDatosAdicionales,
    sendMailFirstUpdateColaborador,
  } = useColaborador();
  const { updateUser } = useUser();

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("foto", file);
    setPreviewImage(URL.createObjectURL(file));
  }, []);


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    noKeyboard: true,
    multiple: false,
    onDrop,
  });


  const formik = useFormik({
    initialValues: initialValues(colaborador),
    validationSchema: Yup.object(updateSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      if (colaborador.id_colaborador['estatus'] == 1) {
        console.log("PAso1");
        await updateUser(colaborador.id_colaborador.user['id'], datosUser(colaborador.id_colaborador.user))
        await updateColaboradorFirstTime(formValue, colaborador.id_colaborador['id']);
        await sendMailFirstUpdateColaborador(formValue.mail);
      }
      await updateColaboradorDatosAdicionales(formValue, colaborador.id);
      onRefetch();
      onClose();
    }

  });

  console.log(colaborador);

  return (
    <Form className="add-edit-colaborador-form" onSubmit={formik.handleSubmit}>

      <div className="container">
        <input{...getInputProps()} />
        <Image
          className="imagen"
          src={previewImage}
          thumbnail={true}
        />
        <div className="middle">
          <div>
            <Button variant="primary" {...getRootProps()}>
              "Cambiar imagen"
            </Button>
          </div>
        </div>
      </div>

      <hr></hr>
      <div className="container">
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-4 col-sm-4 col-lg-4">
            <Form.Label>Nombre(s).</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.nombre}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col-4 col-sm-4 col-lg-4">
            <Form.Label>Apellido Paterno.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.apellido_paterno} 
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col-4 col-sm-4 col-lg-4">
            <Form.Label >Apellido Materno.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.apellido_materno}
            </Form.Text>
          </Form.Group>
        </Row>
      </div>
      <br />
      <Form.Label
        className="formatoLabel">
        Datos de contacto.
      </Form.Label>
      <div className="container">
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-6">
            <Form.Label>Teléfono de oficina.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.tel_oficina}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-6">
            <Form.Label>Teléfono de celular.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.tel_celular}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="mt-3 col-12 col-sm-12 col-lg-6">
            <Form.Label>Email.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.mail}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="mt-3 col-12 col-sm-12 col-lg-6">
            <Form.Label>Sitio web.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.sitio_web}
            </Form.Text>
          </Form.Group>
        </Row>
      </div>
      <br />
      <div className="container">
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-6">
            <Form.Label>País.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.pais['nombre']}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-6">
            <Form.Label>Ciudad.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.ciudad}
            </Form.Text>
          </Form.Group>
        </Row>
      </div>
      <br />
      <Form.Label
        className="formatoLabel">
        Datos ocupacionales.
      </Form.Label>
      <div className="container">
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-6">
            <Form.Label>Institución.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.institucion}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-6">
            <Form.Label>Cargo.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.cargo}
            </Form.Text>
          </Form.Group>
        </Row>
      </div>
      <br />
      <div className="container">
        <Form.Group widths="equal">
          <Form.Label >Colaboración.</Form.Label>
          <br />
          <Form.Text
            className="formatoLabel-datosUser"
            onChange={formik.handleChange}
          >
            {formik.values.colaboracion}
          </Form.Text>
        </Form.Group>
      </div>
      <br />
      <Form.Label
        className="formatoLabel">
        Datos adicionales.
      </Form.Label>
      <div className="container">
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-4 col-sm-4 col-lg-4">
            <Form.Label>Fecha de alta.</Form.Label>
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            ><br />
              {formatDateMonthName(formik.values.fecha_alta)}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col-4 col-sm-4 col-lg-4">
            <Form.Label>Estatus.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {datosEstaticosOTS.estatusColaborador[formik.values.estatus]}
            </Form.Text>
          </Form.Group>
          <Form.Group widths="equal" className="col-4 col-sm-4 col-lg-4">
            <Form.Label >Aviso de privacidad.</Form.Label>
            <br />
            <Form.Text
              className="formatoLabel-datosUser"
              onChange={formik.handleChange}
            >
              {formik.values.aviso_privacidad}
            </Form.Text>
          </Form.Group>
        </Row>
      </div>
      <br />
      <Form.Group>
        <div className="container">
          <Form.Label>Especialización de la investigación.</Form.Label>
        </div>
        <Form.Control
          name="especializacion"
          as="textarea"
          placeholder="especializacion"
          rows={3}
          value={formik.values.especializacion}
          onChange={formik.handleChange}
        />
        <Form.Text className="text-danger">
          {formik.touched.especializacion && formik.errors.especializacion ? (
            <div className="text-danger">{formik.errors.especializacion}</div>
          ) : null}
        </Form.Text>
      </Form.Group>
      <div className="container">
        <Row className="mb-6">
          <Form.Group className="col-12 mt-3 col-sm-12 col-lg-8">
            <Form.Label>Trayectoria profesional.</Form.Label>
            <Form.Control
              name="trayectoria"
              as="textarea"
              placeholder="Trayectoria profesional"
              value={formik.values.trayectoria}
              onChange={formik.handleChange}
            />
            <Form.Text>Máximo 60 palabras.</Form.Text>
            <Form.Text className="text-danger">
              {formik.touched.trayectoria && formik.errors.trayectoria ? (
                <div className="text-danger">{formik.errors.trayectoria}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
          <Form.Group
            className="col-12 mt-3 col-sm-12 col-lg-4"
          >
            <Form.Label>Sectores.</Form.Label>
            <Form.Select
              name="sectores"
              defaultValue={formik.values.sectores}
              onChange={formik.handleChange}
            >
              <option>Selecciona una opción</option>
              {map(sectores, (sector, index) => (
                <option key={index} value={sector['id']}>{sector['descripcion']}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
      </div>
      <div className="container">
        <Row className="mb-6">
          <Form.Group widths="equal" className="col-12 mt-3 col-sm-12 col-lg-4">
            <Form.Label>Rango de Honorarios.</Form.Label>
            <Form.Select
              name="honorarios"
              defaultValue={formik.values.honorarios}
              onChange={formik.handleChange}
            >
              <option>Selecciona una opción</option>
              {map(honorarios, (honorario, index) => (
                <option key={index} value={honorario['id']}>{honorario['nombre']}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group widths="equal" className="col-12 mt-3 col-sm-12 col-lg-4">
            <Form.Label>Disponibilidad de días por semana.</Form.Label>
            <Form.Select
              name="dispone_dias"
              defaultValue={formik.values.dispone_dias}
              onChange={formik.handleChange}
            >
              <option>Selecciona una opción</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </Form.Select>
          </Form.Group>
          <Form.Group widths="equal" className="col-12 mt-3 col-sm-12 col-lg-4">
            <Form.Label>Horas de trabajo por semana.</Form.Label>
            <Form.Select
              name="dispone_horas"
              defaultValue={formik.values.dispone_horas}
              onChange={formik.handleChange}
            >
              <option>Selecciona una opción</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
            </Form.Select>
          </Form.Group>
        </Row>
      </div>
      <Button variant="primary" type="submit">
        Actualizar
      </Button>

    </Form>
  );
}

function initialValues(data) {
  return {
    nombre: data?.id_colaborador.user['nombre'] || "S/D",
    apellido_paterno: data?.id_colaborador.user['apellido_paterno'] || "S/D",
    apellido_materno: data?.id_colaborador.user['apellido_materno'] || "S/D",
    institucion: data?.id_colaborador['institucion'] || "S/D",
    sitio_web: data?.id_colaborador['sitio_web'] || "S/D",
    cargo: data?.id_colaborador['cargo'] || "S/D",
    tel_oficina: data?.id_colaborador['tel_oficina'] || "S/D",
    tel_celular: data?.id_colaborador['tel_celular'] || "S/D",
    mail: data?.id_colaborador.user['email'] || "S/D",
    pais: data?.id_colaborador.pais || "S/D",
    ciudad: data?.id_colaborador['ciudad'] || "S/D",
    colaboracion: data?.id_colaborador['colaboracion'] || "S/D",
    aviso_privacidad: "Confirmado",
    fecha_alta: data?.id_colaborador['fecha_alta'] || "S/D",
    estatus: data?.id_colaborador['estatus'] || "S/D",
    especializacion: data?.especializacion || "",
    trayectoria: data?.trayectoria || "",
    sectores: data?.sectores['id'] || 1,
    dispone_dias: data?.dispone_dias || "",
    dispone_horas: data?.dispone_horas || "",
    honorarios: data?.honorarios['id'] || 1,
    foto: "",
  };
}

function updateSchema() {
  return {
    estatus: Yup.string("El colaborador debe tener un status.")
      .required("El status es requerido"),
    especializacion: Yup.string("El colaborador debe tener una especialización.")
      .required("La especialización es requerida"),
    trayectoria: Yup.string("El colaborador debe tener una trayectoria")
      .required("La trayectoria es requerida"),
    sectores: Yup.string("El colaborador debe tener una trayectoria")
      .required("La trayectoria es requerida"),
    dispone_dias: Yup.string("El resumen no puede ir vacío")
      .required("El resumen es requerido"),
    dispone_horas: Yup.string("Debe de tener una redacción la noticia")
      .required("La redacción es requerida"),
    honorarios: Yup.string("Debe de haber un autor/institución de la noticia")
      .required("El autor/institución es requerido"),
  };
}

function datosUser(user) {
  const userData = {
    'is_active': true,
    'is_staff': false,
    'email': user.email
  }
  return userData
}
