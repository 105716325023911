import React from 'react'
import { Row } from "react-bootstrap";
import { TituloPrincipal } from "../../../components/Common"
import { BuscadorExterno } from '../../../components/Client/BuscadorExterno';

export function BusquedaExterna() {
  return (
    <>
      <Row>
        <TituloPrincipal titulo={"Búsqueda especializada"} />
      </Row>
      <div>
        <BuscadorExterno />
      </div>
    </>
  );
}
