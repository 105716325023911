import React from 'react'
import { CompanyProducts, EnterpriseDate } from '../../../components/Admin'

export function ProductMenu() {
  return (
    <>
    <CompanyProducts />
    <EnterpriseDate />
    </>
  )
}
