import React from "react";
import Button from "react-bootstrap/Button";
import "./DeleteAyudaServicio.scss";
import { useAyuda } from "../../../../hooks";
export function DeleteAyudaServicio(props) {
  const { onClose, onRefetch, ayudaservicio } = props;
  const { deleteAyudaServicio } = useAyuda();

  async function delServicioAyuda() {
    try {
      await deleteAyudaServicio(ayudaservicio.id);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h3 className="title-spacing">
        ¿Está seguro de eliminar el solictud de ayuda?
      </h3>
      <div className="delete-user-form">
      <Button variant="danger" className="btnaling"  onClick={delServicioAyuda}>
          Eliminar
        </Button>
        <Button variant="outline-danger" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}
