import React, { useCallback, useState } from 'react'
import { Form, Button, Image } from 'react-bootstrap';
import { useDropzone } from "react-dropzone"
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useEquipoOTS } from '../../../../hooks/useEquipoOTS'
import './AddEditEquipoOTSForm.scss'


export function AddEditEquipoOTSForm(props) {
    const { onClose, onRefetch, equipo } = props;
    const [previewImage, setPreviewImage] = useState(equipo?.foto || null)
    const { addEquipoOTS, updateEquipoOTS } = useEquipoOTS();

    /* console.log(equipo) */

    const onDrop = useCallback(async (acceptedFile) => {
        const file = acceptedFile[0]
        await formik.setFieldValue("foto", file)
        setPreviewImage(URL.createObjectURL(file))
        console.log(file)
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: { "image/*": [".jpeg", ".jpg", ".png"] },
        noKeyboard: true,
        multiple: false,
        onDrop,
    })


    const formik = useFormik({
        initialValues: initialValues(equipo),
        validationSchema: Yup.object(equipo ? updateSchema() : newSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                if (equipo) {
                    await updateEquipoOTS(equipo.id, formValue)
                    toast.success("Integrante actualizado");
                }
                else {

                    await addEquipoOTS(formValue);
                    toast.success("Integrante dado de alta");
                }
                onRefetch();
                onClose();
                console.log('Datos Enviados')
                console.log(formValue)

            } catch (error) {
                console.error(error);
                toast.error(error.message);

            }
        }
    });


    return (
        <>
            <Form
                className='add-edit-equipoots-form'
                onSubmit={formik.handleSubmit}>
                <Form.Group>
                    <Image className='add-edit-equipoots-foto' />
                    <div className='add-edit-equipoots-contenedorFoto'>
                        <Image className='foto-tamanio' src={previewImage} />
                        <Button
                            className='add-edit-equipoots-input'
                            variant={formik.errors.foto && "danger"}
                            {...getRootProps()}>
                            <FontAwesomeIcon icon={faUpload} className="me-2" />
                            {previewImage ? "Cambiar imagen" : "Ingresar imagen"}

                        </Button>
                    </div>
                    <input {...getInputProps()} />
                    <Form.Label>Nombres(s)</Form.Label>
                    <Form.Control
                        className='col col-sm-4'
                        name='nombre'
                        placeholder='Ingresa los nombre(s)'
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        error={formik.errors.nombre}
                        onBlur={formik.handleBlur}
                    />
                    <Form.Text className="text-danger">
                        {formik.touched.nombre && formik.errors.nombre ? (
                            <div className='text-danger'>{formik.errors.nombre}</div>
                        ) : null}
                    </Form.Text>
                    <Form.Label>Apellido Paterno</Form.Label>
                    <Form.Control
                        className='col col-sm-4'
                        name='apellido_paterno'
                        placeholder='Ingresa el apellido paterno'
                        value={formik.values.apellido_paterno}
                        onChange={formik.handleChange}
                        error={formik.errors.apellido_paterno}
                        onBlur={formik.handleBlur}
                    />
                    <Form.Text className="text-danger">
                        {formik.touched.apellido_paterno && formik.errors.apellido_paterno ? (
                            <div className='text-danger'>{formik.errors.apellido_paterno}</div>
                        ) : null}
                    </Form.Text>
                    <Form.Label>Apellido Materno</Form.Label>
                    <Form.Control
                        className='col col-sm-4'
                        name='apellido_materno'
                        placeholder='Ingresa el apellido materno'
                        value={formik.values.apellido_materno}
                        onChange={formik.handleChange}
                        error={formik.errors.apellido_materno}
                        onBlur={formik.handleBlur}
                    />
                    <Form.Text className="text-danger">
                        {formik.touched.apellido_materno && formik.errors.apellido_materno ? (
                            <div className='text-danger'>{formik.errors.apellido_materno}</div>
                        ) : null}
                    </Form.Text>
                    <Form.Label>Descripción profesional</Form.Label>
                    <Form.Control
                        className='col col-sm-4'
                        name='descripcion_profesion'
                        as='textarea'
                        placeholder='Ingresar descripción'
                        value={formik.values.descripcion_profesion}
                        onChange={formik.handleChange}
                        error={formik.errors.descripcion_profesion}
                        onBlur={formik.handleBlur}
                    />
                    <Form.Text className="text-danger">
                        {formik.touched.descripcion_profesion && formik.errors.descripcion_profesion ? (
                            <div className='text-danger'>{formik.errors.descripcion_profesion}</div>
                        ) : null}
                    </Form.Text>
                    <Form.Label>Características o fortalezas</Form.Label>
                    <Form.Control
                        className='col col-sm-4'
                        name='caracteristicas_persona'
                        as='textarea'
                        placeholder='Ingresar Características'
                        value={formik.values.caracteristicas_persona}
                        onChange={formik.handleChange}
                        error={formik.errors.caracteristicas_persona}
                        onBlur={formik.handleBlur}
                    />
                    <Form.Text className="text-danger">
                        {formik.touched.caracteristicas_persona && formik.errors.caracteristicas_persona ? (
                            <div className='text-danger'>{formik.errors.caracteristicas_persona}</div>
                        ) : null}
                    </Form.Text>
                    <Form.Label>Correo Electrónico</Form.Label>
                    <Form.Control
                        className='col col-sm-4'
                        name='email'
                        placeholder='Ingresa el correo electrónico'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                        onBlur={formik.handleBlur}
                    />
                    <Form.Text className="text-danger">
                        {formik.touched.email && formik.errors.email ? (
                            <div className='text-danger'>{formik.errors.email}</div>
                        ) : null}
                    </Form.Text>
                    <div className="d-grid gap-2">
                        <Button
                            variant="outline-primary"
                            className='add-edit-equipoots-bc'
                            type="submit"
                        >
                            {equipo ? "Actualizar" : "Crear"}

                        </Button>
                    </div>
                </Form.Group>
            </Form>
        </>
    )
}

function initialValues(data) {
    return {
        foto: "",
        nombre: data?.nombre || "",
        apellido_paterno: data?.apellido_paterno || "",
        apellido_materno: data?.apellido_materno || "",
        descripcion_profesion: data?.descripcion_profesion || "",
        caracteristicas_persona: data?.caracteristicas_persona || "",
        email: data?.email || "",
    }
}

function newSchema() {
    return {
        foto: Yup.string().required(true),
        nombre: Yup.string()
            .required("El nombre es requerido"),
        apellido_paterno: Yup.string()
            .required('Este campo no puede ser vacio'),
        apellido_materno: Yup.string()
            .required('Este campo no puede ser vacio'),
        descripcion_profesion: Yup.string()
            .required('Este campo no puede ser vacio'),
        caracteristicas_persona: Yup.string()
            .required('Este campo no puede ser vacio'),
        email: Yup.string()
            .email()
            .required("El mail es requerido"),
    };
}


function updateSchema() {
    return {
        foto: Yup.string(),
        nombre: Yup.string()
            .required("El nombre es requerido"),
        apellido_paterno: Yup.string()
            .required('Este campo no puede ser vacio'),
        apellido_materno: Yup.string()
            .required('Este campo no puede ser vacio'),
        descripcion_profesion: Yup.string()
            .required('Este campo no puede ser vacio'),
        caracteristicas_persona: Yup.string()
            .required('Este campo no puede ser vacio'),
        email: Yup.string()
            .email()
            .required("El mail es requerido"),
    };
}