import React from "react";
import { Row } from "react-bootstrap";
import "./Evento.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocation,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import { map } from "lodash";
import { Link } from "react-router-dom";

import { Divider } from "../../../Common";

export function   Evento(props) {
  const { eventos } = props;

  return (
    <div className="event-page">
      <div className="animacionSomn">
        <FontAwesomeIcon
          icon={faCalendarDays}
          className="indicator-page-inline indicator-page-iconTit"
        />
        <h4 className="indicator-page-inline ">Eventos</h4>
      </div>
      <Divider />
      <ul>
        {map(eventos, (evento, index) => (
          <li key={index} className="animacionSom-evento">
            <a href={evento.url} target="_blank" rel="noopener noreferrer">
              <span className="date color-cranberry bg-after">
                <span className="day">{evento.fecha_evento}</span>
                <span className="month">{evento.hora_evento}</span>
              </span>
              <span className="description">
                <Row>
                  <strong>{evento.nombre}</strong>
                </Row>
                <Row>
                  <strong>{evento.autor}</strong>
                </Row>
                <span className="location">
                  <FontAwesomeIcon icon={faMapLocation} />{" "}
                  {evento.sede ? evento.sede : evento.url}
                </span>
              </span>
            </a>
          </li>
        ))}
      </ul>
      
      <div className="event-page__divBtn">
        <Link
          to="/eventos/lista/"
          className="justify-content-center event-page__btnMas btn"
          size="sm"
        >
          Ver más eventos
        </Link>
      </div>
      <Divider />
    </div>
  );
}
