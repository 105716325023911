import React from "react";
import { Row, Col } from "react-bootstrap";

import { CookiePolicy } from '../../../../Client'
import "./CookiePage.scss";

export function CookiePage() {
  return (
    <div>
      <Row className="justify-content-center">
        <Col sm={8}>
          <div className="text-center">
            Utilizamos cookies para mejorar la experiencia del usuario y
            analizar el tráfico del sitio. Para obtener más información, lea
            nuestra{" "}
            <a className="cookie-policy-link" href="/aviso/privacidad/">
              politica de privacidad.
            </a>
            <p>
              Puede decidir qué cookies se utilizan seleccionando las
              respectivas opciones a continuación. Tenga en cuenta que su
              selección puede perjudicar en la funcionalidad del servicio.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
