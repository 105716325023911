import React from "react";
import { Row, Container } from "react-bootstrap";
import { Encabezado } from "./Encabezado";
import { BannerPrincipal } from "./BannerPrincipal";
import { InformacionGeneral } from "./InformacionGeneral";
import { BannerInformativo } from "./BannerInformativo";
import { GaleriaInformativa } from "./GaleriaInformativa";

import "./Inicio.scss";

export function Inicio({ inicio, datos }) {
  const {
    logo,
    setLogo,
    social,
    setSocial,
    principal,
    setPrincipal,
    infoGeneral,
    setInfoGeneral,
    bannerInfo,
    setbannerInfo,
    tituloGale,
    setTituloGale,
    colElements,
    setColElements,
  } = inicio;

  return (
    <Container>
      <Row>
        <label className="fs-5-5 text-resal text-start">
          *Si no llena algun campo no aparecera en la publicacion
        </label>
      </Row>
      <Row className="Container-general">
        <Encabezado
          logo={logo}
          setLogo={setLogo}
          social={social}
          setSocial={setSocial}
          datos={datos}
        />
      </Row>
      <Row className="Container-general">
        <BannerPrincipal principal={principal} setPrincipal={setPrincipal} />
      </Row>
      <Row className="Container-general">
        <InformacionGeneral
          infoGeneral={infoGeneral}
          setInfoGeneral={setInfoGeneral}
        />
      </Row>
      <Row className="Container-general">
        <BannerInformativo
          bannerInfo={bannerInfo}
          setbannerInfo={setbannerInfo}
        />
      </Row>
      <Row className="Container-general">
        <GaleriaInformativa
          tituloGale={tituloGale}
          setTituloGale={setTituloGale}
          colElements={colElements}
          setColElements={setColElements}
        />
      </Row>
    </Container>
  );
}
