import React, { useEffect, useState } from "react";
import { Spinner, Row } from "react-bootstrap";
import {
  faNewspaper,
  faEdit,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";

import { useRecurso, useCatalagos } from "../../hooks";
import { ModalBasic } from "../../components/Common";
import {
  TableRecursos,
  DeleteRecursoForm,
  EditNewRecursoForm,
  HeaderPage,
} from "../../components/Admin";

export function RecursosAdmin() {
  const { loading, recursos, getRecurso } = useRecurso();
  const {
    getSectores, sectores,
    getTipoRecurso, tipoRecurso
  } = useCatalagos();
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("xl");

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    getRecurso();
    getSectores();
    getTipoRecurso()
  }, [refetch]);

  const addRecurso = () => {
    setTitleModal("Crear Recurso");
    setIconModal(faNewspaper);
    setsizeModal("xl");
    setContentModal(
      <EditNewRecursoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        sectores={sectores}
        tipoRecurso={tipoRecurso}
      />
    );
    openCloseModal();
  };

  const updateRecurso = (data) => {
    setTitleModal("Actualizar Recurso");
    setIconModal(faEdit);
    setsizeModal("xl");
    setContentModal(
      <EditNewRecursoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        recurso={data}
        sectores={sectores}
        tipoRecurso={tipoRecurso}
      />
    );
    openCloseModal();
  };

  const onDeleteRecurso = async (data) => {
    setTitleModal("Borrar Recurso");
    setIconModal(faDeleteLeft);
    setsizeModal("lg");
    setContentModal(
      <DeleteRecursoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        recurso={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <Row>
        <HeaderPage
          title="Recursos de administrador"
          btnTitle="Nuevo Recurso"
          btnClick={addRecurso}
        />
      </Row>
      <Row>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">
                Obteniendo información .....
              </span>
            </Spinner>
          </div>
        ) : (
          <TableRecursos
            recursos={recursos}
            updateRecurso={updateRecurso}
            onDeleteRecurso={onDeleteRecurso}
          />
        )}
      </Row>
      <Row></Row>
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
