import React from 'react'
import { Container } from "react-bootstrap";
import "./BuscadorExterno.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export function BuscadorExterno() {
    return (
        <div>
            <Container>
                <div className='busquedaTitulo'>
                    <h2 className="busqueda-banner-tit animacionSomn">
                        ¿ESTÁS BUSCANDO ALGO MÁS ESPECIALIZADO?
                    </h2>
                </div>
                <div className="busqueda-banner-tit animacionSomn">
                    <form className="buscador" method="get" action="https://search.entireweb.com/search?">
                        <input className="search" placeholder="Buscar en la web..." name="q" />
                        <button size="sm" type="submit" className='botonBuscar'>
                            <FontAwesomeIcon className="iconBoton" icon={faSearch} />
                        </button>
                    </form>
                </div>
                <div className="busquedaLore">
                    <h4 className="busqueda-banner-tit animacionSomn">
                        En esta sección podrás encontrar el producto que quieras comprar en cuestión de milisegundos,
                        realizar búsquedas en tu aplicación por comandos de voz, buscar lugares, negocios o productos cerca de ellos.
                    </h4>
                </div>
            </Container>
        </div>
    )
}
