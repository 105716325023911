import { useState } from "react";
import { useAuth } from "./";

import {
 getSectoresNoticiasApi,
 getSectoresNoticiasTopApi,
 addSectorNoticiaApi,
 deleteSectorNoticiaApi,
 updateSectorNoticiaApi
} from "../api/sectoresNot";


export function useSectoresN() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [sectoresNoti, setSectoresN] = useState(null);
  const { auth } = useAuth();

  

  const getSectoresN = async () => {
    try {
      setLoading(true);
      const response = await getSectoresNoticiasApi();
      setLoading(false);
      setSectoresN(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

    const getSectoresNTop = async () => {
        try {
            setLoading(true);
            const response = await getSectoresNoticiasTopApi();
            setLoading(false);
            setSectoresN(response);
          } catch (error) {
            setError(error);
            setLoading(false);
          }
    };

    const addSectorNoticia = async (data) => {
      try {
        setLoading(true);
        await addSectorNoticiaApi(data, auth.token);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    const updateSectorNoticia = async (id, data) => {
      try {
        setLoading(true);
        await updateSectorNoticiaApi(id, data, auth.token);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    const deleteSectorNoticia = async (id) => {
      try {
        setLoading(true);
        await deleteSectorNoticiaApi(id, auth.token);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
  
  return {
    loading,
    error,
    sectoresNoti,
    getSectoresN,
    getSectoresNTop,
    addSectorNoticia,
    updateSectorNoticia,
    deleteSectorNoticia,
  };

}