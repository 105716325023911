import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import "./HeaderPage.scss";

export function HeaderPage(props) {
  const { title, btnTitle, btnClick, btnTitleTwo, btnClickTwo } = props;
  return (
    <div className="header-page-admin">
      <h4>{title}</h4>
      <div>
        {btnTitle && (
          <Button variant="success" onClick={btnClick} size="sm">
            <FontAwesomeIcon
              icon={faCirclePlus}
              className="header-page-admin__btnAdd"
            />
            {btnTitle}
          </Button>
        )}
        {btnTitleTwo && (
          <Button variant="danger" onClick={btnClickTwo}>
            {btnTitleTwo}
          </Button>
        )}
      </div>
    </div>
  );
}