import React, { useEffect } from "react";
import "./AlianzaPage.scss";
import { Row, Col, Image, Card } from "react-bootstrap";
import { map } from "lodash";
import { DividerIcon } from "../../../../components/Common";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { initAOS } from "../../../Common/AOS";

// import { Tooltip2 } from "./Tooltip";

export function AlianzaPage(props) {
  const { alianzas } = props;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 575 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  };

  // Animaciones
  useEffect(() => {
    initAOS();
  }, []);

  return (
    <div className="alianza my-5">
      <Row className="alianza my-3">
        <DividerIcon data-aos="fade-up" titulo="Nuestras Alianzas" />
      </Row>
      <Row className="justify-content-md-center alianza_content">
        <Col xs={6}>
          <p
            align="center"
            data-aos="fade-up"
            data-aos-delay="400"
            className="alianza_text"
          >
            Buscamos alianzas en busca de obtener el mejor valor agregado
          </p>
        </Col>
      </Row>
      <div data-aos="zoom-in" data-aos-delay="400" className="alianza-margen">
        <Carousel responsive={responsive} arrows infinite>
          {map(alianzas, (alianza, index) => (
            <div
              key={index}
              id="multi-carousel"
              className="carousel slide d-block"
              data-ride="carousel"
            >
              <div className="slide-container swiper">
                <div className="slide-content">
                  <div className="card-wrapper swiper-wrapper">
                    <div className="card swiper-slide">
                      <div className="image-content">
                        <span className="overlay"></span>
                        <div className="card-image">
                          <a
                            href={alianza.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image className="card-image" src={alianza.logo} />
                          </a>
                        </div>
                        <div className="card-content">
                          <div className="name">{alianza.nombre}</div>
                          <div className="description">
                            {alianza.descripcion}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
