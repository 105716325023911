import { useState } from "react";
import { useAuth } from "./";
import { getCarouselApi, editCarouselApi, addCarouselApi, getCarouselIdApi } from "../api/carousel";

export function useCarousel() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [carousel, setCarousel] = useState(null);
  const [carouselId, setCarouselId] = useState(0);
  const { auth } = useAuth();

  const addCarousel = async (data) => {
    try {
      setLoading(true);
      await addCarouselApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getCarousel = async () => {
    try {
      setLoading(true);
      const response = await getCarouselApi();
      setLoading(false);
      setCarousel(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getCarouselId = async (id) => {
    try {
      setLoading(true);
      const response = await getCarouselIdApi(id);
      setLoading(false);
      setCarouselId(response);
    } catch (error) {
      setError(error);
      throw error;
    }
  }

  const editCarousel = async (data, id) => {
    try {
      setLoading(true);
      await editCarouselApi(data, id, auth.token);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    carousel,
    carouselId,
    addCarousel,
    getCarousel,
    getCarouselId,
    editCarousel,
  };
}
