import { useState } from 'react'
import { getEquipoOTSApi, addEquipoOTSApi, updateEquipoOTSApi, deleteEquipoOTSApi } from '../api/equipoOTS'
import { useAuth } from './'

export function useEquipoOTS() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [equipoOTS, setEquipoOTS] = useState(null);
    const { auth } = useAuth();
    
    const getEquipoOTS = async () => {
        try {
            setLoading(true);
            const response = await getEquipoOTSApi();
            setLoading(false);
            setEquipoOTS(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const addEquipoOTS = async (data) =>{
        try {
            setLoading(true);
            await addEquipoOTSApi(data, auth.token);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }
    const updateEquipoOTS = async (id, data) =>{
        try {
            setLoading(true);
            await updateEquipoOTSApi(id, data, auth.token);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    const deleteEquipoOTS = async (id) =>{
        try {
            setLoading(true);
            await deleteEquipoOTSApi (id, auth.token);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    return {
        loading, error, equipoOTS, getEquipoOTS, addEquipoOTS, updateEquipoOTS, deleteEquipoOTS
    }
}