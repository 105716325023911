import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FilterInput } from "../../../../Common";

export function TablaRegistroExternoComprador(props) {
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row?.id_micrositio_cliente?.nombre_micrositio,
      sortable: true,
    },
    {
      name: "Mesa Asignada",
      selector: (row) => row.mesa_asignada,
      sortable: true,
    },
    {
      name: "Tipo Registro",
      selector: (row) =>
        row.tipo_registro === 1
          ? "Vendedor"
          : row.tipo_registro === 2
          ? "Comprador"
          : "",
      sortable: true,
      grow: 2,
    },
    {
      name: "Asignar Requerimientos",
      button: true,
      cell: (row) => (
        <>
          <a
            href={`/requerimientos-comprador-registro/${row?.id_micrositio_cliente?.id}`}
            className="table-crm-admin boton"
          >
            <Button
              size="sm"
              variant="secondary"
              data-toggle="tooltip"
              data-placement="top"
              title="Asignacion de requerimientos"
            >
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
            </Button>
          </a>
        </>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterInput
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="Sitios Web"
      columns={columns}
      data={filteredItems}
      defaultSortField="name"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
    />
  );
}
