import React, { useState, useMemo } from 'react'

import { Pagination } from '../Pagination'
import { icons } from '../../../images/IconsB2B/exportIconsB2B'
import { IconsDetails } from './IconsDetails'

let pageSize = 4

export function PaginationIconsB2B(props) {
    const { setIcono, onClose } = props 
    const [currentPage, setCurrentPage] = useState(1);
    const iconsArray = acomodarIcons();

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return iconsArray.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);

    return (
        <>
            <div className='text-center'>
                <label className="text-bold text-resal" >¡Escoge un Icono dandole click!</label>
            </div>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={iconsArray.length}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />
            {currentTableData.map((item, index) => {
                return (
                    <IconsDetails
                        key={index}
                        dato={item}
                        setIcono ={setIcono} 
                        onClose = {onClose} 
                    />
                );
            })}

            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={iconsArray.length}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </>
    )
}

function acomodarIcons() {
    const iconsLength = icons.length 
    let currentPage = 1;
    const iconsArray = []
    const numPaginas = iconsLength % 6 + Math.trunc(iconsLength / 6)
    for (let i = 0; i < numPaginas; i++ ){
        const arr = separarIconos(currentPage, 6);
        currentPage++;
        iconsArray.push(arr);
    }
    return iconsArray
}

function separarIconos(currentPage, pageSize) {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return icons.slice(firstPageIndex, lastPageIndex);
}