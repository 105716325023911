import { BASE_API } from '../utils/constants'

export async function getEquipoOTSApi(){
    try {
        const url = `${BASE_API}/api/equipoOTS/`;
        const response = await fetch(url);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export async function addEquipoOTSApi(data, token){
    try {
        const formData = new FormData();
        formData.append("foto", data.foto);
        formData.append("nombre", data.nombre);
        formData.append('apellido_paterno', data.apellido_paterno);
        formData.append('apellido_materno', data.apellido_materno);
        formData.append("descripcion_profesion", data.descripcion_profesion);
        formData.append("caracteristicas_persona", data.caracteristicas_persona);
        formData.append("email", data.email);
        console.log("data", formData);

        const url = `${BASE_API}/api/equipoOTS/`;
        const params = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          };

          const response = await fetch(url, params);
          const result = await response.json();
          return result;
    } catch (error) {
        throw error;
        
    }
}

export async function updateEquipoOTSApi(id, data, token){
    try {
        const formData = new FormData();
        if(data.foto) formData.append("foto", data.foto);
        formData.append("nombre", data.nombre);
        formData.append('apellido_paterno', data.apellido_paterno);
        formData.append('apellido_materno', data.apellido_materno);
        formData.append("descripcion_profesion", data.descripcion_profesion);
        formData.append("caracteristicas_persona", data.caracteristicas_persona);
        formData.append("email", data.email);
        console.log("data", formData);

        const url = `${BASE_API}/api/equipoOTS/${id}/`;
        const params = {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          };

          const response = await fetch(url, params);
          const result = await response.json();
          return result;
    } catch (error) {
        throw error;
            }
}

export async function deleteEquipoOTSApi(id, token) {
    try {
      const url = `${BASE_API}/api/equipoOTS/${id}/`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }