import React, { useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import "./RegistroComprador.scss";
import { Selector } from "./Selector";
import { IngresaDatosForm } from "../../../../../components/Generales/IngresaDatosForm";
export function RegistroComprador() {
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [cantidad, setCantidad] = useState(1); // Valor inicial de la cantidad

  const handleSectorChange = (event) => {
    setSelectedSector(event.target.value);
  };

  const handleIncrement = () => {
    setCantidad(cantidad + 1);
  };

  const handleDecrement = () => {
    if (cantidad > 1) {
      setCantidad(cantidad - 1);
    }
  };
  const handleImageSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const dashedLineStyle = {
    border: "1px dashed #000",
    height: "2px",
    width: "100%",
    marginTop: "20px",
  };

  return (
    <Col md={12}>
      <label className="d-flex justify-content-start text-bold fs-5-5 text-resal mb-3">
        Registro Comprador
      </label>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Form id="frmDatos">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <IngresaDatosForm
                      col="col-sm-12 col-md-12 col-xl-12 mb-3"
                      formatoLabel="tituloIzquierda"
                      nameControl="nombre"
                      type="text"
                      placeHolderControl="Nombre(s)"
                      floating
                    />
                    <IngresaDatosForm
                      col="col-sm-12 col-md-12 col-xl-12 mb-3"
                      formatoLabel="tituloIzquierda"
                      nameControl="Empresa"
                      type="text"
                      placeHolderControl="Empresa"
                      floating
                    />
                  </div>
                  <div className="col-md-6">
                    <IngresaDatosForm
                      col="col-sm-12 col-md-12 col-xl-12 mb-3"
                      formatoLabel="tituloIzquierda"
                      nameControl="apellido"
                      type="text"
                      placeHolderControl="Apellido Paterno"
                      floating
                    />
                    <form>
                      <div className="mb-6">
                        <select
                          id="sectorInput"
                          className="form-select"
                          value={selectedSector}
                          onChange={handleSectorChange}
                        >
                          <option value="">Sector</option>
                          <option value="1">Sector 1</option>
                          <option value="2">Sector 2</option>
                          <option value="3">Sector 3</option>
                        </select>
                      </div>
                    </form>
                  </div>
                  <div style={{ paddingTop: "20px", marginBottom: "20px" }}>
                    <IngresaDatosForm
                      rows={10}
                      col="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-12"
                      formatoLabel="tituloIzquierda"
                      nameControl="Descripcion"
                      type="text"
                      placeHolderControl="Descripcion de la Empresa"
                      style="height: 600px;"
                      floating
                    />
                  </div>
                  <IngresaDatosForm
                    col="col-sm-12 col-md-12 col-xl-12 mb-3"
                    formatoLabel="tituloIzquierda"
                    nameControl="web"
                    type="text"
                    placeHolderControl="Sitio Web"
                    style="paddingTop: 20px;"
                    floating
                  />
                  <div style={{ paddingTop: "20px", marginBottom: "20px" }}>
                    <IngresaDatosForm
                      col="col-sm-12 col-md-12 col-xl-12 mb-3"
                      formatoLabel="tituloIzquierda"
                      nameControl="correo"
                      type="text"
                      placeHolderControl="Correo"
                      style="paddingTop: 20px;"
                      floating
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div className="col-md-6">
            <Form id="frmDatos">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <IngresaDatosForm
                      col="col-sm-12 col-md-12 col-xl-12 mb-3"
                      formatoLabel="tituloIzquierda"
                      nameControl="apMaterno"
                      type="text"
                      placeHolderControl="Apellido Materno"
                      floating
                    />
                    <IngresaDatosForm
                      col="col-sm-12 col-md-12 col-xl-12 mb-3"
                      formatoLabel="tituloIzquierda"
                      nameControl="cargo"
                      type="text"
                      placeHolderControl="Cargo"
                      floating
                    />
                  </div>
                  <div className="col-md-6">
                    <IngresaDatosForm
                      col="col-sm-12 col-md-12 col-xl-12 mb-3"
                      formatoLabel="tituloIzquierda"
                      nameControl="telefono"
                      type="text"
                      placeHolderControl="Telefono"
                      floating
                    />
                  </div>
                </div>
                <Form>
                  <label className="d-flex justify-content-start text-bold fs-4-4 text-resal mb-3">
                    Carge el logo de la empresa
                  </label>
                  <Form.Group>
                    <Form.Control
                      type="file"
                      onChange={handleImageSelect}
                      color="blue"
                    />
                  </Form.Group>
                </Form>
                <div className="mt-3">
                  <p>Imagen seleccionada:</p>
                  <div
                    style={{
                      width: "400px",
                      height: "400px",
                      border: "1px dashed #ccc",
                      background: `url(${selectedImage}) center center / cover no-repeat`,
                      alignContent: "center",
                    }}
                  ></div>
                </div>
              </div>
            </Form>{" "}
          </div>
        </div>
        <div style={dashedLineStyle}></div>
        <label
          className="d-flex justify-content-start text-bold fs-5-5 text-resal mb-3"
          style={{ marginTop: "40px" }}
        >
          Seleccione los tipos de requerimientos de bienes y servicios{" "}
        </label>
        <div>
          <div>
            <Selector />
          </div>
          {/* Requerimientos Comprador */}

          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="mb-12">
                  <label
                    htmlFor="requerimientos"
                    className="form-label d-flex justify-content-start text-bold fs-5-5 text-resal"
                  >
                    Requerimientos
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <label
                    htmlFor="presupuestos"
                    className="form-label d-flex justify-content-start text-bold fs-5-5 text-resal"
                  >
                    Presupuestos
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="presupuestos"
                    style={{ borderRadius: "20px", right: "25px" }}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <label
                  htmlFor="cantidad"
                  className="form-label d-flex justify-content-start text-bold fs-5-5 text-resal"
                >
                  Cantidad
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    id="cantidad"
                    value={cantidad}
                    onChange={(e) => setCantidad(parseInt(e.target.value))}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="unidadMedida"
                    className="form-label d-flex justify-content-start text-bold fs-5-5 text-resal"
                  >
                    Unidad de medida
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="unidadMedida"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
