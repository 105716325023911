import React, { useState, useMemo } from 'react'

import { DetalleRecurso } from '../DetalleRecurso';
import { Pagination } from '../../../../Common';
import './PaginacionRecursos.scss'

let PageSize = 6;

export function PaginacionRecursos(props) {
  const { datos } = props
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return datos.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <>
      {currentTableData.map(item => {
        return (
          <DetalleRecurso
            key = {item.id}
            dato = {item}
          />
        );
      })}
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={datos.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
    </>
  );
}
