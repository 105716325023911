import { useState } from "react";
import { useAuth } from "./";
import {
    addRecursoApi,
    deleteRecursosApi,
    getRecursosApi,
    getRecursosInvestigacionApi,
    getRecursosIdApi,
    updateRecursosApi,
} from "../api/recursos"


export function useRecurso(){
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [recursos, setRecursos] = useState(null);
    const { auth } = useAuth();

    const getRecurso = async () => {
        try {
            setLoading(true);
            const response = await getRecursosApi(auth.token);
            setLoading(false);
            setRecursos(response);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const getRecursoInvestigacion = async (idUser) => {
        try {
            setLoading(true);
            const response = await getRecursosInvestigacionApi(idUser, auth.token);
            setLoading(false);
            setRecursos(response);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const getRecursoID = async (id) => {
        try {
            setLoading(true);
            const response = await getRecursosIdApi(id);
            setLoading(false);
            setRecursos(response);
            return response;
        } catch (error) {
            setError(error);
            console.log(error);
            setLoading(false);
        }
    };

    const addRecurso = async (data, tipoDocumento) => {
        try {
            setLoading(true);
            await addRecursoApi(data, tipoDocumento, auth.token);
            setLoading(false);
        } catch (error){
            setError(error);
            setLoading(false);
        }
    };

    const updateRecurso = async (id, data, tipoDocumento) => {
        try {
            setLoading(true);
            await updateRecursosApi(id, data, tipoDocumento, auth.token);
            setLoading(false);
        } catch (error){
            setError(error);
            setLoading(false);
        }
    };

    const deleteRecurso = async (id) => {
        try {
            setLoading(true);
            await deleteRecursosApi(id, auth.token);
            setLoading(false);
        } catch (error){
            setError(error);
            setLoading(false);
        }
    }

    return{
        loading,
        error,
        recursos,
        addRecurso,
        deleteRecurso,
        getRecurso,
        getRecursoInvestigacion,
        getRecursoID,
        updateRecurso,
    }
}