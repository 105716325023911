import React, { useEffect, useMemo, useState } from 'react'
import { map } from 'lodash'
import { formatDate } from '../../../../metodosGlobales'
import { asignarNombreDocumento } from '../metodosBoletin'
import "./TableBoletin.scss"
import DataTable from "react-data-table-component";
import { FilterInput } from '../../../Common'

export function TableBoletin(props) {

  const { boletines } = props;
  const [loading, setLoading] = useState(false);

  const nombreMes = (mesId) => {
    if (mesId === 1) {
      return "Enero";
    } else if (mesId === 2) {
      return "Febrero";
    } else if (mesId === 3) {
      return "Marzo";
    } else if (mesId === 4) {
      return "Abril";
    } else if (mesId === 5) {
      return "Mayo";
    } else if (mesId === 6) {
      return "Junio";
    } else if (mesId === 7) {
      return "Julio";
    } else if (mesId === 8) {
      return "Agosto";
    } else if (mesId === 9) {
      return "Septiembre";
    } else if (mesId === 10) {
      return "Octubre";
    } else if (mesId === 11) {
      return "Noviembre";
    } else if (mesId === 12) {
      return "Diciembre";
    }
  };

  const changeFormat = () => {

    map(boletines, (boletin) => {
      boletin.mesBoletin = nombreMes(boletin.mesBoletin);
      boletin.fechaSubida = formatDate(boletin.fechaSubida)
      boletin.nombreArchivo = asignarNombreDocumento(boletin.boletin)
    })
    setLoading(true);
  }

  useEffect(() => {
    changeFormat();
  }, [])


  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      grow: 0,
      width: "300px"

    },
    {
      name: "Mes",
      selector: (row) => row.mesBoletin,
      sortable: true,
      grow: 0,
      compact: true,
      width: "300px"
    },
    {
      name: "Boletin",
      selector: (row) => row.nombreArchivo,
      sortable: true,
      grow: 1,
      compact: true,
      cell: (row) => (
        <a
          href={row.boletin}
          download={asignarNombreDocumento(row.boletin)}
          target="_blank"
        >
          {row.nombreArchivo}
        </a>
      )
    },
    {
      name: "Fceha de subido",
      selector: (row) => row.fechaSubida,
      sortable: true,
      compact: true,
      width: "300px"

    },
  ]

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.boletines.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterInput
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      {loading && (
        <DataTable
          columns={columns}
          data={filteredItems}
          defaultSortField="name"
          striped
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
        />
      )}
    </>
  )
}
