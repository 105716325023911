import React, { useState, useRef } from "react";
import { Table, Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { ModalVerMas } from "../../../Common/ModalVerMas/ModalVerMas";

import { map } from "lodash";
import "./ExperienciaCliente.scss";

export function TableExperienciaCliente(props) {
  const { experienciaC, deleteExperienciaCliente, publicarExperienciaCliente } = props;

  return (
    <div className="table-responsive-xl">
      <Table striped bordered hover className="table-contactanos table-sm">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido Paterno</th>
            <th>Apellido Materno</th>
            <th>Empresa</th>
            <th>Puesto</th>
            <th>Comentario</th>
            <th>Logo</th>
          </tr>
        </thead>
        <tbody>
          {map(experienciaC, (experiencia, index) => (
            <tr key={index}>
              <td>{experiencia.nombre}</td>
              <td>{experiencia.apellidoPaterno}</td>
              <td>{experiencia.apellidoMaterno}</td>
              <td>{experiencia.empresa}</td>
              <td>{experiencia.puesto}</td>
              <td>
                <ModalVerMas title="Asunto" content={experiencia.comentario} />
              </td>
              <td>
                <Image src={experiencia.logo} className="foto" />
              </td>

              <Actions
                experiencia={experiencia}
                deleteExperienciaCliente={deleteExperienciaCliente}
                publicarExperienciaCliente={publicarExperienciaCliente}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
  function Actions(props) {
    const { experiencia, deleteExperienciaCliente, publicarExperienciaCliente } = props;

    return (
      <td>
        <Button
          size="m"
          variant="info"
          onClick={() => publicarExperienciaCliente(experiencia)}
          className="action-button green btn-boton"
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
        <Button
          size="m"
          variant="danger"
          onClick={() => deleteExperienciaCliente(experiencia)}
          className="action-button red btn-boton"
        >
          <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
        </Button>
      </td>
    );
  }
}
