import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useB2B } from "../../../../hooks";
import { Requerimientos } from "../../../../components/Client/MenuRequerimientoCliente/Requerimientos/Requerimientos";
import { TablaRegistroExternoComprador } from "../../../../components/Client/MenuRequerimientoCliente";

export function MenuRequerimiento() {
  const [refetch, setRefetch] = useState(false);
  const { loading, getSitioWeb, agenda } = useB2B();
  useEffect(() => {
    getSitioWeb();
  }, [refetch]);
  console.log("sitio web", agenda);

  return (
    <>
      <Row className="justify-content-center">
        <article>
          {loading ? null : (
            <TablaRegistroExternoComprador
              data={agenda}
              Requerimientos={Requerimientos}
            />
          )}
        </article>
      </Row>
    </>
  );
}
