import React, { useEffect, useState } from "react";
import { Form, Button, Table, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useB2B } from "../../../hooks";

export function NewList(props) {
  const [items, setItems] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const { subCategoria2, catId } = props;
  const { addSubMicrositio, getsubCatbyCat, b2bCategoria, getRegistroExternoVendedor, loading, userData, getExternoVendedorbyIdCat, b2bExterno, deleteExternoVendedor } = useB2B();

  useEffect(() => {
    getsubCatbyCat(catId);
    getRegistroExternoVendedor();
    getExternoVendedorbyIdCat(catId);
  }, [refetch]);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    onSubmit: async (values) => {
      handleAddItem();
    },
  });

  const handleAddItem = async () => {
    if (formik.values.nombre.trim() !== "" && formik.values.descripcion.trim() !== "") {
      const selectedItem = b2bCategoria.find(option => option.descripcion === formik.values.nombre);
      const newItem = {
        id: selectedItem.id,
        name: formik.values.nombre,
        description: formik.values.descripcion,
        id_micrositio_cliente: selectedItem.id_categoria_micrositio.id_micrositio_cliente,
      };

      try {
        await addSubMicrositio([newItem]);
        toast.success("Datos enviados correctamente");
        setItems([...items, newItem]);
        setRefetch(!refetch);
        formik.setFieldValue("nombre", "");
        formik.setFieldValue("descripcion", "");
      } catch (error) {
        console.error(error);
        toast.error("Hubo un error al enviar los datos");
      }
    }
  };

  const handleDeleteItem = async (index, itemId) => {
    try {
      await deleteExternoVendedor(itemId);
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
      setRefetch(!refetch);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Form className="mt-4" onSubmit={formik.handleSubmit}>
        <Row className="mb-4">
          <Col>
            <Form.Select
              value={formik.values.nombre}
              onChange={formik.handleChange}
              name="nombre"
            >
              <option value="">Selecciona una opción</option>
              {b2bCategoria &&
                b2bCategoria.map((option, index) => (
                  <option key={index} value={option.descripcion}>
                    {option.descripcion}
                  </option>
                ))}
            </Form.Select>
            {formik.errors.nombre && (
              <div className="text-danger">{formik.errors.nombre}</div>
            )}
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Descripción"
              value={formik.values.descripcion}
              onChange={formik.handleChange}
              name="descripcion"
            />
            {formik.errors.descripcion && (
              <div className="text-danger">{formik.errors.descripcion}</div>
            )}
          </Col>
          <Col col="col-2">
            <Button onClick={formik.handleSubmit}>Agregar</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Producto o Servicio</th>
                  <th>Descripción</th>
                  <th>Borrar</th>
                </tr>
              </thead>
              <tbody>
                {b2bExterno &&
                  b2bExterno.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.id_sub_categoria_micrositio?.descripcion}</td>
                      <td>{item?.descripcion}</td>
                      <td>
                        <Button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleDeleteItem(index, item.id)}
                        >
                          Eliminar
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Form>
    </>
  );
}

function initialValues() {
  return {
    nombre: "",
    descripcion: "",
  };
}

function validationSchema() {
  return Yup.object().shape({
    nombre: Yup.string().required("El nombre es requerido"),
    descripcion: Yup.string().required("La descripción es requerida"),
  });
}
