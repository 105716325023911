import React, { useState, useRef } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { IngresaDatosForm } from "../../../../../components/Generales/IngresaDatosForm";
import { ModalIconsB2B } from "../../../../../components/Common";

import "./InformacionGeneral.scss";

export function InformacionGeneral(props) {
  const { infoGeneral, setInfoGeneral } = props;
  const [icono1, setIcono1] = useState({ image: null, filename: "" });
  const [icono2, setIcono2] = useState({ image: null, filename: "" });
  const [icono3, setIcono3] = useState({ image: null, filename: "" });
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  console.log("inofo general", infoGeneral);

  const handleInputChange = (event) => {
    setInfoGeneral({
      ...infoGeneral,
      [event.target.name]: event.target.value,
    });
  };

  const handleIconSelect = (selectedIcon, index) => {
    setIcono1(index === 0 ? selectedIcon : icono1);
    setIcono2(index === 1 ? selectedIcon : icono2);
    setIcono3(index === 2 ? selectedIcon : icono3);
    console.log("que es", selectedIcon);

    setInfoGeneral({
      ...infoGeneral,
      [`icono${index + 1}`]: selectedIcon,
    });
  };

  return (
    <Row>
      <Row className="row-banner">
        <Col xs={6} md={4} className="col-label">
          <Form.Label className="d-flex justify-content-start text-bold text-resal mb-3">
            Información general
          </Form.Label>
        </Col>
      </Row>

      <Row className="justify-content-center align-items-center">
        <IngresaDatosForm
          col="col-sm-4 col-md-4 col-xl-4 mb-3"
          formatoLabel="tituloIzquierda"
          nameControl="tituloSeccion"
          type="text"
          placeHolderControl="Titulo de seccion (300 caracteres)"
          valueControl={infoGeneral.tituloSeccion}
          onChangeControl={handleInputChange}
          floating
        />
        <Row>
          {[
            { icono: icono1, setIcono: setIcono1, ref: fileInputRef1 },
            { icono: icono2, setIcono: setIcono2, ref: fileInputRef2 },
            { icono: icono3, setIcono: setIcono3, ref: fileInputRef3 },
          ].map(({ icono, setIcono, ref }, index) => (
            <Col
              key={index}
              xs={4}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <div className="row-icono">
                {icono && (
                  <img
                    src={infoGeneral[`icono${index + 1}`]}
                    alt="Imagen seleccionada"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
              <ModalIconsB2B
                setIcono={(selectedIcon) =>
                  handleIconSelect(selectedIcon, index)
                }
              />
              <IngresaDatosForm
                col="col-sm-8 col-md-8 col-xl-8 mb-3"
                formatoLabel="tituloIzquierda"
                nameControl={`titulo${index + 1}`}
                type="text"
                placeHolderControl="Titulo (100 caracteres)"
                valueControl={infoGeneral[`titulo${index + 1}`]}
                onChangeControl={handleInputChange}
                floating
              />
              <IngresaDatosForm
                col="col-sm-8 col-md-8 col-xl-8 mb-3"
                formatoLabel="tituloIzquierda"
                nameControl={`texto${index + 1}`}
                type="text"
                placeHolderControl="Texto (300 caracteres)"
                valueControl={infoGeneral[`texto${index + 1}`]}
                onChangeControl={handleInputChange}
                rows={3}
                floating
              />
            </Col>
          ))}
        </Row>
      </Row>
    </Row>
  );
}
