import React from 'react'
import { Card } from 'react-bootstrap'

import "./TitleCluster.scss"

export function TitleCluster(props) {

    const { title, date } = props;

    return (
        <div>
            <Card>
                <Card.Body className='centerText'>
                    {title || "Prueba del título"}<br />
                    Generado por el cluster estadístico basado por Estado,<br />
                    Tomando datos del año {date ? date[0] : "2015"} al {date ? date[1] : "2018"}
                </Card.Body>
            </Card>
        </div>
    )
}
