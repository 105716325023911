import React, { useState, useEffect } from "react";
import {
  TableAyudaCambio,
  HeaderPage,
  FormAyudaCambio,
  DeleteAyudaCambio,
} from "../../../components/Admin";
import { Spinner, Button, Form, Row } from 'react-bootstrap'
import { ModalBasic, BackButton } from "../../../components/Common";
import {
  faUserPen,
  faEdit,
  faDeleteLeft,
  faArrowsRotate
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAyuda, useCatalagos, useAuth } from "../../../hooks";

export function AyudaCambio() {
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const { loading, cambios, getAyudaCambio, cambiosUser, getAyudaCambioUser } =
    useAyuda();
  const { tiposPlan, getTipoPlan } = useCatalagos();
  const { auth } = useAuth();

  useEffect(() => {
    getTipoPlan();
    if (auth.me.role === 5) {
      getAyudaCambioUser(auth.me.id);
    } else {
      getAyudaCambio();
    }
  }, [refetch]);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateAyudaServicio = (data) => {
    setTitleModal("Actualizar Registro de Ayuda");
    setIconModal(faEdit);
    setContentModal(
      <FormAyudaCambio
        onClose={openCloseModal}
        onRefetch={onRefetch}
        planes={tiposPlan}
        cambios={data}
      />
    );
    openCloseModal();
  };

  const onDeleteAyudaServicio = async (data) => {
    setTitleModal("Cancelar cambio de plan");
    setIconModal(faDeleteLeft);
    setContentModal(
      <DeleteAyudaCambio
        onClose={openCloseModal}
        onRefetch={onRefetch}
        cambio={data}
      />
    );
    openCloseModal();
  };

  const addAyuda = () => {
    setTitleModal(" Solicitar Cambio de Plan");
    setIconModal(faUserPen);
    setContentModal(
      <FormAyudaCambio
        onClose={openCloseModal}
        onRefetch={onRefetch}
        planes={tiposPlan}
      />
    );
    openCloseModal();
  };

  return (
    <div>
      <br />
      <Row className="mb-6">
        <BackButton
          btnTitle="Regresar a Administración soporte"
          link="/admin/soporteNavegacaion"
        />
      </Row>
      <br />
      {auth.me.role === 5 ? (

        <HeaderPage
          title="Solicitudes de cambio de plan"
          btnTitle="Solicitar Cambio de Plan"
          btnClick={addAyuda}
        />
      ) : (
        <HeaderPage title="Solicitudes de cambio de plan" />

      )}

      <Form.Group className="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-1">
        <Form.Label>Actualizar</Form.Label>
        <Button
          className="form-control"
          variant="outline-info"
          onClick={() => onRefetch()}
        >
          <FontAwesomeIcon icon={faArrowsRotate} />

        </Button>
      </Form.Group>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner className="spinner" animation="border" />
        </div>
      ) : (

        <TableAyudaCambio
          cambios={auth.me.role === 5 ? cambiosUser : cambios}
          updateAyudaServicio={updateAyudaServicio}
          onDeleteAyudaServicio={onDeleteAyudaServicio}
        />
      )}


      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size="xl"
      />
    </div>
  );
}
