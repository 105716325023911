import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";

import { comprobarNombreMicroSitio, validatorSchema } from "./CheckData";

export function FormaNombre(props) {
  const { datos, setDatos, setButtonNext } = props;
  const [error, setError] = useState("");

  if (datos["nombre"] === "") setButtonNext(false);
  else setButtonNext(true);

  const handleInputChange = (e) => {
    if (e.target.value.length <= 20) {
      const sanitizedValue = e.target.value
        .toLowerCase()
        .replace(/[^a-z0-9 _-]/g, "")
        .replace(/\s+/g, "-");

      setDatos({ ...datos, nombre: sanitizedValue });
    }
  };

  const comprobarData = async (data) => {
    const formData = {
      microSite: data,
    };
    await validatorSchema(
      comprobarNombreMicroSitio,
      formData,
      setButtonNext,
      setError
    );
  };

  return (
    <Row className="mt-4">
      <label className="text-start text-bold fs-5-5 text-resal">
        Escoge un nombre para el micrositio.
      </label>
      <Form id="frmNombre">
        <div style={{ display: "flex", width: "79%" }}>
          <IngresaDatosForm
            col="col-sm-12 col-md-12 col-xl-6"
            formatoLabel="tituloCentrado"
            nameControl="nombre"
            type="text"
            placeHolderControl="Nombre del Sitio"
            onChangeControl={handleInputChange}
            valueControl={datos?.nombre}
            rounded="50px"
          />
          <p
            className="fw-bold text-resal"
            style={{ marginTop: 9, marginLeft: 8, fontSize: 25 }}
          >
            /ots.com
          </p>
        </div>
        {error === "" ? null : (
          <div style={{ display: "flex", width: "79%" }}>
            <p
              className="fw-bold text-resal"
              style={{ marginTop: 1, marginLeft: 8, fontSize: 16 }}
            >
              {error}
            </p>
          </div>
        )}
      </Form>

      <Row className="mt-3">
        <Col md="5">
          <ul className="text-start fw-bold text-resal">
            <li>Te recomendamos un nombre corto.</li>
            <li>Solo puedes utilizar minúsculas.</li>
            <li>No puedes utilizar caracteres espaciales.</li>
            <li>
              No puedes utilizar espacios estos se sustituirán por guiones (-).
            </li>
          </ul>
        </Col>
      </Row>
    </Row>
  );
}
