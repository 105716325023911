import React from "react";
import { IndicadorVistaPage } from "../../components/Client";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export function IndicadorVista() {
    const { id } = useParams();

    return (
        <Row>
            <IndicadorVistaPage indicadorID={id} />
        </Row>
    );
}
