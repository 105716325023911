import React from "react";
import { map } from "lodash";
import { Link } from "react-router-dom";
import { Row, Card, Button, CardGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquarePollVertical,
  faArrowUpRightFromSquare,
  faChartArea,
  faChartLine,
  faChartPie,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";

import { formatDateMonthName } from "../../../metodosGlobales";
import { DividerIcon } from "../../Common";
import "./TopBusqueda.scss";

export function TopBusqueda(props) {
  const { tituloPrincipal, palabraClave, data } = props;
  const obtenPath = (id, link) => {
    return `${link}${id}`;
  };

  const pathMas = {
    Noticias: `/noticia/lista/busqueda-${palabraClave}`,
    Indicadores: `/indicador/lista/busqueda-${palabraClave}`,
    Eventos: `/eventos/lista/busqueda-${palabraClave}`,
  };

  return (
    <div>
      <DividerIcon titulo={tituloPrincipal} />
      <br />
      <Row>
        <CardGroup className="col col-sm-10">
          {map(data, (dato, index) => (
            <SeleccionVista
              key={index}
              tituloPrincipal={tituloPrincipal}
              data={dato}
              obtenPath={obtenPath}
            />
          ))}
        </CardGroup>
        <div className="col col-sm-2">
          <div className="contenedor">
            <Link to={pathMas[tituloPrincipal]}>
              <Button variant="secondary" id="btn-mas">
                Ver más...
              </Button>
            </Link>
          </div>
        </div>
      </Row>
    </div>
  );
}

function SeleccionVista(props) {
  const { tituloPrincipal, data, obtenPath } = props;
  const vista = {
    Noticias: vistaNoticia(data, obtenPath),
    Indicadores: vistaIndicador(data, obtenPath),
    Eventos: vistaEvento(data, obtenPath),
  };
  return vista[tituloPrincipal];
}

function vistaNoticia(data, obtenPath) {
  return (
    <div className="contenedorCard">
      <Card style={{ width: "18rem" }}>
        <Card.Img className="imagen" variant="top" src={`${data?.imagen}`} />
        <Card.Body>
          <Card.Title>{data?.titular_noticia}</Card.Title>
          <Card.Subtitle>Sector: {data?.sectores?.descripcion}</Card.Subtitle>
          <Card.Text>{data?.resumen}</Card.Text>
          <small>Escrito por:</small>
          <br />
          <small>{data?.autor_institucion}</small>
          <br />
          <Card.Link href={obtenPath(data.id, "/noticia/detalle/")}>
            Ver noticia
          </Card.Link>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">
            {" "}
            Fecha de subida: {formatDateMonthName(data?.fecha_alta)}
          </small>
        </Card.Footer>
      </Card>
    </div>
  );
}

function vistaIndicador(data, obtenPath) {
  const iconos = {
    faSquarePollVertical: faSquarePollVertical,
    faArrowUpRightFromSquare: faArrowUpRightFromSquare,
    faChartArea: faChartArea,
    faChartLine: faChartLine,
    faChartPie: faChartPie,
    faChartSimple: faChartSimple,
  };
  return (
    <div className="contenedorCard">
      <Card style={{ width: "18rem" }}>
        <Card.Body>
          <Card.Title>
            <FontAwesomeIcon icon={iconos[data?.icono]} />{" "}
            {data?.tituloIndicador}
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            Sector: {data?.sectores}
          </Card.Subtitle>
          <Card.Text>{data?.detalle}</Card.Text>
          <br />
          <Card.Link href={obtenPath(data.id, "/indicador/vista/")}>
            Ver indicador
          </Card.Link>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">
            {" "}
            Fecha de subida: {formatDateMonthName(data?.fechaModificacion)}
          </small>
        </Card.Footer>
      </Card>
    </div>
  );
}

function vistaEvento(data, obtenPath) {
  return (
    <div className="contenedorCard">
      <Card style={{ width: "18rem" }}>
        <Card.Img className="imagen" variant="top" src={`${data?.imagen}`} />
        <Card.Body>
          <Card.Title>{data?.nombre}</Card.Title>
          <br />
          <Card.Text>{data?.descripcion}</Card.Text>
          <small>Dado por:</small>
          <br />
          <small>{data?.autor}</small>
          <br />
          <Card.Link href={data.url} target="_blank" rel="noopener noreferrer">
            Ver evento
          </Card.Link>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">
            {" "}
            Fecha de evento: {formatDateMonthName(data?.fecha_evento)} a las{" "}
            {data?.hora_evento}
          </small>
        </Card.Footer>
      </Card>
    </div>
  );
}
