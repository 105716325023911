import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Image } from "react-bootstrap";
import logo2 from '../../../../images/hactBlanco.png';
import logoBN from "../../../../images/ots-footer.png";
import logo from "../../../../images/png-blanco.png";
import "./Footer.scss";


export function Footer() {
  return (
    <footer className="footer-page">
      <nav className="foot menu">

        <ul className="footer-page__col">

          <li className="footer-page-item-node">
            <ul className="footer-page__ul">
              <strong>Contenidos</strong>
              <li className="item">
                <a href="/noticia/lista" target="" rel="noreferrer">
                  Noticias
                </a>
              </li>
              <li className="item">
                <a href="/indicador/lista/" target="" rel="noreferrer">
                  Indicadores
                </a>
              </li>
              <li className="item">
                <a href="/eventos/lista/" target="" rel="noreferrer">
                  Eventos
                </a>
              </li>
            </ul>
          </li>

          <li className="footer-page-item-node">
            <ul className="footer-page__ul">
              <strong>Herramientas</strong>
              <li className="item">
                <a href="/admin/" target="_blank" rel="noreferrer">
                  Seguimiento
                </a>
              </li>
            </ul>
          </li>
          <li className="footer-page-item-node ots">
            <ul className="footer-page__ul">
              <strong>OTS</strong>
              <li className="item">
                <a href="/acerca/" target="" rel="noreferrer">
                  Observatorio tecnológico
                </a>
              </li>
              <li className="item">
                <a href="/aviso/privacidad/" target="" rel="noreferrer">
                  Aviso de privacidad
                </a>
              </li>
            </ul>
          </li>



          <li className="footer-page-item-node">
            <ul className="footer-page__ul">
              <strong>Síguenos</strong>
              <li className="item">
                <a
                  href="https://www.facebook.com/Observatorio-Tecnol%C3%B3gico-y-Sistemas-de-Informaci%C3%B3n-OTS-102890962535501/"
                  title="Facebook"
                  className="facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                  <span style={{ marginLeft: "10px" }}>Facebook</span>
                </a>
              </li>
              <li className="item">
                <a
                  href="https://twitter.com/o_t_s__"
                  title="Twitter"
                  className="twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                  <span style={{ marginLeft: "10px" }}>Twitter</span>
                </a>
              </li>
              <li className="item">
                <a
                  href="https://www.instagram.com/"
                  title="Instagram"
                  className="Instagram"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                  <span style={{ marginLeft: "10px" }}>Instagram</span>
                </a>
              </li>
              <li className="item">
                <a
                  href="https://www.youtube.com/"
                  title="Youtube"
                  className="youtube"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                  <span style={{ marginLeft: "10px" }}>Youtube</span>
                </a>
              </li>
            </ul>
          </li>

        </ul>



        <div className="footer-page__link">
          <Image src={logo} />
          <Image src={logoBN} />
          <Image src={logo2} />
        </div>
      </nav>
    </footer>
  );
} 
