import React from "react";
import { Button } from "react-bootstrap";

import "./DeleteEventoForm.scss";
import { useEventos } from "../../../../hooks";
export function DeleteEventoForm(props) {
  const { onClose, onRefetch, evento } = props;
  const { deleteEvento } = useEventos();

  async function delEvento() {
    try {
      await deleteEvento(evento.id);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h3 className="title-spacing">¿Está seguro de eliminar el evento?</h3>
      <div className="delete-user-form">
        <Button className="btnaling" onClick={delEvento}>
          Eliminar
        </Button>
        <Button className="btnaling" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}
