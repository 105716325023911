import React, { useState } from 'react';
import './AddEditCatalogoForm.scss';

export function AddEditCatalogoForm({ onCategoryAdd }) {
  const [categoryName, setCategoryName] = useState('');

  const handleAddCategory = () => {
    if (categoryName.trim() !== '') {
      onCategoryAdd(categoryName);
      setCategoryName('');
    }
  };

  return (
    <div className="add-edit-catalogo-form">
      <input
        type="text"
        placeholder="Escribe categoría"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
      />
      <button onClick={handleAddCategory}>
        Agregar
      </button>
    </div>
  );
}
