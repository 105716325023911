import React, { useEffect, useState } from "react";
import { Row, Spinner } from "react-bootstrap";

import { useSolicitudServicios } from "../../../hooks";
import {
  HeaderPage,
  PaginacionWebinar,
  DescripcionWebinar,
} from "../../../components/Admin";
import { BackButton } from "../../../components/Common";

export function WebinarsCliente() {
  const { getWebinar, webinars, loading } = useSolicitudServicios();

  const [refetch, setRefetch] = useState(false);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    const fetchData = async () => {
      getWebinar();
    };
    fetchData();
  }, [refetch]);

  return (
    <div>
      <br />
      <Row className="mb-6">
        <BackButton
          btnTitle="Regresar a los servicios de membresía"
          link="/admin/serviciosLicenciamiento"
        />
      </Row>
      <br />
      <Row className="mb-6">
        <HeaderPage title="Webinars" />
        <div className="col col-sm-2" />
      </Row>
      <DescripcionWebinar />
      <br />
      <Row>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span>Obteniendo informacion .....</span>
            </Spinner>
          </div>
        ) : (
          <PaginacionWebinar datos={webinars} />
        )}
      </Row>
    </div>
  );
}
