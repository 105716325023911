import React, { useMemo, useState } from 'react'
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { formatDate } from "../../../../../metodosGlobales"
import "./TableRecurso.scss"
import { FilterInput } from '../../../../Common';

export function TableRecursos(props) {
  const { recursos, updateRecurso, onDeleteRecurso } = props
  console.log(recursos);

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Titulo",
      selector: (row) => row.titulo,
      sortable: true,
    },
    {
      name: "Catálogo",
      selector: (row) => row.catalogo.descripcion,
      sortable: true,
    },
    {
      name: "Tipo de documento",
      selector: (row) => row.tipoDeinvestigacion.nombre,
      sortable: true,
    },
    {
      name: "Tipo de recurso",
      selector: (row) => row.tipoRecurso.nombre,
      sortable: true,
    },
    {
      name: "Fecha de alta",
      selector: (row) => row.fecha_alta,
      sortable: true,
      cell: (row) => (<span>{formatDate(row.fecha_alta)}</span>)
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="success"
            className="table-recursos-admin__boton btn-boton"
            onClick={() => updateRecurso(row)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button
            size="sm"
            variant="danger"
            className="table-recursos-admin__boton btn-boton"
            onClick={() => onDeleteRecurso(row)}
          >
            <FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
          </Button>
        </>
      )
    },
  ]

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.recursos.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterInput
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (

    <DataTable
      columns={columns}
      data={filteredItems}
      defaultSortField="name"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
    />
  );
}