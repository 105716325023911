import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import * as Yup from "yup";
import { useB2B } from "../../../../hooks";
import "./requerimientos.scss";

export function NewList(props) {
  const [items, setItems] = useState([]);

  const [newItem, setNewItem] = useState("");
  const [newItemQuantity, setNewItemQuantity] = useState("");
  const [newItemBudget, setNewItemBudget] = useState("");
  const [newItemUnit, setNewItemUnit] = useState("");
  const {
    getUnidadDeMedida,
    loading,
    UnidadDeMedida,
    getExternoVendedorbyIdCat,
    b2bExterno,
    addExternoComprador,
    getExternoCompradorbyIdCat,
    b2bExternoComprador,
    deleteExternoComprador,
    getsubCatbyCat,
    b2bCategoria,
  } = useB2B();
  const [refetch, setRefetch] = useState(false);
  const { subCategoria2, catId } = props;

  useEffect(() => {
    getUnidadDeMedida();
    getExternoVendedorbyIdCat(catId);
    getExternoCompradorbyIdCat(catId);
    getsubCatbyCat(catId);
  }, [refetch]);
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    onSubmit: async (values) => {
      try {
        const { nombre, descripcion } = values;
      } catch (error) {
      }
    },
  });

  const handleAddItem = async () => {

    if (
      formik.values.id_registro_externo.trim() !== "" &&
      formik.values.cantidad.trim() !== "" &&
      formik.values.presupuesto.trim() !== "" &&
      formik.values.id_unidades_medida.trim() !== ""
    ) {
      const selectedItem = b2bCategoria.find(
        (option) => option.descripcion === formik.values.nombre
      );
      const newItem = {
        id_registro_externo: formik.values.id_registro_externo,
        cantidad: formik.values.cantidad,
        presupuesto: formik.values.presupuesto,
        id_unidades_medida: formik.values.id_unidades_medida,
        id_sub_categoria_micrositio_id: formik.values.id_registro_externo,
        // selectedItem?.id_sub_categoria_micrositio?.id,
      };
      setItems([...items, newItem]);
      formik.setFieldValue("id_registro_externo", "");
      formik.setFieldValue("cantidad", "");
      formik.setFieldValue("presupuesto", "");
      formik.setFieldValue("id_unidades_medida", "");
      setNewItem("");
      setNewItemQuantity("");
      setNewItemBudget("");
      await handleSaveData(newItem);
      setRefetch(!refetch);
    }
  };

  const handleSaveData = async (newItem) => {
    try {
      await addExternoComprador([newItem]);

    } catch (error) {
    }
    setRefetch(!refetch);
  };

  const handleDeleteItem = async (index, itemId) => {
    try {
      await deleteExternoComprador(itemId);

      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
      setRefetch(!refetch);
    } catch (error) {

    }
  };

  return (
    <>
      <h2 className="mt-4 mb-4">Productos o servicios ofertados</h2>
      <Row className="mb-3">
        <Col>
          <Form.Select
            value={formik.values.id_registro_externo}
            onChange={(e) =>
              formik.setFieldValue("id_registro_externo", e.target.value)
            }
            name="id_registro_externo"
          >
            <option value="">Selecciona una opción</option>
            {b2bCategoria &&
              b2bCategoria.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.descripcion}
                </option>
              ))}
          </Form.Select>

          <Form.Control
            type="text"
            placeholder="Cantidad"
            value={formik.values.cantidad}
            onChange={(e) => formik.setFieldValue("cantidad", e.target.value)}
            name="cantidad"
          />

          <Form.Control
            type="text"
            placeholder="Presupuesto"
            value={formik.values.presupuesto}
            onChange={(e) =>
              formik.setFieldValue("presupuesto", e.target.value)
            }
            name="presupuesto"
          />

          <Form.Select
            value={formik.values.id_unidades_medida}
            onChange={(e) =>
              formik.setFieldValue("id_unidades_medida", e.target.value)
            }
            name="id_unidades_medida"
            className="w-55"
            style={{ backgroundColor: "white", width: "10px" }}
          >
            <option value="">Unidad de medida</option>
            {UnidadDeMedida &&
              UnidadDeMedida.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.nombre}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col col="col-2">
          <Button onClick={handleAddItem}>Agregar</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Producto o Servicio</th>
                <th>Cantidad</th>
                <th>Presupuesto</th>
                <th>Unidad</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {b2bExternoComprador &&
                b2bExternoComprador.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {console.log(item.id_sub_categoria_micrositio, "c:")}
                      {item.id_sub_categoria_micrositio?.descripcion}
                    </td>
                    <td>{item.cantidad}</td>
                    <td>{item.presupuesto}</td>
                    <td>{item.id_unidades_medida.nombre}</td>
                    <td>
                      <Button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteItem(index, item.id)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row></Row>
    </>
  );
}

function initialValues() {
  return {
    id_registro_externo: "",
    cantidad: "",
    presupuesto: "",
    id_sub_categoria_micrositio_id: "",
    id_unidades_medida: "",
  };
}

function validationSchema() {
  return Yup.object({
    nombre: Yup.string().required("El nombre es requerido"),
    descripcion: Yup.string().required("La descripción es requerida"),
  });
}
