import React from "react";
import { map } from "lodash";
import { useFormik } from "formik";
import { Form, Row, Nav, Button } from "react-bootstrap";
import * as Yup from "yup";

import { Divider } from "../../../../../components/Common/Divider";
import { usePlanPago, usePagos, useUser } from "../../../../../hooks";
import { IngresaDatosForm } from "../../../../Generales";

import "./Renovacion.scss";

export function Renovacion(props) {
  const { onClose, onRefetch, sectores, paises, usuario, cliente } = props;
  const { getUrlCheckout } = usePagos();
  const { updateClientePlan } = usePlanPago();
  const { updateUser } = useUser();

  const formik = useFormik({
    initialValues: initialValues(usuario, cliente),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      try {
        //actualizar el usuraio
        //actualizar el cliente
        //obtener url

        const frmUser = {
          nombre: formvalue.nombre,
          username: formvalue.email,
          apellido_paterno: formvalue.apellido_paterno,
          apellido_materno: formvalue.apellido_materno,
          is_active: true,
          is_staff: false,
          role: 5,
          email: formvalue.email,
          estatus: 3,
        };
        console.log("formulario-cliente", formvalue);
        updateUser(usuario.id, frmUser);
        console.log("Actualizacion de cliente", usuario);
        updateClientePlan(formvalue, cliente.id, usuario.id).then((data) => {
          console.log("token asignado", data);
          getUrlCheckout(formvalue.plan, data.clave).then((response) => {
            console.log("url", response.url);
            window.open(response.url, "_self");
          });
        });

        //const response = await getUrlCheckout(formvalue.plan, clave);
        //console.log("url", response.url);
        //window.open(response.url, "_self");
        console.log("plan", formvalue.plan);
        onRefetch();
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Form className="alta-col-form" onSubmit={formik.handleSubmit}>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Nombre(s)"
          formatoLabel="tituloIzquierda"
          nameControl="nombre"
          type="text"
          placeHolderControl="Ingrese su nombre"
          error={formik.errors.nombre}
          touched={formik.touched.nombre}
          valueControl={formik.values.nombre}
          onChangeControl={formik.handleChange}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Apellido Paterno"
          formatoLabel="tituloIzquierda"
          nameControl="apellido_paterno"
          type="text"
          placeHolderControl="Ingrese su apellido paterno"
          valueControl={formik.values.apellido_paterno}
          touched={formik.touched.apellido_paterno}
          error={formik.errors.apellido_paterno}
          onChangeControl={formik.handleChange}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Apellido Materno"
          formatoLabel="tituloIzquierda"
          nameControl="apellido_materno"
          type="text"
          placeHolderControl="Ingrese su apellido materno"
          valueControl={formik.values.apellido_materno}
          touched={formik.touched.apellido_materno}
          error={formik.errors.apellido_materno}
          onChangeControl={formik.handleChange}
        />
      </Row>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Email"
          formatoLabel="tituloIzquierda"
          nameControl="email"
          type="email"
          placeHolderControl="Ingrese su correo"
          valueControl={formik.values.email}
          onChangeControl={formik.handleChange}
          touched={formik.touched.email}
          error={formik.errors.email}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Teléfono de casa u oficina"
          formatoLabel="tituloIzquierda"
          nameControl="telefono"
          type="text"
          placeHolderControl="Ingrese su telefono"
          valueControl={formik.values.telefono}
          onChangeControl={formik.handleChange}
          touched={formik.touched.telefono}
          error={formik.errors.telefono}
        />
        <Form.Group controlId="frmplan" className="col col-sm-4">
          <Form.Label>Tipo de Membresia</Form.Label>
          <Form.Select
            defaultValue={formik.values.plan}
            className="form-control"
            name="plan"
            onChange={formik.handleChange}
          >
            <option value={1}>Plan Gratis</option>
            <option value={2}>Plan Basico</option>
            <option value={3}>Plan Premium</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Institución de procedencia"
          formatoLabel="tituloIzquierda"
          nameControl="institucion"
          type="text"
          placeHolderControl="Ingrese su institucion"
          valueControl={formik.values.institucion}
          onChangeControl={formik.handleChange}
          touched={formik.touched.institucion}
          error={formik.errors.institucion}
        />
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Cargo"
          formatoLabel="tituloIzquierda"
          nameControl="cargo"
          type="text"
          placeHolderControl="Ingrese su cargo"
          valueControl={formik.values.cargo}
          onChangeControl={formik.handleChange}
        />
        <Form.Group controlId="formPais" className="col col-sm-4">
          <Form.Label>Pais</Form.Label>
          <Form.Select
            defaultValue={formik.values.pais}
            className="form-control"
            name="pais"
            onChange={formik.handleChange}
          >
            {map(paises, (pais, index) => (
              <option key={index} value={pais["id"]}>
                {pais["nombre"]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group controlId="formsectores" className="col col-sm-4">
          <Form.Label>Sectores de Interes</Form.Label>
          <Form.Select
            defaultValue={formik.values.sectores}
            name="sectores"
            onChange={formik.handleChange}
          >
            <option value={"0"}>Seleccione una opcion</option>
            {map(sectores, (sector, index) => (
              <option key={index} value={sector["id"]}>
                {sector["descripcion"]}
              </option>
            ))}
          </Form.Select>
          <Form.Text className="text-danger">
            {formik.touched.sectores && formik.errors.sectores ? (
              <div className="text-danger">{formik.errors.sectores}</div>
            ) : null}
          </Form.Text>
        </Form.Group>
        <IngresaDatosForm
          col="col col-sm-4"
          tituloLabel="Qué información es de tu interés"
          formatoLabel="tituloIzquierda"
          nameControl="interes"
          type="text"
          placeHolderControl="Ingrese su interes"
          valueControl={formik.values.interes}
          onChangeControl={formik.handleChange}
          touched={formik.touched.interes}
          error={formik.errors.interes}
        />
      </Row>
      <Divider />
      <Row className="mb-3">
        <Form.Group controlId="formCheckbox" className="col col-sm-6">
          <Button variant="primary" type="submit" className="me-4 btn-block">
            Aceptar
          </Button>

          <Button
            variant="secondary"
            className="me-4 btn-block"
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </Button>
        </Form.Group>
      </Row>
    </Form>
  );
}

function initialValues(usuario, cliente) {
  return {
    nombre: usuario.nombre,
    apellido_paterno: usuario.apellido_paterno,
    apellido_materno: usuario.apellido_materno,
    email: usuario.email,
    telefono: cliente.telefono,
    institucion: cliente.institucion,
    pais: cliente.pais,
    cargo: cliente.cargo,
    sectores: cliente.sectores,
    interes: cliente.interes,
    plan: cliente.plan,
  };
}
function newSchema() {
  return {
    nombre: Yup.string("El nombre no puede ser vacio").required(
      "El usuario es requerido"
    ),
    apellido_paterno: Yup.string(
      "El apellido paterno no puede ser vacio"
    ).required("El apellido paterno es requerido"),
    apellido_materno: Yup.string(
      "El apellido materno no puede ser vacio"
    ).required("El apellido materno es requerido"),
    email: Yup.string("El mail no puede ser vacio")
      .email("No es un formato de mail valido")
      .required("El mail es requerido"),
    telefono: Yup.string("Este campo no puede ser vacio").required(
      "El telefono es requerido"
    ),
    institucion: Yup.string("Este campo no puede ser vacio").required(
      "La institucion es requerida"
    ),
    sectores: Yup.string("Este campo no puede ser vacio")
      .required("Debe seleccionar un sector")
      .notOneOf(["0"]),
    interes: Yup.string("Este campo no puede ser vacio").required(
      "El interes es requerido"
    ),
  };
}
