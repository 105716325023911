import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  AddEditAlianzasForm,
  HeaderPage,
  TableAlianzas,
  DeleteAlianzas,
} from "../../components/Admin/";
import { useAlianzas } from "../../hooks/useAlianzas";
import { ModalBasic } from "../../components/Common";
import {
  faHandshake,
  faTrash,
  faFilePen,
} from "@fortawesome/free-solid-svg-icons";

export function AlianzasAdmin() {
  const { loading, alianzas, getAlianzas } = useAlianzas();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [sizeModal, setsizeModal] = useState("g");
  const [refetch, setRefetch] = useState(false);

  /*  console.log( alianzas ) */
  useEffect(() => {
    getAlianzas();
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const addAlianza = () => {
    setTitleModal("Nueva alianza");
    setIconModal(faHandshake);
    setsizeModal("l");
    setContentModal(
      <AddEditAlianzasForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  const updateAlianza = (data) => {
    setTitleModal("Actualizar alianza");
    setIconModal(faFilePen);
    setsizeModal("l");
    setContentModal(
      <AddEditAlianzasForm
        alianza={data}
        onClose={openCloseModal}
        onRefetch={onRefetch}
      />
    );
    openCloseModal();
  };

  const deleteAlianzas = async (data) => {
    setTitleModal("Borrar Alianza");
    setIconModal(faTrash);
    setsizeModal("m");
    setContentModal(
      <DeleteAlianzas
        onClose={openCloseModal}
        onRefetch={onRefetch}
        alianza={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <HeaderPage
        title="Alianzas"
        btnTitle="Nueva Alianza"
        btnClick={addAlianza}
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <TableAlianzas
          alianzas={alianzas}
          updateAlianza={updateAlianza}
          deleteAlianzas={deleteAlianzas}
        />
      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
