import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

import { useColaborador, useCatalagos } from "../../hooks";
import { ColaboraPage, AltaColaboraForm } from "../../components/Client";
import { ModalBasic, TituloPrincipal } from "../../components/Common";

export function Colabora() {
  const { activos, getActivos } = useColaborador();
  const { paises, getPaises } = useCatalagos();
  const [showModal, setshowModal] = useState(false);
  const [iconModal, setIconModal] = useState(null);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [sizeModal, setsizeModal] = useState("sm");
  const openCloseModal = () => setshowModal((prev) => !prev);

  useEffect(() => {
    getActivos();
    getPaises();
  }, []);

  const addColaborador = () => {
    setTitleModal(" Alta Colaborardor");
    setIconModal(faUserPlus);
    setsizeModal("xl");
    setContentModal(
      <AltaColaboraForm
        onClose={openCloseModal}
        paises={paises}
      />);
    openCloseModal();
  };

  return (
    <div>
      <Row>
        <TituloPrincipal titulo={"¿Cómo Colaborar?"} />
      </Row>
      <Row>
        <ColaboraPage
          onAdd={addColaborador}
          activos={activos} />
      </Row>

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </div>
  );
}
