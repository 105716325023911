import React from "react";
import { NoticiaDetalle } from "../../components/Client";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export function NoticiaDetail() {
  const { id } = useParams();

  return (
    <Row>
      <NoticiaDetalle noticiaID={id} />
    </Row>
  );
}
