import React from "react";
import "./Descripcion.scss";
import { Row, Col } from "react-bootstrap";

export function DescripcionWebinar() {
  return (
    <div className="descripcionWebinar">
      <Row className="justify-content-center">
        <Col md={10}>
          <h1 className="desc-tituloWebinar">La última información que buscas disponible con nosotros</h1>
          <p className="infoWebinar">Encuentra la informacion que necesitas aqui encontraras webinars, realizados por los expertos del OTS.</p>
        </Col>
      </Row>
    </div>
  );
}
