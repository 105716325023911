import React from "react";
import Button from "react-bootstrap/Button";
import { useColaborador } from "../../../../hooks"; 
import "./DeleteColaboradoresForm.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

export function DeleteColaboradoresForm(props) {
  const { onClose, onRefetch, colaborador } = props;
  const { deleteColaborador } = useColaborador();

  async function delColaboradores() {
    try {
      await deleteColaborador(colaborador.id_colaborador['id']);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h1 className="title-spacing"><FontAwesomeIcon icon={faCircleExclamation} className="icon" /></h1>
      <h5 className="title-spacing">¿Está seguro de eliminar este colaborador?</h5>
      <div className="delete-colaborador-form">
        <Button className="btnaling" onClick={delColaboradores}>
          Eliminar
        </Button>
        <Button className="btnaling" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}
