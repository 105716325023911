import { formatDate } from "../../../metodosGlobales";

export function asignarMesBoletin() {
    const meses = [
        '',
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ]
    const fecha = formatDate(new Date().toISOString()).split('/');
    return meses[fecha[1]];
}

export function asignarNombreDocumento(documento) {
    const nombre = documento.split(/([/?])+/);
    return nombre[6]
}