import React, { useEffect, useState } from 'react'
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import * as Yup from "yup";
import { map } from "lodash";
import { useFormik } from "formik";
import { Form, Button, Row } from 'react-bootstrap';

import { useRequestClient } from '../../../../../hooks';
import { IngresaDatosForm } from '../../../../Generales';
import "./NewRequestClient.scss"

export function NewFormRequestClient(props) {

  const { onClose, onRefetch, membresia, sectores } = props;
  const [state, setState] = useState([]);
  const { addServicioCliente, addServicioDatosAdicionales } = useRequestClient();
  useEffect(() => {
    const fetchData = async () => {
      formik.setFieldValue("indicadores", state);
    };
    fetchData()
  }, [state]);

  const formik = useFormik({
    initialValues: initialValues(),
    //validationSchema: newSchema(),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      addServicioCliente(formvalue, membresia.id).then((servicio) => {
        addServicioDatosAdicionales(servicio.id)
      })
      onRefetch();
      onClose();
    }
  });

  return (
    <>
      <Form className='formRequestService' onSubmit={formik.handleSubmit}>
        <IngresaDatosForm
          tituloLabel="Solicitud de servicio"
          formatoLabel="tituloCentrado"
        />
        <br />

        <IngresaDatosForm
          tituloLabel="Datos Cliente"
          formatoLabel="tituloCentrado"
        />

        <br />

        <Row className="mb-6">
          <IngresaDatosForm
            col="col-12 col-sm-6 col-lg-4 col-xl-3"
            tituloLabel={`Nombre: ${membresia['user']['nombre']} ${membresia['user']['apellido_paterno']} ${membresia['user']['apellido_materno']}`}
            formatoLabel="tituloIzquierda"
          />
          <IngresaDatosForm
            col="col-6 col-sm-6 col-lg-4 col-xl-3"
            tituloLabel={`correo: ${membresia['user']['email']}`}
            formatoLabel="tituloCentrado"
          />
          <IngresaDatosForm
            col="col-6 col-sm-6 col-lg-4 col-xl-3"
            tituloLabel={`Sector: ${membresia.sectores['descripcion']}`}
            formatoLabel="tituloCentrado"
          />
          <IngresaDatosForm
            col="col-12 col-sm-6 col-lg-12 col-xl-3"
            tituloLabel={`Teléfono: ${membresia['telefono']}`}
            formatoLabel="tituloCentrado"
          />
        </Row>

        <br />
        <Row>
          <Form.Group widths="equal" className="col-12 col-sm-3 col-lg-4 col-xl-4" />
          <Form.Group widths="equal" className="col-8 col-sm-6 col-lg-4 col-xl-4">
            <IngresaDatosForm
              tituloLabel="Enfoque del Sector en el reporte:"
              formatoLabel="tituloCentrado"
            />
            <Form.Select
              name="sectores"
              defaultValue={formik.values.sectores}
              onChange={formik.handleChange}
            >
              {map(sectores, (sector, index) => (
                <option
                  key={index}
                  value={sector.id}
                >
                  {sector.descripcion}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <br />
        <IngresaDatosForm
          tituloLabel="Objetivo del reporte"
          formatoLabel="tituloCentrado"
          nameControl="objetivoReporte"
          type="text"
          rows={3}
          placeHolderControl="¿Qué quiere lograr con este servicio en su empresa?"
          valueControl={formik.values.objetivoReporte}
          onChangeControl={formik.handleChange}
          error={formik.errors.objetivoReporte}
          touched={formik.touched.objetivoReporte}
        />

        <Form.Group>
          <IngresaDatosForm
            tituloLabel="Indicadores"
            formatoLabel="tituloCentrado"
          />
          <div style={{ margin: 10 }}>
            <div className='input-group'>
              <InputTags
                values={state}
                onTags={(value) => setState(value.values)}
              />
            </div>
          </div>
        </Form.Group>
        <Button variant="primary" type="submit">
          Subir informe
        </Button>
      </Form>
    </>
  )
}




 

function initialValues() {
  return {
    objetivoReporte: "",
    indicadores: "",
    sectores: 1,

  };
}

function newSchema() {
  return {
    objetivoReporte: Yup.string("Debe de haber un objetivo")
      .required("Es necesario especificar el objetivo del reporte"),
    requerimientos: Yup.string("Debe especificar un requerimiento")
      .required("Debe especificar un requerimiento"),
    temasDesarrollar: Yup.string("Asigna los temas a desarrollar")
      .required("Este campo es requerido"),
  };
}

