import React from "react";
import "./ContactoFrm.scss";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContactanos } from "../../../../hooks/useContactanos";
import { toast } from "react-toastify";

export function ContactoFrm() {
  const { addContactanos } = useContactanos();

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue, { resetForm }) => {
      try {
        await addContactanos(formValue);
        /* console.log(formValue); */
        toast.success(
          "Los datos fueron enviados correctamente, nosotros nos comunicaremos con usted, gracias"
        );
      } catch (error) {
        toast.error(
          "Hubo problemas para el envio de datos por favor vuelva a intentarlo"
        );
        console.error(error);
      }
      resetForm();
    },
  });
  return (
    <Row>
      <Col className="contacto-frm">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Form.Group controlId="frmName" className="col col-sm-6 mb-1 mt-3">
              <Form.Label className="label">Nombre</Form.Label>
              <Form.Control
                className="w-100"
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.errors.name}
                onBlur={formik.handleBlur}
              />
              <Form.Text className="text-danger">
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-danger">{formik.errors.name}</div>
                ) : null}
              </Form.Text>
            </Form.Group>

            <Form.Group
              controlId="frmApell"
              className="col col-sm-6  mb-2 mt-3"
            >
              <Form.Label className="label">Apellidos</Form.Label>
              <Form.Control
                className="w-100"
                type="text"
                name="apellidos"
                value={formik.values.apellidos}
                onChange={formik.handleChange}
                error={formik.errors.apellidos}
                onBlur={formik.handleBlur}
              />
              <Form.Text className="text-danger">
                {formik.touched.apellidos && formik.errors.apellidos ? (
                  <div className="text-danger">{formik.errors.apellidos}</div>
                ) : null}
              </Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="frmEmail" className="col col-sm-6 mb-2">
              <Form.Label className="label">Email</Form.Label>
              <Form.Control
                className="w-100"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
                onBlur={formik.handleBlur}
              />
              <Form.Text className="text-danger">
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="frmCel" className="col col-sm-6  mb-2">
              <Form.Label className="label">
                Teléfono particular (Celular)
              </Form.Label>
              <Form.Control
                className="w-100"
                type="text"
                name="celular"
                value={formik.values.celular}
                onChange={formik.handleChange}
                error={formik.errors.celular}
                onBlur={formik.handleBlur}
              />
              <Form.Text className="text-danger">
                {formik.touched.celular && formik.errors.celular ? (
                  <div className="text-danger">{formik.errors.celular}</div>
                ) : null}
              </Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="frmTelOficina" className="col col-sm-6 mb-2">
              <Form.Label className="label">Teléfono de Oficina</Form.Label>
              <Form.Control
                className="w-100"
                type="text"
                name="telOficina"
                value={formik.values.telOficina}
                onChange={formik.handleChange}
                error={formik.errors.telOficina}
                onBlur={formik.handleBlur}
              />
              <Form.Text className="text-danger">
                {formik.touched.telOficina && formik.errors.telOficina ? (
                  <div className="text-danger">{formik.errors.telOficina}</div>
                ) : null}
              </Form.Text>
            </Form.Group>
            
            <Form.Group controlId="frmEmpresa" className="col col-sm-6  mb-2 ">
              <Form.Label className="label">Empresa</Form.Label>
              <Form.Control
                className="w-100"
                type="text"
                name="empresa"
                value={formik.values.empresa}
                onChange={formik.handleChange}
                error={formik.errors.empresa}
                onBlur={formik.handleBlur}
              />
              <Form.Text className="text-danger">
                {formik.touched.empresa && formik.errors.empresa ? (
                  <div className="text-danger">{formik.errors.empresa}</div>
                ) : null}
              </Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="frmCargo" className="col col-sm-6 mb-2">
              <Form.Label className="label">Cargo</Form.Label>
              <Form.Control
                className="w-100"
                type="text"
                name="cargo"
                value={formik.values.cargo}
                onChange={formik.handleChange}
                error={formik.errors.cargo}
                onBlur={formik.handleBlur}
              />
              <Form.Text className="text-danger">
                {formik.touched.cargo && formik.errors.cargo ? (
                  <div className="text-danger">{formik.errors.cargo}</div>
                ) : null}
              </Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="frmAsunto" className="mb-2 ">
              <Form.Label className="label">
                ¿En qué podemos ayudarte?
              </Form.Label>
              <Form.Control
                className="w-100"
                as="textarea"
                rows="{3}"
                name="asunto"
                value={formik.values.asunto}
                onChange={formik.handleChange}
                error={formik.errors.asunto}
                onBlur={formik.handleBlur}
              />
              <Form.Text className="text-danger">
                {formik.touched.asunto && formik.errors.asunto ? (
                  <div className="text-danger">{formik.errors.asunto}</div>
                ) : null}
              </Form.Text>
            </Form.Group>
          </Row>
          <div className="col-md-12 text-center mt-3 mb-3">
            <Button variant="primary" type="submit" >
              Enviar correo
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
}

function initialValues(data) {
  return {
    name: data?.name || "",
    apellidos: data?.apellidos || "",
    email: data?.email || "",
    celular: data?.celular || "",
    telOficina: data?.telOficina || "",
    empresa: data?.empresa || "",
    cargo: data?.cargo || "",
    asunto: data?.asunto || "",
  };
}

function newSchema() {
  return {
    name: Yup.string("El campo no puede estar vacio").required(
      "El nombre es requerido"
    ),
    apellidos: Yup.string("El campo no puede estar vacio").required(
      "El apellido es requerido"
    ),
    email: Yup.string("El campo no puede estar vacio")
      .email("No es un formato de mail valido")
      .required("El correo es requerido"),
    celular: Yup.string("El campo no puede estar vacio").required(
      "El celular es requerido"
    ),
    telOficina:Yup.string("El campo no puede estar vacio").required(
      "El teléfono es requerido"
    ),
    empresa:Yup.string("El campo no puede estar vacio").required(
      "La empresa es requerido"
    ),
    cargo:Yup.string("El campo no puede estar vacio").required(
      "El cargo es requerido"
    ),
    asunto:Yup.string("El campo no puede estar vacio").required(
      "El asunto es requerido"
    ),
  };
}
