import icono1 from './annual-assessment-svgrepo-com.svg'
import icono2 from './archive-svgrepo-com.svg'
import icono3 from './bank-svgrepo-com.svg'
import icono4 from './bar-chart-svgrepo-com.svg'
import icono5 from './biohazard-svgrepo-com.svg'
import icono6 from './book-closed-svgrepo-com.svg'
import icono7 from './book-opened-svgrepo-com.svg'
import icono8 from './briefcase-svgrepo-com 2.svg'
import icono9 from './briefcase-svgrepo-com.svg'
import icono10 from './browser-svgrepo-com.svg'
import icono11 from './building-svgrepo-com 2.svg'
import icono12 from './building-svgrepo-com.svg'
import icono13 from './bus-front-svgrepo-com.svg'
import icono14 from './cac-o-svgrepo-com.svg'
import icono15 from './cac-svgrepo-com.svg'
import icono16 from './capitol-svgrepo-com.svg'
import icono17 from './chart-line-1-svgrepo-com.svg'
import icono18 from './chart-pie-svgrepo-com.svg'
import icono19 from './check-square-o-svgrepo-com.svg'
import icono20 from './check-svgrepo-com.svg'
import icono21 from './clock-o-svgrepo-com.svg'
import icono22 from './cloud-o-svgrepo-com.svg'
import icono23 from './cloud-svgrepo-com 2.svg'
import icono24 from './cloud-svgrepo-com.svg'
import icono25 from './code-svgrepo-com.svg'
import icono26 from './cog-svgrepo-com-2.svg'
import icono27 from './cog-svgrepo-com.svg'
import icono28 from './cogs-svgrepo-com.svg'
import icono29 from './comment-svgrepo-com.svg'
import icono30 from './comments-svgrepo-com.svg'
import icono31 from './community-corrections-bureau-svgrepo-com.svg'
import icono32 from './community-service-station-svgrepo-com.svg'
import icono33 from './compass-svgrepo-com.svg'
import icono34 from './computer-svgrepo-com.svg'
import icono35 from './computer-svgrepo-com.svg'
import icono36 from './construction-svgrepo-com.svg'
import icono37 from './credit-card-svgrepo-com.svg'
import icono38 from './database-svgrepo-com.svg'
import icono39 from './dc-flag-svgrepo-com.svg'
import icono40 from './dna-svgrepo-com.svg'
import icono41 from './dogtags-svgrepo-com.svg'
import icono42 from './eye-svgrepo-com.svg'
import icono43 from './factory-svgrepo-com.svg'
import icono44 from './file-2-svgrepo-com.svg'
import icono45 from './file-3-svgrepo-com.svg'
import icono46 from './file-contract-o-svgrepo-com.svg'
import icono47 from './file-excel-o-svgrepo-com.svg'
import icono48 from './file-excel-svgrepo-com.svg'
import icono49 from './file-text-svgrepo-com.svg'
import icono50 from './file-word-o-svgrepo-com.svg'
import icono51 from './fingerprint-svgrepo-com.svg'
import icono52 from './folder-misc-svgrepo-com.svg'
import icono53 from './foreign-law-firm-svgrepo-com.svg'
import icono54 from './forensic-institute-svgrepo-com.svg'
import icono55 from './forensic-member-services-svgrepo-com.svg'
import icono56 from './globe-1-svgrepo-com.svg'
import icono57 from './globe-2-svgrepo-com.svg'
import icono58 from './hard-disk-svgrepo-com.svg'
import icono59 from './house-svgrepo-com.svg'
import icono60 from './industry-association-svgrepo-com.svg'
import icono61 from './jefferson-memorial-svgrepo-com.svg'
import icono62 from './law-office-svgrepo-com.svg'
import icono63 from './leaf-svgrepo-com.svg'
import icono64 from './legal-aid-agency-svgrepo-com.svg'
import icono65 from './legal-service-svgrepo-com.svg'
import icono66 from './lightbulb-svgrepo-com.svg'
import icono67 from './medal-circle-svgrepo-com.svg'
import icono68 from './medal-star-svgrepo-com.svg'
import icono69 from './medkit-svgrepo-com.svg'
import icono70 from './metro-front-svgrepo-com.svg'
import icono71 from './microscope-svgrepo-com.svg'
import icono72 from './online-notarization-svgrepo-com.svg'
import icono73 from './presenter-svgrepo-com.svg'
import icono74 from './procuratorate-svgrepo-com.svg'
import icono75 from './reticle-dot-svgrepo-com.svg'
import icono76 from './road-sign-svgrepo-com.svg'
import icono77 from './script-svgrepo-com.svg'
import icono78 from './ship-front-svgrepo-com.svg'
import icono79 from './stethoscope-svgrepo-com.svg'
import icono80 from './truck-front-svgrepo-com.svg'
import icono81 from './truck-svgrepo-com.svg'
import icono82 from './usd-svgrepo-com.svg'
import icono83 from './user-graduate-svgrepo-com.svg'
import icono84 from './user-military-svgrepo-com.svg'
import icono85 from './user-politician-svgrepo-com.svg'
import icono86 from './user-suit-svgrepo-com.svg'
import icono87 from './user-svgrepo-com.svg'
import icono88 from './users-svgrepo-com.svg'
import icono89 from './wheel-barrow-svgrepo-com.svg'
import icono90 from './white-house-svgrepo-com.svg'
import icono91 from './world-svgrepo-com.svg'

export const icons = [
    icono1,
    icono2,
    icono3,
    icono4,
    icono5,
    icono6,
    icono7,
    icono8,
    icono9,
    icono10,
    icono11,
    icono12,
    icono13,
    icono14,
    icono15,
    icono16,
    icono17,
    icono18,
    icono19,
    icono20,
    icono21,
    icono22,
    icono23,
    icono24,
    icono25,
    icono26,
    icono27,
    icono28,
    icono29,
    icono30,
    icono31,
    icono32,
    icono33,
    icono34,
    icono35,
    icono36,
    icono37,
    icono38,
    icono39,
    icono40,
    icono41,
    icono42,
    icono43,
    icono44,
    icono45,
    icono46,
    icono47,
    icono48,
    icono49,
    icono50,
    icono51,
    icono52,
    icono53,
    icono54,
    icono55,
    icono56,
    icono57,
    icono58,
    icono59,
    icono60,
    icono61,
    icono62,
    icono63,
    icono64,
    icono65,
    icono66,
    icono67,
    icono68,
    icono69,
    icono70,
    icono71,
    icono72,
    icono73,
    icono74,
    icono75,
    icono76,
    icono77,
    icono78,
    icono79,
    icono80,
    icono81,
    icono82,
    icono83,
    icono84,
    icono85,
    icono86,
    icono87,
    icono88,
    icono89,
    icono90,
    icono91,
]