import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav, Navbar, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import "./Header.scss";
import logo from "../../../../images/Logo.png";

export function Header() {
  const { pathname } = useLocation();

  return (
    <div className="container-navbar">
      <Navbar collapseOnSelect expand="sm" className="header-client">
        <Navbar.Brand as={Link} to={"/"} className="">
          <Image className="logo d-inline-block align-top" src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle
          className="boton-vermas"
          aria-controls="navbarScroll"
          data-bs-formtarget="#navbarScroll"
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav activeKey={pathname}>
            <Nav.Link eventKey="1" as={Link} to={"/"} active={pathname === "/"}>
              Inicio
            </Nav.Link>
            <Nav.Link
              eventKey="2"
              as={Link}
              to={"/acerca"}
              active={pathname === "/acerca"}
            >
              OT'S
            </Nav.Link>
            <Nav.Link
              eventKey="3"
              as={Link}
              to={"/colabora"}
              active={pathname === "/colabora"}
            >
              Colabora con nosotros
            </Nav.Link>
            <Nav.Link
              eventKey="4"
              as={Link}
              to={"/servicios"}
              active={pathname === "/servicios"}
            >
              Servicios
            </Nav.Link>
            <Nav.Link
              eventKey="5"
              as={Link}
              to={"/contactanos"}
              active={pathname === "/contactanos"}
            >
              Contáctanos
            </Nav.Link>
            <Nav.Link
              eventKey="6"
              as={Link}
              to={"/busquedaExterna"}
              active={pathname === "/busquedaExterna"}
            >
              Búsqueda especializada
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse>
          <div className="container-vermas">
            <Nav className="me-auto header-adm">
              <Nav.Link href="/admin" className="header-client__in">
                <FontAwesomeIcon icon={faRightToBracket} /> Mi OT's
              </Nav.Link>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
