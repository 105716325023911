import React from 'react'
import { Form, Button } from "react-bootstrap";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useContactanos } from '../../../../hooks/useContactanos'
import { Calendario } from "../../../../components/Common";
import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";

import { formatDateNow, formatDate } from '../../../../metodosGlobales'

import './AddContactanos.scss'

export function AddContactanos(props) {
  const { onClose, onRefetch, contacto } = props;
  const { updateContactanos } = useContactanos();


  const formik = useFormik({
    initialValues: initialValues(contacto),
    validationSchema: Yup.object(updateSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await updateContactanos(contacto.id, formValue, formValue.estatus);
        /* console.log(formValue); */
        onRefetch();
        onClose();

      } catch (error) {
        console.error(error);
      }

    }
  });
  return (
    <>
      <Form
        className='add-edit-contacto-form'
        onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Label className="label">Observaciones</Form.Label>
          <Form.Control
            className="w-100"
            as="textarea"
            rows="{3}"
            name="observacion"
            value={formik.values.observacion}
            onChange={formik.handleChange}
            error={formik.errors.observacion}
            onBlur={formik.handleBlur}
          />
          <Form.Text className="text-danger">
            {formik.touched.observacion && formik.errors.observacion ? (
              <div className='text-danger'>{formik.errors.observacion}</div>
            ) : null}
          </Form.Text>
        </Form.Group>
          
         
        
        
        <Form.Group className="col col-lg-6">
          <div className="add-edit-user-form__staff">
            <Form.Check
              type="switch"
              label="¿El problema está solucionado?"
              defaultChecked={formik.values.estatus}
              onChange={(data) =>
                formik.setFieldValue("estatus", data.target.checked)
              }
            />
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label className="label">Fecha Atención</Form.Label>
         
          <IngresaDatosForm
          col="col col-sm-4"
          type="text"
          
          tituloLabel={formatDateNow(contacto.fechaAtencion)}
          disabled={true}
          />

        </Form.Group>
        <div className="btn-Agregar d-grid gap-2">

        <Button
          variant="outline-primary"
          className='add-edit-equipoots-bc'
          type="submit"
        >Agregar</Button>

        </div>

      </Form>

    </>
  )
}
function initialValues(data) {
  return {
    observacion: data?.observacion || "",
    fechaAtencion: formatDate(data?.fechaAtencion) || formatDateNow(),
    estatus: data?.estatus ? true : false,
  }
}

function updateSchema() {
  return {
    observacion: Yup.string("El nombre no puede ser vacio")
      .required("El nombre es requerido"),
    fechaAtencion: Yup.string().required(false),
    estatus: Yup.bool().required(false),
  }
}