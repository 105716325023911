import { useState } from 'react'
import { getAlianzaApi, addAlianzaApi, updateAlianzaApi, deleteAlianzaApi } from '../api/alianzas'
import { useAuth } from './'

export function useAlianzas() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [alianzas, setAlianzas] = useState(null);
    const { auth } = useAuth();

    const getAlianzas = async () => {
        try {
            setLoading(true);
            const response = await getAlianzaApi();
            setLoading(false);
            setAlianzas(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const addAlianza = async (data) =>{
        try {
            setLoading(true);
            await addAlianzaApi(data, auth.token);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    const updateAlianza = async (id, data) =>{
        try {
            setLoading(true);
            await updateAlianzaApi(id, data, auth.token);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    const deleteAlianza = async (id) => {
        try {
          setLoading(true);
          await deleteAlianzaApi(id, auth.token);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      };

    return {
        loading, error, alianzas, getAlianzas, addAlianza, updateAlianza, deleteAlianza
    }
}