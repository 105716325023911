import { BASE_API } from "../utils/constants";

/*
export async function addMicroSiteB2BApi() {
  try {
    const url = `${BASE_API}/api/contactanos/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addContactanosApi(data) {
  try {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("apellidos", data.apellidos);
    formData.append("email", data.email);
    formData.append("celular", data.celular);
    formData.append("telOficina", data.telOficina);
    formData.append("empresa", data.empresa);
    formData.append("cargo", data.cargo);
    formData.append("asunto", data.asunto);
    formData.append("fechaRegistro", formatDate());
    console.log("data", formData);

    const url = `${BASE_API}/api/contactanos/envio/`;
    const params = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}*/

export async function addMicroSiteB2BApi(data, token) {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append(
      "inicio_bannerInformativo_imagen",
      data.inicio.bannerInformativo.imagen
    );
    formData.append(
      "inicio_bannerPrincipal_imagen",
      data.inicio.bannerPrincipal.imagen
    );
    if (data.inicio.galeriaInformativa.columnas[0]?.imagen)
      formData.append(
        "inicio_galeriaInformativa_icono_1",
        data.inicio.galeriaInformativa.columnas[0]?.imagen
      );
    if (data.inicio.galeriaInformativa.columnas[1]?.imagen)
      formData.append(
        "inicio_galeriaInformativa_icono_2",
        data.inicio.galeriaInformativa.columnas[1]?.imagen
      );
    if (data.inicio.galeriaInformativa.columnas[2]?.imagen)
      formData.append(
        "inicio_galeriaInformativa_icono_3",
        data.inicio.galeriaInformativa.columnas[2]?.imagen
      );
    if (data.inicio.galeriaInformativa.columnas[3]?.imagen)
      formData.append(
        "inicio_galeriaInformativa_icono_4",
        data.inicio.galeriaInformativa.columnas[3]?.imagen
      );
    if (data.inicio.galeriaInformativa.columnas[4]?.imagen)
      formData.append(
        "inicio_galeriaInformativa_icono_5",
        data.inicio.galeriaInformativa.columnas[4]?.imagen
      );
    if (data.inicio.galeriaInformativa.columnas[5]?.imagen)
      formData.append(
        "inicio_galeriaInformativa_icono_6",
        data.inicio.galeriaInformativa.columnas[5]?.imagen
      );
    formData.append("logotipo_imagen", data.inicio.logotipo);
    formData.append(
      "info_imagen_1",
      data.paginasDeInformacion[0].infoCom.icono1
    );
    formData.append(
      "info_imagen_2",
      data.paginasDeInformacion[0].infoCom.icono2
    );
    formData.append(
      "info_imagen_3",
      data.paginasDeInformacion[0].infoCom.icono3
    );
    formData.append(
      "info_imagen_4",
      data.paginasDeInformacion[0].infoCom.icono4
    );
    formData.append(
      "pagina_informativa_imagen_header_1",
      data.paginasDeInformacion[0].titulo.imagen
    );
    formData.append(
      "pagina_informativa_imagen_header_2",
      data.paginasDeInformacion[1].titulo.imagen
    );
    formData.append(
      "pagina_informativa_imagen_header_3",
      data.paginasDeInformacion[2].titulo.imagen
    );
    formData.append(
      "pagina_informativa_imagen_body_1",
      data.paginasDeInformacion[0].informacionGen.imagen
    );
    formData.append(
      "pagina_informativa_imagen_body_2",
      data.paginasDeInformacion[1].informacionGen.imagen
    );
    formData.append(
      "pagina_informativa_imagen_body_3",
      data.paginasDeInformacion[2].informacionGen.imagen
    );

    console.log(formData);
    const url = `${BASE_API}/api/b2b_micrositio_cliente/generacionMicrositio/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getCompanysToB2BApi(token) {
  try {
    const url = `${BASE_API}/api/b2b_micrositio_cliente/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getDataFormusApi(token) {
  try {
    const url = `${BASE_API}/api/b2b_micrositio_cliente/getDataForums/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// B2B Ayuda

export async function getDataHelpToUserApi(idUser, token) {
  try {
    const url = `${BASE_API}/api/b2b_preguntas_externas/getPreguntas/${idUser}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addDataHelpToUserApi(data, id, token) {
  try {
    console.log("id ", id);
    const formData = new FormData();
    formData.append("id_registro_externo", id);
    formData.append("problema_acceder", data.problema_acceder);
    formData.append("problema_navegacion", data.problema_navegacion);
    formData.append("descripcion", data.descripcion);
    const url = `${BASE_API}/api/b2b_preguntas_externas/addPregunta/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function editDataHelpToUserApi(data, id, token) {
  try {
    const formData = new FormData();
    formData.append("fecha_respuesta", data.fecha_respuesta);
    formData.append("estatus", data.estatus);
    const url = `${BASE_API}/api/b2b_preguntas_externas/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteDataHelpToUserApi(id, token) {
  try {
    const url = `${BASE_API}/api/b2b_preguntas_externas/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// Agendas obtener usuarios registrados en sitio web

export async function getUsersWebSiteApi(idUser, tipoUsuario, token) {
  try {
    const url = `${BASE_API}/api/b2b_registro_usuario_sitio_web/obtenerUsuariosRegistrados/${idUser}/${tipoUsuario}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function editMesaAsignadaApi(id, mesa, token) {
  try {
    const formData = new FormData();
    formData.append("mesa_asignada", mesa)
    const url = `${BASE_API}/api/b2b_registro_usuario_sitio_web/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    console.log("Mesa");

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function postGenerarAgendaApi(id, token) {
  try {
    const url = `${BASE_API}/api/b2b_agenda/crearAgenda/${id}/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getAgendaAdminApi(id, token) {
  try {
    const url = `${BASE_API}/api/b2b_agenda/obtenerAgendaAdmin/${id}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getAgendaUserApi(id, idSite, tipoUsuario, token) {
  try {
    const url = `${BASE_API}/api/b2b_agenda/obtenerAgendaUser/${id}/${idSite}/${tipoUsuario}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getSitioWebApi(idUser, token) {
  try {
    const url = `${BASE_API}/api/b2b_registro_usuario_sitio_web/obtenerSitiosWeb/${idUser}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function getSubcategoriaApi(idCategoria, token) {
  try {
    const url = `${BASE_API}/api/b2b_subCategoria/${idCategoria}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getSubcategoria2Api(token) {
  try {
    const url = `${BASE_API}/api/b2b_subCategoria/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getUnidadDeMedidaApi(token) {
  try {
    const url = `${BASE_API}/api/unidades_medida/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getCategoriasApi(token) {
  try {
    const url = `${BASE_API}/api/b2b_cat_micrositios/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}


export async function getCategoriaByUserApi(idUser, token) {
  try {
    const url = `${BASE_API}/api/b2b_externo_vender/getCatb2b/${idUser}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getsubCatbyCatApi(idCat, token) {
  try {
    const url = `${BASE_API}/api/b2b_externo_vender/getSubCat/${idCat}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getRegistroExternoVendedorApi(token) {
  try {
    const url = `${BASE_API}/api/b2b_externo_vender/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getExternoVendedorbyIdCatApi(idCat, token) {
  try {
    const url = `${BASE_API}/api/b2b_externo_vender/getExternobyCat/${idCat}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addExternoCompradorApi(data, token, idUser) {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("idUser", idUser);
    const url = `${BASE_API}/api/b2b_externo_comprador/addCompradorExterno/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addSubMicrositioApi(data, token, idUser) {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("idUser", idUser);
    const url = `${BASE_API}/api/b2b_externo_vender/addSubcategoria/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteExternoVendedorApi(id, token){
  try {
      const url = `${BASE_API}/api/b2b_externo_vender/${id}/`;
      const params= {
          method: "DELETE",
          headers: {
              Authorization: `Bearer ${token}`,
          },

      };
      const response = await fetch(url, params);
      const result = await response.json();
      return result;
  } catch (error) {
      throw error;
  }
}

export async function getExternoCompradorbyIdCatApi(idCat, token) {
  try {
    const url = `${BASE_API}/api/b2b_externo_comprador/getCompradorbyCat/${idCat}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
export async function deleteExternoCompradorApi(id, token){
  try {
      const url = `${BASE_API}/api/b2b_externo_comprador/${id}/`;
      const params= {
          method: "DELETE",
          headers: {
              Authorization: `Bearer ${token}`,
          },

      };
      const response = await fetch(url, params);
      const result = await response.json();
      return result;
  } catch (error) {
      throw error;
  }
}

export async function getCatbyMicrosiotiob2bApi(idMicro, token) {
  try {
    const url = `${BASE_API}/api/b2b_externo_vender/getCatbyMicrosiotiob2b/${idMicro}/`;
    const params = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}