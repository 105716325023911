import React from 'react'
import { Table } from 'react-bootstrap'
import { map } from 'lodash'


export function InfoFacturaCRM(props) {
    const { factura } = props;
    return (
        <>
            <h2 className='titulo_CRM'>Datos de Facturación</h2>
            <div className="table-responsive-xl">
                <Table striped bordered hover className='table-CRM table-sm'>
                    <thead>
                        <tr>
                            <th>Razon Social</th>
                            <th>RFC</th>
                            <th>Regimen</th>
                            <th>Calle</th>
                            <th>Numero</th>
                            <th>Colonia</th>
                            <th>Cuidad</th>
                            <th>Estado</th>
                            <th>Codigo Postal</th>
                            <th>Uso de Factura</th>
                            <th>Email</th>
                            <th>Telefono</th>
                        </tr>
                    </thead>
                    <tbody>
                        {map(factura, (facturas, index) => (
                            <tr key={index}>
                                <td>{facturas.razon_social}</td>
                                <td>{facturas.rfc}</td>
                                <td>{facturas.regimen.nombre}</td>
                                <td>{facturas.calle}</td>
                                <td>{facturas.numero}</td>
                                <td>{facturas.colonia}</td>
                                <td>{facturas.ciudad}</td>
                                <td>{facturas.estado}</td>
                                <td>{facturas.codigo_potal}</td>
                                <td>{facturas.uso_factura}</td>
                                <td>{facturas.mail_factura}</td>
                                <td>{facturas.telefono_factura}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}
