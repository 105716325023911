import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Carrusel,
  Evento,
  Indicator,
  Publicity,
  Slider,
} from "../../components/Client";
import { SectoresN } from "../../components/Client/LandingPage/SectoresN";
import { Divider } from "../../components/Common";
import {
  useCatalagos,
  useEventos,
  useLicenciaturas,
  useSectoresN
} from "../../hooks";

export function Home() {
  //const { indicadores, getIndicadoresTop } = useIndicadores();
  const { eventos, getEventosTop } = useEventos();
  const { sectoresNoti, getSectoresNTop } = useSectoresN();
  const [selectFilter, setSelectFilter] = useState(0)
  const [filter, setFilter] = useState(undefined)


  const {
    getInstituciones,
    getLicenciaturasFiltrado,
    licenciaturas,
    totalLicenciaturas
  } = useLicenciaturas()
  const {
    getCarreras, carreras,
    getEstado, estados,
    getTopSectores, topsectores
  } = useCatalagos()

  useEffect(() => {
    //getIndicadoresTop();
    const getData = async () => {
      if (selectFilter === 0) {
        await getInstituciones();
      }
      else if (filter !== 0) {
        await getLicenciaturasFiltrado(selectFilter, filter)
      }
      await getEventosTop();
      await getTopSectores();
      await getCarreras()
      await getEstado()
    }
    getData()
  }, [filter]);

  return (
    <div>
      <Carrusel />
      <Divider />
      <Container fluid>
        <Row className="margen">
          <Col xs={12} md={7}>
            <SectoresN sectorN={topsectores} />
          </Col>
          <Col xs={12} md={5}>
            <Indicator
              licenciaturas={licenciaturas}
              carreras={carreras}
              estados={estados}
              selectFilter={selectFilter}
              setSelectFilter={setSelectFilter}
              setFilter={setFilter}
              totalLicenciaturas={totalLicenciaturas}
            />
            <Evento eventos={eventos} />
          </Col>
        </Row>
        <Row className="margen">
          <Divider />
          <Publicity />
          <Slider />
          <Divider />
        </Row>
      </Container>
    </div>

  );
}
