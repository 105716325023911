import { BASE_API } from "../utils/constants";

export async function getUrlCheckoutApi(data, clave) {
  try {
    console.log("envio de data", data);
    console.log(window.location.origin);
    const formData = new FormData();
    formData.append("plan", data);
    formData.append("clave", clave);
    formData.append("url_front", window.location.origin);
    const url = `${BASE_API}/api/clientes/checkout/`;
    const params = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    console.log("params", response);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getDatosFacturaApi(id) {
  try {
    const url = `${BASE_API}/api/pagos/factura/${id}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addConfirmPagoApi(estatus, clave) {
  try {
    const formData = new FormData();
    formData.append("estatus_pago", estatus);
    formData.append("clave", clave);
    formData.append("url_front", window.location.origin);
    const url = `${BASE_API}/api/clientes/confirm-alta/`;
    const params = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addDatosFacturaApi(data, id) {
  try {
    const formData = new FormData();
    formData.append("cliente", id);
    formData.append("razon_social", data.razon);
    formData.append("rfc", data.rfc);
    formData.append("regimen", data.regimen);
    formData.append("calle", data.calle);
    formData.append("numero", data.numero);
    formData.append("colonia", data.colonia);
    formData.append("ciudad", data.ciudad);
    formData.append("estado", data.estado);
    formData.append("codigo_potal", data.cp);
    formData.append("uso_factura", data.uso);
    formData.append("mail_factura", data.email);
    formData.append("telefono_factura", data.telefono);
    const url = `${BASE_API}/api/pagos/factura/`;
    const params = {
      method: "POST",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateDatosFacturaApi(data, id, clienteId) {
  try {
    const formData = new FormData();
    formData.append("cliente", clienteId);
    formData.append("razon_social", data.razon);
    formData.append("rfc", data.rfc);
    formData.append("regimen", data.regimen);
    formData.append("calle", data.calle);
    formData.append("numero", data.numero);
    formData.append("colonia", data.colonia);
    formData.append("ciudad", data.ciudad);
    formData.append("estado", data.estado);
    formData.append("codigo_potal", data.cp);
    formData.append("uso_factura", data.uso);
    formData.append("mail_factura", data.email);
    formData.append("telefono_factura", data.telefono);
    const url = `${BASE_API}/api/pagos/factura/${id}/`;
    const params = {
      method: "PATCH",
      headers: {},
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getPagosClienteApi(cliente_id) {
  try {
    const url = `${BASE_API}/api/pagos/cliente/pagosCliente/${cliente_id}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getFacturaClienteApi(cliente_id) {
  try {
    const url = `${BASE_API}/api/pagos/factura/facturaCliente/${cliente_id}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
