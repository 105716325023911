import React, { useEffect } from "react";
import {
  MisionPage,
  EquipoPage,
  AlianzaPage,
} from "../../components/Client/Acerca";
import { Row } from "react-bootstrap";
import { TituloPrincipal } from "../../components/Common";

import { useEquipoOTS } from "../../hooks/useEquipoOTS";
import { useAlianzas } from "../../hooks/useAlianzas";

export function Acerca() {
  const { equipoOTS, getEquipoOTS } = useEquipoOTS();
  const { loading, alianzas, getAlianzas } = useAlianzas();

  useEffect(() => {
    getEquipoOTS();
    getAlianzas();
  }, []);

  return (
    <>
      <Row>
        <TituloPrincipal titulo={"Acerca de Nosotros"} />
      </Row>
      <Row>
        <MisionPage />
      </Row>
      <Row>
        <EquipoPage equipoOTS={equipoOTS} />
      </Row>
      <Row>
        <AlianzaPage alianzas={alianzas} />
      </Row>
    </>
  );
}
