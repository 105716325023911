import React, { useMemo, useState } from "react";

import { Pagination } from "../../../../Common";
import { DetalleWebinar } from "../DetalleWebinar";
import "./PaginationWebinar.scss";

let PageSize = 8;

export function PaginacionWebinar(props) {
  const { datos } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return datos.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={datos.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      {currentTableData.map((item) => {
        return <DetalleWebinar key={item.id} dato={item} />;
      })}

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={datos.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
}
