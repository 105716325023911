import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FilterInput } from "../../../../Common";

import "./TableAyuda.scss";

export function TableAyuda(props) {
  const estatus_ayuda = ["", "Sin revisar", "En revisión", "Completada"];

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.id_registro_externo.user.nombre,
      sortable: true,
    },
    {
      name: "Apellido paterno",
      selector: (row) => row.id_registro_externo.user.apellido_paterno,
      sortable: true,
    },
    {
      name: "Apellido materno",
      selector: (row) => row.id_registro_externo.user.apellido_materno,
      sortable: true,
    },
    {
      name: "Fecha de solicitud",
      selector: (row) => `${row.fecha_solicitud.split("T")[0]}`,
      sortable: true,
    },
    {
      name: "Fecha de atención",
      selector: (row) =>
        `${row.fecha_respuesta ? row.fecha_respuesta : "--/--/----"}`,
      sortable: true,
    },
    {
      name: "Telefono de contacto",
      selector: (row) => row?.fecha_respuesta,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.id_registro_externo.user.email,
      sortable: true,
    },
    {
      name: "Estatus",
      selector: (row) => estatus_ayuda[row.estatus],
      sortable: true,
    },
    {
      name: "Ver más...",
      button: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="secondary"
            data-toggle="tooltip"
            data-placement="top"
            title="Ver sitio Web"
            onClick={() => props.showAyudaDescripcion(row, estatus_ayuda)}
            className="table-crm-admin boton"
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} />
          </Button>
        </>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterInput
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="Negocios"
      columns={columns}
      data={filteredItems}
      defaultSortField="name"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
    />
  );
}
