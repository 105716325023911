import React from "react";
import { Carousel, Image } from "react-bootstrap";

export function ImageCarosuel(props) {
  const { imagen } = props;


  if (imagen.link) {
    return (
      <a href={imagen.link} target="_blank">
        <Image src={imagen.imagen} className="image-size" />
        <Carousel.Caption>
          <p className="legend">{imagen.descripcion}</p>
        </Carousel.Caption>
      </a>
    );
  } else {
    return (
      <>
        <Image src={imagen.imagen} className="image-size" />
        <Carousel.Caption>
          <p className="legend">{imagen.descripcion}</p>
        </Carousel.Caption>
      </>
    );
  }
}
