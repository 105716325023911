import { BASE_API } from "../utils/constants";

export async function getSectoresApi() {
  try {
    const url = `${BASE_API}/api/sectores/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getPaisesApi() {
  try {
    const url = `${BASE_API}/api/pais/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getTipoRecursoApi() {
  try {
    const url = `${BASE_API}/api/TipoRecurso/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getHonorariosApi() {
  try {
    const url = `${BASE_API}/api/honorario/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getGeographicalScopeApi() {
  try {
    const url = `${BASE_API}/api/alcanceGeografico/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getTipoServicioApi() {
  try {
    const url = `${BASE_API}/api/tipoServicio/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getTipoPlanApi() {
  try {
    const url = `${BASE_API}/api/tipocliente/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getStatusApi() {
  try {
    const url = `${BASE_API}/api/estatus/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getMesesApi() {
  try {
    const url = `${BASE_API}/api/meses/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getRegimenFiscalApi() {
  try {
    const url = `${BASE_API}/api/regimenFiscal/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getEstadosApi() {
  try {
    const url = `${BASE_API}/api/estados/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getCarrerassApi() {
  try {
    const url = `${BASE_API}/api/carrera/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getTopSectoresApi() {
  try {
    const url = `${BASE_API}/api/Cat_Top_sectores/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function editTopSectorApi(idTop, idSector, token) {
  try {
    const formData = new FormData();
    formData.append("id_sector", idSector);
    const url = `${BASE_API}/api/Cat_Top_sectores/${idTop}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
} 