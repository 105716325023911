import * as Yup from "yup";
import { toast } from "react-toastify";
const fecha = new Date();

export const comprobarNombreMicroSitio = Yup.object().shape({
  microSite: Yup.string()
    .required("El nombre del dominio es requerido")
    .matches(
      /^[a-z0-9-_]+$/g,
      "No se aceptan mayusculas y/o caracteres especiales que no sean '-' o '_' en el micrositio"
    ),
});

export const comprobarFormaDatos = Yup.object().shape({
  nombre: Yup.string().required("El nombre es requerido"),
  email: Yup.string()
    .required("El correo es requerido")
    .email("Escribe un email válido"),
  telefono: Yup.string().required("El telefono es requerdo"),
  capacidad_personas: Yup.string().required("La cantidad es requerida"),
  fecha_inicio: Yup.string().required("La fecha de inicio es requerida"),
  fecha_fin: Yup.string().required("La fecha de inicio es requerida"),
});

export async function validatorSchema(schema, data, setButton, setError) {
  try {
    await schema.validate(data, { abortEarly: false });
    // El parámetro "abortEarly" asegura que todas las validaciones se ejecuten para que puedas capturar todos los errores.
    setButton(true);
    // El objeto "data" es válido, realiza las acciones que necesites aquí.
    setError("");
  } catch (error) {
    // Maneja el error de validación
    error.inner.forEach((err) => {
      console.log(`Campo que ha fallado: ${err.path}`);
    });
    const errorMessage = error.errors[0]; // Obtiene el primer mensaje de error
    setButton(false);
    setError(errorMessage);
  }
}
