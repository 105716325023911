import React from 'react'
import { HeaderPage, TableQuestions } from '../../components/Admin'

export function QuestionsAdmin() {
    return (
        <>
            <HeaderPage
                title=''
            />
            <TableQuestions />
        </>
    )
}
