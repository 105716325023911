import React, { useState } from "react";
import { CookieConsent, Cookies } from "react-cookie-consent";
import { Form, Row, Col } from "react-bootstrap";

import "./CookiePolicy.scss";

export function CookiePolicy() {
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);

  const handleChangeOne = () => {
    setCheckedOne(!checkedOne);
  };
  /*
  let CookieName = "PCmin"
    Cookies.remove(CookieName)
    console.log("cookie: ", Cookies.get(CookieName));
    Cookies.set(CookieName, 500);
    console.log("cookie: ", Cookies.get(CookieName));
*/
  const handleChangeTwo = () => {
    setCheckedTwo(!checkedTwo);
  };

  return (
    <div className="cookie-policy">
      <CookieConsent
        buttonText="Aceptar"
        cookieName="myAwesomeCookieName2"
        overlay
        overlayClasses="overlayclass"
        // style={{ background: "#2B373B" }}
        // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        // expires={150}
      >
        <Row className="justify-content-center">
          <Col sm={8}>
            <div className="text-center">
              Utilizamos cookies para mejorar la experiencia del usuario y
              analizar el tráfico del sitio. Para obtener más información, lea
              nuestra{" "}
              <a className="cookie-policy-link" href="/aviso/privacidad/">
                politica de privacidad.
              </a>
              <p>
                Puede decidir qué cookies se utilizan seleccionando las
                respectivas opciones a continuación. Tenga en cuenta que su
                selección puede perjudicar en la funcionalidad del servicio.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm={8}>
            <Form.Check
              className="cookie-policy-input"
              label="   Cookies Escenciales: Le
            permiten navegar y utilizar las funciones básicas."
              checked
              disabled
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={8}>
            <Form.Check
              className="cookie-policy-input"
              label="   Cookies de análisis: nos permiten determinar cómo interactúan los visitantes con nuestro servicio para mejorar la experiencia del usuario."
              value={checkedOne}
              onChange={handleChangeOne}
              checked
            />
          </Col>
        </Row>
      </CookieConsent>
    </div>
  );
}
