import React from "react";
import { Row, Col, Image, Card } from "react-bootstrap";

import "./wizard.scss";
import plantilla_1 from "../../../../images/b2b-template-1.png";
import plantilla_2 from "../../../../images/b2b-template-2.png";
import plantilla_3 from "../../../../images/b2b-template-3.png";
import plantilla_4 from "../../../../images/b2b-template-4.png";
import plantilla_5 from "../../../../images/b2b-template-5.png";
import plantilla_6 from "../../../../images/b2b-template-6.png";

export function FormaTema(props) {
  const {tema,setTema, setButtonNext}=props;
  const onChangeValue=(event) =>{
    setTema(event.target.value);
  }
  if(tema) setButtonNext(true);
  else setButtonNext(false)
  return (
    <div className="wizard" onChange={onChangeValue}>
      <Row>
        <Col sm={12} md={12} lg={4}>
          <div className="border rounded m-1 p-1 shadow">
            <Image className="img-fluid" src={plantilla_1} />
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Colores del tema:</div>
              <div className="square d-inline tema-1-c1 me-2 "></div>
              <div className="square d-inline tema-1-c2 me-2 "></div>
              <div className="square d-inline tema-1-c3 me-2 "></div>
            </Row>
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Tipografía:</div>
              <div className="tit-tema d-inline text-start p-0">Arial</div>
            </Row>
          </div>
          <Row className="my-2 text-center">
            <div className="d-inline ">
              <input type="radio" id="tema1" name="tema" value="1" className=" me-2" checked={tema==1} />
              <label className="text-resal">Green Bussines</label>
            </div>
          </Row>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <div className="border rounded m-1 p-1 shadow">
            <Image className="img-fluid" src={plantilla_2} />
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Colores del tema:</div>
              <div className="square d-inline tema-2-c1 me-2 "></div>
              <div className="square d-inline tema-2-c2 me-2 "></div>
              <div className="square d-inline tema-2-c3 me-2 "></div>
            </Row>
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Tipografía:</div>
              <div className="tit-tema d-inline text-start p-0">Popins</div>
            </Row>
          </div>
          <Row className="my-2 text-center">
            <div className="d-inline ">
              <input type="radio" id="tema1" name="tema" value="2" className=" me-2" checked={tema==2} />
              <label className="text-resal">Red Organic</label>
            </div>
          </Row>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <div className="border rounded m-1 shadow">
            <Image className="img-fluid" src={plantilla_3} />
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Colores del tema:</div>
              <div className="square d-inline tema-3-c1 me-2 "></div>
              <div className="square d-inline tema-3-c2 me-2 "></div>
              <div className="square d-inline tema-3-c3 me-2 "></div>
            </Row>
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Tipografía:</div>
              <div className="tit-tema d-inline text-start p-0">Merriweather</div>
            </Row>
          </div>
          <Row className="my-2 text-center">
            <div className="d-inline ">
              <input type="radio" id="tema2" name="tema" value="3" className=" me-2" checked={tema==3} />
              <label className="text-resal">Blue Classic</label>
            </div>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={4}>
          <div className="border rounded m-1 shadow">
            <Image className="img-fluid" src={plantilla_4} />
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Colores del tema:</div>
              <div className="square d-inline tema-4-c1 me-2 "></div>
              <div className="square d-inline tema-4-c2 me-2 "></div>
              <div className="square d-inline tema-4-c3 me-2 "></div>
            </Row>
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Tipografía:</div>
              <div className="tit-tema d-inline text-start p-0">Noto Sans</div>
            </Row>
          </div>
          <Row className="my-2 text-center w-100">
            <div className="d-inline ">
              <input type="radio" id="tema3" name="tema" value="4" className=" me-2" checked={tema==4} />
              <label className="text-resal">Purple Tonic</label>
            </div>
          </Row>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <div className="border rounded m-1 shadow">
            <Image className="img-fluid" src={plantilla_5} />
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Colores del tema:</div>
              <div className="square d-inline tema-5-c1 me-2 "></div>
              <div className="square d-inline tema-5-c2 me-2 "></div>
              <div className="square d-inline tema-5-c3 me-2 "></div>
            </Row>
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Tipografía:</div>
              <div className="tit-tema d-inline text-start p-0">Open Sans</div>
            </Row>
          </div>
          <Row className="my-2 text-center w-100">
            <div className="d-inline ">
              <input type="radio" id="tema3" name="tema" value="5" className=" me-2" checked={tema==5} />
              <label className="text-resal">Red Elemental</label>
            </div>
          </Row>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <div className="border rounded m-1 shadow">
            <Image className="img-fluid" src={plantilla_6} />
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Colores del tema:</div>
              <div className="square d-inline tema-6-c1 me-2 "></div>
              <div className="square d-inline tema-6-c2 me-2 "></div>
              <div className="square d-inline tema-6-c3 me-2 "></div>
            </Row>
            <Row className="mb-3">
              <div className="tit-tema d-inline text-end">Tipografía:</div>
              <div className="tit-tema d-inline text-start p-0">Roboto</div>
            </Row>
          </div>
          <Row className="my-2 text-center w-100">
            <div className="d-inline ">
              <input type="radio" id="tema3" name="tema" value="6" className=" me-2" checked={tema==6} />
              <label className="text-resal">Bluish</label>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
