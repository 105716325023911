import { useState } from "react";
import {
  getIndicadoresApi,
  getIndicadoresTopApi,
  updateIndicadorApi,
  addIndicadorApi,
  deleteIndicadorApi,
  getIndicadoresIdApi,
  getIndicadorBuscadorTopApi,
  getIndicadorBuscadorApi,
} from "../api/indicadores";
import { useAuth } from ".";

export function useIndicadores() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [indicadores, setIndicadores] = useState(null);
  const [indicadorId, setIndicadorId] = useState(0);
  const { auth } = useAuth();

  const getIndicadores = async () => {
    try {
      setLoading(true);
      const response = await getIndicadoresApi();
      setLoading(false);
      setIndicadores(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getIndicadoresTop = async () => {
    try {
      setLoading(true);
      const response = await getIndicadoresTopApi();
      setLoading(false);
      setIndicadores(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getIndicadoresBuscadorTop = async (filtro) => {
    try {
      setLoading(true);
      const response = await getIndicadorBuscadorTopApi(filtro);
      setLoading(false);
      setIndicadores(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getIndicadoresBuscador = async (filtro) => {
    try {
      setLoading(true);
      const response = await getIndicadorBuscadorApi(filtro);
      setLoading(false);
      setIndicadores(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const addIndicador = async (data) => {
    try {
      setLoading(true);
      await addIndicadorApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const updateIndicador = async (id, data) => {
    try {
      setLoading(true);
      await updateIndicadorApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const deleteIndicador = async (id) => {
    try {
      setLoading(true);
      await deleteIndicadorApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const getIndicadorId = async (id) => {
    try {
      setLoading(true);
      const response = await getIndicadoresIdApi(id);
      setLoading(false);
      setIndicadorId(response);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    indicadores,
    getIndicadores,
    getIndicadoresTop,
    addIndicador,
    updateIndicador,
    deleteIndicador,
    indicadorId,
    getIndicadorId,
    getIndicadoresBuscadorTop,
    getIndicadoresBuscador,
  };
}
