import React from 'react';
import './DeleteCatalogoForm.scss';



export function DeleteCatalogoForm({ onDelete, onClose, index }) {
  const handleCondrimarClick = () => {
        onDelete(index);
    onClose();

  };

  const handleCancelarClick = () => {
    onClose();
  };


  return (
    <div>
      <h2>¿Deseas eliminar esta categoría?</h2>
      <button className="Eliminar" onClick={handleCondrimarClick}>Eliminar</button>
      <button className="Cancelar" onClick={handleCancelarClick}>Cancelar</button>
    </div>
  );
}
