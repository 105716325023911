import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { Button } from 'react-bootstrap';

export function ModalError(props) {

    const { tipoError, icon, onClose, color } = props;

    const closeBtn = () => {
        onClose();
    }

    return (
        <div className='text-center'>
            <FontAwesomeIcon icon={icon} style={{ color: color, height: 100, width: 100 }} />

            <br />
            <br />
            <h5 style={{ color: "black" }}>
                <pre>
                    {tipoError}
                </pre>
            </h5>
            <Button variant='danger' onClick={closeBtn}>
                Cerrar
            </Button>
        </div>
    )
}
