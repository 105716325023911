import React from 'react'
import { Button } from 'react-bootstrap';

import { useContactanos } from '../../../../hooks';

export function DeleteContactanos(props) {
  const { onClose, onRefetch, equipo } = props;
  const { deleteContactanos } = useContactanos();

  async function deleContactanos() {
    try {
      await deleteContactanos(equipo.id);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h3 className="title-spacing">¿Está seguro de eliminar al integrante?</h3>
      <div className="delete-user-form">
        <Button variant="danger" className="btnaling" onClick={deleContactanos}>
          Eliminar
        </Button>
        <Button variant="outline-danger" className="btnaling" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}