import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import {
  DataTableCRM,
  InfoPagosCRM,
  InfoFacturaCRM,
} from "../../../components/Admin";
import { usePlanPago, usePagos } from "../../../hooks";
import { ModalBasic } from "../../../components/Common";
import {
  faMoneyBill,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";

export function SoporteCRMAdmin() {
  const { loading, busquedaCliente, getBusquedaPorCliente } = usePlanPago();
  const { getPagosCliente, getFacturaCliente } = usePagos();
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [sizeModal, setsizeModal] = useState("xl");
  const [refetch, setRefetch] = useState(false);
  console.log(busquedaCliente);
  useEffect(() => {
    getBusquedaPorCliente();
  }, [refetch]);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const viewPagos = (cliente_id) => {
    getPagosCliente(cliente_id).then((pagos) => {
      setTitleModal("Pagos de Cliente");
      setIconModal(faMoneyBill);
      setContentModal(
        <InfoPagosCRM
          pagos={pagos}
          onClose={openCloseModal}
          onRefetch={onRefetch}
        />
      );
      setsizeModal("xl");
      openCloseModal();
    });
  };

  const viewFactura = (cliente_id) => {
    getFacturaCliente(cliente_id).then((factura) => {
      setTitleModal("Factura del Cliente");
      setIconModal(faFileInvoiceDollar);
      setContentModal(
        <InfoFacturaCRM
          factura={factura}
          onClose={openCloseModal}
          onRefetch={onRefetch}
        />
      );
      setsizeModal("xl");
      openCloseModal();
    });
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <DataTableCRM
          data={busquedaCliente}
          viewPagos={viewPagos}
          viewFactura={viewFactura}
        />
      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}
