import { BasicLayout } from "../layouts";
import { Error404 } from "../pages";
import routerAdmin from "./routes.admin";
import routesB2BAdmin from "./routes.b2b.Admin";
import routesB2BComprador from "./routes.b2b.comprador";
import routesB2BVendedor from "./routes.b2b.vendedor";
import routerClient from "./routes.client";
import routesExtras from "./routes.extras";
import routesOperativo from "./routes.operativo";
import routesMartketingAdmin from "./routes.martketing.admin";

const routes = [
  ...routerAdmin,
  ...routerClient,
  ...routesExtras,
  ...routesB2BComprador,
  ...routesB2BVendedor,
  ...routesB2BAdmin,
  ...routesOperativo,
  ...routesMartketingAdmin,
  {
    path: "*",
    layout: BasicLayout,
    component: Error404,
  },
];

export default routes;
