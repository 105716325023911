import React, { useState, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import { Form, Button, Image } from "react-bootstrap";
import { IngresaDatosForm } from "../../../Generales";

import { useCarousel } from "../../../../hooks";
import "./EditFormCarousel.scss";
export function EditFormCarousel(props) {
  const { onClose, onRefetch, carousel } = props;
  const [previewImage, setPreviewImage] = useState(carousel?.imagen || null);
  const { editCarousel, addCarousel } = useCarousel();

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("imagen", file);
    console.log(file);
    setPreviewImage(URL.createObjectURL(file));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const formik = useFormik({
    initialValues: initialValues(carousel),
    validationSchema: Yup.object(updateSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      if (carousel) await editCarousel(formvalue, carousel.id);
      else await addCarousel(formvalue);
      console.log(formvalue);
      onRefetch();
      onClose();
    },
  });

  return (
    <>
      <Form className="formEditCarousel" onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Image src={previewImage} className="imagen" />
          <br />
          <Button className="botonEditarImagen" type="button" {...getRootProps()}>
            Cambiar imagen
          </Button>
          <input {...getInputProps()} />
        </Form.Group>

        <IngresaDatosForm
          col="col-12"
          tituloLabel="Descripcion."
          formatoLabel="tituloIzquierda"
          nameControl="descripcion"
          type="text"
          placeHolderControl="Ingresa un descripción"
          valueControl={formik.values.descripcion}
          onChangeControl={formik.handleChange}
          error={formik.errors.descripcion}
          touched={formik.touched.descripcion}
        />

        <IngresaDatosForm
          col="col-12"
          tituloLabel="Link."
          formatoLabel="tituloIzquierda"
          nameControl="link"
          type="text"
          placeHolderControl="Ingresa un link"
          valueControl={formik.values.link}
          onChangeControl={formik.handleChange}
          error={formik.errors.link}
          touched={formik.touched.link}
        />

        <Form.Group>
          <Form.Label className="texto">Ultima actualización: {formatDate(formik.values.fecha_alta)}</Form.Label>
        </Form.Group>

        <Button variant="primary" type="submit" className="botonEditarImagen">
          Actualizar
        </Button>
      </Form>
    </>
  );
}

function initialValues(data) {
  return {
    descripcion: data?.descripcion || "",
    link: data?.link || "",
    fecha_alta: data?.fecha_alta || new Date().toISOString(),
    imagen: "",
  };
}

function updateSchema() {
  return {
    descripcion: Yup.string("La descripción no puede estar vacia").required("La descripción es requerida"),
  };
}

function formatDate(date) {
  const dateConvert = date.split(/([-TZ])+/);
  return `${dateConvert[4]}/${dateConvert[2]}/${dateConvert[0]}`;
}
