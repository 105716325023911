import React, { useEffect, useState } from "react";
import { Spinner, Row } from "react-bootstrap";
import {
  faClapperboard,
  faEdit,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";

import { useSolicitudServicios } from "../../../hooks";
import {
  HeaderPage,
  AddEditWebinarsOperativo,
  DeleteWebinarsOperativo,
  TableWebinarsOperativo
} from '../../../components/Admin'
import {
  BackButton,
  ModalBasic
} from '../../../components/Common'

export function WebinarsOperativo() {

  const { getWebinar, webinars, loading } = useSolicitudServicios();
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("xl");

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);


  useEffect(() => {
    const fetchData = async () => {
      getWebinar()
    };
    fetchData()
  }, [refetch]);

  const addWebinar = async () => {
    setTitleModal("Agregar nuevo webinar");
    setIconModal(faClapperboard);
    setsizeModal("xl");
    setContentModal(
      <AddEditWebinarsOperativo
        onClose={openCloseModal}
        onRefetch={onRefetch}
      />
    );
    openCloseModal();
  };

  const updateWebinar = async (data) => {
    setTitleModal("Actualizar Webinar");
    setIconModal(faEdit);
    setsizeModal("xl");
    setContentModal(
      <AddEditWebinarsOperativo
        onClose={openCloseModal}
        onRefetch={onRefetch}
        webinar={data}
      />
    );
    openCloseModal();
  };

  const onDeleteWebinar = async (data) => {
    setTitleModal("Borrar Webinar");
    setIconModal(faDeleteLeft);
    setsizeModal("lg");
    setContentModal(
      <DeleteWebinarsOperativo
        onClose={openCloseModal}
        onRefetch={onRefetch}
        webinar={data}
      />
    );
    openCloseModal();
  };

  return (
    <div>
      <br />
      <Row className="mb-6">
        <BackButton
          btnTitle="Regresar a los servicios de Membresía"
          link="/admin/serviciosLicenciamientoOperativo"
        />
      </Row>
      <br />
      <Row className="mb-6">
        <HeaderPage
          title="Administración de Webinars"
          btnTitle="Subir Webinar"
          btnClick={addWebinar}
        />
        <div className="col col-sm-2" />
      </Row>

      {loading ? (
        <Spinner animation="border" role="status" />
      ) : (
        <TableWebinarsOperativo
          webinars={webinars}
          updateWebinars={updateWebinar}
          onDeleteWebinars={onDeleteWebinar}
        />
      )}
      <br />
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </div>
  )
}
