import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faToolbox,
  faCircleInfo,
  faUsersViewfinder,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";

import "./SoporteAdmin.scss";
export function SoporteAdmin() {
  return (
    <Row className="ayuda justify-content-center mt-5">
      <Col xs={10}>
        <Card>
          <Card.Body>
            <Row className="my-3">
              <Col className="ayuda-menu-izq p-0">
                <Link to="/admin/soporte/soporte">
                  <Button className="ayuda-btn" variant="outline-primary">
                    <div className="ayuda-icon-btn">
                      <FontAwesomeIcon icon={faToolbox} className="icon" />
                    </div>
                    <div className="ayuda-text-btn">Soporte</div>
                  </Button>
                </Link>
              </Col>
              <Col className="ayuda-menu-der p-0">
                <Link to="/admin/contactanos">
                  <Button className="ayuda-btn" variant="outline-danger">
                    <div className="ayuda-icon-btn">
                      <FontAwesomeIcon icon={faCircleInfo} className="icon" />
                    </div>
                    <div className="ayuda-text-btn">Contactanos</div>
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row className="my-3">
              <Col className="ayuda-menu-izq p-0">
                <Link to="/admin/soporte/crm">
                  <Button className="ayuda-btn" variant="outline-success">
                    <div className="ayuda-icon-btn">
                      <FontAwesomeIcon
                        icon={faUsersViewfinder}
                        className="icon"
                      />
                    </div>
                    <div className="ayuda-text-btn">CRM</div>
                  </Button>
                </Link>
              </Col>
              <Col className="ayuda-menu-der p-0">
                <Link to="/admin/ayuda/cambioplan">
                  <Button className="ayuda-btn" variant="outline-warning">
                    <div className="ayuda-icon-btn">
                      <FontAwesomeIcon
                        icon={faShareFromSquare}
                        className="icon"
                      />
                    </div>
                    <div className="ayuda-text-btn">Cambio de Plan</div>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
