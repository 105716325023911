import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faXmark,
  faMoneyBill,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { FilterInput } from "../../../Common";
import "./TableCRM.scss";
export function DataTableCRM(props) {
  const columns = [
    {
      name: "N° Membresia",
      selector: (row) => row.id,

      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.user.nombre,
      sortable: true,
      grow: 2,
    },
    {
      name: "Apellido Paterno",
      selector: (row) => row.user.apellido_paterno,
      sortable: true,
      grow: 2,
    },
    {
      name: "Apellido Materno",
      selector: (row) => row.user.apellido_materno,
      sortable: true,
      grow: 2,
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
      sortable: true,
      grow: 3,
    },
    {
      name: "Pais",
      selector: (row) => row.pais["nombre"],
      sortable: true,
      hide: "md",
    },
    {
      name: "Telefono",
      selector: (row) => row.telefono,
      sortable: true,
      grow: 2,
    },
    {
      name: "Institucion",
      selector: (row) => row.institucion,
      sortable: true,
      hide: "md",
    },
    {
      name: "Cargo",
      selector: (row) => row.cargo,
      sortable: true,
      hide: "md",
    },
    {
      name: "Sectores de Interes",
      selector: (row) => row.sectores["descripcion"],
      sortable: true,
      hide: "md",
    },
    {
      name: "Estatus del Cliente",
      selector: (row) => row.user.is_active,
      sortable: true,
      cell: (row) =>
        row.user.is_active ? (
          <FontAwesomeIcon
            icon={faCheck}
            color="green"
            data-toggle="tooltip"
            data-placement="top"
            title="Usuario activo"
          />
        ) : (
          <FontAwesomeIcon
            icon={faXmark}
            color="red"
            data-toggle="tooltip"
            data-placement="top"
            title="Usuario Inactivo"
          />
        ),
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => (
        <>
          <Button
            size="sm"
            variant="secondary"
            data-toggle="tooltip"
            data-placement="top"
            title="Ver Pagos"
            onClick={() => props.viewPagos(row.id)}
            className="table-crm-admin boton"
          >
            <FontAwesomeIcon icon={faMoneyBill} />
          </Button>
          <Button
            size="sm"
            variant="secondary"
            data-toggle="tooltip"
            data-placement="top"
            title="Datos de Factura"
            onClick={() => props.viewFactura(row.id)}
            className="table-crm-admin boton"
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} />
          </Button>
        </>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props.data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterInput
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="CRM"
      columns={columns}
      data={filteredItems}
      defaultSortField="name"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
    />
  );
}
