import React from "react";
import Button from "react-bootstrap/Button";
import { useAlianzas } from "../../../../hooks/useAlianzas";
import "./DeleteAlianzas.scss";

export function DeleteAlianzas(props) {
  const { onClose, onRefetch, alianza } = props;
  const { deleteAlianza } = useAlianzas();

  async function deleAlianza() {
    try {
      await deleteAlianza(alianza.id);
      onRefetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal() {
    onClose();
  }

  return (
    <div>
      <h3 className="title-spacing">¿Está seguro de eliminar la alianza?</h3>
      <div className="delete-user-form">
        <Button variant="danger" className="btnaling" onClick={deleAlianza}>
          Eliminar
        </Button>
        <Button variant="outline-danger" className="btnaling" onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}
