import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Form, Row, Button, FormGroup } from "react-bootstrap";
import { map } from "lodash";
import { toast } from "react-toastify";

import { useBoletin } from "../../../../hooks";
import { IngresaDatosForm } from "../../../Generales";
import { formatDate } from "../../../../metodosGlobales";
import { ButtonLoad } from "../../../Common";
import "./FormBoletin.scss";

export function FormBoletin(props) {
  const { onClose, onRefetch, dateMeses, users } = props;
  const { addBoletin } = useBoletin();
  const [previewDocument, setPreviewDocument] = useState(null);
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);


  async function handleUpload(event) {
    setPreviewDocument(event.target.files[0]);
    const file = event.target.files[0];
    setDocument(URL.createObjectURL(event.target.files[0]));
    await formik.setFieldValue("boletin", file);
  }


  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    //validateOnChange: false,
    onSubmit: async (formvalue) => {
      try {
        setLoading(true);
        await addBoletin(formvalue);
        toast.success("Tu boletín se guardo con exito ", {
          position: "top-center",
        });
        setLoading(false);
        onClose();
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
      onRefetch();
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group widths="equal">
          <IngresaDatosForm
            tituloLabel="Boletín del mes de:"
            formatoLabel="tituloCentrado"
          />
          <Form.Select
            defaultValue={formik.values.mesBoletin}
            className="form-control"
            name="mesBoletin"
            onChange={formik.handleChange}
          >
            <option value={"0"}>Seleccionar mes</option>
            {map(dateMeses, (mess, index) => (
              <option key={index} value={mess.id}>
                {mess.mes}
              </option>
            ))}
          </Form.Select>
          <Form.Text className="text-danger">
            {formik.touched.mesBoletin && formik.errors.mesBoletin ? (
              <div className="text-danger">{formik.errors.mesBoletin}</div>
            ) : null}
          </Form.Text>
        </Form.Group>
        <br />
        <FormGroup>
          <Row className="mb-6">
            <IngresaDatosForm
              col="col col-sm-6"
              tituloLabel="Fecha de subida: "
              formatoLabel="tituloCentrado"
            />

            <IngresaDatosForm
              col="col col-sm-6"
              tituloLabel={formatDate(formik.values.fechaSubida)}
              formatoLabel="tituloCentrado"
            />
          </Row>
        </FormGroup>
        <br />
        <Row className="mb-6">
          <Form.Group className="col col-sm-8">
            <input
              name="boletin"
              type="file"
              onChange={handleUpload}
              className="text"
              accept=".pdf"
            />
            <Form.Text className="text-danger">
              {formik.touched.boletin && formik.errors.boletin ? (
                <div className="text-danger">{formik.errors.boletin}</div>
              ) : null}
            </Form.Text>
          </Form.Group>
        </Row>
        <br />
        {loading ? (
          <ButtonLoad></ButtonLoad>
        ) : (
          <Button variant="primary" type="submit">
          Crear Boletín
        </Button>
        )}
        
      </Form>
    </div>
  );
}

function initialValues(data) {
  return {
    mesBoletin: data?.mesBoletin || "",
    fechaSubida: new Date().toISOString(),
    boletin: "",
  };
}

function validationSchema() {
  return {
    mesBoletin: Yup.string().required("Ingresa el mes del boletín"),
    boletin: Yup.string().required("Este campo no puede ser vacío"),
  };
}

/* 
function envioBoletin() {
  const { auth } = useAuth();
  const rol = auth.me["role", "email"];
  if (rol === 5) {
    // email.filter(['email'])
  } 
  
  return ;
    
} */