import { useState } from "react";

import { useAuth } from "./useAuth";
import {
    getServicesMembershipApi,
    getDisplayServicesDataApi,
    getDisplayServicesDataIdColaboratorApi,
    getDisplayServicesDataIdOperativeApi,
    addServiceClientApi,
    getDisplayServicesDataIdClientApi,
    getDisplayServicesDataIdServiceApi,
    addServiciosDatosAdicionalesApi,
    editServiceClient_Admin_Api,
    editAditionalData_Admin_Api,
    editAditionalData_ColabOpe_Api,
} from "../api/requestClient";

export function useRequestClient() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [serviciosPorMembresia, setServiciosPorMembresia] = useState(null);
    const [serviciosSolicitados, setServiciosSolicitados] = useState(null);
    const [serviciosSolicitadosIdCliente, setServiciosSolicitadosIdCliente] = useState(null);
    const { auth } = useAuth();

    const getServiciosPorMembresia = async () => {
        try {
            setLoading(true);
            const response = await getServicesMembershipApi( auth.token);
            setLoading(false);
            setServiciosPorMembresia(response);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const getServiciosSolicitados = async () => {
        try {
            setLoading(true);
            const response = await getDisplayServicesDataApi(auth.token);
            setLoading(false);
            setServiciosSolicitados(response)
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const getServiciosSolicitadosIdCliente = async (idUser) => {
        try {
            setLoading(true);
            const response = await getDisplayServicesDataIdClientApi(idUser, auth.token);
            setLoading(false);
            setServiciosSolicitadosIdCliente(response)
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const getServiciosSolicitadosIdColaborador = async (id) => {
        try {
            setLoading(true);
            const response = await getDisplayServicesDataIdColaboratorApi(id, auth.token);
            setLoading(false);
            setServiciosSolicitados(response)
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const getServiciosSolicitadosIdOperativo = async (id) => {
        try {
            setLoading(true);
            const response = await getDisplayServicesDataIdOperativeApi(id, auth.token);
            setLoading(false);
            setServiciosSolicitados(response)
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const getDatosAdicionalesIdServicio = async (idSolicitudReporte) => {
        try {
            setLoading(true);
            const response = await getDisplayServicesDataIdServiceApi(idSolicitudReporte, auth.token);
            setLoading(false);
            setServiciosSolicitadosIdCliente(response)
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const addServicioCliente = async (data, idCliente) => {
        try {
            setLoading(true);
            const response = await addServiceClientApi(data, idCliente, auth.token);
            setLoading(false);
            return response;
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const addServicioDatosAdicionales = async (idSolicitudReporte) => {
        try {
            setLoading(true);
            await addServiciosDatosAdicionalesApi(idSolicitudReporte, auth.token);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const editServicioClienteAdmin = async (data, id) => {
        try {
            setLoading(true);
            await editServiceClient_Admin_Api(data, id, auth.token)
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    const editReporteAdmin = async (data, id) => {
        try {
            setLoading(true);
            await editAditionalData_Admin_Api(data, id, auth.token)
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    const editReporteOperativoColaborador = async (data, id) => {
        try {
            setLoading(true);
            await editAditionalData_ColabOpe_Api(data, id, auth.token)
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }



    return {
        loading,
        error,
        serviciosPorMembresia,
        serviciosSolicitados,
        getServiciosPorMembresia,
        getServiciosSolicitados,
        getDatosAdicionalesIdServicio,
        addServicioCliente,
        addServicioDatosAdicionales,
        editServicioClienteAdmin,
        editReporteAdmin,
        editReporteOperativoColaborador,
        getServiciosSolicitadosIdCliente,
        getServiciosSolicitadosIdColaborador,
        getServiciosSolicitadosIdOperativo,
        serviciosSolicitadosIdCliente,
    };
}