import { useState } from "react";
import {
  getMeApi,
  getUsersApi,
  addUserApi,
  updateUserApi,
  deleteUserApi,
  sendMailRecApi,
  updatePassApi,
  addUserColaboradorApi,
  getUsersFilterApi,
  sendMailNewUserApi,

} from "../api/user";
import { useAuth } from ".";

export function useUser() {
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState(null);
  const [users, setUsers] = useState(null);
  const { auth } = useAuth();

  const getMe = async (token) => {
    try {
      const response = await getMeApi(token);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await getUsersApi(auth.token);
      setLoading(false);
      setUsers(response);
    } catch (error) {
      setLoading(false);
      seterror(error);
    }
  };

  const getUsersFiltrados = async (filtro) => {
    try {
      setLoading(true);
      const response = await getUsersFilterApi(auth.token, filtro);
      setLoading(false);
      setUsers(response);
    } catch (error) {
      setLoading(false);
      seterror(error);
    }
  };

  const addUser = async (data, role, estatus, is_active, is_staff) => {
    try {
      setLoading(true);
      await addUserApi(data, role, estatus, is_active, is_staff);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      seterror(error);
    }
  };

  const updateUser = async (id, data) => {
    try {
      setLoading(true);
      await updateUserApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      seterror(error);
    }
  };

  const deleteUser = async (id) => {
    try {
      setLoading(true);
      await deleteUserApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      seterror(error);
    }
  };

  const sendMailRecover = async (data) => {
    try {
      await sendMailRecApi(data);
    } catch (error) {
      seterror(error);
    }
  };

  const resetPassword = async (id, data) => {
    try {
      await updatePassApi(id, data);
    } catch (error) {
      seterror(error);
      throw error;
    }
  };

  const addUserColaborador = async (data, role, estatus, is_active, is_staff) => {
    try {
      const response = await addUserColaboradorApi(data, role, estatus, is_active, is_staff);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const sendMailForNewUser = async (data) => {
    try {
      console.log(data)
      await sendMailNewUserApi(data, auth.token);
    } catch (error) {
      throw error;
    }
  }


  return {
    loading,
    error,
    users,
    getMe,
    getUsers,
    addUser,
    updateUser,
    deleteUser,
    sendMailRecover,
    resetPassword,
    addUserColaborador,
    getUsersFiltrados,
    sendMailForNewUser
  };
}
