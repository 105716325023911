import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";
import { comprobarFormaDatos } from "./CheckData";
export function FormaDatos(props) {
  const { setDatos, datos, setButtonNext } = props;
  const [errors, setErrors] = useState({});

  function getAtributes() {
    const array = [];
    Object.keys(datos).forEach((atributo) => {
      array.push(atributo);
    });
    return array;
  }
  const attributos = getAtributes();
  function comprobarDatosIngreso() {
    for (let i = 0; i < attributos.length; i++) {
      if (!datos[attributos[i]]) {
        setButtonNext(false);
        return;
      }
    }
  }
  useEffect(() => {
    comprobarDatosIngreso();
  }, [datos]);
  const handleInputChange = async (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
    Object.keys(datos).forEach((atributo) => {
      if (!datos[atributo]) {
        setButtonNext(false);
        return;
      }
    });
    await checkData();
  };

  const checkData = async () => {
    await comprobarFormaDatos
      .validate(datos, { abortEarly: false })
      .then(() => {
        console.log("Entre bien aqui");
        setButtonNext(true);
      })
      .catch((validationErrors) => {
        console.log("entre aqui como error de validacion");
        setButtonNext(false);
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
        console.log(newErrors);
      });
  };

  return (
    <Form id="frmDatos">
      <label className="text-start text-bold fs-5-5 text-resal mb-3">
        Revisa y edita la información del responsable del micrositio.
      </label>
      <br />
      <br />
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <IngresaDatosForm
            col="col-sm-12 col-md-12 col-xl-12 mb-3"
            tituloLabel="Nombre de usuario"
            formatoLabel="tituloIzquierda"
            nameControl="nombre"
            type="text"
            placeHolderControl="Nombre de usuario"
            valueControl={datos.nombre}
            onChangeControl={handleInputChange}
            floating
            rounded="50px"
          />
          {errors.nombre && (
            <div className="error fw-bold text-resal">{errors.nombre}</div>
          )}
          <IngresaDatosForm
            col="col-sm-12 col-md-12 col-xl-12 mb-3"
            tituloLabel="Correo"
            formatoLabel="tituloIzquierda"
            nameControl="email"
            type="text"
            placeHolderControl="Correo"
            valueControl={datos.email}
            onChangeControl={handleInputChange}
            floating
            rounded="50px"
          />
          {errors.email && (
            <div className="error fw-bold text-resal">{errors.email}</div>
          )}
          <IngresaDatosForm
            col="col-sm-12 col-md-12 col-xl-12 mb-3"
            tituloLabel="Telefono"
            formatoLabel="tituloIzquierda"
            nameControl="telefono"
            type="text"
            placeHolderControl="Telefono"
            valueControl={datos.telefono}
            onChangeControl={handleInputChange}
            floating
            rounded="50px"
          />
          {errors.telefono && (
            <div className="error fw-bold text-resal">{errors.telefono}</div>
          )}
        </Col>
        <Col sm={12} md={6}>
          <Row className="">
            <div className="col-sm-6 col-md-6 col-xl-6 mb-6">
              <IngresaDatosForm
                col=""
                tituloLabel="Fecha de inicio del evento"
                formatoLabel="tituloCentrado"
                nameControl="fecha_inicio"
                type="date"
                placeHolderControl="hora inicial"
                valueControl={datos.fecha_inicio}
                onChangeControl={handleInputChange}
                floating
                rounded="50px"
              />
              {errors.fecha_inicio && (
                <div className="error fw-bold text-resal">
                  {errors.fecha_inicio}
                </div>
              )}
            </div>
            <div className="col-sm-6 col-md-6 col-xl-6 mb-6">
              <IngresaDatosForm
                tituloLabel="Fecha fin del evento"
                formatoLabel="tituloCentrado"
                nameControl="fecha_fin"
                type="date"
                placeHolderControl="hora final"
                valueControl={datos.fecha_fin}
                onChangeControl={handleInputChange}
                floating
                rounded="50px"
              />
              {errors.fecha_fin && (
                <div className="error fw-bold text-resal">
                  {errors.fecha_fin}
                </div>
              )}
            </div>
          </Row>
          <Row style={{ paddingTop: 6 }}>
            <IngresaDatosForm
              col="col-sm-6 col-md-6 col-xl-6 mb-6"
              tituloLabel="Hora de inicio del evento"
              formatoLabel="tituloCentrado"
              nameControl="hora_inicio"
              type="time"
              placeHolderControl=""
              valueControl={datos.hora_inicio}
              onChangeControl={handleInputChange}
              floating
              rounded="50px"
            />
            <IngresaDatosForm
              col="col-sm-6 col-md-6 col-xl-6 mb-6"
              tituloLabel="Hora fin del evento"
              formatoLabel="tituloCentrado"
              nameControl="hora_fin"
              type="time"
              placeHolderControl=""
              valueControl={datos.hora_fin}
              onChangeControl={handleInputChange}
              floating
              rounded="50px"
            />
          </Row>
          <div style={{ paddingTop: 11 }}>
            <IngresaDatosForm
              col="col-sm-6 col-md-6 col-xl-6 mb-6"
              tituloLabel="Mesas disponibles"
              formatoLabel="tituloCentrado"
              nameControl="capacidad_personas"
              type="number"
              placeHolderControl="0"
              valueControl={datos.capacidad_personas}
              onChangeControl={handleInputChange}
              floating
              rounded="50px"
            />
            {errors.fecha_fin && (
              <div className="error fw-bold text-resal">{errors.fecha_fin}</div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
}
