import AOS from 'aos';
import 'aos/dist/aos.css';

const aosConfig = {
  duration: 1000, // duración de transición en milisegundos (0.5s)
  easing: 'ease-in-out', // función de temporización
  once: false, // si la animación solo se reproduce una vez
  disable: 'mobile',
  trigger: 'both'
};

export const initAOS = () => {
  AOS.init(aosConfig);
};
