import { format } from "date-fns";

const meses = [
  "",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export function formatDate(date) {
  //const dateConvert = date.split(/([-TZ:.])+/);
  //return `${dateConvert[4]}/${dateConvert[2]}/${dateConvert[0]}`;
  if (date) {
    const dateConvert = new Date(date);
    /*var isoDate = new Date(
      dateConvert.getTime() + dateConvert.getTimezoneOffset() * 60000
    );*/
    return format(dateConvert, "yyyy-MM-dd HH:mm:ss");
  } else {
    return null;
  }
}

export function formatDateNow() {
  const dateConvert = new Date();
  return format(dateConvert, "yyyy-MM-dd HH:mm:ss");
}

export function formatDateHours(date) {
  //const dateConvert = date.split(/([-TZ:.])+/);
  //return `${dateConvert[4]}/${dateConvert[2]}/${dateConvert[0]} ${dateConvert[6]}:${dateConvert[8]} Hrs`;
  if (date) {
    const dateConvert = new Date(date);
    return format(dateConvert, "HH:mm");
  } else {
    return null;
  }
}

export function formatDateMonthName(date) {
  if (date) {
    const dateConvert = new Date(date);
    return format(dateConvert, "yyyy-MM-dd");
  } else {
    return null;
  }
}
  
export function formatDateMonthNameWYear(date) {
    if (date) {
      const dateConvert = new Date(date);
      return format(dateConvert, "dd MMM", { useAdditionalDayOfYearTokens: true });
    } else {
      return null;
    }
}
