import React, { useEffect } from "react";
import { Form, Button, Row } from "react-bootstrap";
import { useFormik } from "formik";

export function AddRequerimientosVendedor(props) {
  const { subCategoria2 } = props;

  return (
    <>
      <div></div>
    </>
  );
}

function initialValues(data) {
    return{
        id: "",
        nombre: "",
        descripcion: "",
        id_micrositio_cliente: "", 
    };
}
