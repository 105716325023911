import React, { useState, useEffect } from "react";
import { Row, Spinner } from "react-bootstrap";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";

import { ModalBasic } from "../../components/Common";
import { HeaderPage, TableRequestColaborador } from "../../components/Admin";
import {
  useCatalagos,
  useAuth,
  usePlanPago,
  useRequestClient,
} from "../../hooks";

export function RequestClient_Colaborador() {
  const { auth } = useAuth();
  const {
    loading,
    getServiciosSolicitadosIdColaborador,
    serviciosSolicitados,
  } = useRequestClient();
  const [idCliente, setIdCliente] = useState(null);

  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setsizeModal] = useState("xl");
  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    const fetchData = async () => {
      getServiciosSolicitadosIdColaborador(auth.me["id"]);
    };
    fetchData();
  }, [idCliente, refetch]);

  return (
    <>
      <Row>
        <HeaderPage title="Gestión de reportes" />
      </Row>
      <br />
      <br />
      <Row>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">
                Obteniendo información .....
              </span>
            </Spinner>
          </div>
        ) : (
          <TableRequestColaborador
            serviciosSolicitados={serviciosSolicitados}
            onRefetch={onRefetch}
          />
        )}
      </Row>
      <Row></Row>

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );

  function AsignarIDCliente(clienteIdUser) {
    if (clienteIdUser) return clienteIdUser["id"];
    else return 0;
  }
}
