import { BASE_API } from "../utils/constants";

export async function addCarouselApi(data, token) {
  try {
    const formData = new FormData();
    formData.append("descripcion", data.descripcion);
    formData.append("imagen", data.imagen);
    formData.append("fecha_alta", obtenerTiempo());
    const url = `${BASE_API}/api/carousel/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getCarouselApi() {
  try {
    const url = `${BASE_API}/api/carousel/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getCarouselIdApi(id) {
  try {
    const url = `${BASE_API}/api/carousel/${id}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function editCarouselApi(data, id, token) {
  try {
    console.log(obtenerTiempo());
    const formData = new FormData();
    formData.append("descripcion", data.descripcion);
    if (data.imagen) formData.append("imagen", data.imagen);
    formData.append("fecha_alta", obtenerTiempo());
    formData.append('link', data.link);
    const url = `${BASE_API}/api/carousel/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

function obtenerTiempo() {
  const fecha = new Date().toISOString();
  return fecha;
}
