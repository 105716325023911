import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";


import { GCP_KEY } from "../../../../../utils/constants";
import { IngresaDatosForm } from "../../../../../components/Generales/IngresaDatosForm/IngresaDatosForm";

export function FormaContacto({ informacionContacto }) {
  const { contacto, setContacto } = informacionContacto;

  const [embedURL, setEmbedURL] = useState('');
  const [direccion, setDireccion] = useState('');
  const [mapa, setMapa] = useState(null);
  const [marker, setMarker] = useState(null);

  console.log(direccion);
  console.log(mapa);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GCP_KEY}&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = initMap;

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function initMap() {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: { lat: 19.433122389530244, lng: - 99.13373430048318 }

    });

    const marker = new window.google.maps.Marker({
      map: map,
      position: { lat: 19.433122389530244, lng: - 99.13373430048318 },
      draggable: true // Permitir que el marcador sea arrastrable
    });

    setMapa(map);
    setMarker(marker);

    // Evento que se dispara cuando el marcador se arrastra y se suelta
    window.google.maps.event.addListener(marker, 'dragend', function () {
      updateAddress(marker.getPosition());
    });
  }

  function geocodeAddress(address) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, function (results, status) {
      if (status === 'OK') {
        mapa.setCenter(results[0].geometry.location);
        marker.setPosition(results[0].geometry.location);
        updateAddress(results[0].geometry.location);
        generateEmbedURL(results[0].geometry.location.lat(), results[0].geometry.location.lng());
      } else {
        alert('La geocodificación falló debido a: ' + status);
      }
    });
  }

  function updateAddress(location) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ 'location': location }, function (results, status) {
      if (status === 'OK') {
        if (results[0]) {
          setDireccion(results[0].formatted_address);
        } else {
          alert('No se encontraron resultados para esta ubicación.');
        }
      } else {
        alert('La geocodificación inversa falló debido a: ' + status);
      }
    });
  }

  function generateEmbedURL(lat, lng) {
    const embedURL = `https://www.google.com/maps/embed/v1/view?q=${lat},${lng}`;
    setEmbedURL(embedURL);
  }

  function handleSubmit(event) {
    event.preventDefault();
    geocodeAddress(direccion);
  }



  const handleChange = (event) => {
    const link = event.target.value;
    try {

      setContacto({
        ...contacto,
        ["api_google"]: embedURL,
      });
    } catch (error) { }
  };
  const handleInputChange = (event) => {
    setContacto({
      ...contacto,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <div>
      <label className="d-flex justify-content-start text-bold fs-5-5 texlgt-resal mb-3">
        Registro de contacto
      </label>
      <Form style={{ padding: 20 }}>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="6" xxl="6">
            <label
              className="d-flex justify-content-start text-bold fs-5-5 mb-3"
              style={{ color: "#8ec93d" }}
            >
              Dirección
            </label>
            <Row>
              <Col xs="1" sm="1" md="1" lg="1" xl="1" xxl="1">
                <i
                  className="fa-solid fa-location-dot fa-xl"
                  style={{ color: "#77767b", paddingTop: 35, paddingLeft: 2 }}
                ></i>
              </Col>
              <IngresaDatosForm
                col="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11"
                nameControl="direccion"
                type="text"
                valueControl={direccion}
                onChangeControl={(e) => setDireccion(e.target.value)}
                placeHolderControl="Agrega una dirección"
                rounded="15px"
                id="address"
              />
            </Row>
            <Row>
              <Col xs="1" sm="1" md="1" lg="1" xl="1" xxl="1">
                <i
                  className="fa-solid fa-envelope fa-xl"
                  style={{ color: "#77767b", paddingTop: 35 }}
                ></i>
              </Col>
              <IngresaDatosForm
                col="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11"
                nameControl="email"
                type="text"
                placeHolderControl="Agrega un correo electrónico"
                valueControl={contacto.email}
                onChangeControl={handleInputChange}
                rounded="15px"
              />
            </Row>
            <Row>
              <Col xs="1" sm="1" md="1" lg="1" xl="1" xxl="1">
                <i
                  className="fa-solid fa-mobile-screen fa-xl"
                  style={{ color: "#77767b", paddingTop: 35, paddingLeft: 3 }}
                ></i>
              </Col>
              <IngresaDatosForm
                col="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11"
                nameControl="telefono"
                type="text"
                placeHolderControl="Agrega un teléfono"
                valueControl={contacto.telefono}
                onChangeControl={handleInputChange}
                rounded="15px"
              />
            </Row>
            <Row>
              <Col xs="1" sm="1" md="1" lg="1" xl="1" xxl="1">
                <i
                  className="fa-solid fa-link fa-xl"
                  style={{ color: "#77767b", paddingTop: 35 }}
                ></i>
              </Col>
              <IngresaDatosForm
                col="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11"
                nameControl="api_google"
                type="text"
                placeHolderControl="Agrega ubicación por google Maps"
                valueControl={embedURL}
                rounded="15px"
                onChangeControl={handleChange}
                disabled
              />
            </Row>
            <br />
            <center>
              <div style={{ width: 250 }}>
                <Button
                  onClick={handleSubmit}
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "#8ec93d",
                    borderColor: "#8ec93d",
                  }}
                >
                  Mostrar ubicación
                </Button>
              </div>
            </center>
            <br />

            <div id="map" style={{ height: '400px', width: '100%' }}></div>

            <br />
          </Col>
          <Col xs="12" sm="12" md="12" lg="12" xl="6" xxl="6">
            <label
              className="d-flex justify-content-start text-bold fs-5-5 mb-3"
              style={{ color: "#8ec93d" }}
            >
              Contáctanos
            </label>
            <IngresaDatosForm
              nameControl="No-data"
              type="text"
              placeHolderControl="Nombre"
              rounded="15px"
              disabled={true}
            />
            <IngresaDatosForm
              nameControl="No-data"
              type="text"
              placeHolderControl="Teléfono"
              rounded="15px"
              disabled={true}
            />
            <IngresaDatosForm
              nameControl="No-data"
              type="text"
              placeHolderControl="Correo"
              rounded="15px"
              disabled={true}
            />
            <IngresaDatosForm
              nameControl="No-data"
              type="text"
              placeHolderControl="Dirección"
              rounded="15px"
              disabled={true}
            />
            <IngresaDatosForm
              nameControl="No-data"
              type="text"
              placeHolderControl="Asunto"
              rounded="15px"
              disabled={true}
            />
            <IngresaDatosForm
              nameControl="No-data"
              type="text"
              placeHolderControl="Mensaje"
              rounded="15px"
              disabled={true}
              rows={4}
            />
            <br />
            <center>
              <div style={{ width: 120 }}>
                <Button
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "#8ec93d",
                    borderColor: "#8ec93d",
                  }}
                >
                  enviar
                </Button>
              </div>
            </center>
          </Col>
        </Row>
      </Form>
      <div>
        <h1>Mapa de Google Maps desde dirección (React)</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="address">Ingrese una dirección:</label>
          <input type="text" id="address" name="address" value={direccion} onChange={(e) => setDireccion(e.target.value)} required />
          <button type="submit">Mostrar Mapa</button>
        </form>
      </div>
    </div>
  );
}
