import React from "react";
import { ContactoFrm, DatosCompania } from "../../components/Client";
import { TituloPrincipal } from "../../components/Common";
import { Row, Col } from "react-bootstrap";
import './Contactanos/Contactanos.scss'

export function Contactanos() {
  return (
    <>
      <Row>
        <TituloPrincipal titulo={"Contacto"} />
      </Row>
      <div className="mt-3 mx-3 contenedor_contactanos ">
        <Col className="datosCompania animacionSomn_c">
          <DatosCompania />
        </Col>
        <Col className="datosForm animacionSomn_f">
          <ContactoFrm />
        </Col>
      </div>
    </>
  );
}
