import React from "react";
import { map } from "lodash";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

import { ModalVerMas } from "../../../Common/";
import { formatDateMonthName, datosEstaticosOTS } from "../../../../metodosGlobales"
import "./TableColaboradores.scss"

export function TableColaboradores(props) {
  const { colaboradores, updateColaborador, onDeleteColaborador } = props;
  console.log(colaboradores);
  return (
    <div className="table-responsive-xl">
      <Table striped bordered hover className="table-colaborador-new table-sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Apellido Paterno</th>
            <th>Apellido Materno</th>
            <th>Institución</th>
            <th>Colaboración</th>
            <th>Estatus</th>
            <th>Fecha de alta</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {map(colaboradores, (colaborador, index) => (
            <tr key={index}>
              <td>{colaborador.id_colaborador.id}</td>
              <td>{colaborador.id_colaborador.user['nombre']}</td>
              <td>{colaborador.id_colaborador.user['apellido_paterno']}</td>
              <td>{colaborador.id_colaborador.user['apellido_materno']}</td>
              <td>{colaborador.id_colaborador.institucion}</td>
              <td>{<ModalVerMas title="Colaboración" content={colaborador.id_colaborador.colaboracion} />}</td>
              <td>{datosEstaticosOTS.estatusColaborador[colaborador.id_colaborador.estatus]}</td>
              <td>{formatDateMonthName(colaborador.id_colaborador.fecha_alta)}</td>

              <Actions
                colaborador={colaborador}
                updateColaborador={updateColaborador}
                onDeleteColaborador={onDeleteColaborador}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function Actions(props) {
  const { colaborador, updateColaborador, onDeleteColaborador } = props;
  return (
    <td>
      <Button
        size="sm"
        variant="success"
        className="table-users-new__boton btn-boton"
        onClick={() => updateColaborador(colaborador)}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Button>
      <Button
        size="sm"
        variant="danger"
        className="table-users-new__boton btn-boton"
        onClick={() => onDeleteColaborador(colaborador)}
      >
<FontAwesomeIcon className="eliminar-btn" icon={faXmark} />
      </Button>
    </td>
  );
}
