import React from "react";
import { LoginAdmin, RenuevaMembresia } from "../../pages/Admin";
import { Container, Row, Col } from "react-bootstrap";
import { TopMenu, LeftMenu } from "../../components/Admin";
import { useAuth } from "../../hooks";
import "./AdminLayout.scss";

export function AdminLayout(props) {
  const { children } = props;
  const { auth } = useAuth();

  if (!auth) return <LoginAdmin />;
  if (auth.me.role === 5 && auth.me.estatus === 3) return <RenuevaMembresia usuario={auth.me} />;

  return (
    <Container fluid className="admin-layout">
      <Row>
        <TopMenu />
      </Row>
      <Row>
        <div className="divi">
          <section className="layout-sidebar mt-3 ms-4">
            <LeftMenu />
          </section>
          <section className="layout-navbar">
            <div className="layout-tablas">
              <Row>{children}</Row>
            </div>
          </section>
        </div>
      </Row>
    </Container>
  );
}
