import React, { useState, useCallback, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import { Button, Form, Row, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { IngresaDatosForm } from "../../../Generales/IngresaDatosForm/IngresaDatosForm";
import "react-bootstrap-tagsinput/dist/index.css";
import { useSectoresN } from "../../../../hooks";
import { toast } from "react-toastify";
import "./AddEditSectorsForm.scss";

export function AddEditSectorsForm(props){
    const { onClose, onRefetch, sectores } = props;
    const [previewImage, setPreviewImage] = useState(sectores?.imagen || null);
    const { addSectorNoticia, updateSectorNoticia } = useSectoresN();

    const onDrop = useCallback(async (acceptedFile) => {
        const file = acceptedFile[0];
        await formik.setFieldValue("imagen", file);
        setPreviewImage(URL.createObjectURL(file));
        console.log(file);
      }, []);
    
      const { getRootProps, getInputProps } = useDropzone({
        accept: { "image/*": [".jpeg", ".jpg", ".png"] },
        noKeyboard: true,
        multiple: false,
        onDrop,
      });
      
      const formik = useFormik({
        initialValues: initialValues(sectores),
        validationSchema: Yup.object(sectores ? updateSchema() : newSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
          try {
            if (sectores) {
              await updateSectorNoticia(sectores.id, formValue);
              toast.success("Sectores actualizado");
            }
            else {
              await addSectorNoticia(formValue);
              toast.success("Se dio de alta un sector");
    
            }
            onRefetch();
            onClose();
            console.log("Datos Enviados");
            console.log(formValue);
          } catch (error) {
            console.error(error);
            toast.error(
              "Hubo problemas para el envio de datos por favor vuelva a intentarlo."
            );
          }
        },
      });
    

  return (
    <>
      <Form className="add-edit-alianzas-form" onSubmit={formik.handleSubmit}>
        <Form.Group>

        <Form.Label>Nombre(s)</Form.Label>
          <Form.Control
            className="add-edit-alianzas-input"
            name="descripcion"
            placeholder="Ingresar descripcion"
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            error={formik.errors.descripcion}
          />

          <Image className="add-edit-alianzas-foto" />
          <div className="add-edit-alianzas-contenedorFoto">
            <Form.Label>Imagen</Form.Label>
            <Image className="foto-tamanio" src={previewImage} />
            <Button
              className="add-edit-alianzas-input"
              variant={formik.errors.imagen && "danger"}
              {...getRootProps()}
            >
              <FontAwesomeIcon icon={faUpload} className="me-2" />
              {previewImage ? "Cambiar Imagen" : "Ingresar imagen"}
            </Button>
          </div>
          <input {...getInputProps()} />

          <div className="d-grid gap-2">
            <Button
              className="add-edit-alianzas-bc"
              type="submit"
              variant="outline-primary"
              size="lg"
            >
              {sectores ? "Actualizar" : "Crear"}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </>
  )
}


function initialValues(data) {
    return {
      imagen: "",
      descripcion: data?.descripcion || "",
    };
}
  
function newSchema() {
    return {
      imagen: Yup.string().required(true),
      descripcion: Yup.string().required(true),
    };
}
  
  function updateSchema() {
    return {
      imagen: Yup.string(),
      descripcion: Yup.string().required(true),
    };
  }
  
// export function AddEditSectorsForm(props) {
//     const { onClose, onRefetch, sectores } = props;//creo este no se ocupan sectores
//     const [state, setState] = useState(sectores?.descripcion || []);

//     const { addSectorNoticia, updateSectorNoticia } = useSectoresN();
//     const [previewImage, setPreviewImage] = useState(
//         sectores?.imagen ? sectores.imagen : null
//       );
//     const onDrop = useCallback(async (acceptedFile) => {
//         const file = acceptedFile[0];
//         await formik.setFieldValue("imagen", file);
//         setPreviewImage(URL.createObjectURL(file));
//       }, []);

//     const { getRootProps, getInputProps } = useDropzone({
//         accept: {
//           "image/*": [".jpeg", ".jpg", ".png"],
//         },
//         noKeyboard: true,
//         multiple: false,
//         onDrop,
//       });

//       useEffect(() => {
//         const fetchData = async () => {
//           formik.setFieldValue("descripcion", state);
//         };
//         fetchData();
//       }, [state]);

    
//       const formik = useFormik({
//         initialValues: initialValues(sectores),
//         validationSchema: Yup.object(sectores ? updateSchema() : newSchema()),
//         validateOnChange: false,
//         onSubmit: async (formvalue) => {
//           if (sectores) await updateSectorNoticia(sectores.id, formvalue);
//           else await addSectorNoticia(formvalue);
//           onRefetch();
//           onClose();
//         },
//       });
    
//   return (
//     <Form className="add-edit-new-form" onSubmit={formik.handleSubmit}>
//        <Row className="mb-6">
//         <IngresaDatosForm
//           col="col-12 col-sm-6 col-xl-4"
//           tituloLabel="Sector Noticias."
//           formatoLabel="tituloCentrado"
//           nameControl="descripcion"
//           type="text"
//           placeHolderControl="Ingresa el nombre del Sector:"
//           valueControl={formik.values.descripcion}
//           onChangeControl={formik.handleChange}
//           error={formik.errors.descripcion}
//           touched={formik.touched.descripcion}
//         />
//        </Row>

//        <Row className="mb-6 mt-3">
//         <div className="contenedor">
//           <Form.Group widths="equal">
//             <Form.Label className="titulo">Imagen.</Form.Label>
//             <Button type="button" {...getRootProps()}>
//               {previewImage ? "Cambiar imagen" : "Subir imagen"}
//             </Button>

//             <input {...getInputProps()} />
//             <Image src={previewImage} className="imagen" />
//           </Form.Group>
//         </div>
//       </Row>

//       <Button variant="primary" type="submit">
//         {sectores ? "Actualizar" : "Crear sector"}
//       </Button>

//     </Form>
//   );
// }
// function initialValues(data) {
//     return {
//       descripcion: data?.descripcion || "",
//       imagen: "",
//     };
// }

// function newSchema() {
//   return {
//     descripcion: Yup.string("El nombre no puede estar vacio").required(
//       "El nombre es requerido"
//     ),
//   };
// }

// function updateSchema() {
//     return {
//         descripcion: Yup.string("El nombre no puede estar vacio").required(
//             "El nombre es requerido"
//           ),
//     };
//   }