import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IngresaDatosForm } from "../../../../../components/Generales/IngresaDatosForm";
import "./BannerPrincipal.scss";

export function BannerPrincipal(props) {
  const { principal, setPrincipal } = props;
  console.log("asi guarda la imagen", principal.imagen);
  const [image, setImage] = useState(null);
  console.log("Principal", principal);
  // UseEffect para cargar la imagen al inicio y cuando cambie la imagen en principal
  useEffect(() => {
    // Verificar si hay una imagen en principal
    if (principal.imagen) {
      const objectURL = URL.createObjectURL(principal.imagen);
      setImage(objectURL);
    } else {
      setImage(null);
    }
  }, [principal.imagen]);

  const handleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file);
    setImage(objectURL);
    setPrincipal({
      ...principal,
      ["imagen"]: file,
    });
  };

  const handleInputChange = (event) => {
    setPrincipal({
      ...principal,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event) => {
    setPrincipal({
      ...principal,
      ["modalidad"]: event,
    });
  };

  return (
    <Row>
      <Row className="row-banner">
        <Col xs={6} md={4} className="col-label">
          <Form.Label className="d-flex justify-content-start text-bold text-resal mb-3">
            Banner Principal
          </Form.Label>
        </Col>
      </Row>
      <Row className="info-banner">
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="6"
          xxl="6"
          className="data-banner"
        >
          <label className="text-bold text-resal mb-3">
            Información del banner
          </label>
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="tituloEvento"
            type="text"
            placeHolderControl="Titulo de evento (100 caracteres)"
            valueControl={principal.tituloEvento}
            onChangeControl={handleInputChange}
            floating
          />
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="anfrition"
            type="text"
            placeHolderControl="Ponente o anfrition (50 caracteres)"
            valueControl={principal.anfrition}
            onChangeControl={handleInputChange}
            floating
          />
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="description"
            type="text"
            placeHolderControl="Descripcion del evento (100 caracteres)"
            valueControl={principal.description}
            onChangeControl={handleInputChange}
            floating
          />
          <Form.Select
            label="Modalidad"
            value={principal.modalidad}
            onChange={(e) => handleSelectChange(e.currentTarget.value)}
          >
            <option value="" disabled hidden>
              Modalidad
            </option>
            <option hidden></option>
            <option value="virtual">Virtual</option>
            <option value="presencial">Presencial</option>
            <option value="mixto">Mixto</option>
          </Form.Select>
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="direccion"
            type="text"
            placeHolderControl="Direccion del evento (100 caracteres)"
            valueControl={principal.direccion}
            onChangeControl={handleInputChange}
            floating
          />
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="enlace"
            type="text"
            placeHolderControl="Enlace externo (50 caracteres)"
            valueControl={principal.enlace}
            onChangeControl={handleInputChange}
            floating
          />
        </Col>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="6"
          xxl="6"
          className="image-banner"
        >
          <Form.Label className="d-flex text-bold text-resal mb-3">
            Color de banner
          </Form.Label>
          <div className="input-group mb-3">
            <input
              type="color"
              className="form-control"
              placeholder="#5d5f82"
              name="color"
              value={principal.color}
              onChange={handleInputChange}
              aria-describedby="button-addon2"
            />
          </div>
          <Form.Label className="d-flex text-bold text-resal mb-3">
            Imagen de banner
          </Form.Label>
          <div class="input-group mb-3">
            <input
              type="file"
              className="form-control"
              id="inputGroupFile01"
              onChange={handleChange}
            />
          </div>
          <div class="row-image input-group-append">
            <img
              src={image}
              alt={
                principal.imagen ? principal.imagen.name : "Imagen seleccionada"
              }
              width="100%"
              height="100%"
            />
          </div>
        </Col>
      </Row>
    </Row>
  );
}
