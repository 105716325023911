import { ColorTranslator } from 'colortranslator'

export function colorAssign(colorMax, colorMin) {

  if (colorMax === "lime") {
    const color = ColorTranslator.toHSL(colorMax)
    const colorsMax = assignColorDefault(color)
    return colorsMax
  }

  if (colorMin && colorMax) {
    const color1 = ColorTranslator.toHSL(colorMin)
    const color2 = ColorTranslator.toHSL(colorMax)
    const colorsMin = assignColor(color1, 5)
    const colorsMax = assignColor(color2, 5)
    return colorsMin.concat(colorsMax.reverse())
  }
  if (colorMax) {
    const color = ColorTranslator.toHSL(colorMax)
    const colorsMax = assignColor(color, 10)
    return colorsMax
  }
  return
}

function assignColor(color, num) {
  let dataColor = new ColorTranslator(color);
  let colors = [dataColor];
  for (let i = 0; i < num; i++) {
    colors.push(createColor(colors[i]))
  }
  return colors;
}

function createColor(dataColor) {
  const h = 0.1 * ((2)) + (10)
  const l = 0.1 * (-7) + 1
  const color = new ColorTranslator(`hsl(${dataColor.H + h}, ${dataColor.S}%, ${dataColor.L + l}%)`);
  return color
}

function assignColorDefault(color) {
  let dataColor = new ColorTranslator(color);
  let colors = [dataColor];
  for (let i = 0; i < 9; i++) {
    colors.push(createColorDefault(colors[i]))
  }
  return colors.reverse();
}

function createColorDefault(dataColor) {
  const color = new ColorTranslator(`hsl(${dataColor.H - 15}, ${dataColor.S}%, ${dataColor.L}%)`);
  return color
}

export function reglaDeTres(maxNum, numero) {
  const valor = Math.round((10 * numero) / maxNum)
  return valor - 1
}