import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { useExperienciaUsuario } from "../../../../../hooks";
import { ModalBasic } from "../../../../Common/ModalBasic";
import { Formulario } from "../Formulario";

export function Slider() {
    const { loading, experienciaC, getExperienciaCliente } = useExperienciaUsuario();
    const [showModal, setshowModal] = useState(false);
    const [iconModal, setIconModal] = useState(null);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [refetch, setRefetch] = useState(false);
    const [sizeModal, setsizeModal] = useState("sm");
    const openCloseModal = () => setshowModal((prev) => !prev);
    const onRefetch = () => setRefetch((prev) => !prev);

    useEffect(() => {
        getExperienciaCliente();
    }, [refetch]);

    const addFormulario = () => {
        setTitleModal(" Dejanos tú opinión");
        setIconModal(faComments);
        setsizeModal("xl");
        setContentModal(
            <Formulario
                onClose={openCloseModal}
                onRefetch={onRefetch}
            />
        );
        openCloseModal();
    };
    return (
        <>
            <div>
                <Button
                    onClick={addFormulario}
                    className="opiniones-button"
                >
                    <FontAwesomeIcon icon={faComments} className="me-2" />
                    DEJANOS TÚ OPINIÓN
                </Button>
            </div>

            <ModalBasic
                show={showModal}
                onClose={openCloseModal}
                icon={iconModal}
                title={titleModal}
                children={contentModal}
                size={sizeModal}
            />
        </>
    )
}
