import React from 'react'
import { Button, Card } from 'react-bootstrap'
import './ChartCluster.scss'

export function ChartCluster(props) {
    const { data, setDataC } = props

    const handleOnClick = () => {
        setDataC(null)
    }
    return (
        <div className='content'>
            <Card style={{ width: "14rem" }}>
                <Card.Body>
                    {setDataC && (
                        <div className='luis'>
                            <Button onClick={handleOnClick} variant="outline-danger" size='sm'><p>X</p></Button>
                        </div>
                    )}
                    <Card.Title>{data}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                        Card Subtitle
                    </Card.Subtitle>
                    <Card.Text>
                        Some quick example text to build on the card title and make
                        up the bulk of the card's content.
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export function ChartClusterLicenciatura(props) {

    const { escuela } = props;

    return (
        <div className='content'>
            <Card style={{ width: "14rem" }}>
                <Card.Header> {escuela.nombre} </Card.Header>
                <Card.Body>
                    <Card.Subtitle className="mb-2 text-muted">
                        Card Subtitle
                    </Card.Subtitle>
                    <Card.Text>
                        Some quick example text to build on the card title and make
                        up the bulk of the card's content.
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}