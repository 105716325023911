import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { IngresaDatosForm } from "../../../../../components/Generales/IngresaDatosForm";
import { Encabezado } from "../../../../../pages/Admin/B2b/FormaEdit/Inicio/Encabezado";
import "./Informacion.scss";

export function Informacion(props) {
  const { informacion, setInformacion, index, datos, inicio } = props;
  console.log("informacion ", informacion);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [selectedImage4, setSelectedImage4] = useState(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (informacion[index].infoCom.icono1) {
      const objectURL = URL.createObjectURL(informacion[index].infoCom.icono1);
      setSelectedImage1(objectURL);
    } else {
      setImage(null);
    }
  }, [informacion[index].infoCom.icono1]);

  useEffect(() => {
    if (informacion[index].infoCom.icono2) {
      const objectURL = URL.createObjectURL(informacion[index].infoCom.icono2);
      setSelectedImage2(objectURL);
    } else {
      setImage(null);
    }
  }, [informacion[index].infoCom.icono2]);

  useEffect(() => {
    if (informacion[index].infoCom.icono3) {
      const objectURL = URL.createObjectURL(informacion[index].infoCom.icono3);
      setSelectedImage3(objectURL);
    } else {
      setImage(null);
    }
  }, [informacion[index].infoCom.icono3]);

  useEffect(() => {
    if (informacion[index].infoCom.icono4) {
      const objectURL = URL.createObjectURL(informacion[index].infoCom.icono4);
      setSelectedImage4(objectURL);
    } else {
      setImage(null);
    }
  }, [informacion[index].infoCom.icono4]);

  const handleImageUpload = (event, setSelectedImage, name) => {
    const file = event.target.files[0];
    console.log("data ", file, " y ", name);
    const data = {
      file,
      target: { name },
    };
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    handleInputChange(data, "infoCom");
  };

  const handleFileSelect = (fileInputRef, setSelectedImage) => {
    fileInputRef.current.click();
  };

  const [isChecked1, setIsChecked1] = useState(!!informacion[index].titulo.color);
  const [isChecked2, setIsChecked2] = useState(!!informacion[index].titulo.imagen);

  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
    setIsChecked2(isChecked1);
    const newArray = [...informacion];
    newArray[index].titulo.imagen = "";
    setInformacion(newArray);
  };

  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
    setIsChecked1(isChecked2);
    const newArray = [...informacion];
    newArray[index].titulo.color = "";
    setInformacion(newArray);
  };

  const handleButtonClick = () => { };

  const handleChange = (event, prop) => {
    const file = event.target.files[0];
    const objectURL = file;
    const newArray = [...informacion];
    if (prop === "titulo") {
      newArray[index].titulo.imagen = objectURL;
    } else {
      newArray[index].informacionGen.imagen = objectURL;
    }
    setInformacion(newArray);
  };

  const handleInputChange = (event, prop) => {
    console.log("Entre con: ", event, " y ", prop);
    const newArray = [...informacion];
    if (prop === "titulo") {
      if (event.target.name == "titulo") {
        newArray[index].titulo.titulo = event.target.value;
      } else {
        newArray[index].titulo.color = event.target.value;
      }
    } else if (prop == "informacionGen") {
      if (event.target.name == "titulo") {
        newArray[index].informacionGen.titulo = event.target.value;
      } else {
        newArray[index].informacionGen.texto = event.target.value;
      }
    } else if (prop == "infoCom") {
      if (event.target.name == "titulo1") {
        newArray[index].infoCom.titulo1 = event.target.value;
      } else if (event.target.name == "titulo2") {
        newArray[index].infoCom.titulo2 = event.target.value;
      } else if (event.target.name == "titulo3") {
        newArray[index].infoCom.titulo3 = event.target.value;
      } else if (event.target.name == "titulo4") {
        newArray[index].infoCom.titulo4 = event.target.value;
      } else if (event.target.name == "texto1") {
        newArray[index].infoCom.texto1 = event.target.value;
      } else if (event.target.name == "texto2") {
        newArray[index].infoCom.texto2 = event.target.value;
      } else if (event.target.name == "texto3") {
        newArray[index].infoCom.texto3 = event.target.value;
      } else if (event.target.name == "texto4") {
        newArray[index].infoCom.texto4 = event.target.value;
      } else if (event.target.name === "icono1") {
        newArray[index].infoCom.icono1 = event.file;
      } else if (event.target.name === "icono2") {
        newArray[index].infoCom.icono2 = event.file;
      } else if (event.target.name === "icono3") {
        newArray[index].infoCom.icono3 = event.file;
      } else if (event.target.name === "icono4") {
        newArray[index].infoCom.icono4 = event.file;
      }
    }
    setInformacion(newArray);
  };

  return (
    <div>
      <div
        className="text-start fs-5-5 text-resal mb-3"
        style={{ whiteSpace: "nowrap" }}
      >
        *Si no se llena algún campo no aparecerá en la publicación
      </div>

      <div className="Container-general">
        <Encabezado
          color="green"
          showEditMessages={false}
          datos={datos}
          logo={inicio.logo}
        />
      </div>
      <Row className="Container-general">
        {/* Sección 1 */}
        <Row className="row-banner">
          <Col xs={6} md={4} className="col-label">
            <Form.Label className="text-start text-bold fs-5-5 text-resal mb-3">
              Titulo de sección
            </Form.Label>
          </Col>
        </Row>

        <div className="Container-general">
          <Row className="info-banner-edit">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                style={{
                  width: "300px",
                  height: "40px",
                  marginTop: "50px",
                  marginBottom: "80px",
                  marginLeft: "50px",
                }}
                name="titulo"
                value={informacion[index].titulo.titulo}
                onChange={(e) => handleInputChange(e, "titulo")}
                placeholder="Titulo de sección(100 caracteres)"
                floating
              />
            </div>
            <div className="col-md-6">
              <div className="input-group">
                <Form.Label className="d-flex text-bold text-resal mb-3">
                  Color de banner
                </Form.Label>
                <div className="input-group">
                  <div className="input-check input-group-text">
                    <input
                      className="form-check-input mt-0"
                      type="checkbox"
                      aria-label="Radio button for following text input"
                      checked={isChecked1}
                      onChange={handleCheckboxChange1}
                    />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with radio button"
                    name="color"
                    onChange={(e) => handleInputChange(e, "titulo")}
                    disabled={!isChecked1}
                  />
                  <button
                    className="Btn-Color"
                    type="button"
                    id="button-addon2"
                    onClick={handleButtonClick}
                    disabled={!isChecked1}
                  >
                    Escoge Color
                  </button>
                </div>
              </div>

              <div className="input-group">
                <Form.Label className="d-flex text-bold text-resal mb-3">
                  Imagen de fondo
                </Form.Label>
                <div className="input-group">
                  <div className="input-check input-group-text">
                    <input
                      className="form-check-input mt-0"
                      type="checkbox"
                      aria-label="Radio button for following text input"
                      value={informacion[index].titulo.imagen}
                      checked={isChecked2}
                      onChange={handleCheckboxChange2}
                    />
                  </div>
                  <input
                    type="file"
                    class="form-control"
                    id="inputGroupFile03"
                    aria-describedby="inputGroupFileAddon03"
                    aria-label="Upload"
                    onChange={(e) => handleChange(e, "titulo")}
                    disabled={!isChecked2}
                  />
                </div>
              </div>
            </div>
          </Row>
        </div>

        {/*Sección 2 */}
        <Row className="row-banner" style={{ marginTop: "30px" }}>
          <Col xs={6} md={4} className="col-label">
            <Form.Label className="text-start text-bold fs-5-5 text-resal mb-3">
              Información general
            </Form.Label>
          </Col>
        </Row>

        <div>
          <Row className="info-banner-edit">
            <Col xs={12} className="d-flex justify-content-center">
              <input
                type="text"
                className="form-control"
                style={{ height: "40px" }}
                name="titulo"
                value={informacion[index].informacionGen.titulo}
                onChange={(e) => handleInputChange(e, "informacionGen")}
                placeholder="Titulo (300 caracteres)"
              />
            </Col>
            <Col xs={12} className="d-flex justify-content-center mt-2">
              <textarea
                className="form-control"
                placeholder="Texto (2500 caracteres)"
                style={{ width: "100%", height: "150px" }}
                name="texto"
                value={informacion[index].informacionGen.texto}
                onChange={(e) => handleInputChange(e, "informacionGen")}
              ></textarea>
            </Col>
            <Col
              xs={6}
              className="data-banner d-flex justify-content-center align-items-center"
            >
              <div className="col-md-6"></div>
            </Col>

            <Col
              xs={6}
              className="data-banner d-flex justify-content-start align-items-center"
              style={{ marginRight: "50px", padding: "10px" }}
            >
              <Form.Label className="d-flex text-bold text-resal mb-3">
                Imagen
              </Form.Label>
              <div className="input-group mb-3">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile01"
                  onChange={(e) => handleChange(e, "informacionGen")}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col
          xs={4}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <div className="row-icono">
            {selectedImage1 && (
              <img
                src={selectedImage1}
                alt={
                  informacion.infoCom
                    ? informacion.infoCom.icono1
                    : "Imagen seleccionada"
                }
                width="100%"
                height="100%"
              />
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef1}
            style={{ display: "none" }}
            accept="image/*"
            onChange={(event) =>
              handleImageUpload(event, setSelectedImage1, "icono1")
            }
          />
          <Button
            className="boton-agregar-icono"
            onClick={() => handleFileSelect(fileInputRef1, setSelectedImage1)}
          >
            Seleccione icono
          </Button>
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="titulo1"
            type="text"
            placeHolderControl="Titulo (100 caracteres)"
            valueControl={informacion[index].infoCom.titulo1}
            onChangeControl={(e) => handleInputChange(e, "infoCom")}
            floating
          />
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="texto1"
            type="text"
            placeHolderControl="Texto (300 caracteres)"
            valueControl={informacion[index].infoCom.texto1}
            onChangeControl={(e) => handleInputChange(e, "infoCom")}
            rows={3}
            floating
          />
        </Col>
        <Col
          xs={4}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="row-icono">
            {selectedImage2 && (
              <img
                src={selectedImage2}
                alt={
                  informacion.infoCom
                    ? informacion.infoCom.icono2
                    : "Imagen seleccionada"
                }
                width="100%"
                height="100%"
              />
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef2}
            style={{ display: "none" }}
            accept="image/*"
            onChange={(event) =>
              handleImageUpload(event, setSelectedImage2, "icono2")
            }
          />
          <Button
            className="boton-agregar-icono"
            onClick={() => handleFileSelect(fileInputRef2, setSelectedImage2)}
          >
            Seleccione icono
          </Button>
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="titulo"
            nameControl="titulo2"
            type="text"
            placeHolderControl="Titulo (100 caracteres)"
            valueControl={informacion[index].infoCom.titulo2}
            onChangeControl={(e) => handleInputChange(e, "infoCom")}
            floating
          />
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="texto2"
            type="text"
            placeHolderControl="Texto (300 caracteres)"
            valueControl={informacion[index].infoCom.texto2}
            onChangeControl={(e) => handleInputChange(e, "infoCom")}
            rows={3}
            floating
          />
        </Col>
        <Col
          xs={4}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="row-icono">
            {selectedImage3 && (
              <img
                src={selectedImage3}
                alt={
                  informacion.infoCom
                    ? informacion.infoCom.icono3
                    : "Imagen seleccionada"
                }
                width="100%"
                height="100%"
              />
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef3}
            style={{ display: "none" }}
            accept="image/*"
            onChange={(event) =>
              handleImageUpload(event, setSelectedImage3, "icono3")
            }
          />
          <Button
            className="boton-agregar-icono"
            onClick={() => handleFileSelect(fileInputRef3, setSelectedImage3)}
          >
            Seleccione icono
          </Button>
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="titulo3"
            type="text"
            placeHolderControl="Titulo (100 caracteres)"
            valueControl={informacion[index].infoCom.titulo3}
            onChangeControl={(e) => handleInputChange(e, "infoCom")}
            floating
          />
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="texto3"
            type="text"
            placeHolderControl="Texto (300 caracteres)"
            valueControl={informacion[index].infoCom.texto3}
            onChangeControl={(e) => handleInputChange(e, "infoCom")}
            rows={3}
            floating
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          lg={12}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <div className="row-icono">
            {selectedImage4 && (
              <img
                src={selectedImage4}
                alt={
                  informacion.infoCom
                    ? informacion.infoCom.icono4
                    : "Imagen seleccionada"
                }
                width="100%"
                height="100%"
              />
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef4}
            style={{ display: "none" }}
            accept="image/*"
            onChange={(event) =>
              handleImageUpload(event, setSelectedImage4, "icono4")
            }
          />
          <Button
            className="boton-agregar-icono"
            onClick={() => handleFileSelect(fileInputRef4, setSelectedImage4)}
          >
            Seleccione icono
          </Button>
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="titulo4"
            type="text"
            placeHolderControl="Titulo (100 caracteres)"
            valueControl={informacion[index].infoCom.titulo4}
            onChangeControl={(e) => handleInputChange(e, "infoCom")}
            floating
          />
          <IngresaDatosForm
            col="col-sm-8 col-md-8 col-xl-8 mb-3"
            formatoLabel="tituloIzquierda"
            nameControl="texto4"
            type="text"
            placeHolderControl="Texto (200 caracteres)"
            valueControl={informacion[index].infoCom.texto4}
            onChangeControl={(e) => handleInputChange(e, "infoCom")}
            rows={3}
            floating
          />
        </Col>
      </Row>
    </div>
  );
}
