import { BASE_API } from "../utils/constants";

export async function getEventosApi() {
  try {
    const url = `${BASE_API}/api/eventos/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getEventosTopApi() {
  try {
    const url = `${BASE_API}/api/eventos/top/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getEventoBuscadorTopApi(filtro) {
  try {
    const url = `${BASE_API}/api/eventos/eventosFiltroBuscadorTOP/${filtro}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getEventoBuscadorApi(filtro) {
  try {
    const url = `${BASE_API}/api/eventos/eventosFiltroBuscador/${filtro}/`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addEventoApi(data, token) {
  try {
    const formData = new FormData();
    formData.append("nombre", data.nombre);
    formData.append("descripcion", data.descripcion);
    formData.append("fecha_evento", data.fecha_evento);
    formData.append("hora_evento", data.hora_evento);
    formData.append("autor", data.autor);
    formData.append("keywords", data.keywords);
    formData.append("modalidad", data.modalidad);
    formData.append("sede", data.sede);
    formData.append("calle", data.calle);
    formData.append("numero", data.numero);
    formData.append("colonia", data.colonia);
    formData.append("ciudad", data.ciudad);
    formData.append("pais", data.pais);
    formData.append("cp", data.cp);
    formData.append("url", data.url);
    formData.append("imagen", data.imagen);

    console.log("formData::", data);

    const url = `${BASE_API}/api/eventos/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateEventoApi(id, data, token) {
  try {
    const formData = new FormData();
    formData.append("nombre", data.nombre);
    formData.append("descripcion", data.descripcion);
    formData.append("fecha_evento", data.fecha_evento);
    formData.append("hora_evento", data.hora_evento);
    formData.append("autor", data.autor);
    formData.append("modalidad", data.modalidad);
    formData.append("keywords", data.keywords);
    formData.append("sede", data.sede);
    formData.append("calle", data.calle);
    formData.append("numero", data.numero);
    formData.append("colonia", data.colonia);
    formData.append("ciudad", data.ciudad);
    formData.append("pais", data.pais);
    formData.append("cp", data.cp);
    formData.append("url", data.url);
    if (data.imagen) formData.append("imagen", data.imagen);

    const url = `${BASE_API}/api/eventos/${id}/`;
    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteEventoApi(id, token) {
  try {
    const url = `${BASE_API}/api/eventos/${id}`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
