import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

import { DetalleRecursoInformacion } from "../../components/Admin"
import { BackButton } from '../../components/Common'


export function RecursoDetalle() {

  const { id } = useParams();

  return (
    <div>
      <BackButton
        btnTitle="Regresar a los recursos"
        link="/admin/serviciosLicenciamiento/EstudiosInteligencia"
      />
      <br />
      <DetalleRecursoInformacion
        idRecurso={id}
      />
    </div>
  )
}
