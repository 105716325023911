import React, { useState, useEffect } from "react";
import {
  HeaderPage,
  TableUsers,
  AddEditUserForm,
  DeleteUserForm,
} from "../../components/Admin";
import { Spinner } from "react-bootstrap";
import { ModalBasic } from "../../components/Common";
import { useUser } from "../../hooks";
import {
  faUserPlus,
  faEdit,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";

export function UsersAdmin() {
  const { loading, users, getUsers } = useUser();
  const [showModal, setshowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [iconModal, setIconModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);


  useEffect(() => {
    getUsers();

  }, [refetch]);

  const openCloseModal = () => setshowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);
  const addUser = () => {
    setTitleModal(" Crear Usuario");
    setIconModal(faUserPlus);
    setContentModal(
      <AddEditUserForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  const updateUser = (data) => {
    setTitleModal("Actualizar usuario");
    setIconModal(faEdit);
    setContentModal(
      <AddEditUserForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        user={data}
      />
    );
    openCloseModal();
  };

  const onDeleteUser = async (data) => {
    setTitleModal("Eliminar usuario");
    setIconModal(faDeleteLeft);
    setContentModal(
      <DeleteUserForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        user={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <HeaderPage
        title="Usuarios"
        btnTitle="Nuevo Usuario"
        btnClick={addUser}
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              Obteniendo informacion .....
            </span>
          </Spinner>
        </div>
      ) : (
        users ? (
          <TableUsers
            users={users}
            updateUser={updateUser}
            onDeleteUser={onDeleteUser}
          />
        ) : (
          null
        )
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        icon={iconModal}
        title={titleModal}
        children={contentModal}
        size="xl"
      />
    </>
  );
}
