import React, { useEffect, useState } from 'react'

import { Button, Card, Form } from 'react-bootstrap'
import { Divider, DividerIcon } from '../../components/Common'
import { useCatalagos, useSectoresN } from '../../hooks'
import './TopSectoresNoticias.scss'

export function TopSectoresNoticias() {

    const { getTopSectores, topsectores, editTopSector } = useCatalagos()
    const { getSectoresN, sectoresNoti } = useSectoresN()

    const [refetch, setRefetch] = useState(false);
    const onRefetch = () => setRefetch((prev) => !prev);

    useEffect(() => {
        const getData = async () => {
            await getTopSectores()
            await getSectoresN()
        }
        getData()
    }, [refetch])

    const longitudTop = topsectores?.length




    return (
        <div>
            <DividerIcon titulo="Selección de los sectores a mostrar." />
            <br />
            <br />
            <div className='cardConteiner'>
                {
                    topsectores?.map((top, index) => (
                        <SectorCard
                            key={index}
                            card={top}
                            sectoresNoti={sectoresNoti}
                            onRefetch={onRefetch}
                            editTopSector={editTopSector}
                        />
                    ))
                }
            </div>
            <br />
            {
                longitudTop < 4
                    ?
                    <div className='cardConteiner'>
                        <SectorCard
                            id={longitudTop + 1}
                            sectoresNoti={sectoresNoti}
                            onRefetch={onRefetch}
                            editTopSector={editTopSector}
                        />
                    </div>
                    : null
            }
        </div>
    )
}

function SectorCard(props) {
    const {
        card,
        sectoresNoti,
        onRefetch,
        editTopSector,
        id,
    } = props
    const [sector, setSector] = useState(undefined);

    const updateTopSector = async (idTop, idSector) => {
        console.log(idTop, idSector);
        if (idSector > 0 && idSector) {
            try {
                await editTopSector(idTop, idSector)
                onRefetch()
            } catch (error) {
                onRefetch()
            }
        }
        else
            alert("Selecciona un sector.")

    }

    if (id)
        return (
            <Card style={{
                width: '17rem',
                height: '35rem'
            }}
            >
                <Card.Header className='text-center'>
                    Ingresa un sector
                </Card.Header>
                <Card.Body className='text-center selectSector'>
                    <Form.Select
                        onChange={(e) => setSector(e.target.value)}
                    >
                        <option>
                            Selecciona un sector
                        </option>
                        {
                            sectoresNoti?.map((sector_noticia, index) => (
                                <option key={index} value={sector_noticia.id}>
                                    {sector_noticia.descripcion}
                                </option>
                            ))
                        }
                    </Form.Select>
                    <Button style={{ width: "6rem" }} onClick={async () => await updateTopSector(id, sector)}>
                        cambiar
                    </Button>
                </Card.Body>
            </Card>
        )

    return (
        <Card style={{
            width: '17rem',
            height: '35rem'
        }}
        >
            <Card.Header className='text-center'>
                {card.id_sector_noticia.descripcion}
            </Card.Header>
            <Card.Img style={{ height: '18rem' }} variant="top" src={card.id_sector_noticia.imagen} />
            <Divider />
            <Card.Header>
                {card.id}
            </Card.Header>
            <Card.Body className='text-center selectSector'>
                <Form.Select
                    onChange={(e) => setSector(e.target.value)}
                >
                    <option>
                        Selecciona un sector
                    </option>
                    {
                        sectoresNoti?.map((sector_noticia, index) => (
                            <option key={index} value={sector_noticia.id}>
                                {sector_noticia.descripcion}
                            </option>
                        ))
                    }
                </Form.Select>
                <Button style={{ width: "6rem" }} onClick={async () => await updateTopSector(card.id, sector)}>
                    cambiar
                </Button>
            </Card.Body>
            <Card.Footer>
                Última modificación: {card.date_update.split('T')[0]}
            </Card.Footer>
        </Card>
    )
}

