import React, { useState, useEffect } from 'react'
import * as Yup from "yup";
import { map } from 'lodash';
import { useFormik } from "formik";
import { Form, Row, Button } from 'react-bootstrap'
import 'react-bootstrap-tagsinput/dist/index.css'
import { InputTags } from 'react-bootstrap-tagsinput'

import { useSolicitudServicios } from "../../../../../hooks"
import { IngresaDatosForm } from '../../../../Generales';
import { formatDateMonthName } from '../../../../../metodosGlobales';

export function AddEditTalentoEspecializadoCliente(props) {

  const { onClose, onRefetch, sectores, cliente, talentoEspecializado } = props
  const {
    editServicioGeneral, 
    addSolicitudPeticion
  } = useSolicitudServicios();
  const indicadores = talentoEspecializado ? Object.values(talentoEspecializado?.idSolicitudServicio?.indicadores) : [];
  const [state, setState] = useState(indicadores);

  useEffect(() => {
    const fetchData = async () => {
      formik.setFieldValue("indicadores", state);
    };
    fetchData()
  }, [state]);

  const formik = useFormik({
    initialValues: initialValues(talentoEspecializado),
    //validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formvalue) => {
      if (talentoEspecializado) await editServicioGeneral(formvalue, talentoEspecializado.idSolicitudServicio.id);
      else await addSolicitudPeticion(2, formvalue);
      onRefetch();
      onClose();
    },
  });


  return (
    <div>
      <Form className="" onSubmit={formik.handleSubmit}>
        <IngresaDatosForm
          tituloLabel={
            talentoEspecializado
              ? "Editar solicitud de Perfilamiento y colocación de talento especializado"
              : "Solicitud de Perfilamiento y colocación de talento especializado"
          }
          formatoLabel="tituloCentrado"
        />
        <br />
        <Row className="mb-6">
          <Form.Group className="col-12 col-sm-12 col-lg-6 col-xl-4">
            <IngresaDatosForm
              tituloLabel="Nombre del cliente"
              formatoLabel="tituloCentrado"
            />
            <Form.Control
              placeholder={`${cliente.user['nombre']} ${cliente.user['apellido_paterno']} ${cliente.user['apellido_materno']}`}
              disabled
            />
          </Form.Group>
          <Form.Group className="col-12 col-sm-12 col-lg-6 col-xl-4">
            <IngresaDatosForm
              tituloLabel="Correo electróncio"
              formatoLabel="tituloCentrado"
            />
            <Form.Control
              placeholder={`${cliente.user['email']}`}
              disabled
            />
          </Form.Group>
          <Form.Group className="col-12 col-sm-12 col-lg-6 col-xl-4">
            <IngresaDatosForm
              tituloLabel="Número de contacto"
              formatoLabel="tituloCentrado"
            />
            <Form.Control
              placeholder={`${cliente['telefono']}`}
              disabled
            />
          </Form.Group>
        </Row>
        <br />
        <Row>
          <Form.Group widths="equal" className="col-12 col-sm-12 col-lg-1" />
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-4 col-xl-3">
            <IngresaDatosForm
              tituloLabel="Enfoque del Sector en la petición:"
              formatoLabel="tituloCentrado"
            />
            <Form.Select
              name="sectores"
              defaultValue={formik.values.sectores}
              onChange={formik.handleChange}
            >
              {map(sectores, (sector, index) => (
                <option
                  key={index}
                  value={sector.id}
                >
                  {sector.descripcion}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group widths="equal" className="col-12 col-sm-12 col-lg-2 col-xl-4" />
          <Form.Group widths="equal" className="col-6 col-sm-6 col-lg-4 col-xl-3">
            <IngresaDatosForm
              tituloLabel="Fecha de subida:"
              formatoLabel="tituloCentrado"
            />
            <Form.Control
              placeholder={
                talentoEspecializado
                  ? formatDateMonthName(talentoEspecializado.idSolicitudServicio['fechaAlta'])
                  : formatDateMonthName(new Date().toISOString())
              }
              disabled
            />
          </Form.Group>
        </Row>
        <br />
        <IngresaDatosForm
          tituloLabel="Objetivo de la petición"
          formatoLabel="tituloCentrado"
          nameControl="objetivoServicio"
          rows={3}
          placeHolderControl="¿Qué quiere lograr con este servicio en su empresa?"
          valueControl={formik.values.objetivoServicio}
          onChangeControl={formik.handleChange}
          error={formik.errors.objetivoServicio}
          touched={formik.touched.objetivoServicio}
        />
        <br />
        <Form.Group>
          <IngresaDatosForm
            tituloLabel="indicadores"
            formatoLabel="tituloCentrado"
          />
          <div style={{ margin: 10 }}>
            <div className='input-group'>
              <InputTags
                values={state}
                onTags={(value) => setState(value.values)}
              />
            </div>
          </div>
        </Form.Group>
        <Button variant="primary" type="submit">
          Subir Solicitud
        </Button>

      </Form>
    </div>
  )
}

function initialValues(data) {
  return {
    objetivoServicio: data?.idSolicitudServicio?.objetivoServicio || "",
    indicadores: "",
    sectores: data?.idSolicitudServicio?.sectores['id'] || 1,
  };
}

function newSchema() {
  return {
    objetivoServicio: Yup.string("Debe de haber un objetivo")
      .required("Es necesario especificar el objetivo del reporte"),
    indicadores: Yup.string("Debe especificar un requerimiento")
      .required("Debe especificar un requerimiento"),
  };
}