import React from "react";
import "./ButtonLoad.scss";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export function ButtonLoad() {
  return (
    <div  className="d-grid gap-2">
      <Button className="button-load" variant="outline-primary" disabled>
        <FontAwesomeIcon icon={faSync} spin />
        Cargando ...
      </Button>
    </div>
  );
}
