import React, { useCallback, useState } from 'react';
import "./Formulario.scss"
import { Row, Col, Form, Image, Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useExperienciaUsuario } from "../../../../../hooks/useExperienciaUsuario";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export function Formulario(props) {
    const { addExperienciaCliente } = useExperienciaUsuario();
    const { onClose, onRefetch, experienciaC } = props;
    const [previewImage, setPreviewImage] = useState(experienciaC?.logo || null);

    const onDrop = useCallback(async (acceptedFile) => {
        const file = acceptedFile[0];
        await formik.setFieldValue("logo", file);
        setPreviewImage(URL.createObjectURL(file));
        console.log(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: { "image/*": [".jpeg", ".jpg", ".png"] },
        noKeyboard: true,
        multiple: false,
        onDrop,
    });

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: false,
        onSubmit: async (formValue, { resetForm }) => {
            try {
                await addExperienciaCliente(formValue);
                toast.success("El Registro fue enviado con éxito, nos pondremos en contacto contigo a la brevedad");
                onRefetch();
                onClose();
                console.log('Datos Enviados')
                console.log(formValue)
            } catch (error) {
                console.error(error);
                toast.error(error.message);
            }
            resetForm();
        },
    });
    return (
        <Row>
            <Col className='opiniones-form'>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Form.Group controlId="formNombre" className="col col-sm-6 col-lg-4 mb-1 mt-3">
                            <Form.Label>Nombres(s)</Form.Label>
                            <Form.Control
                                className="w-100"
                                type="text"
                                name="nombre"
                                placeholder='Ingresa los nombre(s)'
                                value={formik.values.nombre}
                                onChange={formik.handleChange}
                                error={formik.errors.nombre}
                                onBlur={formik.handleBlur}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.nombre && formik.errors.nombre ? (
                                    <div className='text-danger'>{formik.errors.nombre}</div>
                                ) : null}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formApellidoP" className="col col-sm-6 col-lg-4 mb-2 mt-3">
                            <Form.Label>Apellido Paterno</Form.Label>
                            <Form.Control
                                className="w-100"
                                type="text"
                                name="apellidoPaterno"
                                placeholder='Ingresa el apellido paterno'
                                value={formik.values.apellidoPaterno}
                                onChange={formik.handleChange}
                                error={formik.errors.apellidoPaterno}
                                onBlur={formik.handleBlur}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.apellidoPaterno && formik.errors.apellidoPaterno ? (
                                    <div className='text-danger'>{formik.errors.apellidoPaterno}</div>
                                ) : null}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formApellidoM" className="col col-sm-6 col-lg-4 mb-2 mt-3">
                            <Form.Label>Apellido Materno</Form.Label>
                            <Form.Control
                                className="w-100"
                                type="text"
                                name="apellidoMaterno"
                                placeholder='Ingresa el apellido materno'
                                value={formik.values.apellidoMaterno}
                                onChange={formik.handleChange}
                                error={formik.errors.apellidoMaterno}
                                onBlur={formik.handleBlur}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.apellidoMaterno && formik.errors.apellidoMaterno ? (
                                    <div className='text-danger'>{formik.errors.apellidoMaterno}</div>
                                ) : null}
                            </Form.Text>
                        </Form.Group>

                    </Row>
                    <Row>
                        <Form.Group controlId="formEmpresa" className="col col-sm-6 col-lg-4 mb-2 mt-3">
                            <Form.Label>Empresa</Form.Label>
                            <Form.Control
                                className="w-100"
                                type="text"
                                name="empresa"
                                placeholder='Ingresar la empresa'
                                value={formik.values.empresa}
                                onChange={formik.handleChange}
                                error={formik.errors.empresa}
                                onBlur={formik.handleBlur}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.empresa && formik.errors.empresa ? (
                                    <div className='text-danger'>{formik.errors.empresa}</div>
                                ) : null}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formPuesto" className="col col-sm-6 col-lg-4 mb-2 mt-3">
                            <Form.Label>Puesto</Form.Label>
                            <Form.Control
                                className="w-100"
                                type="text"
                                name="puesto"
                                placeholder='Ingresar el puesto'
                                value={formik.values.puesto}
                                onChange={formik.handleChange}
                                error={formik.errors.puesto}
                                onBlur={formik.handleBlur}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.puesto && formik.errors.puesto ? (
                                    <div className='text-danger'>{formik.errors.puesto}</div>
                                ) : null}
                            </Form.Text>
                        </Form.Group>
                    </Row>
                    <Row>

                        <Form.Group controlId="formComentario" className="col col-sm-6 col-lg-8 mb-2 mt-3">
                            <Form.Label>Tu Comentario:</Form.Label>
                            <Form.Control
                                className="w-100"
                                as="textarea"
                                rows="{5}"
                                name="comentario"
                                placeholder='Ingresar tú comentario'
                                value={formik.values.comentario}
                                onChange={formik.handleChange}
                                error={formik.errors.comentario}
                                onBlur={formik.handleBlur}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.comentario && formik.errors.comentario ? (
                                    <div className='text-danger'>{formik.errors.comentario}</div>
                                ) : null}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formLogo" className="col col-sm-6 col-lg-4 mb-2 mt-3">
                            <Image className="add-edit-experiencia-foto" />
                            <div className="add-edit-experiencia-contenedorFoto">
                                <Form.Label>Logo</Form.Label>
                                <Image className="foto-tamanio" src={previewImage} />
                                <Button
                                    className="add-edit-experiencia-input"
                                    variant={formik.errors.logo && "danger"}
                                    {...getRootProps()}
                                >
                                    <FontAwesomeIcon icon={faUpload} className="me-2" />
                                    {previewImage ? "Ingresar Logo" : null}
                                </Button>
                            </div>
                            <input {...getInputProps()} />
                        </Form.Group>
                    </Row>
                    <div className="col-md-12 text-center mt-3 mb-3">
                        <Button variant="primary" type="submit" className="w-25">
                            Enviar Opinión
                        </Button>
                    </div>
                </Form>
            </Col>
        </Row>
    )
}
function initialValues(data) {
    return {
        nombre: data?.nombre || "",
        apellidoPaterno: data?.apellidoPaterno || "",
        apellidoMaterno: data?.apellidoMaterno || "",
        empresa: data?.empresa || "",
        puesto: data?.puesto || "",
        comentario: data?.comentario || "",
        logo: "",
    }
}

function newSchema() {
    return {
        nombre: Yup.string()
            .required("Este campo es obligatorio"),
        apellidoPaterno: Yup.string()
            .required('Este campo es obligatorio'),
        apellidoMaterno: Yup.string(),
        empresa: Yup.string(),
        puesto: Yup.string(),
        comentario: Yup.string().required("Este campo es obligatorio y deberá contar con un espacio máximo para 200 caracteres."),
        logo: Yup.string()
    };
}
