import React, { useEffect } from "react";
import { map } from "lodash";
import "./EquipoPage.scss";
import { DividerIcon } from "../../../../components/Common";
import { Row, Col, Carousel, Image } from "react-bootstrap";
import { initAOS } from "../../../Common/AOS";

export function EquipoPage(props) {
  const { equipoOTS } = props;

  useEffect(() => {
    initAOS();
  }, []);

  return (
    <div className="equipo-page">
      <Row>
        <DividerIcon titulo="Nuestro Equipo" />
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={6} className="conteiner-titulo">
          <p
            align="center"
            className="equipo-titulo"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            Buscamos tener el mejor talento por lo que siempre estamos buscando
            las mejores herramientas, así como la capacitacion adecuada a
            nuestros integrantes de equipo.​
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="container-carousel col-sm-9 col-md-6">
          <div data-aos="zoom-in" data-aos-delay="200">
            <Carousel indicators={false}>
              {equipoOTS
                ? map(equipoOTS, (equipo, index) => (
                    <Carousel.Item key={index}>
                      <Image className="carousel-img" src={equipo.foto} />
                      <Carousel.Caption>
                        <h4 className="equipo-nombre">
                          {equipo.nombre} {equipo.apellido}
                        </h4>
                        <h5 className="equipo-puesto">
                          {equipo.descripcion_profesion}
                        </h5>
                        <p className="equipo-caracteristica">
                          {equipo.caracteristicas_persona}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))
                : null}
            </Carousel>
          </div>
        </Col>
      </Row>
    </div>
  );
}
