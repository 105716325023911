import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { HeaderPage } from "../../../components/Admin";
import { Divider, DividerIcon, ModalBasic } from "../../../components/Common";
import {
  DescripcionAyuda,
  FormAyuda,
  TableAyuda,
} from "../../../components/b2b/comprador";
import { useB2B } from "../../../hooks";
import "./B2B_Ayuda.scss";

export function B2B_Ayuda() {
  const { loading, getDataHelpToUser, userData } = useB2B();
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(undefined);
  const [titleModal, setTitleModal] = useState(undefined);

  const onRefetch = () => setRefetch((prev) => !prev);
  const openCloseModal = () => setShowModal((prev) => !prev);

  useEffect(() => {
    const getData = async () => {
      await getDataHelpToUser();
    };
    getData();
  }, [refetch]);

  const newRequest = () => {
    setContentModal(<FormAyuda />);
    setTitleModal("Nueva solicitud de ayuda");
    openCloseModal();
  };

  const showAyudaDescripcion = (data, estatus_ayuda) => {
    setContentModal(
      <DescripcionAyuda
        data={data}
        estatus_ayuda={estatus_ayuda}
        onClose={openCloseModal}
      />
    );
    setTitleModal("Descripción total del problema.");
    openCloseModal();
  };

  return (
    <div>
      <article className="conteiner_ayuda">
        <DividerIcon titulo="Centro de ayuda B2B" />
        <HeaderPage
          btnTitle="Generar nueva solicitud de ayuda"
          btnClick={() => newRequest()}
        />
        <br />
        <Divider />
        <br />
        <div>
          {loading ? null : (
            <TableAyuda
              data={userData}
              showAyudaDescripcion={showAyudaDescripcion}
            />
          )}
        </div>
      </article>
      <ModalBasic
        show={showModal}
        size="lg"
        icon={faNewspaper}
        title={titleModal}
        children={contentModal}
        onClose={openCloseModal}
      />
    </div>
  );
}
