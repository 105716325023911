import React from "react";
import { Card, CardGroup, Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

import "./MuestraCarousel.scss";
import { formatDateMonthName } from "../../../../metodosGlobales";
import logo from "../../../../images/Logo.png";

export function VistaCarousel(props) {
  const { carousel, addCarousel, updateFormCarousel, index } = props;

  return (
    <div>
      {
        carousel && (
          <CardGroup className="cartaPrincipal-cotenido">
            <Card key={index}>
              <Card.Img
                className="imagen w-75 rounded mt-2"
                variant="top"
                src={carousel.imagen ? carousel.imagen : logo}
              />
              <Card.Body className="contenedor-carta">
                <Card.Title>Imagen {index}</Card.Title>
                <Card.Text>{carousel.descripcion}</Card.Text>
                <Row className="mb-6">
                  <Card.Text className="col col-sm-5" />
                  <Button
                    variant="success"
                    className="col col-sm-2"
                    onClick={() => (carousel.fecha_alta ? updateFormCarousel(carousel) : addCarousel())}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </Button>
                  <Card.Text className="col col-sm-5" />
                </Row>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Última actualización {formatDateMonthName(carousel.fecha_alta)}</small>
              </Card.Footer>
            </Card>
          </CardGroup>
        )
        /*                 // :
                // <CardGroup className='cartaPrincipal-cotenido'>
                //     <Card key={index}>
                //         <Card.Img className='imagen' variant="top" src={logo} />
                //         <Card.Body className='contenedor-carta'>
                //             <Card.Title>Imagen {index}</Card.Title>
                //             <Card.Text>
                //                 Descripción de muestra
                //             </Card.Text>
                //             <Row className="mb-6">
                //                 <Card.Text className="col col-sm-5" />
                //                 <Button
                //                     variant="success"
                //                     className="col col-sm-2"
                //                     onClick={() => addCarousel()}
                //                 >
                //                     <FontAwesomeIcon icon={faPencil} />
                //                 </Button>
                //                 <Card.Text className="col col-sm-5" />
                //             </Row>
                //         </Card.Body>
                //         <Card.Footer>
                //             <small className="text-muted">Última actualización --</small>
                //         </Card.Footer>
                //     </Card>
                // </CardGroup> */
      }
    </div>
  );
}
